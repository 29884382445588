import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
// import Mapa from "../../../../../services/Mapa";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import FolderIcon from "@mui/icons-material/Folder";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CallIcon from "@mui/icons-material/Call";
import { grey } from "@mui/material/colors";
import ContactMailIcon from "@mui/icons-material/ContactMail";
// import Mapa from "./Mapa"
import MapaDomPedido from "./MapaDomPedido"

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -48%)",
  width: "890px",
  height: "515px",
  bgcolor: "#fff",
  border: "0.5px solid #000",
  boxShadow: 24,
};

function VerificacionDomicilio(props) {
  return (
    <div>
      <div>
        <Modal
          open={props.open}
          onClose={() => props.setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ zIndex: 2 }}
        >
          <Box sx={{ ...style2 }}>
            <Box
              backgroundColor="#1F618D"
              justifyContent="center"
              alignItems="center"
              p={1}
            >
              <Typography fontSize={16} variant="caption" color="#ffffff">
                Verificar Ubicación De Domicilio
              </Typography>
            </Box>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              // p={2}
            >
              {/* aca va a ir la info de la direccion */}
              <Grid xs={5}>
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <List dense>
                    <ListItem>
                      <ListItemIcon>
                        <LocationOnIcon color="error" />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${props.direccion.direccion_entrega}, ${props.direccion.localidad}, ${props.direccion.provincia}  (${props.direccion.nombre_direccion})`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <LocationCityIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Sucursal: ${
                          props.direccion.sucursal
                            ? props.direccion.sucursal
                            : "No Registrada"
                        }`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CallIcon color="success" />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Contacto: ${
                          props.direccion.telefono1
                            ? props.direccion.telefono1
                            : "No Registrado"
                        } ${
                          props.direccion.telefono2
                            ? " - " + props.direccion.telefono2
                            : " "
                        }  ${
                          props.direccion.telefono3
                            ? "-" + props.direccion.telefono3
                            : " "
                        }   `}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ContactMailIcon color="grey" />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Mails: ${
                          props.direccion.mails
                            ? props.direccion.mails
                            : "No Registrado"
                        }`}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
              <Grid xs={7}>
                <Grid container direction="column">
                  <MapaDomPedido
                    idDireccion={props.direccion.id}
                    handleClose={() => props.setOpen(false)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>

        </Modal>
      </div>
    </div>
  );
}

export default VerificacionDomicilio;
