//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :19/06/2024
// Version : 1
// Description : Actualizar precio
//------Changes ----------------------------------------------------------//
// Change Date : 15/8
// Change by : Candela Grosso
// Change description: Saque lso console.log y acmode los tityulos porque quedaban muuy arriba de la pantalla
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField,
  IconButton,
  Button,
  Autocomplete,
  Stack,
} from "@mui/material";
import Title from "../Title";
import { logEventos } from "../../services/services";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
//notificacion
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Actualizar_precio(props) {
  const [datoslista, setDatoslista] = useState({
    id_sucursal: null,
    id_segmento: null,
    precio_litro_con_iva: "",
    precio_litro_sin_iva: "",
    precio_kilo_con_iva: "",
    precio_kilo_sin_iva: "",
    vigencia: "",
  });

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  //boton
  const [disabledGuardar, setDisabledGuardar] = useState(false);

  //*********************************** FECHA FIN DE VIGENCIA ************************************************************************************//
  //Fecha fin vigencia
  const fechaVigencia = moment(datoslista.vigencia);
  const fechaFinVigencia = fechaVigencia.subtract(1, "day");
  const fechaFinVigenciaFormat =
    datoslista.vigencia === ""
      ? "dd/mm/aaaa"
      : fechaFinVigencia.format("DD/MM/YYYY");
  const fechaFinVigenciaPut = fechaFinVigencia.format("YYYY-MM-DD");
  //*********************************** OBTENER **************************************************************************************************//
  const [sucursal, setSucursal] = useState();
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState("");

  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setSucursal(data));
  };
  useEffect(() => {
    obtenerSucursales();
  }, []);

  const [segmento, setSegmento] = useState();
  const [segmentoSeleccionado, setSegmentoSeleccionado] = useState("");

  const obtenerSegmentos = () => {
    fetch(UrlApi + "/segmentos")
      .then((data) => data.json())
      .then((data) => setSegmento(data));
  };
  useEffect(() => {
    obtenerSegmentos();
  }, []);

  const [datosSucySeg, setDatosSucySeg] = useState();

  const obtenerXsucySeg = () => {
    fetch(
      UrlApi +
        "/lista_precios/xSucYseg/" +
        sucursalSeleccionada.id +
        "/" +
        segmentoSeleccionado.id
    )
      .then((data) => data.json())
      .then((data) => setDatosSucySeg(data));
  };
  useEffect(() => {
    obtenerXsucySeg();
  }, [sucursalSeleccionada, segmentoSeleccionado]);
  //*********************************** NOTIFICACION **************************************************************************************************//
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    handleClose();
  };
  //*********************************** ESTILO Y CLOSE ***********************************************************************************************//
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  const handleClose = () => {
    props.actualizar(false);
    props.tabla(true);
  };
  //*************************** GUARDAR ************************************************************************************************************//
  const guardarPrecio = async (e) => {
    logEventos(
      "Guardar actualización del precio",
      "puntos de venta",
      "Se guardo el precio actualizado",
      localStorage.getItem("user")
    );
    e.preventDefault();
    const response = await fetch(UrlApi + "/lista_precios", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_sucursal: sucursalSeleccionada.id,
        id_segmento: segmentoSeleccionado.id,
        precio_litro_con_iva: datoslista.precio_litro_con_iva,
        precio_litro_sin_iva: datoslista.precio_litro_sin_iva,
        precio_kilo_con_iva: datoslista.precio_kilo_con_iva,
        precio_kilo_sin_iva: datoslista.precio_kilo_sin_iva,
        vigencia: datoslista.vigencia,
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

    await fetch(UrlApi + "/lista_precios/" + datosSucySeg[0].id_lista, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fin_vigencia: fechaFinVigenciaPut,
      }),
    })
      .then((res) => {
        if (!res.err) {
          console.log("Guardado");
        } else {
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const almacenar = (e) => {
    setDatoslista({
      ...datoslista,
      [e.target.name]: e.target.value,
    });
  };
  //*************************** AUMENTO EN PESO *******************************************************************************************************//
  const [desactivarPorcentaje, setDesactivarPorcentaje] = useState(false);
  const [desactivarPesos, setDesactivarPesos] = useState(false);

  const [aumento, setAumento] = useState("");

  const handleAumentoChange = (e) => {
    const nuevoAumento = e.target.value;
    setAumento(nuevoAumento);
    setDesactivarPorcentaje(nuevoAumento !== "");

    if (!isNaN(nuevoAumento) && nuevoAumento !== "") {
      setDatoslista({
        ...datoslista,
        precio_litro_con_iva:
          datosSucySeg[0].precio_litro_con_iva + parseFloat(nuevoAumento),
        precio_litro_sin_iva:
          datosSucySeg[0].precio_litro_sin_iva + parseFloat(nuevoAumento),
        precio_kilo_con_iva:
          datosSucySeg[0].precio_kilo_con_iva + parseFloat(nuevoAumento),
        precio_kilo_sin_iva:
          datosSucySeg[0].precio_kilo_sin_iva + parseFloat(nuevoAumento),
      });
    } else {
      setDatoslista({
        ...datoslista,
        precio_litro_con_iva: datosSucySeg[0].precio_litro_con_iva,
        precio_litro_sin_iva: datosSucySeg[0].precio_litro_sin_iva,
        precio_kilo_con_iva: datosSucySeg[0].precio_kilo_con_iva,
        precio_kilo_sin_iva: datosSucySeg[0].precio_kilo_sin_iva,
      });
    }
  };

  const handlePrecioChange = (e) => {
    const nuevoPrecio = e.target.value;
    setDatoslista({
      ...datoslista,
      precio_litro_con_iva: nuevoPrecio,
      precio_litro_sin_iva: nuevoPrecio,
      precio_kilo_con_iva: nuevoPrecio,
      precio_kilo_sin_iva: nuevoPrecio,
    });
  };
  //*************************** AUMENTO EN PORCENTAJE **************************************************************************************************//
  const [porcentaje, setPorcentaje] = useState("");

  const handlePorcentajeChange = (e) => {
    const nuevoPorcentaje = e.target.value;
    setPorcentaje(nuevoPorcentaje);
    setDesactivarPesos(nuevoPorcentaje !== "");

    if (!isNaN(nuevoPorcentaje) && nuevoPorcentaje !== "") {
      const porcentajeNumerico = parseFloat(nuevoPorcentaje) / 100;

      const nuevoPrecioLitroConIva =
        datosSucySeg[0].precio_litro_con_iva * (1 + porcentajeNumerico);
      const nuevoPrecioLitroSinIva =
        datosSucySeg[0].precio_litro_sin_iva * (1 + porcentajeNumerico);
      const nuevoPrecioKiloConIva =
        datosSucySeg[0].precio_kilo_con_iva * (1 + porcentajeNumerico);
      const nuevoPrecioKiloSinIva =
        datosSucySeg[0].precio_kilo_sin_iva * (1 + porcentajeNumerico);

      setDatoslista({
        ...datoslista,
        precio_litro_con_iva: nuevoPrecioLitroConIva.toFixed(2),
        precio_litro_sin_iva: nuevoPrecioLitroSinIva.toFixed(2),
        precio_kilo_con_iva: nuevoPrecioKiloConIva.toFixed(2),
        precio_kilo_sin_iva: nuevoPrecioKiloSinIva.toFixed(2),
      });
    } else {
      setDatoslista({
        ...datoslista,
        precio_litro_con_iva: datosSucySeg[0].precio_litro_con_iva,
        precio_litro_sin_iva: datosSucySeg[0].precio_litro_sin_iva,
        precio_kilo_con_iva: datosSucySeg[0].precio_kilo_con_iva,
        precio_kilo_sin_iva: datosSucySeg[0].precio_kilo_sin_iva,
      });
    }
  };
  //*************************** HABILITAR Y DESHABILITAR BOTON GUARDAR *********************************************************************************//
  useEffect(() => {
    if ((aumento === "" && porcentaje === "") || datoslista.vigencia === "") {
      setDisabledGuardar(true);
    } else {
      setDisabledGuardar(false);
    }
  }, [aumento, porcentaje, datoslista.vigencia]);

  //*************************** FECHA MINIMA *********************************************************************************//
  const agregarUnDia = (fecha) => {
    return moment(fecha, 'DD/MM/YYYY').add(1, 'days').format('YYYY-MM-DD');
  };
  // Verifica y formatea la fecha mínima
  const fechaMinima = datosSucySeg && datosSucySeg[0] && datosSucySeg[0].vigencia ? agregarUnDia(datosSucySeg[0].vigencia) : '';

  return (
    <div>
      <Container>
        <Title titulo={"Actualizar Lista de Precios"} handleClose={handleClose}/>
        
        <form onSubmit={guardarPrecio} autoComplete="off">
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={6}>
                {sucursal && (
                  <Autocomplete
                    disabled={!sucursal}
                    size={size}
                    focused
                    disableClearable
                    id="sucursales"
                    noOptionsText={"Sucursal INEXISTENTE"}
                    options={sucursal}
                    autoHighlight
                    getOptionLabel={(sucursal) =>
                      sucursal.id + "-" + sucursal.nombre
                    }
                    onChange={(event, value) => setSucursalSeleccionada(value)}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id}-{option.nombre}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Sucursal"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {segmento && (
                  <Autocomplete
                    disabled={!segmento}
                    size={size}
                    focused
                    disableClearable
                    id="segmentos"
                    noOptionsText={"Segmento INEXISTENTE"}
                    options={segmento}
                    autoHighlight
                    getOptionLabel={(segmento) =>
                      segmento.id + "-" + segmento.segmento
                    }
                    onChange={(event, value) => setSegmentoSeleccionado(value)}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id}-{option.segmento}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Segmento"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              {datosSucySeg && datosSucySeg.length > 0 ? (
                <>
                  <Grid container justifyContent="left" sx={{ mt: 3 }}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: 19,
                          textDecoration: "underline",
                          textAlign: "left",
                        }}
                      >
                        Precio Vigente :
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="precio_litro_con_iva"
                        label="Precio litro con IVA"
                        variant="outlined"
                        value={datosSucySeg[0].precio_litro_con_iva || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="precio_litro_sin_iva"
                        label="Precio litro sin IVA"
                        variant="outlined"
                        value={datosSucySeg[0].precio_litro_sin_iva || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="precio_kilo_con_iva"
                        label="Precio kilo con IVA"
                        variant="outlined"
                        value={datosSucySeg[0].precio_kilo_con_iva || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="precio_kilo_sin_iva"
                        label="Precio kilo sin IVA"
                        variant="outlined"
                        value={datosSucySeg[0].precio_kilo_sin_iva || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="vigencia"
                        label="Entrada en Vigencia"
                        variant="outlined"
                        value={datosSucySeg[0].vigencia || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="vigencia"
                        label="Fin de Vigencia"
                        variant="outlined"
                        value={fechaFinVigenciaFormat || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container justifyContent="left" sx={{ mt: 3 }}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: 19,
                          textDecoration: "underline",
                          textAlign: "left",
                        }}
                      >
                        Aumento :
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="left"
                    alignItems="left"
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="secondary"
                        name="aumento_pesos"
                        label="Aumento en pesos"
                        variant="outlined"
                        value={aumento}
                        onChange={handleAumentoChange}
                        disabled={desactivarPesos}
                        onKeyPress={(event) => {
                          if (!/[-0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <AttachMoneyIcon position="start"></AttachMoneyIcon>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="secondary"
                        name="aumento_porcentaje"
                        label="Aumento en porcentaje"
                        variant="outlined"
                        value={porcentaje}
                        onChange={handlePorcentajeChange}
                        disabled={desactivarPorcentaje}
                        onKeyPress={(event) => {
                          if (!/[-0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <PercentIcon position="start"></PercentIcon>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container justifyContent="left" sx={{ mt: 3 }}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: 19,
                          textDecoration: "underline",
                          textAlign: "left",
                        }}
                      >
                        Precio Con Aumento :
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="precio_litro_con_iva"
                        label="Precio litro con IVA"
                        variant="outlined"
                        value={datoslista.precio_litro_con_iva}
                        onChange={() => {
                          handlePrecioChange();
                          handlePorcentajeChange();
                          almacenar();
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="precio_litro_sin_iva"
                        label="Precio litro sin IVA"
                        variant="outlined"
                        value={datoslista.precio_litro_sin_iva}
                        onChange={() => {
                          handlePrecioChange();
                          handlePorcentajeChange();
                          almacenar();
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="precio_kilo_con_iva"
                        label="Precio kilo con IVA"
                        variant="outlined"
                        value={datoslista.precio_kilo_con_iva}
                        onChange={() => {
                          handlePrecioChange();
                          handlePorcentajeChange();
                          almacenar();
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="precio_kilo_sin_iva"
                        label="Precio kilo sin IVA"
                        variant="outlined"
                        value={datoslista.precio_kilo_sin_iva}
                        onChange={() => {
                          handlePrecioChange();
                          handlePorcentajeChange();
                          almacenar();
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        type="date"
                        name="vigencia"
                        label="Entrada en Vigencia"
                        variant="outlined"
                        value={datoslista.vigencia}
                        onChange={almacenar}
                        inputProps={{
                          min: fechaMinima,
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Typography component="div" sx={{ fontSize: 19, mt: 3 }}>
                  "No hay datos con esa sucursal y segmento seleccionados."
                </Typography>
              )}
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>

              <Button
                variant="contained"
                type="submit"
                color="success"
                disabled={disabledGuardar}
              >
                Guardar Actualización
              </Button>
            </Grid>
          </Box>
        </form>
        {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
      </Container>
    </div>
  );
}

export default Actualizar_precio;
