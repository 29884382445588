import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Stack,
  Snackbar,
  TextField,
  Typography,
  Autocomplete,
  DialogContentText,
  Alert,
  IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { logEventos } from "../../services/services";

function Dialog_EditarVar(props) {
    const [openEditar, setOpenEditar] = useState(props.openEditar)
    const [rowVariables, setRowVariables] = useState(props.rowVariables)
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [openSnackEditar, setOpenSnackEditar] = React.useState(false);

    const [update, setUpdate] = useState(false);

    const guardarEditar = async (e) => {
      logEventos("Guardar edición de variable", "Stock Sucursales", "Se guardo la edición de la variable", localStorage.getItem("user"))
        console.log("Estoy por guardar");
        e.preventDefault();
        await fetch(UrlApi + "/stock_alertas/" + rowVariables.id_articulo + "/" + rowVariables.id_sucursal, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
             alerta:rowVariables.alerta,
             minimo:rowVariables.minimo
          }),
        })
          .then((res) => {
            if (!res.err) {
              setOpenSnack(true);
              // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
            //   setUpdate(true);
              // setMensajeConfirma(true)
              setColorMensaje("#D4EFDF");
              setMensaje("La variable se actualizó exitosamente !");
              console.log("Guardado");
            } else {
              setOpenSnack(false);
              setColorMensaje("#F1948A");
              setMensaje("Error al actualizar la variable");
              console.log("Error");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      };

    const handleCloseEditar = () => {
        setOpenEditar(!openEditar)
          if (props.onClose) {
              props.onClose();
            }
        };  

    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenSnack(false);
        handleCloseEditar()
      };    

    const almacenar = (e) => {
        setRowVariables({
          ...rowVariables,
          [e.target.name]: e.target.value,
        });
      };
  return (
    <>
    <Dialog
          open={openEditar}
          onClose={handleCloseEditar}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box
            style={{
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: "#e32712",
              color: "red",
            }}
          >
          <DialogTitle alignSelf="center">
            <Typography variant="h5" align="center" >
              Editar Variables de {rowVariables.sucursal}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Container maxWidth="md">
                
              <form onSubmit={guardarEditar} autoComplete="off">
                <Grid container mt={2} mb={1} spacing={1}>
                  <Grid item xs={6} py={1} mb={1}>
                    <TextField
                      label="Punto de Pedido"
                      name="alerta"
                      size="small"
                      placeholder="..."
                      inputProps={{
                          maxLength: 55,
                      }}
                      focused
                      required
                      fullWidth
                      value={rowVariables.alerta}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6} py={1} mb={1}>
                    <TextField
                      label="Stock Mínimo"
                      name="minimo"
                      size="small"
                      placeholder="1234"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 10,
                      }}
                      focused
                      required
                      fullWidth
                      value={rowVariables.minimo}
                      onChange={almacenar}
                    />
                  </Grid>
                </Grid>

                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleCloseEditar}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
          </Box>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
        </Dialog>

    </>
  )
}

export default Dialog_EditarVar