import React, { useEffect, useState } from 'react';
import { getData, postData, putData } from '../../../services/library';
import { Typography, Box, Button, Tooltip, IconButton, Grid, TableRow, Dialog, DialogTitle, DialogContent, TableContainer, Paper, Table, TableHead, TableBody, TextField, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Notify from '../../Notify';
import moment from 'moment';
import { logEventos } from '../../../services/services';;

export default function Modalobservaciones(props) {
    const [datos, setdatos] = useState(props.datos);
    const [observaciones, setObservaciones] = useState(null);
    const [almacenarobservaciones, setalmacenarobservaciones] = useState({
        id_pedidos: datos.id,
        observaciones: null,
        usuario: localStorage.getItem('user')
    });
    const [detalles, setDetalles] = useState();
    const [disbtn, setdisbtn] = useState(false);
    const [disbtnAprobar, setDisbtnAprobar] = useState(false);
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const obtenerObservacionesPedidos = () => {
        getData('/gestion/' + datos.id, setObservaciones);
    };

    const obtenerdetalles = async () => {
        getData('/pedidos/detalle/' + datos.id_cliente + "/" + datos.id, setDetalles);
    };

    useEffect(() => {
        obtenerObservacionesPedidos()
    }, [almacenarobservaciones.observaciones]);

    useEffect(() => {
        obtenerdetalles()
    }, [datos]);

    useEffect(() => {
        switch (datos.id_estado) {
            case 19:
                setdisbtn(false)
                setDisbtnAprobar(false)
                break;
            case 21:
                setdisbtn(false)
                setDisbtnAprobar(true)
                break;

            default:
                break;
        }
    }, [datos.id_estado]);

    //guardar la observacion del pedido
    const handleGuardarObservacion = (e) => {
        postData(e,
            '/gestion',
            {
               observaciones: almacenarobservaciones.observaciones,
               usuario:localStorage.getItem("user"),
               id_pedidos: datos.id
            },
            setNotificacion,
            obtenerObservacionesPedidos())
        logEventos("Guardar observaciones", "Gestion cta cte", "Gestion cta cte", localStorage.getItem('user'))
    };

    const handleCambiarEstado = (e) => {
        putData(e,
            '/pedidos/' + datos.id,
            {
                id_estado: 21,
                kgacargar_original: datos.kgacargar
            },
            setNotificacion,
            logEventos("Cambiar de estado de pedidos", "Gestion cta cte ", "Gestion cta cte", localStorage.getItem('user'))
        )
    };

    const handleClose = () => {
        props.open(false)
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#1F618D",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    //para el onchange
    const almacenar = (e) => {
        setalmacenarobservaciones({ ...almacenarobservaciones, [e.target.name]: e.target.value })
    };

    return (
        <Dialog open={props.open} onClose={handleClose} >
            <Box backgroundColor={"#1F618D"}>
                <DialogTitle>
                    <Typography variant="h5" align="center" color="#ffffff"> Gestion cuenta corriente pedido nro: {datos.id}</Typography>
                </DialogTitle>
            </Box>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table sx={{}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Cliente</StyledTableCell>
                                <StyledTableCell align="center">Producto</StyledTableCell>
                                <StyledTableCell align="center">Cantidad</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow
                                align="center"
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell align="center">{datos.cliente}</StyledTableCell>
                                <StyledTableCell align="center">{datos.nombre_producto}</StyledTableCell>
                                <StyledTableCell align="center">{datos.kgacargar}</StyledTableCell>
                            </StyledTableRow>

                        </TableBody>
                    </Table>

                    <Table sx={{}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Fecha</StyledTableCell>
                                <StyledTableCell align="center">Observaciones</StyledTableCell>
                                <StyledTableCell align="center">Usuario</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {observaciones && observaciones.map((row) => (
                                <StyledTableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {moment(row.created_at).format("DD-MM-YYYY")}
                                    </TableCell>
                                    <StyledTableCell align="center">{row.observaciones}</StyledTableCell>
                                    <StyledTableCell align="center">{row.usuario}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Table sx={{}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Pedido nro</StyledTableCell>
                                <StyledTableCell align="center">Dirección entrega</StyledTableCell>
                                <StyledTableCell align="center">Kg a cargar</StyledTableCell>
                                <StyledTableCell align="center">Estado</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {detalles && detalles.map((row) => (
                                <StyledTableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell align="center">{row.id}</StyledTableCell>
                                    <StyledTableCell align="center">{row.nombre_direccion}</StyledTableCell>
                                    <StyledTableCell align="center">{row.kgacargar}</StyledTableCell>
                                    <StyledTableCell align="center">{row.valor}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box>
                    <form autoComplete='off'>
                    <TextField
                        fullWidth
                        placeholder=" Observaciones"
                        name="observaciones"
                        focused
                        autoFocus
                        type="text"
                        margin="dense"
                        variant="standard"
                        color="primary"
                        value={almacenarobservaciones.observaciones}
                        onChange={almacenar}
                    /></form>
                </Box>
            </DialogContent>
            <DialogActions>
    <Grid container spacing={2} style={{ padding: 30 }} justifyContent="center"> 
        <Grid item>
            <Button
                variant="contained"
                style={{ backgroundColor: "#ff3333", color: "#ffffff", marginRight: "5%" }}
                onClick={handleClose}
            >
                Volver
            </Button>
        </Grid>

        <Grid item>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleGuardarObservacion}
            >
                Grabar comentario
            </Button>
        </Grid>

        <Grid item>
            <Button
                variant="contained"
                style={{ backgroundColor: "#288d1f", color: "#ffffff" }}
                onClick={handleCambiarEstado}
                disabled={disbtnAprobar}
            >
                Aprobar
            </Button>
        </Grid>
    </Grid>
</DialogActions>


            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            ) : null}
        </Dialog>
    )
}