import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Stack,
  Snackbar,
  TextField,
  Alert,
  Autocomplete,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment"; // PARA LA FECHA
//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS
import FileUploadStock from "./FileUploadStock";
//enviar mails
import { grey } from "@mui/material/colors";
import Title from "../Title";
import { logEventos } from "../../services/services";

function Dialog_ingresar(props) {
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [disabledGuardarTr, setDisabledGuardarTr] = useState(false);
  const [colorTextField, setcolorTextField] = useState();

  const [usuarioCompras, setUsuarioCompras] = useState([props.usuarioCompras]);
  const [datosArt, setDatosArt] = useState(props.datosArt)
  const [dialogCrearArticulo, setDialogCrearArticulo] = useState(props.dialogCrearArticulo);
  const [openCloseAdjDoc, setOpenCloseAdjDoc] = useState(false); //ADJUNTAR IMAGEN
  const [articuloSeleccionado, setArticuloSeleccionado] = useState({});
  const [proveedorSeleccionado, setProveedorSeleccionado] = useState({});
  const [almacenSeleccionado, setAlmacenSeleccionado] = useState({});

  //console.log("datos art", datosArt);

  //  sumar cantidad en ingreso
  const [cantidadInventario, setCantidadInventario] = useState(0);

  const handleCantidadInventario = (e) => {
    setCantidadInventario(e.target.value);
  };
  // CALCULO DEL TOTAL FINAL
  useEffect(() => {
    setCantidadInventario(
      inventario[0]
        ? parseInt(inventario[0].cantidad) +
            parseInt(datosArt.cantidad_recibida)
        : null
    );
  }, [datosArt.cantidad_recibida]);

  const [inventario, setInventario] = useState([]);

  //funcion para obtener sucursales
  const obtenerInventario = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/xalmart/" +
        almacenSeleccionado.id_almacen +
        "/" +
        articuloSeleccionado.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setInventario(data));
  };

  useEffect(() => {
    obtenerInventario();
  }, [almacenSeleccionado.id_almacen, articuloSeleccionado.id_articulo]);

  
  const guardarCrear = async (e) => {
    logEventos("Guardar ingreso de artículo a sucursal", "Stock Sucursales", "Se guardo el ingreso", localStorage.getItem("user"))
    e.preventDefault();
    await fetch(UrlApi + "/stock_transacciones", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_accion: 158,
        id_transaccion: datosArt.id_transaccion,
        id_proveedor: proveedorSeleccionado.id,
        id_articulo: articuloSeleccionado.id_articulo,
        almacen_destino: almacenSeleccionado.id_almacen,
        cantidad_recibida: datosArt.cantidad_recibida,
        detalle_transaccion: datosArt.detalle_transaccion,
        nro_remito_puntodeventa: datosArt.nro_remito_puntodeventa,
        nro_remito_numero: datosArt.nro_remito_numero,
        fecha_transaccion: moment(datosArt.fecha_transaccion).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        usuario: localStorage.getItem("user"),
        uuid_documentacion: props.newUuidDoc, //ADJUNTAR IMAGEN
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El artículo se guardó exitosamente !");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el artículo");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

    // ------ { AGREGAR SEGUNDO GUARDAR (?) } ------
    e.preventDefault();
    if (inventario.length === 0) {
      await fetch(UrlApi + "/stock_inventarios", {
        // PUT SI YA EXISTE / POST SI ES NUEVO
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_articulo: articuloSeleccionado.id_articulo,
          cantidad: datosArt.cantidad_recibida,
          id_almacen: almacenSeleccionado.id_almacen,
          usuario: localStorage.getItem("user"),
        }),
      })
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El artículo se guardó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      // handleClickOpenCrear();
    } else {
      await fetch(
        UrlApi +
          "/stock_inventarios/" +
          almacenSeleccionado.id_almacen +
          "/" +
          articuloSeleccionado.id_articulo,
        {
          // PUT SI YA EXISTE / POST SI ES NUEVO
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            cantidad: cantidadInventario,
            usuario: localStorage.getItem("user"),
          }),
        }
      )
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El artículo se guardó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    // PARA LIMPIAR LOS DATOS
    handleClose();
  };

  const handleClose = () => {
    setDialogCrearArticulo(!dialogCrearArticulo)
      if (props.onClose) {
          props.onClose();
        }
    };
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenSnack(false);
    };

  const handleOpenCloseAdjDoc = (value) => {
      setOpenCloseAdjDoc(value);
    };

  const almacenar = (e) => {
      setDatosArt({
        ...datosArt,
        [e.target.name]: e.target.value,
      });
    };

    const style = {
      backgroundColor: "#ffffff",
      width: "100%",
    };
    
  return (
    <>
    <Dialog
    open={dialogCrearArticulo}
    onClose={handleClose}
    maxWidth="md"
    fullWidth
  >
    <Title
            titulo={`Ingresar Artículo a la Sucursal`}
            handleClose={() => {
              handleClose()
            }}
          />

      <form onSubmit={guardarCrear} autoComplete="off">
    <Box
      border={0.5}
      borderColor={grey[300]}
      backgroundColor="#F4F6F6"
      p={2}
      py={2}
      mt={1}
    >
      <DialogContent>
            <Grid container mb={1} spacing={1}>
              <Grid item xs={6} py={1} mb={1}>
                <Autocomplete
                  disabled={!props.articulos}
                  size="small"
                  focused
                  disableClearable
                  id="stock_articulos"
                  noOptionsText={"Artículo INEXISTENTE"}
                  options={props.articulos}
                  autoHighlight
                  getOptionLabel={(articulos) =>
                    articulos.codigo + "-" + articulos.nombre
                  }
                  onChange={(event, value) =>
                    setArticuloSeleccionado(value)
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.id}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.codigo}-{option.nombre}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      style={style}
                      focused
                      required
                      size="small"
                      placeholder="Tipee para buscar..."
                      {...params}
                      label="Artículo"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} py={1} mb={1}>
                <Autocomplete
                  disabled={!props.proveedores}
                  size="small"
                  focused
                  disableClearable
                  id="proveedores"
                  noOptionsText={"Proveedor INEXISTENTE"}
                  options={props.proveedores}
                  autoHighlight
                  getOptionLabel={(proveedores) =>
                    proveedores.razon_social + "-" + proveedores.nro_cuit
                  }
                  onChange={(event, value) =>
                    setProveedorSeleccionado(value)
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.id}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.razon_social} - {option.nro_cuit}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      style={style}
                      focused
                      required
                      size="small"
                      placeholder="Tipee para buscar..."
                      {...params}
                      label="Proveedor"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} py={1} mb={1}>
                <Autocomplete
                  disabled={!props.almacenes}
                  size="small"
                  focused
                  disableClearable
                  id="stock_almacenes"
                  noOptionsText={"Almacén INEXISTENTE"}
                  options={props.almacenes}
                  autoHighlight
                  getOptionLabel={(almacenes) =>
                    almacenes.id_almacen + "-" + almacenes.nombre
                  }
                  onChange={(event, value) =>
                    setAlmacenSeleccionado(value)
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.id}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.id_almacen} - {option.nombre}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      style={style}
                      focused
                      required
                      size="small"
                      placeholder="Tipee para buscar..."
                      {...params}
                      label="Almacén"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8} mb={2}>
                <TextField
                  style={style}
                  label="Detalle Transaccion"
                  name="detalle_transaccion"
                  size="small"
                  placeholder="..."
                  inputProps={{
                    maxLength: 60,
                  }}
                  focused
                  required
                  fullWidth
                  value={datosArt.detalle_transaccion}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={2.8} mb={2}>
                <TextField
                  style={style}
                  label="N° Pto.Venta"
                  name="nro_remito_puntodeventa"
                  size="small"
                  placeholder="0000"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 4,
                  }}
                  focused
                  required
                  fullWidth
                  value={datosArt.nro_remito_puntodeventa}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={3} py={1} mb={1}>
                <TextField
                  style={style}
                  label="N° Remito"
                  name="nro_remito_numero"
                  size="small"
                  placeholder="00000123"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 8,
                  }}
                  focused
                  required
                  fullWidth
                  value={datosArt.nro_remito_numero}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={3.3} py={1} mb={1}>
                <TextField
                  style={style}
                  label="Cantidad en el Inventario"
                  size="small"
                  placeholder={cantidadInventario}
                  value={inventario[0] ? inventario[0].cantidad : ""}
                  inputProps={{
                    readOnly: true,
                    "aria-label": "Without label",
                  }}
                  onChange={cantidadInventario}
                />
              </Grid>
              <Grid item xs={2.9} py={1} mb={1}>
                <TextField
                  style={style}
                  label="Cantidad"
                  name="cantidad_recibida"
                  size="small"
                  placeholder="..."
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 6,
                  }}
                  focused
                  required
                  fullWidth
                  value={datosArt.cantidad_recibida}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={3.7} py={1} mb={1}>
                <TextField
                  style={style}
                  label="Fecha Transacción"
                  name="fecha_transaccion"
                  size="small"
                  type="date"
                  focused
                  required
                  fullWidth
                  value={moment(datosArt.fecha_transaccion).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={4} py={1} mb={1}>
                <Button
                  onClick={() => setOpenCloseAdjDoc(!openCloseAdjDoc)}
                  variant="outlined"
                  size="medium"
                  startIcon={<AttachFileIcon />}
                >
                  ADJUNTAR DOCUMENTACIÓN
                </Button>
              </Grid>
            </Grid>

            <Stack direction="row">
              <Grid container direction={{ xs: "column", sm: "row" }}>
                <Grid item xs={8}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Volver
                  </Button>
                </Grid>

                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    type="submit"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Stack>
            </DialogContent>
          </Box>
          </form>
          </Dialog>

  {openCloseAdjDoc ? ( // ADJUNTAR COMPROBANTESss
    <FileUploadStock
      fileId={props.newUuidDoc}
      openclose={true}
      mostrar={true}
      onClose={() => handleOpenCloseAdjDoc(false)} // Proporciona una función de cierre
    />
  ) : null}
  {/* GUARDAR AL CREAR */}
  <Stack spacing={2} sx={{ width: "100%" }}>
    <Snackbar
      open={openSnack}
      autoHideDuration={1500}
      onClose={handleCloseSnack}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleCloseSnack}
        severity="success"
        sx={{ width: "100%" }}
        style={{ backgroundColor: colormensaje, color: "black" }}
      >
        {mensaje}
      </Alert>
    </Snackbar>
  </Stack>
  </>
  )
}

export default Dialog_ingresar