import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DireccionesEntrega from "./direccionescliente/DireccionesEntrega";
import TablaTanques from "./tanquescliente/TablaTanques";
import ListContratos from "./contratos/ListContratos";
import PedidosCli from "./PedidosCli";
import DatosCli from "./DatosCli";
import Title from "../../../Title";
import ReclamosCli from "./reclamos/ReclamosCli";
import ImpuestosCliente from "./impuestos/ImpuestosCliente"

function FichaCliente(props) {
  const [datosformulario, setDatosformulario] = useState(props.datos);
  /************************************************************************************************/
  //Maneja TAB
  const [opcion, setOpcion] = useState("datos");
  const handleChange = (event, newValue) => {
    setOpcion(newValue);
  };

  //Cierra la ficha del cliente
  const handleClose = () => {
    props.ficha(false);
    props.tabla(true);
  };

  // console.log(datosformulario)

  return (
    <div>
      <Container maxWidth="xl">
        <Title
          titulo={` ${datosformulario.razon_social} (${
            datosformulario.sisven_id
              ? datosformulario.sisven_id
              : "Sisven Id No Registrado"
          })  `}
          handleClose={handleClose}
        />
        <Box sx={{ width: "100%" }}>
          <Tabs
            variant="fullWidth"
            value={opcion}
            onChange={handleChange}
            textColor="primary"
            indicatorColor={"primary"}
            aria-label="Clientes"
          >
            <Tab value="datos" label="Datos" />
            {datosformulario.situacion_iva==="1"&&(<Tab value="impuestos" label="Impuestos"/>)}
            <Tab value="direcciones" label="Direcciones de entrega" />
            <Tab value="tanques" label="Tanques" />
            <Tab value="contratos" label="Contratos" />
            <Tab value="pedidos" label="Pedidos" />
            <Tab value="reclamos" label="Reclamos" />
          </Tabs>
          <br />
        </Box>
        {opcion === "datos" ? (
          <DatosCli
            datos={datosformulario}
            ficha={props.ficha}
            tabla={props.tabla}
            actualizar={props.actualizar}
          ></DatosCli>
        ) : null}
        {opcion === "impuestos" ? (
          <ImpuestosCliente cliente={datosformulario} />
        ) : null}
        {opcion === "direcciones" ? (
          <DireccionesEntrega cliente={datosformulario} />
        ) : null}
        {opcion === "tanques" ? <TablaTanques datos={datosformulario} /> : null}
        {opcion === "contratos" ? (
          <ListContratos datos={datosformulario} />
        ) : null}
        {opcion === "pedidos" ? (
          <PedidosCli datos={datosformulario} opcion={"pedidos"} />
        ) : null}
        {opcion === "reclamos" ? (
          <ReclamosCli cliente={datosformulario} />
        ) : null}
      </Container>
    </div>
  );
}

export default FichaCliente;
