import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
} from "@mui/material";
import { getData } from "../../../../../../services/library";

function Impuestos(props) {
  const [impuestos, setImpuestos] = useState(null);
  const [porcentajesPercepcion, setPorcentajesPercepcion] = useState(null);

  useEffect(() => {
    getData("/desplegables/porcentajesPercepcion", setPorcentajesPercepcion);
    getData("/impuestos", setImpuestos);
  }, []);

  const size = "small";
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Box
          mb={1}
          mt={1}
          backgroundColor="#ebebeb"
          p={2}
          border={0.5}
          borderColor={"#ABB2B9"}
          borderRadius={4}
        >
          <Grid container spacing={1.3}>
            {/* {props.dataCliente.situacion_iva === 1 ? ( // Si el cliente es RESPONSABLE INSCRIPTO: */}
            {props.listaImpuestos ? (
              props.listaImpuestos.map((impuesto, index) => (
                <>
                  {props.dataCliente.situacion_iva === 1 ? (
                    <>
                      <Grid item xs={9}>
                        <FormControl style={style} required size={size}>
                          <InputLabel>Impuesto</InputLabel>
                          <Select
                            label="Impuesto"
                            name="id_impuesto"
                            value={impuesto.id_impuesto}
                            onChange={(e) => {
                              props.handleChangeImpuestosCliente(e, index);
                            }}
                          >
                            {impuestos
                              ? impuestos.map((elemento) => (
                                  <MenuItem
                                    key={elemento.idimpuestos}
                                    value={elemento.idimpuestos}
                                  >
                                    {elemento.descripcion}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl style={style} required size={size}>
                          <InputLabel>% Percepción</InputLabel>
                          <Select
                            label="% Percepción"
                            name="porcentaje"
                            onChange={(e) => {
                              props.handleChangeImpuestosCliente(e, index);
                            }}
                            value={impuesto.porcentaje}
                          >
                            {porcentajesPercepcion
                              ? porcentajesPercepcion.map((elemento) => (
                                  <MenuItem
                                    key={elemento.id}
                                    value={elemento.valor}
                                  >
                                    {elemento.valor}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  ) : (
                    <Typography variant="body2" color="initial" align="center">
                      Próximamente...
                    </Typography>
                  )}
                </>
              ))
            ) : (
              <Typography variant="body2" color="initial" align="center">
                Próximamente...
              </Typography>
            )}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default Impuestos;
