import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { UrlApi } from "../../../../../services/apirest";
import Notify from "../../../../Notify";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBateria from "./AddBateria";
import moment from "moment";
import InputMask from "react-input-mask";

function DialogAsociarTq(props) {
  const [datos, setDatos] = useState({
    id_tanque: "",
    id_direccionentrega: props.direccion.id,
    id_bateria: "",
    baja: 0,
    usuario: "",
    nro_remito: "",
    fecha_remito: "",
  });
  const [tanques, setTanques] = useState([{}]);
  const [baterias, setBaterias] = useState([{}]);
  const [altaBateria, setAltaBateria] = useState(false);
  // const [definir, setDefinir] = useState(0);
  const [ultimaBateria, setearUltimaBateria] = useState(false);

  //Manejo de Errores
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [disBtn, setDisBtn] = useState(false);

  const obtenerBaterias = () => {
    fetch(UrlApi + "/baterias2/dir/" + props.direccion.id)
      .then((data) => data.json())
      .then((data) => setBaterias(data));
  };

  const obtenerTanques = () => {
    fetch(UrlApi + "/tanques/disponibles")
      .then((data) => data.json())
      .then((data) => setTanques(data));
  };

  useEffect(() => {
    obtenerTanques();
    obtenerBaterias();
  }, []);

  const almacenar = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const borrarSel = () => {
    datos.id_bateria = "";
    obtenerBaterias();
  };

  useEffect(() => {
    if (
      ultimaBateria === true &&
      baterias &&
      baterias[0] &&
      baterias[0].idbateria
    ) {
      console.log("voy a setear la ultima bateria ");
      datos.id_bateria = baterias[0].idbateria;
      setearUltimaBateria(false);
    }
  }, [baterias]);

  async function guardarRegistro(e) {
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/tanques_contratos", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tanque: datos.id_tanque,
        id_contrato: null,
        usuario: localStorage.getItem("user"),
        id_direccionentrega: props.direccion.id,
        id_bateria: datos.id_bateria === "" ? null : datos.id_bateria,
      }),
    });
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        const json = await response.json();
        if (json.message) {
          updateTk(e);
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          updateTk(e);
        } else {
          alert("ERROR DESCONOCIDO: " + error);
        }
      }
    }
  }

  async function updateTk(e) {
    console.log("Voy a actualizar el tanque");
    e.preventDefault();
    const response = await fetch(UrlApi + "/tanques/" + datos.id_tanque, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_estado: 6,
      }),
    });
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          json.message !== "OK" && console.log("REVISAR RESPUESTA DE API");
          guardarMovimiento(e);
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO. ");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          guardarMovimiento(e);
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
        } else {
          alert("ERROR DESCONOCIDO: " + error);
        }
      }
    }
  }

  async function guardarMovimiento(e) {
    console.log("Voy a actualizar el tanque");
    e.preventDefault();
    const response = await fetch(UrlApi + "/movimientos_tanques/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tanque: datos.id_tanque,
        nro_remito: datos.nro_remito,
        fecha: datos.fecha_remito === "" ? null : datos.fecha_remito,
        id_cliente: props.cliente.id,
        usuario: localStorage.getItem("user"),
      }),
    });
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          if (json.message) {
            console.log("Guardado Satisfactoriamente");
            setNotificacion(true);
            setOpenSnack(true);
            setMensaje(
              json.message !== "OK"
                ? "Guardado. Actualizar RTA API"
                : "Guardado"
            );
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado. Actualizar RTA API.");
        } else {
          alert("ERROR DESCONOCIDO: " + error);
        }
      }
    }
  }

  // console.log(tanques[0])
  return (
    <div>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={"#D4EFDF"}
          severity={"success"}
          handleClose={handleClose}
        />
      ) : null}

      <Dialog
        fullWidth
        maxWidth="md"
        open={props.open}
        onClose={() => props.setOpen(false)}
      >
        <Box backgroundColor={"#1F618D"}>
          <DialogTitle>
            <Typography variant="h6" color="#ffffff">
              Añadir Tanque A {props.direccion.direccion_entrega}
            </Typography>
          </DialogTitle>
        </Box>
        <form onSubmit={guardarRegistro}>
          <DialogContent>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              py={3}
            >
              <Grid item xs={4}>
                <Autocomplete
                  autoFocus
                  disableClearable
                  disabled={!tanques}
                  noOptionsText={"Tanque no existe"}
                  options={tanques}
                  getOptionLabel={(tanques) => tanques.nro_serie}
                  onChange={(event, value) =>
                    setDatos({ ...datos, ["id_tanque"]: value.id })
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.nro_serie} - {option.nombre_fabricante}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      value={datos.id_tanque}
                      focused
                      placeholder="Tipee para buscar tanque.."
                      {...params}
                      label="Tanque"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth focused>
                  <InputLabel>Bateria</InputLabel>
                  <Select
                    name="id_bateria"
                    focused
                    value={datos.id_bateria}
                    label="Bateria"
                    onChange={almacenar}
                  >
                    {baterias
                      ? baterias.map((elemento) => (
                          <MenuItem
                            key={elemento.idbateria}
                            value={elemento.idbateria}
                          >
                            {elemento.nro} - {elemento.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <Tooltip title="Borrar Selección">
                  <span>
                    <IconButton
                      disabled={!baterias}
                      color="primary"
                      size="large"
                      onClick={() => borrarSel()}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={1}>
                <Tooltip title="Nueva Bateria">
                  <span>
                    <IconButton
                      disabled={!datos.id_direccionentrega}
                      color="primary"
                      size="large"
                      onClick={(e) => setAltaBateria(true)}
                    >
                      <AddBoxIcon fontSize="inherit" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <InputMask
                  mask="99999-99999999"
                  value={datos.nro_remito}
                  disabled={false}
                  maskChar=" "
                  onChange={almacenar}
                >
                  {() => (
                    <TextField
                      fullWidth
                      helperText="Opcional"
                      type="text"
                      focused
                      name="nro_remito"
                      label="Nro Remito"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={4} fullWidth>
                <TextField
                  type="date"
                  fullWidth
                  focused
                  name="fecha_remito"
                  label="Fecha Remito"
                  helperText="Opcional"
                  value={moment(datos.fecha_remito).format("YYYY-MM-DD")}
                  onChange={almacenar}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Button variant="outlined" color="error" onClick={handleClose}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  disabled={disBtn || !tanques || !baterias || !datos.id_tanque}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      {altaBateria ? (
        <>
          <AddBateria
            open={altaBateria}
            setOpen={setAltaBateria}
            datos={datos.id_direccionentrega} //el id de la direccion donde ira la bateria
            cliente={props.cliente} //para tomar el sisven_id
            actualizar={obtenerBaterias}
            setearUltimaBateria={setearUltimaBateria}
          />
        </>
      ) : null}
    </div>
  );
}

export default DialogAsociarTq;
