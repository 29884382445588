import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { UrlApi } from "../../../../../services/apirest";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import PropaneIcon from "@mui/icons-material/Propane";
import ForwardIcon from "@mui/icons-material/Forward";
import DialogRetirarTanque from "./DialogRetirarTanque";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import Tooltip from "@mui/material/Tooltip";
import GestionBat from "./GestionBat";


function TanquesEnDireccion(props) {
  const [tanques, setTanques] = useState();
  const [retirar, setRetirar] = useState(false);
  const [tqARetirar, setTqARetirar] = useState(false);

  const [dialogBat, setDialogBat] = useState(false);
  const [registro, setRegistro] = useState({});

  const obtenerTanques = () => {
    fetch(UrlApi + "/tanques/dirent/" + props.direccion.id)
      .then((data) => data.json())
      .then((data) => setTanques(data));
  };

  // tanques && console.log(tanques);

  useEffect(() => {
    obtenerTanques();
  }, []);

  const handleRetirar = (tanque) => {
    setRetirar(true);
    setTqARetirar(tanque);
  };

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={() => props.setOpen(false)}>
        <AppBar sx={{ position: "relative", background: "#1F618D" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => props.setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Tanques en {props.direccion.direccion_entrega}(
              {props.cliente.razon_social})
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid item xs={12} md={6}>
            <List>
              {tanques && tanques.length > 0 ? (
                tanques.map((tanque) => (
                  <>
                    <Box
                      border={0.5}
                      borderColor={"#D4E6F1"}
                      borderRadius={2}
                      mb={2}
                      backgroundColor={"#EAF2F8"}
                    >
                      <ListItem
                        secondaryAction={
                          <Grid container spacing={2} px={1}>
                            <Grid item>
                              <Tooltip title="Cambiar/Asignar/Quitar de Bateria">
                                <IconButton
                                  onClick={() => {
                                    setDialogBat(true);
                                    setRegistro(tanque);
                                  }}
                                >
                                  <PropaneIcon color="#ffffff" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title="Cambiar Ubicación - Retirar A Sucursal - Cambiar De Batería - Quitar De Batería">
                                <Button
                                  color="error"
                                  variant="outlined"
                                  startIcon={<ForwardIcon />}
                                  onClick={() => handleRetirar(tanque)}
                                  style={{
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  Cambiar Ubicación/Retirar
                                </Button>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: "#5499C7" }}>
                            <PropaneIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            tanque.nro_serie +
                            (tanque.id_sisven
                              ? " (Sisven: " + tanque.id_sisven + ")"
                              : "")
                          }
                          secondary={
                            <>
                              {tanque.capacidad ? (
                                <>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    Capacidad:{" "}
                                  </Typography>
                                  {tanque.capacidad} m3
                                  <br></br>
                                </>
                              ) : null}

                              {tanque.fabricante ? (
                                <>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    Fabricante:{" "}
                                  </Typography>
                                  {tanque.fabricante}
                                  <br></br>
                                </>
                              ) : null}

                              {tanque.duenio ? (
                                <>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    Dueño:{" "}
                                  </Typography>
                                  {tanque.duenio} m3
                                  <br></br>
                                </>
                              ) : null}
                              {tanque.nro ? (
                                <>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    Batería:{" "}
                                  </Typography>
                                  {tanque.nro} ({tanque.nombre})<br></br>
                                </>
                              ) : null}
                            </>
                          }
                        />
                      </ListItem>
                    </Box>
                  </>
                ))
              ) : (
                <Alert severity="warning">
                  <AlertTitle>Atención!</AlertTitle>
                  No se encontró ningún tanque en el domicilio.
                </Alert>
              )}
              {retirar ? (
                <DialogRetirarTanque
                  tanque={tqARetirar}
                  direccion={props.direccion}
                  open={retirar}
                  setOpen={setRetirar}
                  actualizar={obtenerTanques}
                  direcciones={props.direcciones}
                  cliente={props.cliente}
                />
              ) : null}
              {dialogBat ? (
                <GestionBat
                  direccion={props.direccion}
                  open={dialogBat}
                  setOpen={setDialogBat}
                  registro={registro}
                  cliente={props.cliente}
                  actualizar={obtenerTanques}
                />
              ) : null}
            </List>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TanquesEnDireccion;
