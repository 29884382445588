import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Panelrrhh from "../vista_rrhh/Panelrrhh";
import Tabla_check from "../checklist/Tabla_check";
import { UrlApi } from "../../../services/apirest";

function Tab_checklistResultados() {
  //MANEJA TAB
  const [opcion, setOpcion] = React.useState("general");
  const [esAuditor, setEsAuditor] = useState(localStorage.getItem("auditor"));

  const handleChange = (event, newValue) => {
    setOpcion(newValue);
  };
  useEffect(() => {
    if (esAuditor === "1") {
      setOpcion("control");
    } else {
      setOpcion("general");
    }
  }, []);

  const [username, setUsername] = useState({})
  const [esComercial, setEsComercial] = useState(false)
  // ******************** Obtener para los comerciales individualmente *********************** //
const obtenerUser = () => {
  fetch(UrlApi + "/representantes/getid/"+localStorage.getItem("user"))
    .then((data) => data.json())
    .then((data) => setUsername(data));
};
    
useEffect(() => {
  obtenerUser();
}, []);
useEffect(() => {
  if (username.length === 1) {
    setEsComercial(true);
  } else {
    setEsComercial(false);
  }
}, [username])
//console.log("es comercial", esComercial, "username", username);

  //FIN TAB

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: "100%",
      width: "100%",
      backgroundColor: "#1d96e2",
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(20),
      marginRight: theme.spacing(1),
      color: "rgba(255, 255, 255, 0.7)",
      "&.Mui-selected": {
        color: "#ffffff",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgb(170, 168, 240)",
      },
    })
  );
  return (
    <div style={{ marginTop: 60 }}>
      <Container maxWidth="xl">
        <Box sx={{ bgcolor: "#2B76A6", width: "100%", height: "47px" }}>
          <StyledTabs
            value={opcion}
            onChange={handleChange}
            indicatorColor={{ color: "#FFFFFF" }}
            aria-label="Tab"
            variant="fullWidth"
          >
            {esAuditor === "1" ? null : (
              <StyledTab
                // icon={<FormatListBulletedIcon />}
                iconPosition="end"
                value="general"
                label="Panel RRHH"
              />
            )}
            <StyledTab
              // icon={<SettingsIcon />}
              iconPosition="end"
              value="control"
              label="Control de depósito"
            />
          </StyledTabs>
        </Box>

        {opcion === "general" ? (
          <Grid mt={-7}>
            <Panelrrhh 
            esComercial={esComercial}
            />
          </Grid>
        ) : null}

        {opcion === "control" ? (
          <Grid mt={-7}>
            <Tabla_check 
            esComercial={esComercial}
            />
          </Grid>
        ) : null}
      </Container>
    </div>
  );
}

export default Tab_checklistResultados;
