import React, { useState, useEffect, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { UrlApi } from "../../../../../services/apirest";
import { getData } from "../../../../../services/library";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";
import InputMask from "react-input-mask";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Button,
  Typography,
  Container,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { validar } from "./validaciones";
import { save } from "./savefunctions";
import Notify from "../../../../Notify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogIIBB_Formulario(props) {
  const [clienteIIBB, setClienteIIBB] = useState(null); //Guarda las percepciones registradas en iibb_clientes (son las que no vienen por padron)
  const [clienteIIBBOriginal, setClienteIIBBOriginal] = useState(null);
  const [padronOriginal, setPadronOriginal] = useState(null);
  const [padronIIBB, setPadronIIBB] = useState(null); //Son las percepciones que vienen por padron
  //inicio este estado para EN UN PRINCIPIO, guardar las excenciones de los impuestos del padron. luego cuando arme el cuerpo, agrego lo que haya en "excenciones" de los impuestos que agrego a mano. si es q hay.
  const [excenciones, setExcenciones] = useState([{}]);
  const [provincias, setProvincias] = useState([{}]);
  const [notify, setNotify] = useState({
    open: false,
    severity: "",
    color: "",
    mensaje: "",
  });

  const handleClose = () => {
    props.setOpen(false);
  };

  const obtenerIIBBManual = () => {
    fetch(UrlApi + "/iibb_clientes/cuit/" + props.cliente.cuit)
      .then((data) => data.json())
      .then((data) => {
        data.length > 0
          ? setearClienteIIBB(data)
          : setClienteIIBB([
              {
                cuit: props.cliente.cuit,
                percepcion: "",
                id_provincia: "",
              },
            ]);
      });
  };

  const setearClienteIIBB = (data) => {
    setClienteIIBB(data);
    setClienteIIBBOriginal(data);
  };

  const setearIIBBPadrones = (data) => {
    const deepCopy = JSON.parse(JSON.stringify(data));
    setPadronIIBB(data);
    setPadronOriginal(deepCopy);
  };

  const obtenerRegPadrones = () => {
    fetch(UrlApi + "/padron/cliente/" + props.cliente.cuit)
      .then((data) => data.json())
      .then((data) => setearIIBBPadrones(data));
  };

  useEffect(() => {
    getData("/provincias/iibbmanual", setProvincias);
    obtenerIIBBManual();
    obtenerRegPadrones();
  }, []);

  const almacenarImpuesto = () => {
    const values = [...clienteIIBB];
    values.push({
      cuit: props.cliente.cuit,
      percepcion: "",
      id_provincia: "",
      porcentaje_excencion: "",
      vto_excencion: "",
    });
    setClienteIIBB(values);
  };

  const deshacerAlmacenar = (index) => {
    const values = [...clienteIIBB];
    values.splice(index, 1);
    // setClienteIIBB(values);
    if (index===0 && clienteIIBB.length===1){
      setClienteIIBB([
        {
          cuit: props.cliente.cuit,
          percepcion: "",
          id_provincia: "",
        },
      ]);
    } else {
    setClienteIIBB(values);
    }

  };

  const handleInputChange = (index, event) => {
    const values = [...clienteIIBB];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;
    setClienteIIBB(values);
  };

  const handlePadronIIBBExc = (index, event, provincia) => {
    const values = [...padronIIBB];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;
    setPadronIIBB(values);
  };

  const removerExcencion = (index) => {
    const values = [...padronIIBB];
    values[index].porcentaje_excencion = "";
    values[index].vto_excencion = "";
    setPadronIIBB(values);
  };

  async function preparar(e) {
    e.preventDefault();

    const excencionesPadronEliminadas = padronOriginal
      .filter((itemOriginal, index) => {
        const itemIIBB = padronIIBB[index];
        return (
          (itemOriginal.porcentaje_excencion &&
            !itemIIBB.porcentaje_excencion) ||
          (itemOriginal.vto_excencion && !itemIIBB.vto_excencion)
        );
      })
      .map((item) => ({
        id_provincia: item.id_provincia,
        cuit: String(props.cliente.cuit).trim(),
      }));

    //armo los correspondientes cuerpos de las cosas que tengo que guardar / enviar a backend
    const reqBody = {
      excencionesDePadron:
        padronIIBB
          .filter(
            (registro) =>
              !!registro.id_provincia &&
              (!!registro.porcentaje_excencion || !!registro.vto_excencion)
          )
          .map((registro) => {
            return {
              cuit: String(props.cliente.cuit).trim(),
              id_provincia: registro.id_provincia,
              porcentaje_excencion: registro.porcentaje_excencion || null,
              vto_excencion: registro.vto_excencion || null,
            };
          }) || [],
      excencionesFueraPadron:
        clienteIIBB.map((registro) => {
          return {
            cuit: String(props.cliente.cuit).trim(),
            id_provincia: registro.id_provincia,
            porcentaje_excencion: registro.porcentaje_excencion || null,
            vto_excencion: registro.vto_excencion || null,
          };
        }) || [],
      clienteIIBB:
        clienteIIBB
          .filter(
            (registro) =>
              !!registro.id_provincia ||
              !!registro.percepcion ||
              !!registro.porcentaje_excencion ||
              !!registro.vto_excencion
          )
          .map((registro) => {
            return {
              // cuit: String(registro.cuit).trim(),
              cuit: String(props.cliente.cuit).trim(),
              percepcion: registro.percepcion,
              id_provincia: registro.id_provincia,
            };
          }) || [],
      eliminados:
        (clienteIIBBOriginal &&
          clienteIIBBOriginal.filter(
            (clienteOriginal) =>
              !clienteIIBB.find(
                (cliente) =>
                  String(cliente.cuit).trim() ===
                    String(clienteOriginal.cuit).trim() &&
                  cliente.id_provincia === clienteOriginal.id_provincia
              )
          )) ||
        [],
      excencionesPadronEliminadas: excencionesPadronEliminadas,
    };

    const errores = validar(
      reqBody.clienteIIBB,
      reqBody.excencionesDePadron,
      reqBody.excencionesFueraPadron
    );
    if (errores.length > 0) {
      alert(errores.join("\n"));
    } else {
      save(reqBody, setNotify);
    }
  }

  const inputProps = {
    disableUnderline: true,
    sx: {
      paddingTop: 0,
      paddingBottom: 0,
      height: "32px",
    },
  };

  const inputsx = {
    ".MuiFilledInput-root": {
      paddingTop: 0,
      paddingBottom: 0,
      height: "32px",
    },
    ".MuiInputBase-input": {
      paddingTop: "6px",
      paddingBottom: "6px",
    },
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            backgroundColor: "#ebebeb",
          },
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Percepciones Ingresos Brutos
            </Typography>
            <Button color="inherit" onClick={preparar}>
              guardar
            </Button>
          </Toolbar>
        </AppBar>

        <Container maxWidth="xl" sx={{ p: 2 }}>
          <Grid container>
            <Grid
              container
              backgroundColor="#fff"
              p={1}
              py={1.7}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              borderRadius={1}
            >
              <Grid item xs={4}>
                <Typography variant="button" color="primary">
                  Provincia
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="button" color="primary">
                  Percepción
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="button" color="primary">
                  % Excención
                </Typography>
              </Grid>
              <Grid item xs={2.5}>
                <Typography variant="button" color="primary">
                  Vencimiento Excención
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="button" color="primary">
                  Acciones
                </Typography>
              </Grid>
            </Grid>

            {padronIIBB && clienteIIBB ? (
              <>
                <form style={{ width: "100%" }}>
                  <Grid item xs={12}>
                    {padronIIBB &&
                      padronIIBB.map((field, index) => (
                        <>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            p={1}
                            backgroundColor="#fff"
                            mt={1}
                            borderRadius={1}
                          >
                            <Grid item xs={0.1}>
                              <LocationOnIcon color="primary" />
                            </Grid>

                            <Grid item xs={3.8}>
                              <Typography variant="subtitle" color="initial">
                                {field.provincia}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography
                                variant="subtitle"
                                sx={{ fontSize: 15 }}
                                color="initial"
                              >
                                {field.percepcion}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                value={field.porcentaje_excencion}
                                onChange={(e) => handlePadronIIBBExc(index, e)}
                                style={{
                                  backgroundColor:
                                    field.porcentaje_excencion > 100 ||
                                    field.porcentaje_excencion === "0" ||
                                    field.porcentaje_excencion === "00" ||
                                    field.porcentaje_excencion === "000"
                                      ? "#fadbd8"
                                      : "#fff",
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                }}
                                variant="filled"
                                size="small"
                                fullWidth
                                name="porcentaje_excencion"
                                InputProps={{
                                  ...inputProps,
                                  startAdornment: (
                                    <InputAdornment
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                        marginRight: 1,
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        color={
                                          field.porcentaje_excencion > 100 ||
                                          field.porcentaje_excencion === "0" ||
                                          field.porcentaje_excencion === "00" ||
                                          field.porcentaje_excencion === "000"
                                            ? "error"
                                            : "primary"
                                        }
                                      >
                                        %
                                      </Typography>
                                    </InputAdornment>
                                  ),
                                }}
                                sx={inputsx}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                inputProps={{
                                  maxLength: 3,
                                }}
                                type="text"
                              />
                            </Grid>
                            <Grid item xs={2.5}>
                              <TextField
                                variant="filled"
                                size="small"
                                fullWidth
                                type="date"
                                name="vto_excencion"
                                value={field.vto_excencion}
                                onChange={(e) =>
                                  handlePadronIIBBExc(index, e, field)
                                }
                                InputProps={inputProps}
                                sx={inputsx}
                              />
                            </Grid>
                            {!!field.vto_excencion ||
                              !!field.porcentaje_excencion ? (
                                <Grid item xs={1}>
                                  <Tooltip title="Quitar Excención">
                                    <IconButton
                                      onClick={() => removerExcencion(index)}
                                      sx={{ p: 0.5 }}
                                    >
                                      <ClearIcon color="warning" />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              ):(
                                <Grid item xs={1}>
                                </Grid>
                              )}
                          </Grid>
                        </>
                      ))}
                  </Grid>
                  <Grid item xs={12}>
                    {clienteIIBB &&
                      clienteIIBB.map((field, index) => (
                        <>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            p={1}
                            backgroundColor="#fff"
                            mt={1}
                            borderRadius={1}
                          >
                            <Grid item xs={0.1}>
                              <LocationOnIcon color="primary" />
                            </Grid>

                            <Grid item xs={3.8}>
                              {provincias && (
                                <FormControl fullWidth>
                                  <Select
                                    disabled={!!field.id_provincia}
                                    variant="filled"
                                    size="small"
                                    value={field.id_provincia}
                                    onChange={(e) =>
                                      handleInputChange(index, e)
                                    }
                                    name="id_provincia"
                                    sx={{
                                      ...inputsx,
                                      height: "32px",
                                      "&:before": {
                                        borderBottom: "none",
                                      },
                                      "&:hover:not(.Mui-disabled):before": {
                                        borderBottom: "none", 
                                      },
                                      "&:after": {
                                        borderBottom: "none", 
                                      },
                                    }}
                                  >
                                    {provincias.map((provincia) => {
                                      const isDisabled = clienteIIBB.some(
                                        (item) =>
                                          item.id_provincia === provincia.id
                                      );

                                      return (
                                        <MenuItem
                                          key={provincia.id}
                                          value={provincia.id}
                                          disabled={isDisabled}
                                        >
                                          {provincia.provincia}{" "}
                                          {provincia.iibb_auto === 1
                                            ? "(Padrón)"
                                            : ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              )}
                            </Grid>
                            <Grid item xs={2}>
                              <InputMask
                                mask="9.99"
                                value={field.percepcion}
                                maskChar=" "
                                onChange={(e) => handleInputChange(index, e)}
                              >
                                {() => (
                                  <TextField
                                    variant="filled"
                                    size="small"
                                    fullWidth
                                    name="percepcion"
                                    InputProps={inputProps}
                                    sx={inputsx}
                                  />
                                )}
                              </InputMask>
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                disabled={
                                  !field.percepcion || !field.id_provincia
                                }
                                value={field.porcentaje_excencion}
                                onChange={(e) => handleInputChange(index, e)}
                                variant="filled"
                                size="small"
                                fullWidth
                                name="porcentaje_excencion"
                                style={{
                                  backgroundColor:
                                    field.porcentaje_excencion > 100 ||
                                    field.porcentaje_excencion === "0" ||
                                    field.porcentaje_excencion === "00" ||
                                    field.porcentaje_excencion === "000"
                                      ? "#fadbd8"
                                      : "#fff",
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                }}
                                InputProps={{
                                  ...inputProps,
                                  startAdornment: (
                                    <InputAdornment
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                        marginRight: 1,
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        color={
                                          field.porcentaje_excencion > 100 ||
                                          field.porcentaje_excencion === "0" ||
                                          field.porcentaje_excencion === "00" ||
                                          field.porcentaje_excencion === "000"
                                            ? "error"
                                            : "primary"
                                        }
                                      >
                                        %
                                      </Typography>
                                    </InputAdornment>
                                  ),
                                }}
                                sx={inputsx}
                              />
                            </Grid>
                            <Grid item xs={2.5}>
                              <TextField
                                disabled={
                                  !field.percepcion || !field.id_provincia
                                }
                                variant="filled"
                                size="small"
                                fullWidth
                                label=""
                                type="date"
                                name="vto_excencion"
                                value={field.vto_excencion}
                                onChange={(e) => handleInputChange(index, e)}
                                InputProps={inputProps}
                                sx={inputsx}
                              />
                            </Grid>
                            <Grid item xs={0.42}>
                              <Tooltip title="Eliminar IIBB">
                                <IconButton
                                  onClick={() => deshacerAlmacenar(index)}
                                  color="error"
                                  sx={{ p: 0.5 }}
                                  // disabled={clienteIIBB.length===1 }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            {index === clienteIIBB.length - 1 ? (
                              <Grid item xs={0.42}>
                                <Tooltip title="Agregar IIBB">
                                  <IconButton
                                    onClick={() => almacenarImpuesto()}
                                    color="success"
                                    sx={{ p: 0.5 }}
                                  >
                                    <AddCircleOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            ) : (
                              <Grid item xs={0.42}>
                                <></>
                              </Grid>
                            )}
                          </Grid>
                        </>
                      ))}
                  </Grid>
                </form>
              </>
            ) : (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs>
                  <Typography m={4} fontSize={22} variant="h2" color="initial">
                    Cargando impuestos..
                  </Typography>
                </Grid>
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
      </Dialog>
      {notify.open ? (
        <Notify
          open={notify.open}
          color={notify.color}
          severity={notify.severity}
          mensaje={notify.mensaje}
          handleClose={() => handleClose()}
        />
      ) : null}
    </div>
  );
}

export default DialogIIBB_Formulario;

/* 
    1- en la consulta de padrones, agregar filtro de fecha par tomar los del mes en transcurso
    2- agregar validaciones en textfields: porcentajes, fechas, solo ingreso de numeros, lo que sea necesario
    3- no poder cargar mas de una vez una percepcion de impuesto. es decir, no repetir la provincia del iibb. en backend, que primero busque si no existe un registro con ese cuit y esa provincia para ver si está cargado ese impuesto y en caso de que esté cargado, que lo modifique. tambien puede que lo busque con el id porque la tabla de iibb_clientes,no  va a cambiar todos los meses como la de padrones.
    4- verificar que cuando uno las dos tablas (iibb_clientes e iibb_excenciones), no haya mas de un registro de la excencion por impuesto :) 
    5- ver que sucede si borro una percepcion de iibb (tanto con el registro de iibb_clientes, como el de iibb_excenciones)
    6- unir padrones con excenciones
    7- las consultas a padrones tardan 250 millones de años
*/
