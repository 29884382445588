import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  Box,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

import Dialog_confirmacion_recibo from "./Dialog_confirmacion_recibo";
import Title from "../Title";

function Dialog_transito_recibir(props) {
  const [datosArt, setDatosArt] = useState(props.datosArt)
  const [abrirDialogTr, setAbrirDialogTr] = useState(props.abrirDialogTr)
  const [transito, setTransito] = useState(props.transito);
  const [tabla2, setTabla2] = useState(props.tabla2)
  const [transferRow, setTransferRow] = useState([]);
  const [abrirDialogTrConf, setAbrirDialogTrConf] = useState(false);
  const [almacenes, setAlmacenes] = useState(props.almacenes);

  const obtenerTransito = () => {
    fetch(UrlApi + "/stock_transacciones/xsucursalr/" + props.sucursal)
      .then((data) => data.json())
      .then((data) => setTransito(data));
  };

  useEffect(() => {
    obtenerTransito();
  }, [tabla2]);

  const [update, setUpdate] = useState(false);
  useEffect(() => {
    if (update) {
      obtenerTransito();
      setUpdate(false);
    }
  }, [update]);

  const handleClose = () => {
    setAbrirDialogTr(!abrirDialogTr)
      if (props.onClose) {
          props.onClose();
        }
    };

  const handleClickOpenAlertTr = (row) => {
      setAbrirDialogTrConf(true);
      setTransferRow(row);
    };
    
  const handleCloseAlertTr = () => {
    setAbrirDialogTrConf(false);
    setUpdate(true)
    };

  const columns2 = useMemo(
      () => [
        {
          header: "ID",
          accessorKey: "id_transaccion",
          size: 60,
          enableHiding: true,
        },
        {
          header: "Fecha de Envío",
          accessorKey: "fecha_transaccion",
          size: 100,
          type: "date",
          format: "YYYY-MM-DD",
        },
        { header: "Artículo", accessorKey: "nombre_articulo", size: 120 },
        {
          header: "Sucursal de Origen",
          accessorKey: "sucursal_origen",
          size: 170,
        },
        { header: "Cant. Enviada", accessorKey: "cantidad_enviada", size: 100 },
        { header: "Detalle", accessorKey: "detalle_transaccion", size: 170 },
        { header: "id_req", accessorKey: "id_requerimiento", size: 170, enableHiding: true, },
      ],
      []
    );

  const initialState = {
      //para inicializar la tabla, y la cantidad_enviada de paginas q se ven ej 5
      density: "compact",
      showGlobalFilter: true,
      pagination: { pageIndex: 0, pageSize: 10 },
      sorting: [
        {
          id: "fecha_transaccion",
          desc: true,
        },
      ],
      columnVisibility: {
        id_articulo: false,
        id_transaccion: false,
        alerta_minima: false,
        valor_minimo: false,
        estado_stock: false,
        uuid_articulo: false,
        convertible_a: false,
        id_requerimiento: false,
      },
    };

    const muiSearchTextFieldProps = {
      placeholder: "Buscar..",
      sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
      variant: "outlined",
      color: "primary",
      size: "small",
    };
    
    const tableHeadCellProps = {
      align: "left",
      sx: {
        fontSize: 16,
        color: "white",
        fontWeight: "normal",
        backgroundColor: "#2471A3",
        borderColor: "black",
      },
    };
  
    const globalTheme = useTheme();
    const tableTheme = useMemo(
      () =>
        createTheme({
          palette: {
            mode: globalTheme.palette.mode,
            primary: globalTheme.palette.primary, //primary color for the table
            info: {
              main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
            },
            background: {
              default:
                globalTheme.palette.mode === "light"
                  ? "rgb(234, 239, 241)"
                  : "#000", //pure black table in dark mode for fun
            },
          },
          typography: {
            button: {
              textTransform: "none", //customize typography styles for all buttons in table by default
              fontSize: "1rem",
            },
          },
          components: {
            MuiTooltip: {
              styleOverrides: {
                tooltip: {
                  fontSize: "0.8rem",
                },
              },
            },
          },
        }),
      [globalTheme]
    );
   // console.log("////", transferRow);
  return (
    <>
    <Dialog
          open={abrirDialogTr}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xl"
        >
          <Title
            titulo={`Transferencias por Recibir`}
            handleClose={() => {
              handleClose()
            }}
          />
          <Container maxWidth="xl">
            {transito && tabla2 ? (
              <Box>
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable
                    enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                    enableGrouping // para permitir agrupar x estado x ej
                    layoutMode="grid"
                    muiTableHeadCellProps={tableHeadCellProps}
                    muiTableBodyCellProps={({ row }) => ({
                      align: "left",
                      sx: {
                        color: "black",
                        borderColor: "black",
                      },
                    })}
                    enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                    enableColumnFilters={false} //desactiva filtros x  columna
                    enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                    enableDensityToggle={false} //
                    localization={MRT_Localization_ES} //idioma
                    enableColumnDragging={false} //para arrastrar columnas
                    initialState={initialState}
                    muiSearchTextFieldProps={muiSearchTextFieldProps}
                    columns={columns2}
                    data={transito} //con lo q la completas (esto cambia siempre)
                    enableStickyFooter
                    enableEditing
                    positionActionsColumn="last" // posicion de la columna de acciones al final
                    // ACCIONES
                    renderToolbarInternalActions={(
                      { table } // boton para nuevo , al lado del buscar
                    ) => (
                      <>
                        <MRT_FullScreenToggleButton table={table} />
                      </>
                    )}
                    renderTopToolbarCustomActions={() => (
                      <>
                        
                      </>
                    )}
                    renderRowActions={(
                      { row } //las acciones del costado editar y ver
                    ) => (
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          onClick={() => handleClickOpenAlertTr(row.original)}
                        >
                          Recibido
                        </Button>
                      </>
                    )}
                  />
                </ThemeProvider>
              </Box>
            ) : null}
          </Container>
        </Dialog>
    {abrirDialogTrConf? 
    <Dialog_confirmacion_recibo
    abrirDialogTrConf={abrirDialogTrConf}
    transferRow={transferRow}
    almacenes={almacenes}
    onClose={() => handleCloseAlertTr(false)}
    />
    :null}
    </>
  )
}

export default Dialog_transito_recibir