/* 
// Developer: Magalí Perea
// Creation Date:
// Version: 
// Description: Este componente presenta un resumen del análisis y los cálculos efectuados en base a los datos ingresados en la factibilidad, ofreciendo la opción de adjuntar archivos. Además, permite ejecutar acciones específicas como guardar, aprobar o desaprobar la factibilidad, dependiendo del perfil del usuario.

//Envío de mails:
Cuando se carga una nueva factibilidad, el sistema envía un mail al AUTORIZANTE. Dicho mail se encuentra en la configuración de la factibilidad.
Cuando se DESAPRUEBA una factibilidad, el sistema envía un mail al COMERCIAL que cargó la factibilidad. Dicho mail se encuentra en la columna "mail" de la tabla REPRESENTANTE_COMERCIAL. 
Cuando se APRUEBA una factibilidad:
    - Si supera el límite de kg mensuales determinados en la configuración de la factibilidad,el único evento que se disparará desde este módulo, es un cambio de estado: la fact pasará a estar "pendiente aprobación crediticia", para así estar disponible al dpto de cuentas corrientes, que ejectuará la correspondiente revisión. 
    - Si NO supera el límite establecido, se aprobará definitivamente, desencadenando así el envío de un mail a las siguientes partes:
        // COMERCIAL --> quién carga la factibilidad
        // AUTORIZANTE --> quién es responsable de autorizarlas
        // ADMINISTRACIÓN --> quienes deban facturar los conceptos que debe absorber el cliente. 
*/

//la reconcha de tu madre all boys

import React, { useState } from "react";
import { Typography, Grid, TextField, Button } from "@mui/material";
import { IconButton, Tooltip, Paper } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import UpdatedUploadFiles from "../../../services/UpdatedUploadFiles";
import UploadFiles2024 from "../../../services/UploadFiles2024";
import * as Save from "../savefunctions";
import Notify from "../../../components/Notify";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";
import moment from "moment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import DialogConTextField from "../../../components/Dialogs/DialogConTextField";
import { v4 as uuidv4 } from "uuid"; ///me da un unico id para poner en el comprobante

function PanelAnalisis(props) {
  const esAdmin = localStorage.getItem("role") === "admin"; //determina si el usuario es administrador o no

  // Son las observaciones ingresadas por el usuario cuando se carga una factibilidad
  // están en este componente porque se la tengo que pasar como parámetro al dialog con textfield
  const [obsRechazo, setObsRechazo] = useState("");

  //Estados para manejar componentes visuales:
  const [disBtn, setDisBtn] = useState(false);
  const readOnly = props.readOnly;
  const [notify, setNotify] = useState({
    open: false,
    severity: "",
    color: "",
    mensaje: "",
  });
  const [showAprobar, setShowAprobar] = useState(false);
  const [showDesaprobar, setShowDesaprobar] = useState(false);
  const [showArchivos, setShowArchivos] = useState(false);
  const [openclose, setOpenClose] = useState(false);
  const iconAprobar = <ThumbUpIcon sx={{ fontSize: 40 }} />;
  const iconDesaprobar = <ThumbDownAltIcon sx={{ fontSize: 40 }} />;

  //Llamado a función para aprobar una factibilidad:
  async function aprobar(e) {
    const kgmensual = props.calculos.kganual / 12; // guarda en variable los kg anuales/12 para calcular cuantos kg al mes se consumirian
    const kgrevision = props.factConfig.kg_revision; // es el limite de kg mensuales que define si el cliente de la factibilidad precisará revisión crediticia.
    //En  base a estos factores, se define con que estado se guardará la factibilidad:
    let estado = null;
    if (kgmensual >= kgrevision) {
      estado = 219; // Pendiente de revisión crediticia
    } else {
      estado = 199; //Aprobada
    }
    // setDisBtn(true);
    if (estado === 199) {
      //Si la factibilidad tiene que salir directamente aprobada, se llama a la función para aprobar y se le envían los siguientes parámetros:
      //aca tambien le voy a pasar como parametro los datos que tiene que modificar en la factibilidad:
      const datosFactActualizar = {
        id_estado: estado,
        usuario_aprueba_tec: localStorage.getItem("user"),
        fecha_aprobado_tec: moment(new Date()).format("YYYY-MM-DD"),
        usuario_aprueba_creditos: "No corresponde",
      };
      Save.aprobarFactUser2(
        e,
        "/factibilidades/aprobaryusuarios2/" + props.dataFact.factuuid + "/" + props.dataFact.id_tipofact,
        reqBodyReclamo, //Cuerpo de nuevo registro que debe insertarse a "reclamos", expresando que hay una nueva incidencia cargada para proceder a una nueva instalación.
        setNotify, //maneja la notificación
        props.receptor, // MAIL DE LA ADMINISTRACION
        props.dataFact, // LOS DATOS DE LA FACTIBILIDAD
        props.cliente, // EL NOMBRE DEL CLIENTE
        props.autorizante, // MAIL DE AUTORIZANTE QUE ESTÁ REGISTRADO EN LA CONFIGURACION
        props.mailSolicitante, // MAIL DEL COMERCIAL QUE CARGÓ LA FACTIBILIDAD.
        datosFactActualizar, 
        props.dataDir,
        props.listaArtefactos,
        props.calculos
      );
    } else if (estado === 219) {
      Save.aprobarTec(
        e,
        "/factibilidades/desaprobar/" + props.dataFact.factuuid,
        { id_estado: estado, usuario_aprueba_tec:localStorage.getItem("user"), fecha_aprobado_tec:moment(new Date()).format("YYYY-MM-DD") },
        setNotify,
        props.factConfig.mail_autorizante_creditos, props.dataFact.id, props.cliente
      );
    }
  }

  //Llamada a la función para desaprobar una factibilidad:
  async function desaprobar(e) {
    setDisBtn(true);
    //Cuando voy a desaprobar una factibilidad, le voy a pasar los siguientes parámetros:
    Save.desaprobarFact(
      e,
      "/factibilidades/desaprobar/" + props.dataFact.factuuid, //ruta
      setNotify, //maneja la notificación
      props.mailSolicitante, //mail del comercial que cargó la factibilidad, para notificarle que se ha desaprobado.
      props.dataFact.id,
      props.cliente, //nombre del cliente al que se le hizo la factibilidad para inclurilo en el mail.
      props.direccion, //idem anterior
      obsRechazo //Observaciones por las cuales se ha rechazado o desaprobado la factibilidad
    );
  }

  const [uuidReclamo, setUuidReclamo]=useState(uuidv4())
  //Cuerpo del reclamo:
  const reqBodyReclamo = {
    id_cliente: props.dataFact.id_cliente,
    id_motivo: 19,
    estado: 192, //abierto
    prioridad: 195,
    fecha_abierto: moment(new Date()).format("YYYY-MM-DD"),
    detalle: "Instalación para cliente por factibilidad aprobada.",
    direccion_entrega: props.dataFact.id_direccion,
    usuario: localStorage.getItem("user"),
    uuid:uuidReclamo,//añadido
    id_fact:props.dataFact.id
  };

  return (
    <div>
      <Paper
        variant="elevation"
        elevation={10}
        style={{ height: "474px", width: "100%", borderRadius: 20 }}
        px={5}
      >
        <Grid
          container
          p={1}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          borderRadius={5}
          style={{ height: "474px" }}
        >
          {notify.open && (
            <Notify
              open={notify.open}
              severity={notify.severity}
              mensaje={notify.mensaje}
              handleClose={() => {
                props.setShowForm(false);
                props.setShowTable(true);
              }}
              color={notify.color}
            />
          )}
          <Grid
            item
            xs={12}
            backgroundColor="#1f618d"
            borderRadius={10}
            p={1}
            mb={1}
          >
            <Typography variant="body2" color="white" textAlign="center">
              Análisis
            </Typography>
          </Grid>
          <Grid item xs={3.9}>
            <Tooltip title="Precio KG (c/ IVA)">
              <TextField
                variant="outlined"
                style={{ backgroundColor: "#fff" }}
                label="Precio KG(c/ IVA)"
                size="small"
                name="precio_kg"
                value={props.dataFact.precio_kg}
                onChange={(e) => {
                  props.setDataFact({
                    ...props.dataFact,
                    precio_kg: e.target.value,
                  });
                  props.setCalculos({
                    ...props.calculos,
                    preciokg_siniva: parseFloat(
                      parseFloat((e.target.value / 1.105).toFixed(2))
                    ),
                  });
                  // props.setCalculos({
                  //   ...props.calculos,
                  //   preciokg_siniva: parseFloat(
                  //     (e.target.value / 1.105).toFixed(2)
                  //   ),
                  // });
                }}
                fullWidth
                focused
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{ maxLength: 5, readOnly: readOnly }}
                color="success"
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3.9}>
            <Tooltip title="Precio KG (S/ IVA)">
              <TextField
                style={{ backgroundColor: "#fff" }}
                variant="outlined"
                label="Precio KG(s/IVA)"
                size="small"
                name="precioporkg_siniva"
                value={props.calculos.preciokg_siniva}
                fullWidth
                focused
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3.9}>
            <Tooltip title="Margen por KG">
              <TextField
                style={{ backgroundColor: "#fff" }}
                label="Margen"
                size="small"
                value={props.calculos.margen}
                focused
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3.9}>
            <Tooltip title="Cotización del dolar">
              <TextField
                style={{ backgroundColor: "#fff" }}
                variant="outlined"
                label="Cotiz. U$D"
                size="small"
                name="precio_dolar"
                value={props.valorDolar}
                fullWidth
                focused
                InputProps={{
                  readOnly: true,
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3.9}>
            <Tooltip title="Costo De Transporte POR KM">
              <TextField
                style={{ backgroundColor: "#fff" }}
                variant="outlined"
                label="Km Transp"
                size="small"
                name="precio_dolar"
                value={props.factConfig&&props.factConfig.costo_transporte_km}
                fullWidth
                focused
                InputProps={{
                  readOnly: true,
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3.9}>
            <Tooltip title="Costo de producto POR TONELADA">
              <TextField
                variant="outlined"
                style={{ backgroundColor: "#fff" }}
                label="Tn Producto "
                size="small"
                name="costo_prod_tn"
                // value={props.factConfig.costo_producto_tn}
                value={props.factConfig&&props.factConfig.costo_producto_tn}
                fullWidth
                focused
                InputProps={{
                  readOnly: true,
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3.9}>
            <TextField
              style={{ backgroundColor: "#fff" }}
              label="Costo T1"
              size="small"
              value={props.calculos.costot1}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              focused
            />
          </Grid>
          <Grid item xs={3.9}>
            <TextField
              style={{ backgroundColor: "#fff" }}
              label="Costo T2"
              size="small"
              value={props.calculos.costot2}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              focused
            />
          </Grid>
          <Grid item xs={3.9}>
            <Tooltip title="Costo Transporte De Tanques">
              <TextField
                style={{ backgroundColor: "#fff" }}
                label="Costo Transp Tanques"
                size="small"
                value={props.calculos.costotranstks}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                focused
              />
            </Tooltip>
          </Grid>
          <Grid item xs={8}>
            <TextField
              style={{ backgroundColor: "#fff" }}
              label="Inversión Total"
              size="small"
              value={props.calculos.inversiontotal}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              focused
            />
          </Grid>
          <Grid item xs={3.9}>
            <Tooltip title="Costos Operativos (fasoneo)">
              <TextField
                style={{ backgroundColor: "#fff" }}
                label="Costos Op."
                size="small"
                value={props.calculos.costos_operativos_total}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                focused
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ backgroundColor: "#fff" }}
              focused
              color="success"
              label="Variación %"
              size="small"
              name="variacion_porcentual"
              value={props.dataFact.variacion_porcentual}
              disabled={!props.calculos.kganual}
              onChange={(e) =>
                props.setDataFact({
                  ...props.dataFact,
                  variacion_porcentual: e.target.value,
                })
              }
              fullWidth
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              inputProps={{ maxLength: 3, readOnly: readOnly }}
            />
          </Grid>
          <Grid item xs={5.9}>
            <Tooltip title="Consumo Estimado">
              <TextField
                style={{ backgroundColor: "#fff" }}
                label="Kgs Estim. Anual"
                size="small"
                value={props.calculos.kganual}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                focused
              />
            </Tooltip>
          </Grid>
          <Grid item xs={5.9}>
            <Tooltip title="Consumo MINIMO estimado">
              <TextField
                style={{ backgroundColor: "#fff" }}
                label="Kgs Mín. Anual"
                size="small"
                value={props.calculos.kganualminimo}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                focused
              />
            </Tooltip>
          </Grid>
          <Grid item xs={5.9}>
            <TextField
              style={{ backgroundColor: "#fff" }}
              label="Cierre Estimado (años)"
              size="small"
              value={props.calculos.cierreestimado}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              focused
            />
          </Grid>

          <Grid item xs={5.9}>
            <TextField
              style={{ backgroundColor: "#fff" }}
              label="Cierre Minimo (años)"
              size="small"
              value={props.calculos.cierreminimo}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              focused
            />
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            {(props.dataFact.id_estado === 199 || props.dataFact.id_estado === 219 || localStorage.getItem("factib")==="1" )? (
              <>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    onClick={() => {
                      props.setShowForm(false);
                      props.setShowTable(true);
                    }}
                  >
                    VOLVER
                  </Button>
                </Grid>

                <Grid item xs={6} textAlign="right">
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => setShowArchivos(true)}
                  >
                    ver archivos
                  </Button>
                </Grid>
              </>
            ) : (
              // SI LA FACTIBILIDAD NO ESTÁ APROBADA ES PORQUE ESTÁ PENDIENTE, O DESAPROBADA, ENTONCES:
              <>
                {props.dataFact.id_estado === 198 ? (
                  <>
                    {esAdmin ? (
                      // el usuario es administrador
                      <>
                        <Grid item xs={12} style={{ textAlign: "right" }}>
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => setShowArchivos(true)}
                          >
                            ver archivos
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="error"
                            disabled={
                              disBtn || props.dataFact.id_estado === 212
                            }
                            onClick={() => setShowDesaprobar(true)}
                          >
                            desaprobar
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => setShowAprobar(true)}
                            disabled={
                              disBtn ||
                              props.dataFact.id_estado === 199 ||
                              !props.receptor 
                              ||(props.dataFact.id_tipoFact===235&&!props.cliente)
                            }
                          >
                            aprobar
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={10}>
                          <Button
                            disabled={
                              !props.validado.cliOK ||
                              !props.validado.dirOK ||
                              !props.validado.artOK ||
                              !props.validado.costosOK ||
                              !props.validado.tksOK ||
                              !props.dataFact.variacion_porcentual ||
                              props.disabledGuardar ||
                              readOnly
                            }
                            variant="contained"
                            color="success"
                            fullWidth
                            onClick={props.guardar}
                          >
                            guardar
                          </Button>
                        </Grid>
                        <Grid item xs={1.6} ml={0.4}>
                          <Tooltip title="Adjuntar Archivo">
                            <IconButton
                              onClick={() => {
                                setOpenClose(!openclose);
                              }}
                              color="primary"
                              style={{ backgroundColor: "#1f618d" }}
                            >
                              <AttachFileIcon sx={{ color: "#fff" }} />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {props.dataFact.id_estado && esAdmin ? (
                      <>
                      </>
                      // <Grid item xs={12}>
                      //   <Button
                      //     fullWidth
                      //     variant="contained"
                      //     color="success"
                      //     onClick={() => setShowAprobar(true)}
                      //     disabled={disBtn || props.dataFact.id_estado === 199 || disBtn || props.dataFact.id_estado === 199 }
                      //   >
                      //     aprobar este es el boton
                      //   </Button>
                      // </Grid>
                    ) : (
                      <>
                        {/* la factibilidad está desaprobada y no es administrador */}
                        <Grid item xs={10}>
                          <Button
                            disabled={
                              !props.validado.cliOK ||
                              !props.validado.dirOK ||
                              !props.validado.artOK ||
                              !props.validado.costosOK ||
                              !props.validado.tksOK ||
                              !props.dataFact.variacion_porcentual ||
                              props.disabledGuardar ||
                              readOnly
                            }
                            variant="contained"
                            color="success"
                            fullWidth
                            onClick={props.guardar}
                          >
                            guardar
                          </Button>
                        </Grid>
                        <Grid item xs={1.6} ml={0.4}>
                          <Tooltip title="Adjuntar Archivo fff">
                            <IconButton
                              onClick={() => setOpenClose(!openclose)}
                              color="primary"
                              style={{ backgroundColor: "#1f618d" }}
                            >
                              <AttachFileIcon sx={{ color: "#fff" }} />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
      {showAprobar ? (
        <ConfirmDialog
          title={"Aprobar factibilidad"}
          subtitle={"Antes de continuar, tenga en cuenta: "}
          open={showAprobar}
          setOpen={setShowAprobar}
          notify={notify}
          setNotify={setNotify}
          btnText={"Aprobar"}
          icon={iconAprobar}
          guardar={aprobar}
          color={"#2ECC71"}
          items={
            props.calculos.kganual / 12 < props.factConfig.kg_revision
              ? [
                  "Se guardará el cliente",
                  "Se guardará el domicilio de entrega",
                  "Se aprobará la factibilidad",
                ]
              : [
                  "Se aprobará técnicamente la factibilidad.",
                  "Se realizará el correspondiente análisis crediticio.",
                ]
          }
        />
      ) : null}

      {showDesaprobar ? (
        <DialogConTextField
          title={"Desaprobar Factibilidad"}
          open={showDesaprobar}
          setOpen={setShowDesaprobar}
          notify={notify}
          setNotify={setNotify}
          btnText="Desaprobar"
          icon={iconDesaprobar}
          guardar={desaprobar}
          color={"#f53649"}
          subtitle={`¿Está seguro que desea desaprobar la factibilidad N° ${props.dataFact.id}? `}
          value={obsRechazo} // este es el valor que va a tomar lo que ingrese en el textfield
          setValue={setObsRechazo} // función para setear lo que el usuario ingrese en el textfield en "value"
        />
      ) : null}
      {showArchivos ? (
        <UpdatedUploadFiles
          fileId={props.dataFact.factuuid}
          open={showArchivos}
          setOpen={setShowArchivos}
          readOnly={true}
          title={"Archivos Cargados en Factibilidad N° " + props.dataFact.id}
        />
      ) : null}
      {openclose ? (
        <UploadFiles2024
          fileId={props.dataFact.factuuid}
          openclose={true}
          tituloDialogo={"Adjuntar Archivos A Factibilidad"}
        />
      ) : null}
    </div>
  );
}

export default PanelAnalisis;
