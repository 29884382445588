//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :12/06/2024
// Version : 1
// Description : Tabla punto de venta
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  Typography,
  Tooltip,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  DialogTitle,
  Stack,
} from "@mui/material";
import Navbar from "../../Navbar";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { logEventos } from "../../../services/services";
import DomainIcon from "@mui/icons-material/Domain";
import DomainDisabledIcon from "@mui/icons-material/DomainDisabled";
import AddPuntoVenta from "./AddPuntoVenta";
import EditarPuntoVenta from "./EditarPuntoVenta";
import CambioPtoVenta from "./CambioPtoVenta";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
//notificacion
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Punto_venta() {
  const [puntosVenta, setPuntosVenta] = useState(); //p/tabla
  const [alta, setAlta] = useState(false); //Estado q mostrará o no la pantalla de alta
  const [tabla, setTabla] = useState(true);
  const [editar, setEditar] = useState(false); //Estado q mostrará o no el form de edicion
  const [cambio, setCambio] = useState(false);
  const [confirmar, setConfirmar] = useState(false);
  const [datosPuntoVenta, setDatosPuntoVenta] = useState({});
  const globalTheme = useTheme();
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
  const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);
  const [obt, setObt] = useState(0);

  //acceso al menu
  useEffect(() => {
    logEventos(
      "Acceso al menú Puntos de Venta",
      "puntos de venta",
      "Agregar,editar,cambiar,activar o desactivar un punto de venta",
      localStorage.getItem("user")
    );
  }, []);

  //*************************************************************************************************************************************/
  const obtenerPuntosActivos = () => {
    setHiddenmuestraactivos(true);
    setHiddenmuestrainactivo(false);
    fetch(UrlApi + "/puntos_venta/activos")
      .then((data) => data.json())
      .then((data) => setPuntosVenta(data));
  };

  const obtenerPuntosInactivos = () => {
    setHiddenmuestrainactivo(true);
    setHiddenmuestraactivos(false);
    fetch(UrlApi + "/puntos_venta/inactivos")
      .then((data) => data.json())
      .then((data) => setPuntosVenta(data));
  };

  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerPuntosActivos();
  }, [tabla, obt, confirmar]);

  const handleVolver = () => {
    setConfirmar(false);
    setTabla(true);
  };

  const abrirModalConfirmar = (datosDeLaLinea) => {
    setConfirmar(true);
    setDatosPuntoVenta(datosDeLaLinea);
  };

  const handleClickNuevo = () => {
    setAlta(true);
    setTabla(false);
  };

  const handleClickCambio = () => {
    setCambio(true);
    setTabla(false);
  };

  const mostrareditar = (datosDeLaLinea) => {
    setEditar(true);
    setTabla(false);
    setDatosPuntoVenta(datosDeLaLinea);
    //console.log("datos linea",datosDeLaLinea ? datosDeLaLinea : "NO");
  };

  //******************************** TABLA *****************************************************************************************************/

  // columnas tabla
  const columns = useMemo(
    () => [
      {
        header: "ID Pto.Venta",
        accessorKey: "id_punto",
        size: 85,
      },
      {
        header: "ID Sucursal",
        accessorKey: "id_sucursal",
        size: 200,
      },
      {
        header: "Sucursal",
        accessorKey: "nombre",
        size: 200,
      },
      {
        header: "Teléfono",
        accessorKey: "telefono",
        size: 200,
      },
      {
        header: "Dirección",
        accessorKey: "direccion",
        size: 200,
      },
    ],
    []
  );

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };
  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const marcarInactivo = async (e) => {
    logEventos(
      "Guardar punto de venta inactivo",
      "puntos de venta",
      "Se guardo el puntos de venta inactivo",
      localStorage.getItem("user")
    );
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/puntos_venta/" + datosPuntoVenta.id_punto,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          datosPuntoVenta.estado === 0 ? { estado: 1 } : { estado: 0 }
        ),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setColorMensaje("#D4EFDF");
          setMensaje("Se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    handleVolver();
    obtenerPuntosActivos();
  };

  // console.log("DATOS PUNTOS", datosPuntoVenta);
  // console.log("DATOS PUNTOS ESTADO", datosPuntoVenta.estado);

  //--------------------------- NOTIFICACION----------------------------------------------//
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1} mb={2}>
        {puntosVenta && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                columns={columns}
                data={puntosVenta}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true}
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor:
                      row.original.estado === 0 ? "#efc3c3" : "#b3e6b3",
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleClickNuevo}
                    >
                      Nuevo Punto de Venta
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CompareArrowsIcon />}
                      onClick={handleClickCambio}
                    >
                      Cambio Punto de Venta
                    </Button>
                    <Typography variant="poster" fontSize={26}>
                      <DomainIcon sx={{ size: 20 }} />
                      Puntos de Venta
                    </Typography>
                  </>
                )}
                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false} //No permite que se arrastren las columnas.
                enableEditing
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    {!hiddenmuestraactivos ? (
                      <Tooltip title="Ver puntos de venta activos">
                        <IconButton
                          color="success"
                          onClick={() => {
                            obtenerPuntosActivos();
                          }}
                        >
                          <DomainIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {!hiddenmuestrainactivo ? (
                      <Tooltip title="Ver puntos de venta inactivos">
                        <IconButton
                          color="error"
                          onClick={() => {
                            obtenerPuntosInactivos();
                          }}
                        >
                          <DomainDisabledIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderRowActions={(
                  { row } //las acciones del costado editar y ver
                ) => (
                  <div>
                    <Tooltip
                      arrow
                      placement="left"
                      title="Editar Punto de venta"
                    >
                      <IconButton
                        onClick={() => mostrareditar(row.original)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    {row.original.estado === 0 ? (
                      <Tooltip title="Marcar como activo">
                        <IconButton
                          color="success"
                          onClick={() => abrirModalConfirmar(row.original)}
                        >
                          <CheckCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Marcar como inactivo">
                        <IconButton
                          onClick={() => abrirModalConfirmar(row.original)}
                          color="error"
                        >
                          <BlockIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                )}
              />
            </ThemeProvider>
            <br />
            <br />
          </Box>
        ) : null}

        {confirmar ? (
          <div>
            <Dialog open={confirmar} onClose={handleVolver}>
              <form onSubmit={marcarInactivo}>
                <Box backgroundColor={"#1F618D"}>
                  <DialogTitle>
                    {datosPuntoVenta.estado === 0 ? (
                      <Typography variant="h6" align="center" color="#ffffff">
                        Activar Pto.Venta N°: {datosPuntoVenta.id_punto}
                      </Typography>
                    ) : (
                      <Typography variant="h6" align="center" color="#ffffff">
                        Desactivar Pto.Venta N°: {datosPuntoVenta.id_punto}
                      </Typography>
                    )}
                  </DialogTitle>
                </Box>
                <DialogContent>
                  <DialogContentText textAlign={"center"}>
                    {datosPuntoVenta.estado === 0 ? (
                      <> ¿Desea activar el punto de venta ? </>
                    ) : (
                      <> ¿Desea desactivar el punto de venta ? </>
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItemns="center"
                  >
                    <Button
                      variant="contained"
                      onClick={handleVolver}
                      style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </DialogActions>
              </form>
            </Dialog>
          </div>
        ) : null}
        {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
        {alta ? (
          <AddPuntoVenta alta={setAlta} tabla={setTabla}></AddPuntoVenta>
        ) : null}
        {editar ? (
          <EditarPuntoVenta
            editar={setEditar}
            tabla={setTabla}
            datos={datosPuntoVenta}
            actualizar={setObt}
          ></EditarPuntoVenta>
        ) : null}
        {cambio ? (
          <CambioPtoVenta
            cambio={setCambio}
            tabla={setTabla}
            datos={datosPuntoVenta}
          ></CambioPtoVenta>
        ) : null}
      </Container>
    </div>
  );
}

export default Punto_venta;
