//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :26/06/2024
// Version : 1
// Description : Agregar precios
//------Changes ----------------------------------------------------------//
// Change Date : 13/8/2024
// Change by :Candela Grosso
// Change description: arregle el boton de post - acomode el titulo y saque los console.log
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField,
  Button,
  Autocomplete,
  Stack,
} from "@mui/material";
import { logEventos } from "../../services/services";
import Title from "../Title";

//notificacion
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddPrecio(props) {
  const [datoslista, setDatoslista] = useState({
    id_sucursal: null,
    id_segmento: null,
    precio_litro_con_iva: "",
    precio_litro_sin_iva: "",
    precio_kilo_con_iva: "",
    precio_kilo_sin_iva: "",
    vigencia: "",
  });
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  //boton
  const [disabledGuardar, setDisabledGuardar] = useState(false);
  //*********************************** OBTENER **************************************************************************************************/
  const [sucursal, setSucursal] = useState();
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState("");

  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setSucursal(data));
  };
  useEffect(() => {
    obtenerSucursales();
  }, []);

  const [segmento, setSegmento] = useState();
  const [segmentoSeleccionado, setSegmentoSeleccionado] = useState("");

  const obtenerSegmentos = () => {
    fetch(UrlApi + "/segmentos")
      .then((data) => data.json())
      .then((data) => setSegmento(data));
  };
  useEffect(() => {
    obtenerSegmentos();
  }, []);

  const [datosSucySeg, setDatosSucySeg] = useState();

  const obtenerXsucySeg = () => {
    fetch(
      UrlApi +
      "/lista_precios/xSucYseg/" +
      sucursalSeleccionada.id +
      "/" +
      segmentoSeleccionado.id
    )
      .then((data) => data.json())
      .then((data) => setDatosSucySeg(data));
  };
  useEffect(() => {
    obtenerXsucySeg();
  }, [sucursalSeleccionada, segmentoSeleccionado]);

  //*********************************** NOTIFICACION **************************************************************************************************/
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    handleClose();
  };

  //**********************************************************************************************************************************//
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  const handleClose = () => {
    props.alta(false);
    props.tabla(true);
  };
  //*************************** GUARDAR *******************************************************************************************************//
  const guardarPrecio = async (e) => {
    logEventos(
      "Guardar nuevo precio",
      "Lista de Precios",
      "Se guardo el nuevo precio",
      localStorage.getItem("user")
    );
    setDisabledGuardar(true)
    e.preventDefault();
    // setDisBtn(true);
    const response = await fetch(UrlApi + "/lista_precios", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_sucursal: sucursalSeleccionada.id,
        id_segmento: segmentoSeleccionado.id,
        precio_litro_con_iva: datoslista.precio_litro_con_iva,
        precio_litro_sin_iva: datoslista.precio_litro_sin_iva,
        precio_kilo_con_iva: datoslista.precio_kilo_con_iva,
        precio_kilo_sin_iva: datoslista.precio_kilo_sin_iva,
        vigencia: datoslista.vigencia,
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setColorMensaje("#D4EFDF");
          setMensaje("Se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const almacenar = (e) => {
    setDatoslista({
      ...datoslista,
      [e.target.name]: e.target.value,
    });
  };

  //*************************** HABILITAR Y DESHABILITAR BOTON GUARDAR ***************************************************************************************//
  useEffect(() => {
    if (datoslista.precio_litro_con_iva === "" || datoslista.precio_litro_sin_iva === "" || datoslista.precio_kilo_con_iva === "" || datoslista.precio_kilo_sin_iva === "" || datoslista.vigencia === "") {
      setDisabledGuardar(true);
    }
    else {
      setDisabledGuardar(false);
    }
  }, [datoslista.precio_litro_con_iva, datoslista.precio_litro_sin_iva, datoslista.precio_kilo_con_iva, datoslista.precio_kilo_sin_iva, datoslista.vigencia]);

  return (
    <div>
      <Container>
        <Title
          titulo={"Nuevo precio"}
          handleClose={handleClose} />

        <form onSubmit={guardarPrecio} autoComplete="off">
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={6}>
                {sucursal && (
                  <Autocomplete
                    disabled={!sucursal}
                    size={size}
                    focused
                    disableClearable
                    id="sucursales"
                    noOptionsText={"Sucursal INEXISTENTE"}
                    options={sucursal}
                    autoHighlight
                    getOptionLabel={(sucursal) =>
                      sucursal.id + "-" + sucursal.nombre
                    }
                    onChange={(event, value) => setSucursalSeleccionada(value)}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id}-{option.nombre}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Sucursal"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {segmento && (
                  <Autocomplete
                    disabled={!segmento}
                    size={size}
                    focused
                    disableClearable
                    id="segmentos"
                    noOptionsText={"Segmento INEXISTENTE"}
                    options={segmento}
                    autoHighlight
                    getOptionLabel={(segmento) =>
                      segmento.id + "-" + segmento.segmento
                    }
                    onChange={(event, value) => setSegmentoSeleccionado(value)}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id}-{option.segmento}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Segmento"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              {datosSucySeg && datosSucySeg.length > 0 ? (
                <>
                  <Typography component="div" sx={{ fontSize: 19, mt: 3 }}>
                    "Ya existe un precio, solo puede actualizarlo."
                  </Typography>
                </>
              ) : (
                <>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="precio_litro_con_iva"
                        label="Precio litro con IVA"
                        variant="outlined"
                        value={datoslista.precio_litro_con_iva}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="precio_litro_sin_iva"
                        label="Precio litro sin IVA"
                        variant="outlined"
                        value={datoslista.precio_litro_sin_iva}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="precio_kilo_con_iva"
                        label="Precio kilo con IVA"
                        variant="outlined"
                        value={datoslista.precio_kilo_con_iva}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="precio_kilo_sin_iva"
                        label="Precio kilo sin IVA"
                        variant="outlined"
                        value={datoslista.precio_kilo_sin_iva}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        type="date"
                        name="vigencia"
                        label="Vigencia"
                        variant="outlined"
                        value={datoslista.vigencia}
                        onChange={almacenar}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>

              <Button
                variant="contained"
                type="submit"
                color="success"
                disabled={disabledGuardar}
              >
                Guardar Precio
              </Button>
            </Grid>
          </Box>
        </form>
        {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
      </Container>
    </div>
  );
}

export default AddPrecio;
