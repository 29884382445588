import React, { useEffect, useState, useMemo } from "react";
import Navbar from "../../../Navbar";
// import { UrlApi } from "../../../services/apirest";
import { UrlApi } from "../../../../services/apirest";
import {
  Container,
  Typography,
  ListItemIcon,
  MenuItem,
  Grid,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Delete } from "@mui/icons-material";
import PropaneIcon from "@mui/icons-material/Propane";
import CircularProgress from "@mui/material/CircularProgress";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import DetallePedido from "../../pedidos/DetallePedido";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useNavigate } from "react-router-dom";

function Pedidos(props) {
  let navigate = useNavigate();
  const [dataCliente, setDataCliente] = useState(props.datos);
  const [pedidos, setPedidos] = useState(null); // Acá me trigo los pedidos que existen
  const [tabla, setTabla] = useState(true);

  //Funcion para obtener pedidos
  const obtenerPedidos = () => {
    fetch(UrlApi + "/pedidos/cli/" + dataCliente.id)
      .then((data) => data.json())
      .then((data) => setPedidos(data));
    // console.log(UrlApi + "/pedidos/cli/" + dataCliente.id);
  };

  useEffect(() => {
    obtenerPedidos();
  }, [tabla]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id_pedido", //access nested data with dot notation
        header: "N°",
        size: 10,
      },
      // {
      //   accessorKey: "cliente", //access nested data with dot notation
      //   header: "Cliente",
      //   size: 130,
      // },
      {
        accessorKey: "direccion", //access nested data with dot notation
        header: "Dirección",
        size: 140,
      },
      {
        accessorKey: "kgacargar", //access nested data with dot notation
        header: "Pedido(KG)",
        size: 90,
      },
      {
        accessorKey: "fecha_requerida", //access nested data with dot notation
        header: "Fecha Requerida",
        size: 90,
      },
      {
        accessorKey: "sucursal_atiende", //access nested data with dot notation
        header: "Sucursal",
        size: 80,
      },
      {
        accessorKey: "empresa", //access nested data with dot notation
        header: "Empresa",
        size: 80,
      },
      {
        accessorKey: "valor", //access nested data with dot notation
        header: "Estado",
        size: 70,
      },
    ],
    []
  );

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: 10,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      pt: 1,
    },
  };

  const rowBg = {
    // 19: "#FFFFFF",
    19: "#F5CBA7", //Lo voy a poner en amarillo
    21: "#ABEBC6", //aprobado -> verde clarito
    22: "#EC7063", //rechazado -> rojo
    23: "#85C1E9", //asignado -> celestito
    24: "#138D75", //entregado
    25: "#D7BDE2", //Facturado -> violeta
    40: "#D6DBDF",
    41: "#EC7063", //CANCELADO -> BORDÓ
    243: "#f29696" //ERROR DE FACTURACION
  };

  return (
    <div>
      {tabla && pedidos ? (
        <>
          {/* <Container> */}
          <Box mb={5}>
            <Box boxShadow={3}>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  enableTopToolbar={false}
                  enableRowActions
                  positionActionsColumn="last"
                  enableHiding={false}
                  enablePinning={false}
                  showGlobalFilter={false}
                  enableDensityToggle={false}
                  enableColumnResizing
                  enableExpandAll={false}
                  columns={columns}
                  data={pedidos}
                  initialState={initialState}
                  muiTableHeadCellProps={tableHeadCellProps}
                  localization={MRT_Localization_ES}
                  muiTableBodyCellProps={({ row }) => ({
                    align: "center",
                    sx: {
                      backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                      color: "black",
                      borderColor: "black",
                    },
                  })}
                  renderDetailPanel={({ row }) => (

                    <DetallePedido datos={row.original} />
                  )}
                  enableColumnActions={false}
                  // muiSearchTextFieldProps={muiSearchTextFieldProps}
                  enableColumnFilters={false} //Quita filtros por columna
                  renderRowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                      disabled={row.original.id_estado !== 25}
                      key={0}
                      onClick={() => {
                        window.open(`http://192.168.34.102:8121/generate-pdf/${row.original.id_factura}?enviar=0`) 
                        // console.log("id", row.original);
                        closeMenu();
                      }}
                      sx={{ m: 0 }}
                    >
                      <ListItemIcon>
                        <PictureAsPdfIcon color="secondary" />
                      </ListItemIcon>
                      Visualizar factura
                    </MenuItem>
                  ]}
                />
              </ThemeProvider>
              {/* <MaterialReactTable
                localization={MRT_Localization_ES}
                enableTopToolbar={false}
                enablePagination={true}
                columns={columns}
                data={pedidos}
                enableColumnFilterModes={false}
                enableColumnOrdering={false}
                enableGrouping={false}
                enablePinning
                initialState={initialState}
                enableRowSelection={false}
                positionToolbarAlertBanner="bottom"
                positionActionsColumn="last"
                enableHiding={false}
                muiTableHeadCellProps={tableHeadCellProps}
                positionExpandColumn="first"
                renderDetailPanel={({ row }) => (
                  <DetallePedido datos={row.original} />
                )}
                enableExpandAll={false}
                muiTableBodyCellProps={({ row }) => ({
                  align: "center",
                  sx: {
                    backgroundColor: "#ffffff",
                    color: "black",
                    borderColor: "#FDFEFE",
                  },
                })}
                enableColumnActions={false}
                enableColumnFilters={false} //Quita filtros por columna
                displayColumnDefOptions={displayColumnDefOptions}
                enableDensityToggle={false}
                enableBottomToolbar={false}
              /> */}
            </Box>
          </Box>
          {/* </Container> */}
        </>
      ) : null}

      {/* {tabla && pedidos ? (
        <Box mb={2}>
          <ThemeProvider theme={defaultMaterialTheme}>
            <MaterialTable
              columns={columns}
              data={pedidos}
              title={<MyNewTitle variant="h5" text="Pedidos" />}
              localization={localization}
              actions={[
                {
                  icon: "add_box",
                  tooltip: "Nuevo Pedido",
                  position: "toolbar",
                  onClick: () => handleClickNuevo(), //en el clic de + agrega una chofer
                },
              ]}
              options={options}
            />
          </ThemeProvider>
          <br></br>
        </Box>
      ) : null} */}
      {/* {alta ? (
        <AddPedidos3
          datos={dataCliente}
          vengo={"PedidosCli"}
          alta={setAlta}
          tabla={setTabla}
        />
      ) : null} */}
    </div>
  );
}

export default Pedidos;
