import { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Divider,
  Container,
  Toolbar,
  Card,
  CardActions,
  CardContent,
  TextField,
  InputAdornment,
  Button,
  Box,
  Typography,
  LinearProgress,
} from "@mui/material";
import Navbar from "../../Navbar";
import UploadPadron from "./components/Uploadpadron";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import { UrlApi } from "../../../services/apirest";

import { v4 as uuid } from "uuid";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Notify from "../../Notify";
import DialogArba from "./components/DialogArba";
import DialogCdba from "./components/DIalogCdba";
const useStyles = makeStyles({
  card: {
    backgroundColor: "#DDDDDD",
    borderRadius: 16,
    padding: 16,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 8,
    alignItems: "center",
  },
  description: {
    marginBottom: 16,
  },
  uploadButton: {
    alignSelf: "flex-end",
  },
});
const Padrones = () => {
  const [Open, setOpen] = useState(false);
  const [OpenArba, setOpenArba] = useState(false);
  const [OpenCdba, setOpenCdba] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [provinciaselected, setprovinciaselected] = useState({
    id_provincia: null,
    provincia: null,
  });

  const [Padprovincia, setPadprovincia] = useState([]);
  const [Provincias, setProvincias] = useState([]);

  const [isLoading, setisLoading] = useState(false);
  const classes = useStyles();

  const getProvincias = () => {
    axios
      .get(UrlApi + "/provincias/padron")
      .then((res) => setProvincias(res.data));
  };
  const getPadronProvincia = useCallback(async () => {
    if (!Provincias || Provincias.length === 0) {
      console.error("Provincias no está definido o está vacío.");
      return;
    }

    setisLoading(true);
    try {
      const response = await axios.post(UrlApi + "/padron/obt-padronfechas", {
        provincias: Provincias, // Envolver en un objeto con la clave `provincias`
      });
      setPadprovincia(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setisLoading(false);
    }
  }, [Provincias]);

  useEffect(() => {
    getProvincias(); // Primero obtenemos las provincias
  }, []);

  useEffect(() => {
    if (Provincias && Provincias.length > 0) {
      getPadronProvincia(); // Luego obtenemos los datos del padrón una vez que las provincias estén cargadas
    }
  }, [Provincias]); // Esto se ejecuta solo cuando `Provincias` cambia

  const onclick = (event, id_provincia, provincia) => {
    setprovinciaselected({ id_provincia: id_provincia, provincia: provincia });
    setOpen(!Open);
  };
  const onclickArba = () => {
    setOpenArba(!OpenArba);
  };
  const onclickCdba = () => {
    setOpenCdba(!OpenCdba);
  };
  const fechaActual = moment().format("YYYY-MM-DD");

  const fecha_vigencia_desde = moment(
    Padprovincia?.fecha_vigencia_desde
  ).format("YYYY-MM-DD");

  const fecha_vigencia_hasta = moment(
    Padprovincia?.fecha_vigencia_hasta
  ).format("YYYY-MM-DD");

  if (isSubmitting === true) {
    setTimeout(() => {
      setisSubmitting(false);
    }, 120000);
  }
  return (
    <div>
      <Navbar />
      <Container fixed maxWidth={"xl"}>
        <Box sx={{ flexGrow: 1, backgroundColor: "#1F618D", marginTop: 2 }}>
          <Grid container p={1.5}>
            <Grid
              // item
              xs={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h5"
                fontSize={"3rem"}
                fontFamily={"initial"}
                color="#ffffff"
              >
                Padrones
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Toolbar />
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="stretch"
          wrap="wrap"
        >
          <Grid item xs={10} md={5}>
            <Card className={classes.card}>
              <CardContent className={classes.title}>Buenos Aires</CardContent>
              <CardContent className={classes.cardContent}>
                {/*    {isSubmiting && (
                  <LinearProgress
                    variant="determinate"
                    value={uploadPercentageArba}
                  />
                )} */}
                Subir Archivo de Percepcion y Retencion
              </CardContent>
              <CardActions className={classes.uploadButton}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={onclickArba}
                  startIcon={<CloudUploadIcon />}
                >
                  Subir Archivos
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={10} md={5}>
            <Card className={classes.card}>
              <CardContent className={classes.title}>Caba</CardContent>
              <CardContent className={classes.cardContent}>
                Subir el archivo txt
              </CardContent>
              <CardActions className={classes.uploadButton}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={(e) => onclick(e, 3, "caba")}
                  startIcon={<CloudUploadIcon />}
                >
                  Subir Archivos
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={10} md={5}>
            <Card className={classes.card}>
              <CardContent className={classes.title}>Cordoba</CardContent>
              <CardContent className={classes.cardContent}>
                Subir Archivo de Percepcion y Retencion
              </CardContent>
              <CardActions className={classes.uploadButton}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={onclickCdba}
                  startIcon={<CloudUploadIcon />}
                >
                  Subir Archivos
                </Button>
                {/*     {isSubmiting && (
                  <LinearProgress
                    variant="determinate"
                    value={uploadPercentageCbda}
                  />
                )} */}
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={10} md={5}>
            <Card className={classes.card}>
              <CardContent className={classes.title}>Entre Rios</CardContent>
              <CardContent className={classes.cardContent}>
                Subir el archivo csv
              </CardContent>
              <CardActions className={classes.uploadButton}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={(e) => onclick(e, 8, "entre-rios")}
                >
                  Subir Archivos
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={10} md={5}>
            <Card className={classes.card}>
              <CardContent className={classes.title}>Jujuy</CardContent>
              <CardContent className={classes.cardContent}>
                Subir el archivo txt
              </CardContent>
              <CardActions className={classes.uploadButton}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={(e) => onclick(e, 9, "jujuy")}
                >
                  Subir Archivos
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {/*      {Padprovincia && !isLoading ? (
            <>
              {moment(fechaActual).isAfter(
                fecha_vigencia_hasta ||
                  moment(fechaActual).isSame(fecha_vigencia_hasta)
              ) ? (
                <Notify
                  open={true}
                  severity={"error"}
                  color={"#F1948A"}
                  mensaje={"Debe Actualizar el Padron"}
                />
              ) : (
                console.log(
                  "la fechaa actual es menor a la fecha vigencia hasta"
                )
              )}
              <Typography variant="body2" className={classes.description}>
                Fecha en la que se subio el archivo{" "}
                {moment(Padprovincia.fecha_subido).format("YYYY-MM-DD")}
              </Typography>
              <Typography variant="body2" className={classes.description}>
                Fecha de vigencia desde {fecha_vigencia_desde} ---{" "}
                {fecha_vigencia_hasta}
              </Typography>
            </>
          ) : null}
 */}
          <Divider />
        </Grid>
        <Toolbar />
        {Open ? (
          <UploadPadron
            openclose={true}
            fileId={uuid()}
            isSubmitting={setisSubmitting}
            provinciaselected={provinciaselected}
            refetch={getPadronProvincia}
          />
        ) : null}
        {OpenArba ? (
          <DialogArba
            openclose={true}
            uuid={uuid()}
            isSubmitting={setisSubmitting}
            refetch={getPadronProvincia}
          />
        ) : null}
        {OpenCdba ? (
          <DialogCdba
            openclose={true}
            uuid={uuid()}
            isSubmitting={setisSubmitting}
            refetch={getPadronProvincia}
          />
        ) : null}
      </Container>
    </div>
  );
};

export default Padrones;
