//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio/Nicolas Pascucci
// Creation Date : 20/08/2024
// Version : 1
// Description : pantalla para ver los resultados del checklist y para
// los auditores auditar
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../../services/apirest";
import Navbar from "../../Navbar";
import {
  Container,
  Box,
  Typography,
  IconButton,
  TextField,
  Tooltip,
  createTheme,
  useTheme,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { ThemeProvider } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Resultado_tabla from "./Resultado_tabla";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import Dialog_revision from "./Dialog_revision";

function Tabla_check(props) {
  const [resultados, setResultados] = useState({});
  const [resultadosUsuario, setResultadosUsuario] = useState({})
  const [tabla, setTabla] = useState(true);
  const [datosResultados, setDatosResultados] = useState({});
  const [mostrarResultadoCheck, setMostrarResultadoCheck] = useState(false);
  const [revision, setRevision] = useState(false);
  const [esAuditor, setEsAuditor] = useState(localStorage.getItem("auditor"));
  const [checklistRow, setChecklistRow] = useState();
  const [selectedDate, setSelectedDate] = useState(
    moment().subtract(1, "month").format("YYYY-MM")
  );
  const formattedDate = `${selectedDate}-01`;

  const [fechaString, setFechaString] = useState("");

  useEffect(() => {
    if (datosResultados.fecha) {
      setFechaString(datosResultados.fecha.toString());
      // Alternativamente, si quieres un formato más específico:
      // setFechaString(new Date(datosResultados.fecha).toLocaleDateString());
      // setFechaString(new Date(datosResultados.fecha).toISOString());
    }
  }, [datosResultados]);

  //console.log("fecha STRINg",fechaString);
  
  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);

    // Actualiza datosCheck.fecha cuando cambia la selección
    setDatosResultados({
      ...datosResultados,
      fecha: moment(newDate, "YYYY-MM").format("YYYY-MM-DD"), // Ajusta el formato según necesites
    });
  };

  const currentMonth = moment();
  const monthOptions = Array.from({ length: 8 }, (_, i) => {
    const month = currentMonth
      .clone()
      .subtract(i + 1, "months")
      .format("YYYY-MM");
    return {
      value: month,
      label: currentMonth
        .clone()
        .subtract(i + 1, "months")
        .format("MMMM YYYY"),
    };
  });

  const obtenerResultados = () => {
    const year = formattedDate.split("-")[0];
    const month = formattedDate.split("-")[1];

    // Construir la URL con el año y el mes
    const url = `${UrlApi}/estados_evaluacion_comercial/checkCompleto/${year}/${month}`;

    fetch(url)
      .then((data) => data.json())
      .then((data) => setResultados(data));
  };

  useEffect(() => {
    obtenerResultados();
  }, [selectedDate]);

  //*********************** RESULTADOS USUARIOS *********************** //
  const obtenerResultadosUsuario = () => {
    const year = formattedDate.split("-")[0];
    const month = formattedDate.split("-")[1];

    // Construir la URL con el año y el mes
    const url = `${UrlApi}/estados_evaluacion_comercial/checkCompletoUser/${year}/${month}/${localStorage.getItem("user")}`;

    fetch(url)
      .then((data) => data.json())
      .then((data) => setResultadosUsuario(data));
  };

  useEffect(() => {
    if (props.esComercial === true) {
      obtenerResultadosUsuario();
    }
  }, [selectedDate, props.esComercial]);
  
  // console.log("selectedDate",selectedDate);
  // console.log("formattedDate",formattedDate);
  // console.log("RESULTADOS -> TABLA CHECK", resultados);

  // *********************************************************** Tabla ********************************************************************** //
  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    grouping: ["nombre_apellido"],
  };

  const columns = useMemo(
    // TABLA Objetivos
    () => [
      { header: "Comercial", accessorKey: "nombre_apellido", size: 110 },
      { header: "Sucursal", accessorKey: "nombre", size: 110 },
      { header: "Cumple", accessorKey: "cumple", size: 110 },
      { header: "No Cumple", accessorKey: "nocumple", size: 110 },
      { header: "Revisado", accessorKey: "auditado", size: 110 },
      {
        header: "Fecha",
        accessorFn: (datosResultados) => {
          return moment(datosResultados.fecha, "YYYY-MM-DD").format("MM-YYYY");
        },
        size: 90,
      },
    ],
    []
  );
  // console.log("FECHA TABLA", moment(datosResultados.fecha, "YYYY-MM-DD").format("MM-YYYY"));
  // console.log("FECHA DE DATOS",datosResultados.fecha);
  const handleClickMostrar = (row) => {
    setDatosResultados(row);
    setTabla(false);
    setMostrarResultadoCheck(true);
  };

  const handleVolverMostrar = () => {
    setRevision(false);
    setTabla(true);
    setMostrarResultadoCheck(false);
    obtenerResultados();
  };
  //console.log("TABLA", tabla, "MOSTRAR RESULTADOS", mostrarResultadoCheck);

  return (
    <div>
      <Navbar />
      <br></br>
      {tabla ? (
        <Container maxWidth="xl" mt={1} mb={2}>
          {resultados ? (
            <Box
              // mt={2}
              boxShadow={3}
            >
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={props.esComercial === true?(resultadosUsuario):(resultados)}
                  initialState={initialState}
                  positionActionsColumn="last"
                  enableExpandAll={false}
                  enableToolbarInternalActions={true}
                  muiTableHeadCellProps={tableHeadCellProps}
                  muiTableBodyCellProps={({ row }) => ({
                    align: "left",
                  })}
                  enableColumnActions={false}
                  muiSearchTextFieldProps={muiSearchTextFieldProps}
                  enableDensityToggle={false}
                  renderTopToolbarCustomActions={() => (
                    <>
                      <Typography variant="poster" fontSize={26}>
                        Control de Depósito
                      </Typography>
                      <FormControl
                        focused
                        color="primary"
                        required
                        size="small"
                      >
                        <InputLabel id="mes">Período</InputLabel>
                        <Select
                          required
                          variant="outlined"
                          name="mes"
                          value={selectedDate}
                          onChange={handleDateChange}
                          label="Período"
                          labelId="mes"
                        >
                          {monthOptions.map((elemento) => (
                            <MenuItem
                              key={elemento.value}
                              value={elemento.value}
                            >
                              {elemento.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                  layoutMode="grid"
                  localization={MRT_Localization_ES}
                  enableColumnDragging={false} //No permite que se arrastren las columnas.
                  enableEditing
                  enableGrouping
                  positionToolbarAlertBanner="none"
                  // ACCIONES
                  renderToolbarInternalActions={(
                    { table } // boton para nuevo , al lado del buscar
                  ) => (
                    <>
                      <MRT_FullScreenToggleButton table={table} />
                    </>
                  )}
                  renderRowActions={(
                    { row } //las acciones del costado editar y ver
                  ) => (
                    <div>
                      <Tooltip title="Ver Control">
                        <IconButton
                          onClick={() => handleClickMostrar(row.original)}
                          color="primary"
                        >
                          <AssignmentIcon />
                        </IconButton>
                      </Tooltip>
                      {esAuditor === "1" && row.original.auditado === "No" ? (
                        <Tooltip title="Revisar">
                          <IconButton
                            onClick={() => {
                              setRevision(!revision);
                              setChecklistRow(row.original);
                            }}
                            color="primary"
                          >
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </div>
                  )}
                />
              </ThemeProvider>
              <br />
              <br />
            </Box>
          ) : null}
        </Container>
      ) : null}

      {mostrarResultadoCheck ? (
        <Resultado_tabla
          tabla={setTabla}
          mostrarResultadoCheck={mostrarResultadoCheck}
          datosResultados={datosResultados}
          onClose={() => handleVolverMostrar()}
        />
      ) : null}

      {revision ? (
        <Dialog_revision
          revision={revision}
          row={checklistRow}
          datosResultados={datosResultados}
          onClose={handleVolverMostrar}
        />
      ) : null}
    </div>
  );
}

export default Tabla_check;
