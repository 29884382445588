import React from "react";
import {
  Typography,
  List,
  Grid,
  ListItem,
  ListItemText,
  Box,
  Dialog,
} from "@mui/material";

function ModalInfoTanque(props) {
 // console.log("datos modal", props.datos);
  return (
    <div>
      <Dialog
        open={() => props.open(true)}
        onClose={() => props.open(false)}
        fullWidth
      >
        <Grid
          container
          spacing={1}
          direction="center"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          backgroundColor="#1F618D"
          pb={1}
          mt={1}
        >
          <Grid item xs={10}>
            <div style={{ textAlign: "center" }}>
              <Typography
                variant="h5"
                component="h2"
                style={{ color: "#ffffff" }}
              >
                Datalle tanques a instalar
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
        >
          <List
            sx={{
              width: "100%",
              maxWidth: 600,
              bgcolor: "background.paper",
              borderRadius: 4,
              fontFamily: "Arial, sans-serif",
            }}
          >
            <ListItem sx={{ textAlign: "center" }}>
              <ListItemText
                sx={{
                  textAlign: "center",
                  maxWidth: 200,
                  "&:not(:first-of-type)": {
                    marginLeft: 1,
                  },
                }}
                primary={
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ fontSize: "1.5rem", color: "primary" }}
                  >
                    0.5m3:
                  </Typography>
                }
                secondary={
                  <Typography variant="body1">
                    {props.datos.canttk_05 ? props.datos.canttk_05 : 0}
                  </Typography>
                }
              />
              <ListItemText
                sx={{
                  textAlign: "center",
                  maxWidth: 200,
                  "&:not(:first-of-type)": {
                    marginLeft: 1,
                  },
                }}
                primary={
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ fontSize: "1.5rem", color: "primary" }}
                  >
                    1m3:
                  </Typography>
                }
                secondary={
                  <Typography variant="body1">
                    {props.datos.canttk_1 ? props.datos.canttk_1: 0}
                  </Typography>
                }
              />
              <ListItemText
                sx={{
                  textAlign: "center",
                  maxWidth: 200,
                  "&:not(:first-of-type)": {
                    marginLeft: 1,
                  },
                }}
                primary={
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ fontSize: "1.5rem", color: "primary" }}
                  >
                    2m3:
                  </Typography>
                }
                secondary={
                  <Typography variant="body1">
                    {props.datos.canttk_2 ? props.datos.canttk_2 : 0}
                  </Typography>
                }
              />
              <ListItemText
                sx={{
                  textAlign: "center",
                  maxWidth: 200,
                  "&:not(:first-of-type)": {
                    marginLeft: 1,
                  },
                }}
                primary={
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ fontSize: "1.5rem", color: "primary" }}
                  >
                    4m3:
                  </Typography>
                }
                secondary={
                  <Typography variant="body1">
                    {props.datos.canttk_4 ? props.datos.canttk_4 : 0}
                  </Typography>
                }
              />
              <ListItemText
                sx={{
                  textAlign: "center",
                  maxWidth: 200,
                  "&:not(:first-of-type)": {
                    marginLeft: 1,
                  },
                }}
                primary={
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ fontSize: "1.5rem", color: "primary" }}
                  >
                    7m3:
                  </Typography>
                }
                secondary={
                  <Typography variant="body1">
                    {props.datos.canttk_7 ?props.datos.canttk_7: 0}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{
                      fontSize: "1.2rem",
                      color: "primary",
                      textDecoration: "underline",
                    }}
                  >
                    Ubicación del cliente:
                  </Typography>
                }
                secondary={
                  <Typography variant="body1">
                    {props.datos.direccion_factibilidad}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{
                      fontSize: "1.2rem",
                      color: "primary",
                      textDecoration: "underline",
                    }}
                  >
                    Nombre del comercial:
                  </Typography>
                }
                secondary={
                  <Typography variant="body1">
                    {props.datos.comercial}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Dialog>
    </div>
  );
}

export default ModalInfoTanque;
