//----- Component --------------------------------------------------------/

// Developer : Daiana Curcio / Nicolas Pascucci

// Creation Date : 3/1/2024

// Version : 1

// Description : Historial de salidas con importación a CSV
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { Box, Typography, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material";
// Boton desplegable
import Button from "@mui/material/Button";
//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { blueGrey } from "@mui/material/colors";

function Historial_salida(props) {
  //CSV
  const [salidasCSV, setSalidasCSV] = useState();

  const obtenerSalidasCSV = () => {
    fetch(UrlApi + "/stock_transacciones/salidaCSV/" + props.sucursal)
      .then((data) => data.json())
      .then((data) => setSalidasCSV(data));
  };
// console.log("csv", salidasCSV);

  useEffect(() => {
    obtenerSalidasCSV();
  }, []);

  useEffect(() => {
    if (salidasCSV && salidasCSV.length > 0) {
      const salidasOrdenadas = salidasCSV.sort(
        (a, b) => new Date(b.fecha_transaccion) - new Date(a.fecha_transaccion)
      );
      setSalidasCSV([...salidasOrdenadas]); // Actualiza el estado con el array ordenado
    }
  }, [salidasCSV]);
  //************************************************************************************************************************************************ */
  const columns = useMemo(
    // TABLA SALIDA
    () => [
      {
        header: "Fecha",
        accessorKey: "fecha_transaccion",
        size: 60,
        type: "date",
        format: "YYYY-MM-DD HH:mm",
      },
      { header: "Almacén", accessorKey: "almacen_nombre_origen", size: 100 },
      { header: "Artículo", accessorKey: "nombre_articulo", size: 110 },
      {
        header: "Motivo de Salida",
        accessorKey: "descripcion_motivo",
        size: 100,
      },
      { header: "Cantidad", accessorKey: "cantidad_enviada", size: 65 },
      { header: "Detalle", accessorKey: "detalle_transaccion", size: 170 },
      {
        header: "Pto. de venta",
        accessorKey: "nro_remito_puntodeventa",
        size: 85,
      },
      { header: "Remito Nro.", accessorKey: "nro_remito_numero", size: 77 },
    ],
    []
  );

  const columnsSalidaCSV = useMemo(
    // TABLA SALIDA
    () => [
      { header: "Artículo", accessorKey: "nombre_articulo", size: 110 },
      { header: "Almacén ", accessorKey: "almacen_nombre_origen", size: 100 },
      { header: "Cantidad", accessorKey: "cantidad_enviada", size: 65 },
      {
        header: "Motivo de Salida",
        accessorKey: "descripcion_motivo",
        size: 100,
      },
      { header: "Detalle", accessorKey: "detalle_transaccion", size: 170 },
      {
        header: "Fecha",
        accessorKey: "fecha_transaccion",
        size: 60,
        type: "date",
        format: "YYYY-MM-DD HH:mm",
      },
      {
        header: "Pto.de venta",
        accessorKey: "nro_remito_puntodeventa",
        size: 85,
      },
      { header: "N° Remito", accessorKey: "nro_remito_numero", size: 77 },
      { header: "Usuario", accessorKey: "usuario", size: 77 },
    ],
    []
  );
  //************************************************************************************************************************************************ */
  //CSV SALIDA
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `Historial de Salidas de Artículos de Sucursal ${props.nombreSuc}`,
    headers: columnsSalidaCSV.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(salidasCSV);
  };

  return (
    <div>
      <Box mt={2} mb={4}>
        <ThemeProvider theme={props.tableTheme}>
          <MaterialReactTable
            enableExpandAll={false}
            enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
            enableGrouping // para permitir agrupar x estado x ej
            layoutMode="grid"
            muiTableHeadCellProps={props.tableHeadCellProps}
            muiTableBodyCellProps={({ row }) => ({
              align: "left",
              sx: {
                color: "black",
                borderColor: "black",
              },
            })}
            enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
            enableColumnFilters={false} //desactiva filtros x  columna
            enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
            enableDensityToggle={false} //
            localization={MRT_Localization_ES} //idioma
            enableColumnDragging={false} //para arrastrar columnas
            initialState={props.initialState}
            muiSearchTextFieldProps={props.muiSearchTextFieldProps}
            columns={columns}
            data={props.salidas} //con lo q la completas (esto cambia siempre)
            enableStickyFooter
            // enableEditing
            positionActionsColumn="last" // posicion de la columna de acciones al final
            // ACCIONES
            renderToolbarInternalActions={(
              { table } // boton para nuevo , al lado del buscar
            ) => (
              <>
                <Grid item xs={6} ml={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<FileDownloadIcon />}
                    onClick={handleExportData}
                    style={{
                      backgroundColor: blueGrey[700],
                      color: "white",
                    }}
                    size="small"
                  >
                    Exportar a CSV
                  </Button>
                </Grid>
                <MRT_FullScreenToggleButton table={table} />
              </>
            )}
            renderTopToolbarCustomActions={() => (
              <>
                <Typography variant="poster" fontSize={26}>
                  Salidas de Articulos
                </Typography>
              </>
            )}
          />
        </ThemeProvider>
      </Box>
    </div>
  );
}

export default Historial_salida;
