//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci / Daiana Curcio

// Creation Date : 21/11/2023

// Version : 1

// Description : Creacion y edición de almacenes vinculados a una sucursal

//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Stack,
  Grid,
  Tooltip,
  IconButton,
  TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import InputMask from "react-input-mask"; // para la mascara del numero de comprobante
import { useLocation, useNavigate } from 'react-router-dom'; // para hacerlo en una pantalla aparte

// ICONOS
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import EditIcon from "@mui/icons-material/Edit";

//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { logEventos } from "../../services/services";

//Dialog de almacen
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Title from "../Title";
import { grey } from "@mui/material/colors";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Almacenes_cargas(props) {
  //para cambiar en otra pantalla (se usa aca arriba)
  let navigate = useNavigate();
  const location = useLocation();
  const [openVerAlmacen, setOpenVerAlmacen] = useState(true);
  const [almacenes, setAlmacenes] = useState();
  const [datosAlmacen, setDatosAlmacen] = useState({
    id_almacen: null,
    id_sucursal: location.state.id,
    nombre: "",
    descripcion: "",
    latitud: "",
    longitud: "",
    usuario: localStorage.getItem("user"),
  });
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [openCrear, setOpenCrear] = React.useState(false);

  // console.log("DATOS DE ALMACENES", datosAlmacen);
  //*************************************************************************************************************************************/
  //funcion para obtener almacenes
  const obtenerAlmacenes = () => {
    fetch(UrlApi + "/stock_almacenes/xsucursal/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setAlmacenes(data));
  };
 // console.log(almacenes);

  useEffect(() => {
    obtenerAlmacenes();
  }, []);

  // console.log("OBTENER DE ALMACENES: ", almacenes)
  //*************************************************************************************************************************************/
  //funcion para obtener sucursales
  const [sucursales, setSucursales] = useState("");

  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  // console.log("trae las sucursales", sucursales);

  //funcion que espera los cambios de la funcion de obtener sucursales
  useEffect(() => {
    obtenerSucursales();
  }, []);

  //*************************************************************************************************************************************/
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  //*************************************************************************************************************************************/

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrear = () => {
    setOpenCrear(true);
    // AL ABRIR EL DIALOG El TEXTFIELDS SE VACIAN
    datosAlmacen.id_almacen = null;
    datosAlmacen.id_sucursal = location.state.id;
    datosAlmacen.nombre = "";
    datosAlmacen.descripcion = "";
    datosAlmacen.latitud = "";
    datosAlmacen.longitud = "";
  };

  const handleCloseCrear = () => {
    setOpenCrear(false);
  };

  //*************************************************************************************************************************************/
  // DIALOG PARA EDITAR LOS DATOS
  const [openEditar, setOpenEditar] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenEditar = (row) => {
    setOpenEditar(true);
    setDatosAlmacen(row);
  };

  const handleCloseEditar = (row) => {
    setOpenEditar(false);
    // setDatosAlmacen(row);
  };
  // // EDITAR
  const handleCloseSnackEditar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackEditar(false);
    setOpenEditar(false);
    setUpdate(false);
    setOpenDialogOnOff(false);
  };
  //*************************************************************************************************************************************/
  const [openDialogOnOff, setOpenDialogOnOff] = React.useState(false);
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpenCrear(false);
    setOpenEditar(false);
    setOpenDialogOnOff(false);
  };

  const handleCloseDialog = () => {
    setOpenVerAlmacen(false);
    props.abrirDialog(false);
  };

  //*************************************************************************************************************************************/
  const columns = useMemo(
    () => [
      { header: "ID", accessorKey: "id_almacen", size: 40 },
      { header: "Nombre", accessorKey: "nombre", size: 150 },
      { header: "Descripción", accessorKey: "descripcion", size: 150 },
      { header: "Latitud", accessorKey: "latitud", size: 95 },
      { header: "Longitud", accessorKey: "longitud", size: 95 },
    ],
    []
  );

  //*************************************************************************************************************************************/
  //Funcion para guardar al crear
  const guardarCrear = async (e) => {
    logEventos("Guardar nuevo almacén", "Stock Sucursales", "Se guardo nuevo almacén", localStorage.getItem("user"))
    // console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/stock_almacenes", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_almacen: datosAlmacen.id_almacen,
        id_sucursal: datosAlmacen.id_sucursal,
        nombre: datosAlmacen.nombre,
        descripcion: datosAlmacen.descripcion,
        latitud: datosAlmacen.latitud,
        longitud: datosAlmacen.longitud,
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El almacén se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el almacén");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // PARA LIMPIAR LOS DATOS
    handleClickOpenCrear();
  };
  // console.log("POST CREAR", datosAlmacen)

  //*************************************************************************************************************************************/
  //Funcion para guardar AL EDITAR
  const guardarEditar = async (e) => {
    logEventos("Guardar editar almacén", "Stock Sucursales", "Se guardo la edición del almacén", localStorage.getItem("user"))
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/stock_almacenes/" + datosAlmacen.id_almacen, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_almacen: datosAlmacen.id_almacen,
        id_sucursal: datosAlmacen.id_sucursal,
        nombre: datosAlmacen.nombre,
        descripcion: datosAlmacen.descripcion,
        latitud: datosAlmacen.latitud,
        longitud: datosAlmacen.longitud,
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El almacén se editó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al editar el almacén");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  // console.log("POST EDITAR", datosAlmacen)

  //*************************************************************************************************************************************/
  const almacenar = (e) => {
    setDatosAlmacen({
      ...datosAlmacen,
      [e.target.name]: e.target.value,
    });
  };
  //*************************************************************************************************************************************/
  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerAlmacenes();
  }, [update]);

  // ESTILO PARA LA TABLA

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    columnVisibility: { id_articulo: false },
  };

  return (
    <>
      <AppBar sx={{ position: "relative"}} style={{backgroundColor:"#0979b0"}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() =>
                navigate("/sucursales_cargas")}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" >
            Almacenes de la Sucursal: {location.state.nombre}
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth="xl" mt={1}>
          {almacenes && tabla ? (
            <Box mt={2} mb={4}>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                  enableGrouping // para permitir agrupar x estado x ej
                  layoutMode="grid"
                  muiTableHeadCellProps={tableHeadCellProps}
                  muiTableBodyCellProps={({ row }) => ({
                    align: "left",
                    sx: {
                      color: "black",
                      borderColor: "black",
                    },
                  })}
                  enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                  enableColumnFilters={false} //desactiva filtros x  columna
                  enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                  enableDensityToggle={false} //
                  localization={MRT_Localization_ES} //idioma
                  enableColumnDragging={false} //para arrastrar columnas
                  initialState={initialState}
                  muiSearchTextFieldProps={muiSearchTextFieldProps}
                  columns={columns}
                  data={almacenes} //con lo q la completas (esto cambia siempre)
                  enableStickyFooter
                  enableEditing
                  positionActionsColumn="last" // posicion de la columna de acciones al final
                  // ACCIONES
                  renderToolbarInternalActions={(
                    { table } // boton para nuevo , al lado del buscar
                  ) => (
                    <>
                      <MRT_FullScreenToggleButton table={table} />
                    </>
                  )}
                  renderTopToolbarCustomActions={() => (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBoxTwoToneIcon color="asd"/>}
                        onClick={handleClickOpenCrear}
                        size="small"
                      >
                        AGREGAR NUEVO ALMACÉN
                      </Button>
                      <Typography variant="poster" fontSize={26}>
                        Almacenes
                      </Typography>
                    </>
                  )}
                  renderRowActions={(
                    { row } //las acciones del costado editar y ver
                  ) => (
                    <div>
                      <Tooltip arrow placement="left" title="Editar Almacén">
                        <IconButton
                          onClick={() => handleClickOpenEditar(row.original)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                />
              </ThemeProvider>
            </Box>
          ) : null}
        </Container>

        {/* AGREGAR ALMACÉN */}
        <Dialog
          open={openCrear}
          onClose={handleCloseCrear}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          color="#F4F6F6"
          >
          <Title
            titulo={`Agregar Nuevo Almacén`}
            handleClose={() => {
              handleCloseCrear()
            }}
          />
           <Box
            border={0.5}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={1}
          > 
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarCrear} autoComplete="off" >
                <Grid container mb={1} spacing={1}>
                  <Grid item xs={12} mb={2}>
                    <TextField
                        label="Sucursal"
                        size="small"
                        name="id_sucursal"
                        value={location.state.nombre}
                        onChange={almacenar}
                        inputProps={{
                          readOnly: true,
                          "aria-label": "Without label",
                        }}
                      />
                  </Grid>
                  <Grid item xs={12} py={1} mb={1}>
                    <TextField
                      label="Nombre"
                      name="nombre"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 55,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosAlmacen.nombre}
                      onChange={almacenar}                 
                    />
                  </Grid>
                  <Grid item xs={12} py={1} mb={1}>
                    <TextField
                      label="Descripción"
                      name="descripcion"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 55,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosAlmacen.descripcion}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6} py={1} mb={1}>
                    <InputMask
                      value={datosAlmacen.latitud}
                      maskChar=" "
                      onChange={almacenar}
                    >
                      {() => (
                        <TextField
                          label="Latitud"
                          name="latitud"
                          size="small"
                          placeholder="0.0"
                          onKeyPress={(event) => {
                            if (!/[-0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          inputProps={{
                            maxLength: 55,
                          }}
                          focused
                          fullWidth
                          value={datosAlmacen.latitud}
                          onChange={almacenar}
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={6} py={1} mb={1}>
                    <InputMask
                      value={datosAlmacen.longitud}
                      maskChar=" "
                      onChange={almacenar}
                    >
                      {() => (
                        <TextField
                          label="Longitud"
                          name="longitud"
                          size="small"
                          placeholder="0.0"
                          onKeyPress={(event) => {
                            if (!/[-0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          inputProps={{
                            maxLength: 55,
                          }}
                          focused
                          fullWidth
                          value={datosAlmacen.longitud}
                          onChange={almacenar}
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>

                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={8}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleCloseCrear}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
          </Box>
        </Dialog>

        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>

        {/* GUARDAR AL CREAR  */}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>

        {/* EDITAR ITEM */}
        <Dialog
          open={openEditar}
          onClose={handleCloseEditar}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <Title
            titulo={`Editar Almacén`}
            handleClose={() => {
              handleCloseEditar()
            }}
          />
          <Box
            border={0.5}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={1}
          >
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarEditar} autoComplete="off"> 
                <Grid container mb={1} spacing={1}>
                  <Grid item xs={12} py={1} mb={1}>
                    <TextField
                      label="Nombre"
                      name="nombre"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 55,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosAlmacen.nombre}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={12} py={1} mb={1}>
                    <TextField
                      label="Descripción"
                      name="descripcion"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 55,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosAlmacen.descripcion}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6} py={1} mb={1}>
                    <InputMask
                      value={datosAlmacen.latitud}
                      maskChar=" "
                      onChange={almacenar}
                    >
                      {() => (
                        <TextField
                          label="Latitud"
                          name="latitud"
                          size="small"
                          placeholder="0.0"
                          onKeyPress={(event) => {
                            if (!/[-0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          inputProps={{
                            maxLength: 55,
                          }}
                          focused
                          fullWidth
                          value={datosAlmacen.latitud}
                          onChange={almacenar}
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={6} py={1} mb={1}>
                    <InputMask
                      value={datosAlmacen.longitud}
                      maskChar=" "
                      onChange={almacenar}
                    >
                      {() => (
                        <TextField
                          label="Longitud"
                          name="longitud"
                          size="small"
                          placeholder="0.0"
                          onKeyPress={(event) => {
                            if (!/[-0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          inputProps={{
                            maxLength: 55,
                          }}
                          focused
                          fullWidth
                          value={datosAlmacen.longitud}
                          onChange={almacenar}
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>

                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleCloseEditar}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
          </Box>
        </Dialog>
        
        {/* GUARDAR AL EDITAR */}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnackEditar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackEditar}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
    </>
  );
}

export default Almacenes_cargas;
