import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import Navbar from '../Navbar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Alert, Autocomplete, Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { UrlApi } from '../../services/apirest';
import { styled } from "@mui/system";
import Title from '../Title';
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DialogCancelarViaje from './DialogCancelarViaje';
import { isUndefined } from 'lodash';
import EditIcon from "@mui/icons-material/Edit";
import DialogEditarVehiculo from './DialogEditarVehiculo';
import { v4 as uuidv4 } from "uuid"; 
import DrawerVehiculos from './DrawerVehiculos';
import { logEventos } from '../../services/services';

const empresas = [
  {id:1, nombre:"HIPERGAS"},
  {id:4, nombre:"NATURALGAS"}
]

const tipos = [
  {id:1, nombre:'butano'},
  {id:2, nombre:'propano'},
  {id:3, nombre:'propelente'},
  {id:4, nombre:'bso'}
]

const vehiculoSinTransportista = [
  {id:1, patente:'Elija Tranportista'},
]

const HeaderCell = styled(TableCell)({
  marginTop: "2px",
  fontSize: 13,
  color: "white",
  fontWeight: "normal",
  backgroundColor: "#2471A3",
  borderColor: "white",
  padding: "6.3px",
});
  
const TotalCell = styled(TableCell)({
  padding: "6px",
});

function Viajes_abastecimiento() {
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
const [datosViaje, setDatosViaje] = useState({
  tipo_viaje:"",
  empresa: null,
})
const [uuid, setUuid] = useState(uuidv4());
//console.log("UUID", uuid);
const [datosRow, setDatosRow] = useState()
const [cancelar, setCancelar] = useState(false)

useEffect(() => {
  logEventos("Acceso al menú viajes", "Viajes", "Cargar nuevos viajes", localStorage.getItem("user"))
}, [])

const handleClickCancelar = (row) => {
  setDatosRow(row);
  setCancelar(true)
};
const handleCloseCancelar = () => {
  obtenerSolicitudesProvee()
  obtenerSolicitudes()
  obtenerViajes()
  obtenerVehiculos()
  setCancelar(false);
};

const [editar, setEditar] = useState(false)
const handleClickEditar = (row) => {
  setDatosRow(row);
  setEditar(true)
};
const handleCloseEditar = () => {
  obtenerSolicitudesProvee()
  obtenerSolicitudes()
  obtenerViajes()
  obtenerVehiculos()
  setEditar(false);
};


const [destinos, setDestinos] = useState();
const [destinoSeleccionado, setDestinoSeleccionado] = useState({})
//funcion para obtener articulos
const obtenerDestinos = () => {
  fetch(UrlApi + "/sucursales/tipoPlanta")
    .then((data) => data.json())
    .then((data) => setDestinos(data));
};

useEffect(() => {
  obtenerDestinos();
}, []);

const [transportistas, setTransportistas] = useState();
const [transportistaSeleccionado, setTransportistaSeleccionado] = useState({})
//funcion para obtener articulos
const obtenerTransportistas = () => {
  fetch(UrlApi + "/transportistas/activos")
    .then((data) => data.json())
    .then((data) => setTransportistas(data));
};

useEffect(() => {
  obtenerTransportistas();
}, []);
//console.log("DESTINO SELECCIONADO", destinoSeleccionado);

const [bocas, setBocas] = useState();
const [bocaSeleccionada, setBocaSeleccionada] = useState({})
//funcion para obtener articulos
const obtenerBoca = () => {
  fetch(UrlApi + "/bocas_carga")
    .then((data) => data.json())
    .then((data) => setBocas(data));
};

useEffect(() => {
  obtenerBoca();
}, []);

//console.log("BOCA SELECCIONADO", bocaSeleccionada, bocas);

const [drawerOpen, setDrawerOpen] = React.useState(false);

const handleAbrirDrawer = () => {
  setDrawerOpen(true);
};

const handleCerrarDrawer = () => {
  setDrawerOpen(false);
};

//********************************************* CALCULO DE FECHAS *********************************************//
const fechaActual = moment();
//console.log(fechaActual);
// Calcula la fecha del próximo lunes
const lunesProximo = fechaActual.clone().day(8); // 8 representa el día lunes de la próxima semana

// Obtén los nombres y fechas de los días de la semana para la próxima semana
const diasProximaSemana = [];
for (let i = 0; i < 6; i++) {
    const fechaDia = lunesProximo.clone().add(i, "days");
    diasProximaSemana.push({
        id: i, // Utilizamos la posición en el bucle como id
        nombre: fechaDia.format("dddd"),
        fecha: fechaDia.format("YYYY-MM-DD"),
    });
}
const [fechaBoton, setFechaBoton] = useState(lunesProximo);

const [vehiculos, setVehiculos] = useState();
const [vehiculoSeleccionado, setVehiculoSeleccionado] = useState({})
//funcion para obtener articulos
const obtenerVehiculos = () => {
  fetch(UrlApi + "/vehiculos/vehict1/fechaytransportista/"+ fechaBoton.format("YYYY-MM-DD")+"/"+transportistaSeleccionado.id_transportista)
    .then((data) => data.json())
    .then((data) => setVehiculos(data));
};

useEffect(() => {
  obtenerVehiculos();
}, [transportistaSeleccionado]);

const [dataDrawer, setDataDrawer] = useState();

const obtenerVehiculosDrawer = () => {
  fetch(UrlApi + "/vehiculos/vehict1/drawer/"+ transportistaSeleccionado.id_transportista)
    .then((data) => data.json())
    .then((data) => setDataDrawer(data));
};

useEffect(() => {
  obtenerVehiculosDrawer();
}, [transportistaSeleccionado]);

//console.log("VEHICULO SELECCIONADO", vehiculoSeleccionado);

//***********************************************************************************************************//
const irHaciaAtras = () => {
    setFechaBoton(prevFecha => prevFecha.clone().subtract(1, 'day'));
};

  // Función para navegar hacia adelante en los días
const irHaciaAdelante = () => {
    setFechaBoton(prevFecha => prevFecha.clone().add(1, 'day'));
};
//***********************************************************************************************************//
//********************************************* CALCULO DE FECHAS SOLICITUDES*********************************************//
const fechaActualSoli = moment();
//console.log(fechaActualSoli);
// Calcula la fecha del próximo lunes
const lunesProximoSoli = fechaActualSoli.clone().day(8); // 8 representa el día lunes de la próxima semana

// Obtén los nombres y fechas de los días de la semana para la próxima semana
const diasProximaSemanaSoli = [];
for (let i = 0; i < 6; i++) {
    const fechaDiaSoli = lunesProximoSoli.clone().add(i, "days");
    diasProximaSemanaSoli.push({
        id: i, // Utilizamos la posición en el bucle como id
        nombre: fechaDiaSoli.format("dddd"),
        fecha: fechaDiaSoli.format("YYYY-MM-DD"),
    });
}
const [fechaBotonSoli, setFechaBotonSoli] = useState(lunesProximoSoli);

//***********************************************************************************************************//
const irHaciaAtrasSoli = () => {
    setFechaBotonSoli(prevFecha => prevFecha.clone().subtract(1, 'day'));
};

  // Función para navegar hacia adelante en los días
const irHaciaAdelanteSoli = () => {
    setFechaBotonSoli(prevFecha => prevFecha.clone().add(1, 'day'));
};
//***************************************VIAJES********************************************************************//
const fechaActualViaje = moment();
//console.log(fechaActualViaje);
// Calcula la fecha del próximo lunes
const lunesProximoViaje = fechaActualViaje.clone().day(8); // 8 representa el día lunes de la próxima semana

// Obtén los nombres y fechas de los días de la semana para la próxima semana
const diasProximaSemanaViaje = [];
for (let i = 0; i < 6; i++) {
    const fechaDiaViaje = lunesProximoViaje.clone().add(i, "days");
    diasProximaSemanaViaje.push({
        id: i, // Utilizamos la posición en el bucle como id
        nombre: fechaDiaViaje.format("dddd"),
        fecha: fechaDiaViaje.format("YYYY-MM-DD"),
    });
}
const [fechaBotonViaje, setFechaBotonViaje] = useState(lunesProximoViaje);

//***********************************************************************************************************//
const irHaciaAtrasViaje = () => {
    setFechaBotonViaje(prevFecha => prevFecha.clone().subtract(1, 'day'));
};

  // Función para navegar hacia adelante en los días
const irHaciaAdelanteViaje = () => {
    setFechaBotonViaje(prevFecha => prevFecha.clone().add(1, 'day'));
};
//***********************************************************************************************************//
const [viajes, setViajes] = useState();
const obtenerViajes = () => {
    fetch(
      UrlApi +
        "/viajes_abastecimiento/tabla/" + fechaBotonViaje.format("YYYY-MM-DD")
        )
      .then((data) => data.json())
      .then((data) => setViajes(data));
  };
  useEffect(() => {
    obtenerViajes();
  }, [fechaBotonViaje]);

  //console.log("VIAJESSSSS", viajes);

const [solicitudes, setSolicitudes] = useState();
const obtenerSolicitudes = () => {
    fetch(
      UrlApi +
        "/solicitudes_abastecimiento/enfecha/'" + fechaBotonSoli.format("YYYY-MM-DD") +"'"
        )
      .then((data) => data.json())
      .then((data) => setSolicitudes(data));
  };
  useEffect(() => {
    obtenerSolicitudes();
  }, [fechaBotonSoli]);

const [solicitudesProvee, setSolicitudesProvee] = useState();
const obtenerSolicitudesProvee = () => {
    fetch(
      UrlApi +
        "/proveedores_abastecimiento/enfecha/'" + fechaBoton.format("YYYY-MM-DD") +"'"
        )
      .then((data) => data.json())
      .then((data) => setSolicitudesProvee(data));
  };
  useEffect(() => {
    obtenerSolicitudesProvee();
  }, [fechaBoton]);
//console.log("fecha boton", solicitudes, solicitudesProvee);

const obtenerDatosPorFechaYSucursal = (sucursalId, tipoGas, solicitudesProvee, empresa) => {
  // Filtrar los datos de abastecimiento por fecha, sucursal y empresa
  if (solicitudesProvee) {
    const datosFiltrados = solicitudesProvee.filter((dato) => {
      return dato.proveedor === sucursalId && dato.empresa_destino === empresa;
    });

    // Verificar si hay datos filtrados
    if (datosFiltrados.length > 0) {
      const dato = datosFiltrados[0]; // Supongamos que solo hay un dato por celda
      // Devolver el valor correspondiente al tipo de gas
      if (tipoGas === 'viaje_butano') {
        return dato.viaje_butano || "-";
      } else if (tipoGas === 'viaje_propano') {
        return dato.viaje_propano || "-";
      } else if (tipoGas === 'viaje_bso'){
        return dato.viaje_bso || "-";
      } else if (tipoGas === 'viaje_propelente'){
        return dato.viaje_propelente || "-";
      }
    }
  }
  return "-";
};


const obtenerDatosPorFechaYSucursalSoli = ( sucursalId, tipoGas) => {
  // Filtrar las solicitudes por fecha y sucursal
  if (solicitudes) {
    const solicitudesFiltradas = solicitudes.filter((solicitud) => {
      return solicitud.id_sucursal === sucursalId;
    });

    // Verificar si hay datos filtrados
    if (solicitudesFiltradas.length > 0) {
      const solicitud = solicitudesFiltradas[0]; // Supongamos que solo hay una solicitud por celda
      // Devolver solo el valor numérico del tipo de gas correspondiente
      return solicitud[tipoGas] || 0;
    } else {
      return "-";
    }
  }
};

//*************************************************** Descontar proveedores****************************************************************** */

const [paraDescontar, setParaDescontar] = useState();

//funcion para obtener articulos
const obtenerParaDescontar = () => {
  fetch(UrlApi + "/proveedores_abastecimiento/paradescontar/"+datosViaje.empresa+"/"+bocaSeleccionada.idbocas_carga+"/"+fechaBoton.format("YYYY-MM-DD"))
    .then((data) => data.json())
    .then((data) => setParaDescontar(data));
};

useEffect(() => {
  obtenerParaDescontar();
}, [datosViaje.empresa, bocaSeleccionada, fechaBoton]);

//console.log("para Descontar", paraDescontar, datosViaje.tipo_viaje);

let clave = null
let tipoViaje = "viaje_"+datosViaje.tipo_viaje;


if (paraDescontar && tipoViaje) {
  // Verificar si paraDescontar es un array y tiene al menos un elemento
  if (Array.isArray(paraDescontar) && paraDescontar.length > 0) {
    // Obtener el primer elemento del array
    const primerElemento = paraDescontar[0];
    // Iterar sobre las claves del primer elemento
    for (let columna in primerElemento) {
      // Verificar si la columna coincide con tipoViaje
      if (columna === tipoViaje) {
        // Obtener el valor correspondiente
        clave = primerElemento[columna];
        break; // Romper el bucle una vez encontrado el valor
      }
    }
  }
}

let clavebd = tipoViaje

let valor = clave - parseInt(1);
//console.log("Valor correspondiente:", clave, valor, clavebd);
// Crear un objeto con la clave variable y el valor deseado
let bodyData = {};
bodyData[clavebd] = valor;

//*************************************************** Descontar solicitudes ****************************************************************** */

const [paraDescontarSoli, setParaDescontarSoli] = useState();

//funcion para obtener articulos
const obtenerParaDescontarSoli = () => {
  fetch(UrlApi + "/solicitudes_abastecimiento/paradescontar/"+destinoSeleccionado.id+"/"+fechaBotonSoli.format("YYYY-MM-DD"))
    .then((data) => data.json())
    .then((data) => setParaDescontarSoli(data));
};

useEffect(() => {
  obtenerParaDescontarSoli();
}, [destinoSeleccionado, fechaBotonSoli]);

//console.log("para Descontar", paraDescontarSoli, datosViaje.tipo_viaje);

let claveSoli = null
let tipoViajeSoli = "viaje_"+datosViaje.tipo_viaje;


if (paraDescontarSoli && tipoViajeSoli) {
  // Verificar si paraDescontar es un array y tiene al menos un elemento
  if (Array.isArray(paraDescontarSoli) && paraDescontarSoli.length > 0) {
    // Obtener el primer elemento del array
    const primerElemento = paraDescontarSoli[0];
    // Iterar sobre las claves del primer elemento
    for (let columna in primerElemento) {
      // Verificar si la columna coincide con tipoViaje
      if (columna === tipoViajeSoli) {
        // Obtener el valor correspondiente
        claveSoli = primerElemento[columna];
        break; // Romper el bucle una vez encontrado el valor
      }
    }
  }
}

let clavebdsoli = tipoViajeSoli

let valorSoli = claveSoli - parseInt(1);
//console.log("Valor correspondiente Soli:", claveSoli, valorSoli, clavebdsoli);
// Crear un objeto con la clave variable y el valor deseado
let bodyDataSoli = {};
bodyDataSoli[clavebdsoli] = valorSoli;



//********************************************************************************************************************* */
function limpiarCampos() {
  setDatosViaje({
    tipo_viaje: "",
    empresa: null
  })
  setBocaSeleccionada({})
  setDestinoSeleccionado({})
  setVehiculoSeleccionado({})
}
const [alerta, setAlerta] = useState(false)
const handleClose = () => {
  setAlerta(false)
};

const guardarViaje = async (e) => {
  logEventos("Guardar viaje", "Viajes", "Se guardo el viaje", localStorage.getItem("user"))
  if (datosViaje.tipo_viaje === "propelente" || datosViaje.tipo_viaje === "bso") {

    e.preventDefault();
    // setDisabledGuardar(true);
    await fetch(UrlApi + "/viajes_abastecimiento", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fecha: fechaBoton.format("YYYY-MM-DD"),
        tipo_viaje: datosViaje.tipo_viaje,
        proveedor: bocaSeleccionada.idbocas_carga,
        empresa: datosViaje.empresa,
        destino: destinoSeleccionado.id,
        vehiculo: vehiculoSeleccionado.id,
        fecha_llegada: fechaBotonSoli.format("YYYY-MM-DD"),
        usuario: localStorage.getItem("user"),
        estado: 229,
        uuid: uuid,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El viaje se guardó exitosamente !");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el viaje");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
      // PUT DE DESCUENTO DE PROVEEDORES
      await fetch(UrlApi + "/proveedores_abastecimiento/descuento/"+datosViaje.empresa+"/"+bocaSeleccionada.idbocas_carga+"/"+fechaBoton.format("YYYY-MM-DD"), {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bodyData),
      })
        .then((res) => {
          if (!res.err) {
            console.log("Guardado");
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
        obtenerSolicitudesProvee()
        obtenerSolicitudes()
        obtenerViajes()
        obtenerVehiculos()
        limpiarCampos()
        setUuid(uuidv4())
  } else {

    if (valor < 0 || valorSoli < 0) {
      setAlerta(true)
    } else {
    e.preventDefault();
    // setDisabledGuardar(true);
    await fetch(UrlApi + "/viajes_abastecimiento", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fecha: fechaBoton.format("YYYY-MM-DD"),
        tipo_viaje: datosViaje.tipo_viaje,
        proveedor: bocaSeleccionada.idbocas_carga,
        empresa: datosViaje.empresa,
        destino: destinoSeleccionado.id,
        vehiculo: vehiculoSeleccionado.id,
        fecha_llegada: fechaBotonSoli.format("YYYY-MM-DD"),
        usuario: localStorage.getItem("user"),
        estado:229,
        uuid: uuid,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El viaje se guardó exitosamente !");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el viaje");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
      // PUT DE DESCUENTO DE PROVEEDORES
      await fetch(UrlApi + "/proveedores_abastecimiento/descuento/"+datosViaje.empresa+"/"+bocaSeleccionada.idbocas_carga+"/"+fechaBoton.format("YYYY-MM-DD"), {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bodyData),
      })
        .then((res) => {
          if (!res.err) {
            console.log("Guardado");
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });

      (datosViaje.tipo_viaje === 'butano' || datosViaje.tipo_viaje === 'propano'?( 
        // PUT DE DESCUENTO DE SOLICITUDES
      await fetch(UrlApi + "/solicitudes_abastecimiento/descuento/"+destinoSeleccionado.id+"/"+fechaBotonSoli.format("YYYY-MM-DD"), {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bodyDataSoli),
      })
        .then((res) => {
          if (!res.err) {
            console.log("Guardado");
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        }))
        :console.log("NOOOOOO"))

        obtenerSolicitudesProvee()
        obtenerSolicitudes()
        obtenerViajes()
        obtenerVehiculos()
        limpiarCampos()
        setUuid(uuidv4())
    }
  }
}


const almacenar = (e) => {
  setDatosViaje({
    ...datosViaje,
    [e.target.name]: e.target.value,
  });
};

//console.log("datos viaje", datosViaje);
const handleCloseSnack = (event, reason) => {
  if (reason === "clickaway") {
    return;
  }
  setOpenSnack(false);
};


const muiSearchTextFieldProps = {
  placeholder: "Buscar..",
  sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
  variant: "outlined",
  color: "primary",
  size: "small",
};

const tableHeadCellProps = {
  align: "left",
  sx: {
    fontSize: 16,
    color: "white",
    fontWeight: "normal",
    backgroundColor: "#2471A3",
    borderColor: "black",
  },
};

const globalTheme = useTheme();
const tableTheme = useMemo(
  () =>
    createTheme({
      palette: {
        mode: globalTheme.palette.mode,
        primary: globalTheme.palette.primary, //primary color for the table
        info: {
          main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
        },
        background: {
          default:
            globalTheme.palette.mode === "light"
              ? "rgb(234, 239, 241)"
              : "#000", //pure black table in dark mode for fun
        },
      },
      typography: {
        button: {
          textTransform: "none", //customize typography styles for all buttons in table by default
          fontSize: "1rem",
        },
      },
      components: {
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: "0.8rem",
            },
          },
        },
      },
    }),
  [globalTheme]
);


const initialState = {
  //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
  density: "compact",
  showGlobalFilter: true,
  pagination: { pageIndex: 0, pageSize: 5 },
};

const columns = useMemo(
  () => [
    { header: "ID", accessorKey: "id_viaje", size: 25 },
    { header: "Inicio", accessorKey: "fecha", size: 80 },
    { header: "Llegada", accessorKey: "fecha_llegada", size: 80 },
    { header: "Producto", accessorKey: "tipo_viaje", size: 70 },
    { header: "Proveedor", accessorKey: "prov_boca", size: 70 },
    { header: "Ubicación Proveedor", accessorKey: "nombre_boca", size: 80 },
    { header: "Para Empresa", accessorKey: "empresa_nombre", size: 80 },
    { header: "Destino", accessorKey: "nombre_sucursal", size: 80 },
    { header: "Vehiculo", accessorKey: "patente", size: 80 },
  ],
  []
);

const [habilitado, setHabilitado] = useState(false)
useEffect(() => {
  if (datosViaje.tipo_viaje === "" || Object.keys(bocaSeleccionada).length === 0 || Object.keys(destinoSeleccionado).length === 0 || Object.keys(vehiculoSeleccionado).length === 0 || datosViaje.empresa === null) {
   // console.log("DESHABILITADO");
    setHabilitado(true)
  } else {
    //console.log("HABILITADO")
    setHabilitado(false)
  }
}, [datosViaje,bocaSeleccionada,destinoSeleccionado,vehiculoSeleccionado])


  return (
    <>
    <div style={{justifyContent: 'center', height: '10%'}}>
    <Navbar/>
    <Box
    sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > *': {
          m: 1,
          marginTop: 2,
          marginLeft: 12,
          marginRight: 12,
        },
      }}
    >
    <ButtonGroup variant="contained" aria-label="Large button group">
        <Button onClick={irHaciaAtras} startIcon={<ArrowBackIcon/>}></Button>
        <Button>{fechaBoton.format("dddd, DD-MM-YYYY")}</Button>
        <Button onClick={irHaciaAdelante} endIcon={<ArrowForwardIcon/>}></Button>
    </ButtonGroup>

    <Button onClick={handleAbrirDrawer} variant='contained' size='small' sx={{margin: 1, marginTop: 1}}>Ver Vehiculos</Button>

    <ButtonGroup variant="contained" aria-label="Large button group">
        <Button onClick={irHaciaAtrasSoli} startIcon={<ArrowBackIcon/>}></Button>
        <Button>{fechaBotonSoli.format("dddd, DD-MM-YYYY")}</Button>
        <Button onClick={irHaciaAdelanteSoli} endIcon={<ArrowForwardIcon/>}></Button>
    </ButtonGroup>
    </Box>
    </div>
    <div style={{ justifyContent: 'center', display: 'flex', height: '65%' }}>
          {/* Comienzo de la tabla de solicitudes de abastecimiento */}
    <div style={{ borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black', width:'32%' }}>
      <Table>
        <TableHead>
          <TableRow>
          <HeaderCell align="center" colSpan={1}></HeaderCell>

          <React.Fragment >
          <HeaderCell align="center" colSpan={2} sx={{borderLeft: "1px solid white", borderRight: "3px solid white"}}>
            <b>NaturalGas</b>
          </HeaderCell>
          <HeaderCell align="center" colSpan={4} sx={{borderLeft: "1px solid white"}}>
            <b>HiperGas</b>
          </HeaderCell>
        </React.Fragment>
          </TableRow>
          <TableRow>
      <HeaderCell ></HeaderCell>
            <React.Fragment >
              <HeaderCell align="center" colSpan={1} sx={{borderLeft: "1px solid white", borderRight: "1px solid white"}}>
                <b>B</b>
              </HeaderCell>
              <HeaderCell align="center" colSpan={1} sx={{borderLeft: "1px solid white", borderRight: "3px solid white"}}>
                <b>P</b>
              </HeaderCell>
              <HeaderCell align="center" colSpan={1} sx={{borderLeft: "1px solid white"}}>
                <b>B</b>
              </HeaderCell>
              <HeaderCell align="center" colSpan={1} sx={{borderLeft: "1px solid white"}}>
                <b>P</b>
              </HeaderCell>
              <HeaderCell align="center" colSpan={1} sx={{borderLeft: "1px solid white", borderRight: "1px solid white"}}>
                <b>BSO</b>
              </HeaderCell>
              <HeaderCell align="center" colSpan={1} sx={{borderLeft: "1px solid white"}}>
                <b>PPTE</b>
              </HeaderCell>
            </React.Fragment>
    </TableRow>
        </TableHead>
        <TableBody>
          {solicitudesProvee? 
          solicitudesProvee
          // Filtrar las solicitudes únicas por proveedor
          .filter((solip, index, self) =>
            index === self.findIndex((s) => s.proveedor === solip.proveedor)
          )
          // Mapear las solicitudes únicas
          .map((solip) => (
            <React.Fragment key={solip.id}>
              <TableRow>
                <HeaderCell >
                  {solip.prove}-{solip.nombre_boca}  
                </HeaderCell>
                <React.Fragment key={solip.id}>
                  <TotalCell
                    align="center"
                    sx={{ borderLeft: "1px solid black", borderRight: "1px solid black" }}
                  >
                    {obtenerDatosPorFechaYSucursal(solip.proveedor, "viaje_butano", solicitudesProvee, 4)}
                  </TotalCell>
                  <TotalCell
                    align="center"
                    sx={{ borderLeft: "1px solid black", borderRight: "3px solid black" }}
                  >
                    {obtenerDatosPorFechaYSucursal(solip.proveedor, "viaje_propano", solicitudesProvee, 4)}
                  </TotalCell>
                  <TotalCell
                    align="center"
                    sx={{ borderLeft: "1px solid black", borderRight: "1px solid black" }}
                  >
                    {obtenerDatosPorFechaYSucursal(solip.proveedor, "viaje_butano", solicitudesProvee, 1)}
                  </TotalCell>
                  <TotalCell
                    align="center"
                    sx={{ borderLeft: "1px solid black", borderRight: "1px solid black" }}
                  >
                    {obtenerDatosPorFechaYSucursal(solip.proveedor, "viaje_propano", solicitudesProvee, 1)}
                  </TotalCell>
                  <TotalCell
                    align="center"
                    sx={{ borderLeft: "1px solid black", borderRight: "1px solid black" }}
                  >
                    {obtenerDatosPorFechaYSucursal(solip.proveedor, "viaje_bso", solicitudesProvee, 1)}
                  </TotalCell>
                  <TotalCell
                    align="center"
                    sx={{ borderLeft: "1px solid black"}}
                  >
                    {obtenerDatosPorFechaYSucursal(solip.proveedor, "viaje_propelente", solicitudesProvee, 1)}
                  </TotalCell>
                </React.Fragment>
              </TableRow>
            </React.Fragment>
          ))   
        :null}
        </TableBody>
      </Table>
    </div>

    {/* Comienzo del formulario para la carga del viaje */}
    <div style={{ borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black', width:'32%', marginLeft: '10px', marginRight: '10px' }}>
        <Box
          bgcolor="#2B76A6"
          padding={2}
          borderRadius={0}
          color="white"
          textAlign="center"
          display="flex" // Agregado para que los elementos se coloquen en línea
          justifyContent="center" 
          >
          <Typography variant="h5" alignSelf={'center'} component="div" marginTop={1}>
            Cargar Viaje
          </Typography>
        </Box>
        <Grid container>
            <Grid item xs={12} m={1} mt={2}>
            <FormControl focused sx={{width: "100%"}}>
              <InputLabel>Producto</InputLabel>
                <Select
                  label="Producto"
                  required
                  size="small"
                  name="tipo_viaje"
                  value={datosViaje.tipo_viaje}
                  onChange={almacenar}
                  inputProps={{ "aria-label": "Without label" }}
                  >
                  {tipos
                    ? tipos.map((elemento) => (
                      <MenuItem key={elemento.id} value={elemento.nombre}>
                          {elemento.nombre.toUpperCase()}
                        </MenuItem>
                      ))
                      : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} m={1} mt={2}>
              <FormControl focused sx={{width: "100%"}}>
              <InputLabel>Para Empresa</InputLabel>
                <Select
                  label="Para Empresa"
                  required
                  size="small"
                  name="empresa"
                  value={datosViaje.empresa}
                  onChange={almacenar}
                  inputProps={{ "aria-label": "Without label" }}
                  >
                  {empresas
                    ? empresas.map((elemento) => (
                      <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.nombre}
                        </MenuItem>
                      ))
                      : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} m={1} mt={2}>
              {openSnack? 
              (<TextField
              // value={(bocaSeleccionada.idbocas_carga = null)}
              disabled
              focused
              fullWidth
              size="small"
              label= "Guardando viaje..."
              />)
              :
              (<Autocomplete
                // disabled={!props.articulos}
                size="small"
                focused
                disableClearable
                id="proveedor"
                noOptionsText={"Proveedor INEXISTENTE"}
                options={bocas}
                autoHighlight
                getOptionLabel={(boca) =>
                  boca.proveedor + "-" + boca.nombre_boca
                }
                onChange={(event, value) =>
                  setBocaSeleccionada(value)
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    key={option.id}
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.proveedor}-{option.nombre_boca}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    // style={style}
                    focused
                    required
                    size="small"
                    placeholder="Tipee para buscar..."
                    {...params}
                    label="Proveedor"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
              )}
            </Grid>
            <Grid item xs={12} m={1} mt={1}>
            {openSnack? 
            (<TextField
            // value={(destinoSeleccionado.id = null)}
            disabled
            focused
            fullWidth
            size="small"
            label= "Guardando viaje..."
            />)
            :
              (<Autocomplete
                // disabled={!props.articulos}
                size="small"
                focused
                disableClearable
                id="destino"
                noOptionsText={"Destino INEXISTENTE"}
                options={destinos}
                autoHighlight
                getOptionLabel={(destino) =>
                  destino.id + "-" + destino.nombre
                }
                onChange={(event, value) =>
                  setDestinoSeleccionado(value)
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    key={option.id}
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.id}-{option.nombre}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    // style={style}
                    focused
                    required
                    size="small"
                    placeholder="Tipee para buscar..."
                    {...params}
                    label="Destino"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
              )}
            </Grid>
            <Grid item xs={12} m={1} mt={1}>
            {openSnack? 
            (<TextField
            // value={(destinoSeleccionado.id = null)}
            disabled
            focused
            fullWidth
            size="small"
            label= "Guardando viaje..."
            />)
            :
              (<Autocomplete
                // disabled={!props.articulos}
                size="small"
                focused
                disableClearable
                id="transportista"
                noOptionsText={"Transportista INEXISTENTE"}
                options={transportistas}
                autoHighlight
                getOptionLabel={(transportista) =>
                  transportista.id_transportista + "-" + transportista.nombre
                }
                onChange={(event, value) =>
                  setTransportistaSeleccionado(value)
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    key={option.id}
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.id_transportista}-{option.nombre}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    // style={style}
                    focused
                    required
                    size="small"
                    placeholder="Tipee para buscar..."
                    {...params}
                    label="Transportista"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
              )}
            </Grid>
            <Grid item xs={12} m={1} mt={2}>
              {openSnack? 
              (<TextField
              // value={(vehiculoSeleccionado.idvehiculo = null)}
              disabled
              focused
              fullWidth
              size="small"
              label= "Guardando viaje..."
              />)
              :
              (<Autocomplete
                // disabled={!props.articulos}
                size="small"
                focused
                disableClearable
                id="vehiculo"
                noOptionsText={"vehiculo INEXISTENTE"}
                options={vehiculos?vehiculos:vehiculoSinTransportista}
                autoHighlight
                getOptionLabel={(vehiculo) =>
                  vehiculo.id + "-" + vehiculo.patente
                }
                onChange={(event, value) =>
                  setVehiculoSeleccionado(value)
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    key={option.id}
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.id}-{option.patente}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    // style={style}
                    focused
                    required
                    size="small"
                    placeholder="Tipee para buscar..."
                    {...params}
                    label="Vehiculo"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
              )}
            </Grid>
        </Grid> 
        <Stack direction="row">
          <Grid container  direction={{ xs: "column", sm: "row" }}>
            <Grid item xs={3} ></Grid>
            <Grid item xs={6} mt={2} mb={1}>
              <Button
                fullWidth
                variant="contained"
                color="success"
                onClick={guardarViaje}
                disabled={habilitado}
              >
                Guardar
              </Button>
            </Grid>
            <Grid item xs={3} ></Grid>
          </Grid>
        </Stack>
    </div>
    {/* Comienzo de la tabla de solicitudes de abastecimiento */}
    <div style={{ borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black', width:'32%' }}>
    <Table>
        <TableHead>
          <TableRow>
          <HeaderCell align="center" colSpan={1} ></HeaderCell>

          <React.Fragment >
          <HeaderCell align="center" colSpan={1} sx={{borderLeft: "1px solid white", borderRight: "1px solid white"}}>
                <b>B</b>
              </HeaderCell>
              <HeaderCell align="center" colSpan={1} sx={{borderLeft: "1px solid white"}}>
                <b>P</b>
          </HeaderCell>
        </React.Fragment>
          </TableRow>
        </TableHead>
        <TableBody>
        {solicitudes
      ? solicitudes.map((soli) => (
          <React.Fragment key={soli.id}>
            <TableRow>
              <HeaderCell >
                {soli.nombre}
              </HeaderCell>
              <TotalCell align="center" sx={{borderLeft: "1px solid black", borderRight: "1px solid black"}}>
                    {obtenerDatosPorFechaYSucursalSoli( soli.id_sucursal, 'viaje_butano')}
                  </TotalCell>
                  <TotalCell align="center" sx={{borderLeft: "1px solid black"}}>
                    {obtenerDatosPorFechaYSucursalSoli( soli.id_sucursal, 'viaje_propano')}
                  </TotalCell>
              </TableRow>
          </React.Fragment>
        ))
      : null}
        </TableBody>
      </Table>
    </div>
    </div>
    <div style={{ justifyContent: 'center', display: 'flex' }}>
    <div style={{ justifyContent: 'center' , borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black', width:'98%', marginTop: '10px' }}>
      {viajes?(

        <Box  >
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
              enableGrouping // para permitir agrupar x estado x ej
              layoutMode="grid"
              muiTableHeadCellProps={tableHeadCellProps}
              muiTableT
              muiTableBodyCellProps={({ row }) => ({
                align: "left",
                sx: {
                  color: "black",
                  borderColor: "black",
                },
              })}
              enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
              enableColumnFilters={false} //desactiva filtros x  columna
              enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
              enableDensityToggle={false} //
              localization={MRT_Localization_ES} //idioma
              enableColumnDragging={false} //para arrastrar columnas
              initialState={initialState}
              muiSearchTextFieldProps={muiSearchTextFieldProps}
              columns={columns}
              data={viajes} //con lo q la completas (esto cambia siempre)
              enableStickyFooter
              enableEditing
              positionActionsColumn="last" // posicion de la columna de acciones al final
              // ACCIONES
              renderToolbarInternalActions={(
                { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                  {/* <MRT_FullScreenToggleButton table={table} /> */}
                </>
              )}
              renderTopToolbarCustomActions={() => (
                <>
                <div >
                  <Typography variant="poster" style={{ fontSize: 26, textAlign: "center" }}>
                  Viajes
                  </Typography>
               </div>
               <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ButtonGroup variant="contained" aria-label="Large button group">
                    <Button onClick={irHaciaAtrasViaje} startIcon={<ArrowBackIcon/>}></Button>
                    <Button>{fechaBotonViaje.format("dddd, DD-MM-YYYY")}</Button>
                    <Button onClick={irHaciaAdelanteViaje} endIcon={<ArrowForwardIcon/>}></Button>
                  </ButtonGroup>
               </div>
                </>
              )}
              renderRowActions={(
                { row, table } //las acciones del costado editar y ver
              ) => (
                <div>
                  <Tooltip arrow placement="top" title="Editar Vehículo">
                      <IconButton
                        onClick={() =>
                          handleClickEditar(row.original)
                        }
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  <Tooltip arrow placement="left" title="Cancelar Viaje">
                    <IconButton
                      onClick={() => handleClickCancelar(row.original)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                )}
              />
          </ThemeProvider>
        </Box>
    ):null}
    </div>
    </div>
    <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
            >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
      <br></br>
      <br></br>
      {alerta?(
        <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
        open={alerta}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
      <Alert severity="warning" onClose={handleClose} >Atención
           <Typography>No se puede guardar un viaje si no hay una solicitud y/o proveedor disponible</Typography>
       </Alert>
       </Snackbar>
          </Stack>
       ):null}

    {cancelar? 
      <DialogCancelarViaje
      cancelar={cancelar}
      datosRow={datosRow}
      onClose={() => handleCloseCancelar(false)}
      />
      :null}
      {editar? 
      <DialogEditarVehiculo
      editar={editar}
      datosRow={datosRow}
      vehiculos={vehiculos}
      onClose={() => handleCloseEditar(false)}
      />
      :null}
      {drawerOpen?
      <DrawerVehiculos 
      isOpen={drawerOpen} 
      onClose={handleCerrarDrawer} 
      dataDrawer={dataDrawer}
      />
      :null}
    </>
  )
}

export default Viajes_abastecimiento