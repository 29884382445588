import React, { useEffect, useState, useMemo } from 'react'
import { Grid, Typography, Dialog, Box, IconButton, Button, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { UrlApi } from "../../../../services/apirest";
import { grey } from "@mui/material/colors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputAdornment from "@mui/material/InputAdornment";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Notify from '../../../Notify';
import Alerta from '../../../Alerta';
import { createTheme, ThemeProvider, useTheme } from "@mui/material";


export default function Llegada(props) {
  //datos para el formulario del reparto
  const [datosreparto, setDatosreparto] = useState({
    // kgentregados: null,
    peso_bruto_final: null,
    km_finales: null,
    usuario_chofercierra: null
  });

  const [choferes, setChoferes] = useState();
  const [total, setTotal] = useState(0); //con esto guardamos el total de kg entregados
  const [alta, setAlta] = useState(true);
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)  
  const [disbtn, setdisbtn] = useState(false);
  // const obtenerTotal = () => {
  //   fetch(UrlApi + '/repartos/total/' + props.datos.nro_reparto)
  //     .then(data => data.json())
  //     .then(data => setTotal(data))
  // };

  const obtenerchoferes = () => {
    fetch(UrlApi + '/choferes/granel') //fetch con la urp de api q esta en services/apirest + la ruta
      .then(data => data.json()) //mando los datos que recibo en json
      .then(data => setChoferes(data)) //seteo choferes los json 
  };


  const handleGuardar = async (e) => {
    setdisbtn(true);
    e.preventDefault();
    let response = await fetch(UrlApi + "/repartos/" + props.datos.idreparto, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        // kgentregados: total[0].kgentregados,
        kgentregados: datosreparto.kgentregados,
        km_finales: datosreparto.km_finales,
        estado: 9,
        usuario: localStorage.getItem('user')
      })
    })
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
          );
          props.actualizar()
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          props.actualizar()
        } else {
          alert("Error: " + error);
        }
      }
    }
    handleClose();
    console.log(UrlApi + "/repartos/" + props.datos.idreparto)
  };

  const almacenar = (e) => {
    setDatosreparto({
      ...datosreparto,
      [e.target.name]: e.target.value
    })
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%"
  };

  // useEffect(() => {
  //   obtenerTotal();
  // }, []);

  useEffect(() => {
    obtenerchoferes()
  }, []);


  const handleClose = () => { return (props.open(false)) }

  return (
    <div>
      {alta ? (
        <Dialog open={props.open} onClose={handleClose} fullWidth>
          <form onSubmit={handleGuardar} autoComplete="off" >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container backgroundColor="#1F618D" p={1.5}>
                <Grid item xs={2}>
                  <IconButton onClick={handleClose}>
                    <ArrowBackIcon sx={{ color: grey[50] }} />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={8}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h5" color="#ffffff">
                    Registrar los datos de la llegada del reparto Nro: {props.datos.id_reparto}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box
              backgroundColor="#F4F6F6"
              mt={2}
              p={3}
              border={0.5}
              borderColor={"#ABB2B9"}
              borderRadius={4}
              mb={2}
              ml={1}
              mr={1}
            >
              <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                <Grid item xs={4}>
                  <FormControl style={style} margin='dense' focused color="primary" size="small">
                    <InputLabel id="lbl_sucursal">Chofer</InputLabel>
                    <Select
                      name='usuario_chofercierra'
                      // margin='dense'
                      // defaultValue={props.datos.sucursal}                    
                      value={datosreparto.usuario_chofercierra}
                      onChange={almacenar}
                      label="Chofer ">
                      {choferes ? choferes.map(elemento => (
                        <MenuItem key={elemento.username} value={elemento.username}>
                          {elemento.nombre_apellido}
                        </MenuItem>
                      )) : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField label="Peso bruto final"
                    size="small"
                    required
                    focused
                    style={style}
                    variant="outlined"
                    margin='dense'
                    type="number"
                    color='primary'
                    name="peso_bruto_final"
                    value={datosreparto.peso_bruto_final}
                    onChange={almacenar}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FactCheckIcon />
                        </InputAdornment>
                      ),
                      // readOnly: true
                    }} />
                </Grid>

                <Grid item xs={4}>
                  <TextField label="Kms finales"
                    required
                    focused
                    variant="outlined"
                    style={style}
                    size="small"
                    margin='dense'
                    type="number"
                    color='primary'
                    placeholder='Kilometros finales del vehiculo'
                    name="km_finales"
                    value={datosreparto.km_finales}
                    onChange={almacenar}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FactCheckIcon />
                        </InputAdornment>
                      ),
                    }}
                    aria-describedby="component-error-text"
                    helperText={
                      props.datos.km_iniciales && ((props.datos.km_iniciales * 1) > datosreparto.km_finales) ? (
                        <>
                          "KM finales no pueden ser menores que los iniciales ".
                        </>
                      ) : null
                    } />
                </Grid>


              </Grid>
              <Grid mt={2} container direction="row" justifyContent="space-between" alignItemns="center">
                <Button variant="contained"
                  mr={5} color="error" size='medium' onClick={handleClose}>Volver</Button>
                <Button variant="contained"
                  ml={5} type="submit" size='medium' color="success" disabled={disbtn} >Guardar</Button>
                {/* {total && total[0].kgentregados === null &&
                  <>
                    <Alert severity="info">
                      Primero debe cerrar todos los pedidos
                    </Alert>
                    <Button variant="contained"
                      mr={5} color="error" size='medium' onClick={handleClose}>Volver</Button>
                    <Button variant="contained"
                      ml={5} type="submit" size='medium' color="success"  >Guardar</Button>
                  </>
                } */}
              </Grid>
            </Box>
          </form>
        </Dialog>
      ) : null}

      {
        notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleClose}
          />
        ) : null
      }
    </div>
  )
}