//----- Component --------------------------------------------------------/

// Developer : Daiana Curcio / Nicolas Pascucci

// Creation Date : 3/1/2024

// Version : 1

// Description : Historial de transferencias enviadas y recibidas con importación a CSV
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { Box, Typography, Grid, FormGroup } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

// Boton desplegable
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

//checked
import Checkbox from "@mui/material/Checkbox";

//Notification
import { lightBlue } from "@mui/material/colors";

//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { blueGrey } from "@mui/material/colors";

function Historial_transf(props) {
  const [selectEnviadas, setSelectEnviadas] = useState(false);
  const [selectRecibidas, setSelectRecibidas] = useState(true);
  const handleClickEnviadas = () => {
    setSelectEnviadas(true);
    setSelectRecibidas(false);
  };
  // console.log(
  //   "ENVIADAS selectEnviadas",
  //   selectEnviadas,
  //   "selectRecibidas",
  //   selectRecibidas
  // );

  const handleClickRecibidas = () => {
    setSelectRecibidas(true);
    setSelectEnviadas(false);
  };
  // console.log(
  //   "RECIBIDAS selectEnviadas",
  //   selectEnviadas,
  //   "selectRecibidas",
  //   selectRecibidas
  // );

  //************************************************************************************************************************************************ */
  const columns3 = useMemo(
    // TRANSF ENVIADAS
    () => [
      {
        header: "Fecha",
        accessorKey: "fecha_transaccion",
        size: 60,
        type: "date",
        format: "YYYY-MM-DD HH:mm",
      },
      { header: "Suc.Destino", accessorKey: "nombre_destino", size: 90 },
      { header: "Alm.Origen", accessorKey: "almacen_nombre_origen", size: 100 },
      { header: "Artículo", accessorKey: "nombre_articulo", size: 100 },
      { header: "Detalle", accessorKey: "detalle_transaccion", size: 200 },
      {
        header: "Pto. de Venta",
        accessorKey: "nro_remito_puntodeventa",
        size: 95,
      },
      { header: "Remito Nro.", accessorKey: "nro_remito_numero", size: 86 },
      { header: "Cant.", accessorKey: "cantidad_enviada", size: 55 },
      { header: "Usuario", accessorKey: "usuario", size: 75 },
      { header: "Estado", accessorKey: "estado_transferencia", size: 65 },
    ],
    []
  );

  const columnsEnviadasCSV = useMemo(
    // TRANSF ENVIADAS
    () => [
      { header: "Artículo", accessorKey: "nombre_articulo", size: 100 },
      { header: "Alm.Origen", accessorKey: "almacen_nombre_origen", size: 100 },
      { header: "Suc.Destino", accessorKey: "nombre_destino", size: 90 },
      { header: "Cantidad Enviada", accessorKey: "cantidad_enviada", size: 55 },
      {
        header: "Pto. de Venta",
        accessorKey: "nro_remito_puntodeventa",
        size: 95,
      },
      { header: "Nro.Remito", accessorKey: "nro_remito_numero", size: 86 },
      { header: "Detalle", accessorKey: "detalle_transaccion", size: 200 },
      {
        header: "Fecha",
        accessorKey: "fecha_transaccion",
        size: 60,
        type: "date",
        format: "YYYY-MM-DD HH:mm",
      },
      { header: "Estado", accessorKey: "estado_transferencia", size: 65 },
      { header: "Usuario", accessorKey: "usuario", size: 75 },
    ],
    []
  );
  //************************************************************************************************************************************************ */

  const columns4 = useMemo(
    // TRANSF RECIBIDAS
    () => [
      {
        header: "Fecha",
        accessorKey: "fecha_transaccion",
        size: 60,
        type: "date",
        format: "YYYY-MM-DD HH:mm",
      },
      { header: "Origen", accessorKey: "sucursal_origen_nombre", size: 80 },
      { header: "Artículo", accessorKey: "nombre_articulo", size: 110 },
      { header: "Detalle", accessorKey: "detalle_transaccion", size: 200 },
      {
        header: "Pto. de Venta",
        accessorKey: "nro_remito_puntodeventa",
        size: 90,
      },
      { header: "Remito Nro.", accessorKey: "nro_remito_numero", size: 90 },
      { header: "Cant.Rec.", accessorKey: "cantidad_recibida", size: 85 },
      { header: "Cant.Pend.", accessorKey: "cantidad_pendiente", size: 85 },
      {
        header: "Pendientes",
        accessorKey: "estado_pendiente_palabra",
        size: 90,
      },
      { header: "Usuario", accessorKey: "usuario", size: 75 },
    ],
    []
  );

  const columnsRecibidasCSV = useMemo(
    // TRANSF RECIBIDAS
    () => [
      { header: "Artículo", accessorKey: "nombre_articulo", size: 110 },
      { header: "Origen", accessorKey: "sucursal_origen_nombre", size: 80 },
      { header: "Destino", accessorKey: "sucursal_destino_nombre", size: 80 },
      { header: "Almacén", accessorKey: "almacen_destino_nombre", size: 80 },
      { header: "Cant.Enviada", accessorKey: "cantidad_enviada", size: 85 },
      { header: "Cant.Recibida", accessorKey: "cantidad_recibida", size: 85 },
      { header: "Cant.Pendiente", accessorKey: "cantidad_pendiente", size: 85 },
      {
        header: "Pto.de Venta",
        accessorKey: "nro_remito_puntodeventa",
        size: 90,
      },
      { header: "Nro.Remito", accessorKey: "nro_remito_numero", size: 90 },
      { header: "Detalle", accessorKey: "detalle_transaccion", size: 200 },
      {
        header: "Fecha",
        accessorKey: "fecha_transaccion",
        size: 60,
        type: "date",
        format: "YYYY-MM-DD HH:mm",
      },
      {
        header: "Estado de los Pendientes",
        accessorKey: "estado_pendiente_palabra",
        size: 90,
      },
      { header: "Usuario", accessorKey: "usuario", size: 75 },
    ],
    []
  );

  //************************************************************************************************************************************************ */
  //CSV
  const [transferenciasEnviadasCSV, setTransferenciasEnviadasCSV] = useState();

  const obtenerTransferenciasEnviadasCSV = () => {
    fetch(
      UrlApi + "/stock_transacciones/transferenciaEnviadasCSV/" + props.sucursal
    )
      .then((data) => data.json())
      .then((data) => setTransferenciasEnviadasCSV(data));
  };
 // console.log("csv", transferenciasEnviadasCSV);

  useEffect(() => {
    obtenerTransferenciasEnviadasCSV();
  }, []);

  useEffect(() => {
    if (transferenciasEnviadasCSV && transferenciasEnviadasCSV.length > 0) {
      const transfEnviadasOrdenadas = transferenciasEnviadasCSV.sort(
        (a, b) => new Date(b.fecha_transaccion) - new Date(a.fecha_transaccion)
      );
      setTransferenciasEnviadasCSV([...transfEnviadasOrdenadas]); // Actualiza el estado con el array ordenado
    }
  }, [transferenciasEnviadasCSV]);
  //************************************************************************************************************************************************ */
  const [checked, setChecked] = React.useState(false);
  //  CHECKED
  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };
 // console.log("CHECKED", checked);
  //************************************************************************************************************************************************ */
  //CSV
  const [transferenciasRecibidasCSV, setTransferenciasRecibidasCSV] =
    useState();

  const obtenerTransferenciasRecibidasCSV = () => {
    fetch(
      UrlApi +
        "/stock_transacciones/transferenciaRecibidasCSV/" +
        props.sucursal
    )
      .then((data) => data.json())
      .then((data) => setTransferenciasRecibidasCSV(data));
  };
 // console.log("csv", transferenciasRecibidasCSV);

  useEffect(() => {
    obtenerTransferenciasRecibidasCSV();
  }, []);

  useEffect(() => {
    if (transferenciasRecibidasCSV && transferenciasRecibidasCSV.length > 0) {
      const transfRecibidasOrdenadas = transferenciasRecibidasCSV.sort(
        (a, b) => new Date(b.fecha_transaccion) - new Date(a.fecha_transaccion)
      );
      setTransferenciasRecibidasCSV([...transfRecibidasOrdenadas]); // Actualiza el estado con el array ordenado
    }
  }, [transferenciasRecibidasCSV]);
  //************************************************************************************************************************************************ */

  //CSV Enviadas
  const csvOptionsEnviadas = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `Historial de Transferencias Enviadas de la Sucursal ${props.nombreSuc}`,
    headers: columnsEnviadasCSV.map((c) => c.header),
  };

  const csvExporterE = new ExportToCsv(csvOptionsEnviadas);

  const handleExportDataE = () => {
    csvExporterE.generateCsv(transferenciasEnviadasCSV);
  };

  //CSV Recibidas
  const csvOptionsRecibidas = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `Historial de Transferencias Recibidas de la Sucursal ${props.nombreSuc}`,
    headers: columnsRecibidasCSV.map((c) => c.header),
  };

  const csvExporterR = new ExportToCsv(csvOptionsRecibidas);

  const handleExportDataR = () => {
    csvExporterR.generateCsv(transferenciasRecibidasCSV);
  };

  return (
    <div>
      <Box mt={2} mb={4}>
        <ThemeProvider theme={props.tableTheme}>
          <MaterialReactTable
            enableExpandAll={false}
            enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
            enableGrouping // para permitir agrupar x estado x ej
            layoutMode="grid"
            muiTableHeadCellProps={props.tableHeadCellProps}
            muiTableBodyCellProps={({ row }) => ({
              align: "left",
              sx: {
                color: "black",
                borderColor: "black",
              },
            })}
            enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
            enableColumnFilters={false} //desactiva filtros x  columna
            enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
            enableDensityToggle={false} //
            localization={MRT_Localization_ES} //idioma
            enableColumnDragging={false} //para arrastrar columnas
            initialState={props.initialState}
            muiSearchTextFieldProps={props.muiSearchTextFieldProps}
            columns={selectEnviadas ? columns3 : columns4}
            data={
              selectEnviadas
                ? props.transferenciasEnviadas
                : checked
                ? props.transferenciasRecibidasPendientes
                : props.transferenciasRecibidas
            } //con lo q la completas (esto cambia siempre)
            enableStickyFooter
            // enableEditing
            positionActionsColumn="last" // posicion de la columna de acciones al final
            // ACCIONES
            renderToolbarInternalActions={(
              { table } // boton para nuevo , al lado del buscar
            ) => (
              <>
                {selectEnviadas ? (
                  <Grid item xs={6} ml={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<FileDownloadIcon />}
                      onClick={handleExportDataE}
                      style={{
                        backgroundColor: blueGrey[700],
                        color: "white",
                      }}
                      size="small"
                    >
                      Exportar a CSV
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={6} ml={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<FileDownloadIcon />}
                      onClick={handleExportDataR}
                      style={{
                        backgroundColor: blueGrey[700],
                        color: "white",
                      }}
                      size="small"
                    >
                      Exportar a CSV
                    </Button>
                  </Grid>
                )}
                <MRT_FullScreenToggleButton table={table} />
              </>
            )}
            renderTopToolbarCustomActions={() => (
              <>
                <Typography variant="poster" fontSize={26}>
                  Transferencias {selectEnviadas ? "Enviadas" : "Recibidas"}
                </Typography>
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                >
                  <Button
                    size="small"
                    style={{
                      backgroundColor: lightBlue[800],
                      color: "white",
                    }}
                    onClick={handleClickEnviadas}
                  >
                    ENVIADAS
                  </Button>
                  <Button
                    size="small"
                    style={{
                      backgroundColor: lightBlue[900],
                      color: "white",
                    }}
                    onClick={handleClickRecibidas}
                  >
                    RECIBIDAS
                  </Button>
                </ButtonGroup>
                {selectRecibidas ? (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleChangeChecked}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Con Cantidades Pendientes"
                    />
                  </FormGroup>
                ) : null}
              </>
            )}
          />
        </ThemeProvider>
      </Box>
    </div>
  );
}

export default Historial_transf;
