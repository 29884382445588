export function calcularCostoT1(distanciabocacarga, costokmtransporte, calculos, setCalculos){
    const distancia =  distanciabocacarga * 2 || 0;
    const capacidadt1 = 21000;
      const costoT1 = parseFloat(
        ((distancia *  costokmtransporte) / capacidadt1).toFixed(
          4
        ) || 0
      );
      setCalculos((calculos) => ({ ...calculos, costot1: costoT1 }));    
}

export function calcularCostoT2(distanciaClienteSuc, costokmtransporte, calculos, setCalculos){
    const distancia = distanciaClienteSuc * 2 || 0; //Distancia total entre ida y vuelta
    const costoT2 = parseFloat(
            ((distancia * costokmtransporte) / 5800).toFixed(4) || 0
          );
    const costotranstk = distancia * costokmtransporte;
    setCalculos((calculos) => ({
      ...calculos,
      costot2: costoT2,
      costotranstks: costotranstk,
    }));
}   

export function calcularCostoTotalTks(dataFact, factConfig, valorDolar, calculos, setCalculos){
    const costoTotal =
      dataFact.canttk_05 * factConfig.costo_tk05 * valorDolar +
      dataFact.canttk_1 * factConfig.costo_tk1 * valorDolar +
      dataFact.canttk_2 * factConfig.costo_tk2 * valorDolar +
      dataFact.canttk_4 * factConfig.costo_tk4 * valorDolar +
      dataFact.canttk_7 * factConfig.costo_tk7 * valorDolar +
      dataFact.canttk_otros * valorDolar;
    setCalculos((calculos) => ({ ...calculos, costototaltks: costoTotal }));
}

export function calcularCostosAbsXEmpresa(dataFact, calculos , setCalculos){
    const costosAbsXEmpresa =
      parseInt(dataFact.cantmotivo1 || 0) +
      parseInt(dataFact.cantmotivo2 || 0) +
      parseInt(dataFact.cantmotivo3 || 0);
    setCalculos((calculos) => ({
      ...calculos,
      costosabsxlaempresa: costosAbsXEmpresa,
    }));
}

export function calcularInversionTotal(calculos, setCalculos){
    const inversionTotal =
      (calculos.costos_operativos_total || 0) +
      (calculos.costosabsxlaempresa || 0) +
      (calculos.costototaltks || 0) +
      (calculos.costotranstks || 0);

      const inversionTotalFixed=inversionTotal.toFixed(2)
      const inversionTotalFloat=parseFloat(inversionTotalFixed)
      console.log("typeof INVERSION TOTAL: " + typeof inversionTotalFloat)
    setCalculos((calculos) => ({
      ...calculos,
      inversiontotal: inversionTotalFloat,
    }));
}

    export function calcularConsumoMinimo(calculos,variacionporcental, setCalculos){
    if (!!calculos.kganual && !!variacionporcental) {
      const exp1 = (calculos.kganual * variacionporcental) / 100;
      const consMinimo = parseFloat((calculos.kganual - exp1).toFixed(4));
      setCalculos((calculos) => ({ ...calculos, kganualminimo: consMinimo }));
    } else {
      setCalculos((calculos) => ({ ...calculos, kganualminimo: 0 }));
    }
  };

  export function calcularCierreXVolEstimado(calculos, setCalculos){
    if (
      !!calculos.inversiontotal &&
      !!calculos.kganual &&
      !!calculos.margen &&
      !!calculos.costot1 &&
      !!calculos.costot2
    ) {
      const cierreXVolEst =
        calculos.inversiontotal /
        (calculos.kganual *
          (calculos.margen - calculos.costot1 - calculos.costot2));

      setCalculos((calculos) => ({
        ...calculos,
        cierreestimado: parseFloat(cierreXVolEst.toFixed(2)),
      }));
    }
  };

  export function calcularCierreXVolMinimo(calculos, setCalculos){
    if (
      !!calculos.inversiontotal &&
      !!calculos.kganualminimo &&
      !!calculos.margen &&
      !!calculos.costot1 &&
      !!calculos.costot2
    ) {
      const cierreXVolEst =
        calculos.inversiontotal /
        (calculos.kganualminimo *
          (calculos.margen - calculos.costot1 - calculos.costot2));
      setCalculos((calculos) => ({
        ...calculos,
        cierreminimo: parseFloat(cierreXVolEst.toFixed(2)),
      }));
    } else {
      setCalculos((calculos) => ({ ...calculos, cierreminimo: 0 }));
    }
  };






export function calcularMargen(costoprodxtonelada, preciokgsiva, calculos, setCalculos){
    //El costo de producto por tonelada es NUMBER
    // precio kg sin iva ES UN STRING :) 
    // console.log("costo producto por tonelada" , typeof costoprodxtonelada)
    // console.log("preciokgsiva" , typeof preciokgsiva)
    const margenTonelada=(preciokgsiva*1000)-costoprodxtonelada 
    const margenKg=margenTonelada/1000
    if(!!preciokgsiva){
        setCalculos((calculos) => ({ ...calculos, margen: margenKg }));
    } else {
        setCalculos((calculos) => ({ ...calculos, margen: 0 }));
    }
}

