import React, { useState, useEffect, useMemo } from 'react';
import { UrlApi } from '../../../services/apirest';
import { getData, postData, putData } from '../../../services/library';
import { enviarmails } from '../../../services/services';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid'
import FileUploadMultiple from '../../../services/UploadFiles';
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { Autocomplete, Box, Select, FormControl, InputLabel, Stack, FormControlLabel, TextField, Grid, InputAdornment, Container, Checkbox, MenuItem, Button, DialogContent } from '@mui/material';
import Title from '../../Title';
import Notify from '../../Notify';
import PanelOrdenCompra from './PanelOrdenCompra';
import AgregarMateriales from '../requerimientos/ver_requerimientos/lista_requerimientos/AgregarMateriales';
// import FormPreguntarEliminar from '../requerimientos/ver_requerimientos/lista_requerimientos/FormPreguntaEliminar';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Modalcondicionnull from './Modalcondicionnull';
import { logEventos } from '../../../services/services';

const AddOrdenCompra = (props) => {
  const globalTheme = useTheme();
  const [reqMaterial, setreqMaterial] = useState(props.datos);
  const [uuidordencompra, setuuid_ordencompra] = useState(uuidv4);
  const [eliminarModalOpen, seteliminarModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [openclose, setOpenClose] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [tabla, settabla] = useState(true);
  const [proveedorSelected, setproveedorSelected] = useState(null);
  const [condVentaNew, setcondVentaNew] = useState(null);
  const [modalVacio, setmodalVacio] = useState(false);
  const [proveedor, setproveedor] = useState([]);
  const [listaMaterial, setlistaMaterial] = useState([]);
  const [datosLista, setdatosLista] = useState({})
  const [tipoMoneda, settipoMoneda] = useState();
  const [bonificacionPesos, setBonificacionPesos] = useState(null);
  const [bonificacionPorcentaje, setBonificacionPorcentaje] = useState(null);
  const [totalFinal, settotalFinal] = useState(0);
  const [CondVenta, setCondVenta] = useState();
  const [subtotal, setsubtotal] = useState(0);
  const [empresas, setempresas] = useState(null)
  const [datosFormulario, setdatosFormulario] = useState({
    id_proveedor: null,
    comentarios_internos: null,
    uuid_ordencompra: uuidordencompra,
    pago_anticipado: null,
    iva_incluido: null,
    tipo_moneda: 83,
    condicion_venta: null, //segun el proveedor que elije le trae la condicion 
    total: totalFinal,
    id_estado: null,
    usuario_crea: localStorage.getItem('user'),
    fecha_entrega: reqMaterial && moment(reqMaterial.fecha_entrega).format('YYYY-MM-DD'),
    origen: "requerimiento",
    facturar_a_empresa: null,
    bonificacion_pesos: null,
    bonificacion_porcentaje: null
  });

  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  const obtenerListaMaterial = () => {
    getData('/lista_materiales/addorden/' + reqMaterial.uuid_requerimiento + '/' + datosFormulario.uuid_ordencompra, setlistaMaterial)
  };

  useEffect(() => {
    obtenerListaMaterial()
  }, [uuidordencompra]);

  useEffect(() => {
    getData('/proveedores/activos', setproveedor)
    getData('/desplegables/tipoMoneda', settipoMoneda)
    getData('/condicion_pago', setCondVenta)
    getData('/empresas', setempresas)
  }, []);

  useEffect(() => {
    proveedorSelected &&
      setcondVentaNew(proveedorSelected.id_condicionventa);
  }, [proveedorSelected, setproveedorSelected]);

  useEffect(() => {
    if (proveedorSelected && proveedorSelected.id_condicionventa === null) {
      setmodalVacio(true)
    }
  }, [proveedorSelected, setproveedorSelected]);

  const setearCondicion = (e) => {
    setcondVentaNew(e.target.value);
  };

  useEffect(() => {
    getTotal()
  }, [listaMaterial]);

  useEffect(() => {
    getBonificacionPorcentaje()
  }, [bonificacionPorcentaje, subtotal, bonificacionPesos]);


  const getTotal = () => {
    let subtotal = 0
    if (listaMaterial) {
      listaMaterial.map((item) => subtotal = subtotal + item.subtotal)
      setsubtotal(subtotal)
    }
  };

  const getBonificacionPorcentaje = () => {
    console.log("ENtro a bonificacion porcentaje")
    if (!!subtotal && !!bonificacionPorcentaje) {
      settotalFinal(subtotal - (subtotal * bonificacionPorcentaje / 100))
    } else if (!!subtotal && !!bonificacionPesos) {
      settotalFinal(subtotal - bonificacionPesos)
    }
    else {
      settotalFinal(subtotal)
    }
  };

  const almacenar = (e) => {
    setdatosFormulario({ ...datosFormulario, [e.target.name]: e.target.value ? e.target.value : null })
  };

  const handleOnClickCheckBox = (e) => {
    setdatosFormulario({ ...datosFormulario, [e.target.name]: e.target.checked })
  };

  const handleClose = () => {
    return (
      props.tabla(true),
      props.alta(false),
      props.tab(true)
    )
  };

  const handleAbrirModal = () => {
    setopenDialog(true)
  };

  async function agregaruuuid(e) {
    console.log("agregar uuid")
    putData(e,
      '/uploadfiles/' + reqMaterial.uuid_requerimiento,
      {
        uuid_ordencompra: datosFormulario.uuid_ordencompra
      }, setNotificacion
    )
  };

  async function cambiarEstadoReq(e) {
    putData(e,
      '/requerimientos_materiales/' + reqMaterial.idrequerimiento,
      {
        estado: 58,
        uuid_ordencompra: datosFormulario.uuid_ordencompra
      },
      setNotificacion,
      logEventos("CAmbiar estado de requerimiento", "Requerimientos materiales", "Requerimientos materiales", localStorage.getItem("user"))
    );
  };

  async function guardarFechaEntrega(e) {
    putData(e,
      '/requerimientos_materiales/' + reqMaterial.idrequerimiento,
      { fecha_entrega: datosFormulario.fecha_entrega },
      setNotificacion,
      logEventos("CAmbiar fecha requerimiento", "Ordenes compra", "Ordenes compra", localStorage.getItem("user"))
    );
  };

  async function guardarOrdenCompra(e) {
    postData(e,
      '/ordencompra',
      {
        id_proveedor: proveedorSelected.id,
        comentarios_internos: datosFormulario.comentarios_internos,
        uuid_ordencompra: datosFormulario.uuid_ordencompra,
        pago_anticipado: datosFormulario.pago_anticipado,
        iva_incluido: datosFormulario.iva_incluido,
        condicion_venta: condVentaNew,
        tipo_moneda: datosFormulario.tipo_moneda,
        total: totalFinal,
        usuario_crea: localStorage.getItem('user'),
        fecha_entrega: datosFormulario.fecha_entrega,
        id_estado: 89,
        bonificacion_pesos: bonificacionPesos,
        bonificacion_porcentaje: bonificacionPorcentaje,
        saldo_pendiente: 1,
        origen: datosFormulario.origen,
        id_sucursal: reqMaterial && reqMaterial.id_sucursal
      },
      setNotificacion,
      proveedorSelected && reqMaterial && enviarmails(
        reqMaterial.email_registrante, "Orden de compra generada", `<p>Para la solicitud ${reqMaterial.idrequerimiento} se ha asignado una orden de compra. Proveedor seleccionado: ${proveedorSelected.nombre_fantasia ?? proveedorSelected.razon_social}, importe: ${totalFinal} </p>`),
      cambiarEstadoReq(e),
      guardarFechaEntrega(e),
      agregaruuuid(e),
      logEventos("Crear orden compra", "Ordenes compra", "Ordenes compra", localStorage.getItem("user"))
    )
  };

  const guardarTodo = async ({ exitEditingMode, row, values }) => {
    let subtotal;
    if (values.bonificacion_pesos !== null && values.bonificacion_pesos !== undefined && values.bonificacion_pesos !== '') {
      // Calcular el subtotal con bonificación en pesos
      subtotal = (values.cantidad_actualizada * values.precio_unitario) - values.bonificacion_pesos;
    } else if (values.bonificacion_porcentaje !== null && values.bonificacion_porcentaje !== undefined && values.bonificacion_porcentaje !== "") {
      // Calcular el subtotal con bonificación en porcentaje
      subtotal = (values.precio_unitario * values.cantidad_actualizada) * (1 - values.bonificacion_porcentaje / 100);
    } else {
      // Calcular el subtotal sin bonificación
      subtotal = values.precio_unitario * values.cantidad_actualizada;
    }
    await fetch(UrlApi + '/lista_materiales/lista/' + row.original.idlista, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        precio_unitario: values.precio_unitario,
        cantidad_actualizada: values.cantidad_actualizada,
        subtotal: subtotal,
        bonificacion_pesos: values.bonificacion_pesos ? values.bonificacion_pesos : null,
        bonificacion_porcentaje: values.bonificacion_porcentaje ? values.bonificacion_porcentaje : null,
        uuid_ordencompra: datosFormulario.uuid_ordencompra,
        usuario: localStorage.getItem('user')
      })
    });
    obtenerListaMaterial();
    exitEditingMode();
    logEventos("Actualizar lista materiales", "Ordenes compra", "Ordenes compra", localStorage.getItem("user"))
  };

  const columns = useMemo(() =>
    [{ header: 'Requerido', accessorKey: 'cantidad', enableEditing: false, size: 50 },
    { header: 'A Comprar', accessorKey: 'cantidad_actualizada', required: false, type: 'number', size: 70 },
    { header: 'Articulo', accessorFn: (lm) => lm.nombre ?? lm.material, enableEditing: false, },
    { header: 'Unidad', accessorFn: (lm) => lm.descripcion ?? lm.descripcion_material, enableEditing: false, size: 40 },
    { header: '$ Unitario', accessorKey: 'precio_unitario', size: 40, type: 'numeric', required: true },
    { header: 'Bonif %', accessorKey: 'bonificacion_porcentaje', required: false, type: 'numeric', size: 40 },
    { header: 'Bonif $', accessorKey: 'bonificacion_pesos', required: false, type: 'numeric', size: 40 },
    { header: 'Subtotal', accessorKey: 'subtotal', enableEditing: false, type: 'numeric', size: 40 },

    ], []);

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size                                
              },
            },
          },
        },

      }), [globalTheme]);

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 14,
      color: "black",
      fontWeight: "normal",
      backgroundColor: "#D4E6F1",
      borderColor: "#FDFEFE",
    },
  };

  const initialState = {
    density: "compact",
    pagination: { pageIndex: 0, pageSize: 15 }
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%"
  };

  const handleCreateNewRow = (values) => {
    setCreateModalOpen(true);
  };


  return (
    <div>
      <Container maxWidth="xl" >
        <Title
          titulo="Generar orden de compra"
          handleClose={handleClose} />
        <form onSubmit={guardarOrdenCompra} autoComplete='off'>
          <Box backgroundColor="#F4F6F6"
            mt={1}
            p={2}
            mb={2}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}>
            <Grid container spacing={2} alignItems='center' >
              <Grid item xs={3} alignItems='flex-start'>
                <Autocomplete
                  focused
                  size="small"
                  style={style}
                  noOptionsText="Proveedor INEXISTENTE"
                  options={proveedor}
                  autoHighlight
                  getOptionLabel={(proveedor) => proveedor.nombre_fantasia || proveedor.razon_social}
                  onChange={(event, value) => setproveedorSelected(value)}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.nombre_fantasia}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {`${option.nombre_fantasia ? option.nombre_fantasia : option.razon_social} - ${option.nro_cuit}`}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      focused
                      fullWidth
                      placeholder="Tipee para buscar proveedores.."
                      {...params}
                      variant="outlined"
                      size="small"
                      label="Proveedores"
                      required
                    />
                  )}
                />

              </Grid>

              <Grid item xs={2}>
                <FormControl fullWidth={true} size="small" focused color="primary" required >
                  <InputLabel id="lbl_estado">Condicion De Venta</InputLabel>
                  <Select
                    variant='outlined'
                    minHeight='20px'
                    onSelect={proveedorSelected ? proveedorSelected.id_condicionventa : ""}
                    name='condicion_venta'
                    value={condVentaNew}
                    style={style}
                    required
                    onChange={setearCondicion}
                    label="Condición de venta">
                    {(CondVenta ?? []).map(elemento => (
                      <MenuItem key={elemento.id} value={elemento.id}>
                        {elemento.condicion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={2}>
                <FormControl fullWidth={true} style={style} focused color="primary" required size="small">
                  <InputLabel id="lbl_estado">Moneda</InputLabel>
                  <Select
                    required
                    variant='outlined'
                    name='tipo_moneda'
                    value={datosFormulario.tipo_moneda}
                    onChange={almacenar}
                    label="Moneda">
                    {tipoMoneda ? tipoMoneda.map(elemento => (
                      <MenuItem key={elemento.id} value={elemento.id}>
                        {elemento.valor}
                      </MenuItem>
                    )) : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={2}>
                <FormControl fullWidth={true} style={style} focused color="primary" required size="small">
                  <InputLabel id="lbl_estado">Facturar a </InputLabel>
                  <Select
                    required
                    variant='outlined'
                    name='facturar_a_empresa'
                    value={datosFormulario.facturar_a_empresa}
                    onChange={almacenar}
                    label="Facturar a ">
                    {(empresas ?? []).map(elemento => (
                      <MenuItem key={elemento.idempresa} value={elemento.idempresa}>
                        {elemento.empresa}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={1}>
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      name='iva_incluido'
                      onClick={handleOnClickCheckBox}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} />}
                  label="Impuestos incluidos "
                  labelPlacement="start"
                />
              </Grid>

              <Grid item xs={1} ml={3}>
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      name='pago_anticipado'
                      onClick={handleOnClickCheckBox}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} />}
                  label="Pago anticipado"
                  labelPlacement="start"
                />
              </Grid>

            </Grid>

            <Grid container spacing={2} alignItems='center' p={1} mb={1.5}>
              <Grid item xs={2}>
                <TextField
                  focused
                  variant='outlined'
                  fullWidth={true}
                  color='primary'
                  style={style}
                  label='Sucursal'
                  size="small"
                  value={reqMaterial.nombre_sucursal || ""}
                  InputProps={{
                    readOnly: true
                  }} />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  focused
                  variant='outlined'
                  fullWidth={true}
                  color='primary'
                  style={style}
                  label='Movil'
                  size="small"
                  name='movil'
                  value={reqMaterial.patente ? reqMaterial.patente : "No hay registro"}
                  InputProps={{
                    readOnly: true
                  }} />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  focused
                  variant='outlined'
                  style={style}
                  color='primary'
                  label='Solicitó'
                  name='usuario_registra'
                  size="small"
                  value={reqMaterial.usuario_registra}
                  InputProps={{
                    readOnly: true
                  }} />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  focused
                  variant='outlined'
                  style={style}
                  label='Autorizante'
                  name='usuario_autoriza'
                  size="small"
                  value={reqMaterial.usuario_autoriza ?? "No hay registro"}
                  placeholder="No hay registro"
                  InputProps={{
                    readOnly: true
                  }} />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  focused
                  color='primary'
                  style={style}
                  label='Fecha autorizado'
                  name='created_at'
                  variant='outlined'
                  size="small"
                  value={reqMaterial.fecha_autorizado !== null ? moment(reqMaterial.fecha_autorizado).format('DD-MM-YYYY') : "No hay registro"}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>

              <Grid item xs={2} >
                <TextField
                  focused
                  variant='outlined'
                  style={style}
                  color='primary'
                  onChange={(e) => setdatosFormulario({ ...datosFormulario, "fecha_entrega": e.target.value })}
                  size="small"
                  label='Fecha de entrega'
                  type='date'
                  name='fecha_entrega'
                  value={datosFormulario.fecha_entrega}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                      ></InputAdornment>)
                  }} />
              </Grid>
              {/* <Grid item xs={0.5}>
                <Button> +</Button>
              </Grid> */}
            </Grid>
            {tabla && listaMaterial ? (
              <Box mt={2} boxShadow={3} >
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable
                    enableColumnResizing
                    enableEditing
                    columns={columns}
                    data={listaMaterial}
                    positionActionsColumn='last'
                    muiTableHeadCellProps={tableHeadCellProps}
                    localization={MRT_Localization_ES}
                    muiTableBodyCellProps={({ row }) => ({
                      align: "left",
                      sx: {
                        backgroundColor: "#ffffff",
                        color: "black",
                        borderColor: "black",
                      },
                    })}
                    enableColumnActions={false}
                    enableDensityToggle={false}
                    layoutMode="grid"
                    enableExpandAll={false}
                    enableToolbarInternalActions={true}
                    editingMode="row"
                    enablePagination={false}
                    enableFullScreenToggle={false}
                    enableColumnFilters={false}
                    enableHiding={false}
                    enableBottomToolbar={false}
                    enableColumnDragging={false}
                    positionExpandColumn='first'
                    enableTopToolbar={false}
                    enableStickyFooter
                    enableGlobalFilter={false}
                    initialState={initialState}
                    onEditingRowSave={guardarTodo}
                  />

                </ThemeProvider>
              </Box>
            ) : null}

            <Grid container justifyContent='space-between' alignItems='center' mt={1}>
              <Stack spacing={2} direction="row" padding={1}>
                <Button
                  onClick={handleAbrirModal}
                  style={{ backgroundColor: '#363979', color: 'white' }}
                  variant="contained"
                  mr={5}>
                  Agregar Pedidos
                </Button>
                <Button
                  onClick={() => setOpenClose(!openclose)}
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<AttachFileIcon />}>
                  Ver presupuestos
                </Button>
                <Button size="small" onClick={handleCreateNewRow} color="primary" variant="contained" > Agregar Articulos</Button>
              </Stack>

              <Grid item xs={1} mt={1}>
                <TextField
                  disabled={!!bonificacionPorcentaje}
                  focused
                  variant='outlined'
                  color='primary'
                  label='Bonificación $'
                  size="small"
                  name='bonificacion'
                  style={{
                    marginLeft: 250, marginRight: 0, backgroundColor: "#ffffff",
                    width: "100%"
                  }}  // Ajustar el estilo para alinear a la derecha
                  value={bonificacionPesos}
                  onChange={(e) => setBonificacionPesos(e.target.value ? e.target.value : null)}
                />
              </Grid>

              <Grid item xs={1} mt={1}>
                <TextField
                  disabled={!!bonificacionPesos}
                  focused
                  variant='outlined'
                  color='primary'
                  label='Bonificación %'
                  size="small"
                  name='bonificacion'
                  style={{
                    marginLeft: 125, marginRight: 0, backgroundColor: "#ffffff",
                    width: "100%"
                  }}  // Ajustar el estilo para alinear a la derecha
                  value={bonificacionPorcentaje}
                  onChange={(e) => setBonificacionPorcentaje(e.target.value ? e.target.value : null)}
                />
              </Grid>

              <Grid item xs={1} mt={1}>
                <TextField
                  focused
                  style={{
                    marginLeft: 'auto', marginRight: 0, backgroundColor: "#ffffff",
                    width: "100%"
                  }}
                  variant='outlined'
                  color='primary'
                  label='Total'
                  name='total'
                  size="small"
                  value={totalFinal && parseFloat(totalFinal ? totalFinal.toFixed(2) : 0)}
                  InputProps={{
                    readOnly: true,
                    disabled: true
                  }} />
              </Grid>

            </Grid>

            <Grid container spacing={2} alignItems='center' p={1}>
              <Grid item xs={4}>
                <TextField
                  focused
                  variant='outlined'
                  style={style}
                  color='primary'
                  size="small"
                  label='Comentarios del requerimiento' value={reqMaterial.comentarios_internos} InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                      ></InputAdornment>),
                    readOnly: true,
                    disabled: true
                  }} />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  placeholder="Comentarios internos"
                  variant='outlined'
                  focused
                  style={style}
                  size="small"
                  name="comentarios_internos"
                  id="id"
                  multiline
                  label="Comentarios internos para la orden de compra"
                  color="primary"
                  onChange={almacenar}
                  value={datosFormulario.comentarios_internos}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  focused
                  variant='outlined'
                  style={style}
                  color='primary'
                  size="small"
                  label='Observaciones'
                  name='observaciones'
                  value={reqMaterial.observaciones ? reqMaterial.observaciones : "No hay observaciones cargadas"} InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                      ></InputAdornment>),
                    readOnly: true,
                    disabled: true
                  }} />
              </Grid>
            </Grid>
            <Grid mt={1} container direction="row" justifyContent="space-between" alignItemns="center">
              <Button variant="contained" style={{ backgroundColor: "#ff3333", color: "#ffffff", marginRight: "5%" }} onClick={handleClose} >Volver</Button>
              <Button variant="contained" type="submit" style={{ backgroundColor: "#2e7d32", color: "#ffffff" }} >Guardar Orden</Button>
            </Grid>

          </Box>
        </form>
      </Container>


      {openDialog ?
        <PanelOrdenCompra
          getTotal={getTotal}
          open={setopenDialog}
          reqmaterial={reqMaterial}
          uuid={uuidordencompra}
          actualizar={obtenerListaMaterial}
          tabla={settabla} /> : null}

      {openclose ?
        <FileUploadMultiple
          fileId={reqMaterial.uuid_requerimiento}
          fileIdcompra={uuidordencompra}
          openclose={true} /> : null}

      {createModalOpen ?
        <AgregarMateriales
          position={1}
          getTotal={getTotal}
          open={setCreateModalOpen}
          actualizar={obtenerListaMaterial}
          uuidordencompra={uuidordencompra} /> : null}

      {/* {eliminarModalOpen ? (
        <FormPreguntarEliminar
          eliminarLista={seteliminarModalOpen}
          datos={datosLista} />) : null} */}

      {notificacion && notificacion.open ? (
        <Notify
          mensaje={notificacion.mensaje}
          open={notificacion.open}
          color={notificacion.color}
          handleClose={handleClose}
          severity={notificacion.severity}
        />
      ) : null}

      {modalVacio ? (
        <Modalcondicionnull
          open={setmodalVacio}
          proveedor={proveedorSelected}
        />
      ) : null}
      <br />
    </div >
  )
}

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date :12/12/2023
// Version       : 1.0  
// Description   :  Con este componente el usuario es capaz de :
    - CREAR UNA ORDEN DE COMPRA PARTIENDO DESDE UN REQUERIMIENTO
    - AGREGAR MATERIALES
    - AGREGAR MATERIALES DESDE OTRO REQUERIMIENTO
    - VER PRESUPUESTOS.
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
//========================================================================*/

export default AddOrdenCompra;
