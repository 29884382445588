import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../services/apirest";
import { getData, postData } from "../../../services/library";
import {
  IconButton,
  Button,
  FormControl,
  Grid,
  Select,
  Tooltip,
  Container,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import moment from "moment";
import Box from "@mui/material/Box";
import Notify from "../../Notify";
import Alerta from "../../Alerta";
import Title from "../../Title";
//Iconos para los textfields
import InputAdornment from "@mui/material/InputAdornment";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import EightteenMpIcon from "@mui/icons-material/EightteenMp";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import Battery50Icon from "@mui/icons-material/Battery50";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddBoxIcon from "@mui/icons-material/AddBox";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddMarcaVehiculo from "../../vehiculos_nuevo/add/AddMarcaVehiculo";
import AddModeloVehiculo from "../../vehiculos_nuevo/add/AddModeloVehiculo";

function Addvehiculos(props) {
  // ********** AGREGADO DE MARCA Y MODELO ********//
  const [tabla, setTabla] = useState(true)
  const [altaMarca, setAltaMarca] = useState(false)
  const [altaModelo, setAltaModelo] = useState(false)
  const [marcas, setMarcas] = useState()
  const [marcaSeleccionada, setMarcaSeleccionada] = useState({})
  const [modelos, setModelos] = useState()
  const [modeloSeleccionado, setModeloSeleccionado] = useState({})
  const [buscando, setBuscando] = useState(false)

  const obtenerMarcas = () => {
    fetch(UrlApi + "/marcas/") 
      .then((data) => data.json())
      .then((data) => setMarcas(data));
  };

  useEffect(() => {
      obtenerMarcas();
  }, [tabla]);

  const obtenerModelos = () => {
    setBuscando(true)
    fetch(UrlApi + "/modelos/xmarca/"+ marcaSeleccionada.id) //cambiar fetch
      .then((data) => data.json())
      .then((data) => setModelos(data))
      .then((data) => setModeloSeleccionado(""))
      .then((data) => setBuscando(false))
  };

  //funcion que espera los cambios de la funcion de obtener familias
  useEffect(() => {
      obtenerModelos();
  }, [marcaSeleccionada]);

  const handleClickMarca = () => {
    setAltaMarca(true);
    setTabla(false);
  };

  const handleClickModelo = (row) => {
    setAltaModelo(true);
    setTabla(false);
  };
  //**********************************************//
  const [datosVehiculo, setDatosVehiculo] = useState({
    id_sisven: null,
    marca: null,
    modelo: null,
    capacidad: null,
    patente: null,
    id_tipo: null,
    id_chofer: null,
    vencimientovtv: null,
    vencimientohidraulica: null,
    uso: null,
    tara: null,
    ltstkcombustible: null,
    usuario: localStorage.getItem("user"),
    activo: 1,
    capacidadtotalm3: null,
    duenio: null,
    id_transportista: null
  });

  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  const [disBtn, setDisbtn] = useState(false);
  const [duenio, setduenio] = useState(null);

  // //Ir a choferes
  // const handleFormChoferes = () => {
  //   window.location.hash = "/choferes";
  // };

  //desplegable para tipo vehiculo
  const [tipoVehiculo, setTipoVehiculo] = useState(null);
  const [choferes, setChoferes] = useState(null);
  const [uso, setUso] = useState(null);
  const [sucursales, setSucursales] = useState(null);
  const [transportistas, settransportistas] = useState(null);

  useEffect(() => {
    getData("/desplegables/tipovehiculos", setTipoVehiculo)
    getData("/choferes", setChoferes)
    getData("/desplegables/usoVehiculo", setUso)
    getData("/sucursales", setSucursales)
    getData("/desplegables/dueniovehiculo", setduenio)
    getData("/transportistas", settransportistas)
  },
    []);

  //Funcion para guardar vehiculos
  const guardarVehiculos = (e) => {
    setDisbtn(true);
    postData(e,
      '/vehiculos',
      {
        id_sisven: datosVehiculo.id_sisven,
        marca: datosVehiculo.marca,
        modelo: datosVehiculo.modelo,
        capacidad: datosVehiculo.capacidad,
        patente: datosVehiculo.patente,
        id_tipo: datosVehiculo.id_tipo,
        id_chofer: datosVehiculo.id_chofer,
        vencimientovtv: datosVehiculo.vencimientovtv,
        vencimientohidraulica: datosVehiculo.vencimientohidraulica,
        uso: datosVehiculo.uso,
        tara: datosVehiculo.tara,
        ltstkcombustible: datosVehiculo.ltstkcombustible,
        usuario: localStorage.getItem("user"),
        activo: 1,
        capacidadtotalm3: datosVehiculo.capacidadtotalm3,
        duenio: datosVehiculo.duenio,
        id_transportista: datosVehiculo.id_transportista,
        id_modelo: modeloSeleccionado.id,
      },
      setNotificacion)
  }

  const almacenar = (e) => {
    setDatosVehiculo({
      ...datosVehiculo,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  const handleClose = () => {
    return props.tabla(true), props.alta(false);
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  useEffect(() => {
    setDatosVehiculo({ ...datosVehiculo, t1: 0 });
  }, [datosVehiculo.id_tipo, datosVehiculo.uso]);

  // console.log("ES T1 O NO : " + datosVehiculo.t1);

  return (
    <div>
      {tabla? 
      <Container>
        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            handleClose={handleClose}
            severity={notificacion.severity}
          />
        ) : null}
        <Title titulo="Nuevo Vehículo" handleClose={handleClose} />
        <form onSubmit={guardarVehiculos} autoComplete="off">
          <Box
            mt={2}
            mb={3}
            backgroundColor="#F4F6F6"
            p={2}
            border={0.5}
            borderRadius={4}
          >
            <Grid container spacing={2} style={{ padding: 10 }}>
              <Grid item xs={3}>
                <TextField
                  placeholder="ID Sisven"
                  required
                  color="primary"
                  focused
                  style={style}
                  name="id_sisven"
                  label="ID Sisven"
                  type="text"
                  value={datosVehiculo.id_sisven}
                  onChange={almacenar}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 7,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MeetingRoomIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* AGREGADO DE MARCA Y MODELO */}
              <Grid item xs={2.5}>
                {marcas && (
                  <Autocomplete
                    disabled={!marcas}
                    // size={size}
                    focused
                    disableClearable
                    id="marcas"
                    noOptionsText={"Marca INEXISTENTE"}
                    options={marcas}
                    autoHighlight
                    getOptionLabel={(marcas) =>
                      marcas.id + "-" + marcas.descripcion
                    }
                    onChange={(event, value) => {setMarcaSeleccionada(value)}}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id}-{option.descripcion}
                      </Box>
                    )}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <TimeToLeaveIcon />
                        </InputAdornment>
                      ),
                    }}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Marca"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={0.5}>
                <Tooltip title="Agregar Marca">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleClickMarca()}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={2.5}>
                {Object.keys(marcaSeleccionada).length === 0 ? (
                  <TextField
                  // value={(bocaSeleccionada.idbocas_carga = null)}
                  disabled
                  focused
                  fullWidth
                  // size="small"
                  label= "Seleccione una Marca..."
                  />
                ):( buscando?
                  <TextField
                      // value={(bocaSeleccionada.idbocas_carga = null)}
                      disabled
                      focused
                      fullWidth
                      size="small"
                      label= "Buscando Modelos..."
                      />
                  :
                  <Autocomplete
                    disabled={!modelos}
                    // size={size}
                    focused
                    disableClearable
                    id="modelos"
                    noOptionsText={"Modelo INEXISTENTE"}
                    options={modelos}
                    autoHighlight
                    getOptionLabel={(modelos) =>
                      modelos.id + "-" + modelos.descripcion
                    }
                    onChange={(event, value) => {setModeloSeleccionado(value)}}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id}-{option.descripcion}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Modelo"
                        inputProps={{
                          ...params.inputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <TimeToLeaveIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  
                )
                }
              </Grid>
              <Grid item xs={0.5}>
                <Tooltip title="Agregar Modelo">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleClickModelo()}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              {/* <Grid item xs={3}>
                <TextField
                  placeholder="Marca"
                  required
                  inputProps={{ maxLength: 45 }}
                  focused
                  style={style}
                  name="marca"
                  label="Marca"
                  color="primary"
                  value={
                    datosVehiculo.marca &&
                    datosVehiculo.marca.replace(/\b\w/g, (l) => l.toUpperCase())
                  }
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TimeToLeaveIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid> */}

              {/* <Grid item xs={3}>
                <TextField
                  placeholder="Modelo"
                  required
                  focused
                  style={style}
                  inputProps={{ maxLength: 45 }}
                  name="modelo"
                  label="Modelo"
                  color="primary"
                  value={datosVehiculo.modelo}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TimeToLeaveIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid> */}

              <Grid item xs={3}>
                <TextField
                  placeholder="Patente"
                  required
                  focused
                  style={style}
                  name="patente"
                  color="primary"
                  label="Patente"
                  value={datosVehiculo.patente}
                  inputProps={{ maxLength: 9 }}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EightteenMpIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  label="Capacidad Tanque Combustible (lts)"
                  color="primary"
                  focused
                  style={style}
                  name="ltstkcombustible"
                  type="number"
                  value={datosVehiculo.ltstkcombustible}
                  onChange={almacenar}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Lts</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  placeholder="Capacidad (m3)"
                  required
                  focused
                  name="capacidadtotalm3"
                  label="Capacidad (M3)"
                  style={style}
                  color="primary"
                  value={datosVehiculo.capacidadtotalm3}
                  onChange={(e) => {
                    setDatosVehiculo({
                      ...datosVehiculo,
                      capacidadtotalm3: e.target.value,
                      capacidad: e.target.value * 1000 * 0.52 * 0.85,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 2,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Battery50Icon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  disabled
                  focused
                  name="capacidad"
                  label="Capacidad KG (85% llenado)"
                  style={style}
                  color="primary"
                  value={datosVehiculo.capacidad}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Battery50Icon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  placeholder="Tara"
                  // required
                  color="primary"
                  focused
                  style={style}
                  name="tara"
                  label="Tara"
                  type="number"
                  value={datosVehiculo.tara}
                  onChange={almacenar}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FactCheckIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  style={style}
                  color="primary"
                  focused
                  required
                  label="Vencimiento VTV"
                  name="vencimientovtv"
                  type="date"
                  variant="outlined"
                  rows={2}
                  value={moment(datosVehiculo.vencimientovtv).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  style={style}
                  color="primary"
                  focused
                  required
                  label="Vencimiento Hidraulica"
                  name="vencimientohidraulica"
                  type="date"
                  variant="outlined"
                  rows={2}
                  value={moment(datosVehiculo.vencimientohidraulica).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                />
              </Grid>

              <Grid item xs={3.5}>
                <FormControl style={style} focused color="primary">
                  <InputLabel>Chofer</InputLabel>
                  <Select
                    name="id_chofer"
                    label="Chofer "
                    value={datosVehiculo.id_chofer}
                    onChange={almacenar}
                  >
                    {choferes
                      ? choferes.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.nombre_apellido}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={0.5}>
                <Tooltip title="Agregar Chofer">
                  <IconButton
                    color="primary"
                    size="small"
                    // onClick={handleFormChoferes}
                    onClick={() => window.open("/#/choferes")}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs={4}>
                <FormControl style={style} focused color="primary" required>
                  <InputLabel>Uso</InputLabel>
                  <Select
                    name="uso"
                    label="Uso "
                    value={datosVehiculo.uso}
                    onChange={almacenar}
                  >
                    {uso
                      ? uso.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.valor}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl style={style} focused color="primary" required>
                  <InputLabel>Sucursal</InputLabel>
                  <Select
                    name="id_sucursal"
                    label="Sucursal "
                    value={datosVehiculo.id_sucursal}
                    onChange={almacenar}
                  >
                    {sucursales
                      ? sucursales.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.nombre}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl style={style} focused color="primary" required>
                  <InputLabel>Dueño</InputLabel>
                  <Select
                    name="duenio"
                    label="Dueño "
                    value={datosVehiculo.duenio}
                    onChange={(e) => {
                      almacenar(e);
                      // setDatosVehiculo({...datosVehiculo, t1:null})
                    }}
                  // onChange={almacenar}
                  >
                    {duenio
                      ? duenio.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.valor}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl style={style} focused color="primary" required>
                  <InputLabel>Transportista</InputLabel>
                  <Select
                    name="id_transportista"
                    label="Transportista"
                    value={datosVehiculo.id_transportista}
                    onChange={(e) => {
                      almacenar(e);
                    }}
                  >
                    {transportistas
                      ? transportistas.map((elemento) => (
                        <MenuItem key={elemento.id_transportista} value={elemento.id_transportista}>
                          {elemento.nombre}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl style={style} focused color="primary" required>
                  <InputLabel>Tipo</InputLabel>
                  <Select
                    name="id_tipo"
                    label="Tipo "
                    value={datosVehiculo.id_tipo}
                    onChange={(e) => {
                      almacenar(e);
                    }}
                  >
                    {tipoVehiculo
                      ? tipoVehiculo.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.valor}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>


              <Grid item xs={1.5}>
                <FormControlLabel
                  disabled={datosVehiculo.id_tipo !== 10 || datosVehiculo.uso !== 66}
                  checked={datosVehiculo.t1 === 1}
                  onChange={(e) =>
                    setDatosVehiculo({
                      ...datosVehiculo,
                      ["t1"]: e.target.checked ? 1 : 0,
                    })
                  }
                  control={<Checkbox />}
                  label="Es T1"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 35 } }}
                />
              </Grid>
            </Grid>
            <Grid
              //   mt={2}
              container
              direction="row"
              justifyContent="space-between"
              alignItemns="center"
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>
              <Button
                variant="contained"
                disabled={disBtn}
                type="submit"
                color="success"
              >
                Guardar Vehiculo
              </Button>
            </Grid>
          </Box>
        </form>
        <br />
      </Container>
      :null}
      {altaMarca ? (
          <AddMarcaVehiculo
            alta={setAltaMarca}
            tabla={setTabla}
          />
        ) : null}
      {altaModelo ? (
          <AddModeloVehiculo
            alta={setAltaModelo}
            tabla={setTabla}
          />
        ) : null}
    </div>
  );
}

export default Addvehiculos;
