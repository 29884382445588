import React, { useEffect, useState } from 'react';
import { Button, Grid, Container, Select, MenuItem, FormControl } from '@mui/material';
// import { enviarmails } from '../../../../services/services';
import moment from 'moment';
import { getData } from '../../services/library';

export default function Openpdf(props) {
    const [lista, setlista] = useState();
    const [empresas, setEmpresas] = useState();
    const [datosOrden, setdatosOrden] = useState([{}])
    const [subtotal, setSubtotal] = useState(0);
    const fecha = (moment(new Date()).format("DD-MM-YYYY"));
    const tipoMonedaMapping = {
        82: "u$s",
        83: "$",
        84: "€",
    };
    const tipoMoneda = datosOrden[0] && datosOrden[0].tipo_moneda !== undefined ? datosOrden[0].tipo_moneda : props.datos.tipo_moneda;
    const iconoMoneda = tipoMonedaMapping[tipoMoneda] || "Error";
    const [selectedEmpresa, setSelectedEmpresa] = useState(null);
    /* tengo que obtener la lista de materiales de la orden de compra 
    tengo que pasar  como proppiedad el uuid de la orden de compra 
    si viene de "requerimientos, va a tener asique no problem"
    en todo caso si viene desde ordenes tambien va a tener uuid */

    const getListaMateriales = () => {
        getData('/lista_materiales/ordencompra/' + props.uuid, setlista)
    };

    /*Si viene desde un requerimiento necesito obtener los datos de la orden de
    compra desde el requerimiento, si no viene como props.datos*/
    const getDatosOrden = () => {
        getData("/ordencompra/requerimiento/" + props.uuid, setdatosOrden)
    };

    useEffect(() => {
        getData('/empresas', setEmpresas)
        getDatosOrden()
        getListaMateriales()
    }, [props.uuidreq]);

    useEffect(() => {
        lista && recorrerSubtotales()
    }, [lista]);

    const recorrerSubtotales = () => {
        let sub = 0
        lista && lista.forEach(element => {
            sub = sub + element.subtotal
        });
        setSubtotal(sub)
    };

    const Print = () => {
        var printContent = document.getElementById('imprimime');
        var WinPrint = window.open();
        WinPrint.document.write(printContent.innerHTML);
        WinPrint.document.close();
        var images = WinPrint.document.getElementsByTagName('img');
        var loadedImages = 0;
        for (var i = 0; i < images.length; i++) {
            images[i].onload = function () {
                loadedImages++;
                if (loadedImages === images.length) {
                    WinPrint.focus();
                    WinPrint.print();
                    WinPrint.close();
                }
            };
        }
        WinPrint.document.close();
    };

    const handleEmpresaChange = (event) => {
        const selectedLogo = event.target.value;

        const selectedEmpresa = empresas.find(empresa => empresa.logo === selectedLogo);
        setSelectedEmpresa(selectedEmpresa);
    };

    /*Con el tema del logo lo mismo puede ser de la orden de compra
    o de la empresa que quiera facturar */
    // console.log("empresas", selectedEmpresa)
    console.log("props.", props.datos)
    // console.log("subtotal", subtotal)
    // datosOrden && console.log("tipomoneda", datosOrden)

    return (
        <>
            <br />
            <select onChange={handleEmpresaChange}>
                <option value="">Seleccione una empresa</option>
                {empresas && empresas.map((empresa, index) => (
                    <option key={index} value={empresa.logo}>
                        {empresa.empresa}
                    </option>
                ))}
            </select>

            <div id="imprimime">
                <table
                    border="1"
                    position="absolute"
                    width="1000px"
                    margin="auto"
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "20px",
                        borderCollapse: "collapse",
                        marginBottom: "20px",
                    }}
                >
                    <table
                        border="1"
                        style={{
                            borderCollapse: "collapse",
                            width: "100%",
                            height: "90px",
                        }}
                    >
                        <tr>
                            <th>

                                {selectedEmpresa && (
                                    <>
                                        <img src={selectedEmpresa.logo} alt="Logo de la empresa" height={90} />
                                        <p>{selectedEmpresa.empresa}
                                            <br />{selectedEmpresa.cuit}</p>
                                    </>)}
                            </th>
                            <td style={{ fontSize: 22, textAlign: "center" }}>
                                <b>Orden de compra {datosOrden && datosOrden[0] && datosOrden[0].idordencompra !== undefined ? datosOrden[0].idordencompra : props.datos.idordencompra}</b>
                                <br />
                                <p style={{ fontSize: 14, textAlign: "center" }}> Fecha:  {fecha} </p></td>
                        </tr>


                    </table>

                    <table
                        border="1"
                        style={{
                            borderCollapse: "collapse",
                            width: "100%",
                            height: "55px",
                        }}
                    >
                        <tr style={{ fontSize: 14 }}>
                            <td style={{ textAlign: "left" }}>
                                <b>  Señores:  </b>{datosOrden?.[0]?.razon_social || props.datos.razon_social}  {" "}
                                {datosOrden?.[0]?.direccion || props.datos.direccion} {" "}
                                {datosOrden?.[0]?.localidad || props.datos.localidad} {" "}
                                {datosOrden?.[0]?.provincia || props.datos.provincia} {" "}
                            </td>
                        </tr>
                    </table>

                    <table
                        border="1"
                        style={{
                            borderCollapse: "collapse",
                            width: "100%",
                            height: "55px",
                        }}
                    >
                        <tr style={{ fontSize: 14 }}>
                            <td style={{ textAlign: "left" }}>
                                Agradeceremos entregar los elementos que detallamos a continuación y con las siguientes condiciones:
                                <br />
                                <b>{datosOrden && datosOrden[0] !== undefined ? datosOrden[0].cond_venta : props.datos.condicion}</b>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                Sucursal:
                                <br />
                                <b>{props.datos.nombre_sucursal}</b>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                Fecha de entrega:
                                <br />
                                <b>{moment(datosOrden && datosOrden[0] !== undefined ? datosOrden[0].fecha_entrega : props.datos.fecha_entrega).format("DD/MM/YYYY")}</b>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                IVA:
                                <br />
                                <b>{datosOrden &&
                                    datosOrden[0] !== undefined
                                    && datosOrden[0].iva_incluido === 1 ?
                                    "Incluido" :
                                    (props.datos && 
                                        props.datos && 
                                        props.datos.iva_incluido === 1 ? "Incluido" : "No incluido")
                                }


                                </b>
                            </td>
                        </tr>
                    </table>
                    <table
                        border="1"
                        style={{
                            borderCollapse: "collapse",
                            width: "100%",
                            height: "55px",
                        }}
                    >
                        <tr style={{ fontSize: 14 }}>
                            <td style={{ textAlign: "center" }}>
                                <b>Observaciones  </b>
                                <br />
                                {props.datos.observaciones}
                            </td>
                        </tr>
                    </table>

                    <table
                        border="1"
                        style={{
                            borderCollapse: "collapse",
                            width: "100%",
                            height: "55px",
                        }}
                    >
                        <tr>
                            <th>Cantidad</th>
                            <th>Articulo</th>
                            <th>Precio Unitario</th>
                            <th>Bonificación por item</th>
                            <th>Neto</th>
                        </tr>

                        {lista && lista.map((row) => (
                            <tr align="center" key={row.id}>
                                <td>{row.cantidad_actualizada ? row.cantidad_actualizada : row.cantidad}</td>
                                <td>{row.nombre}</td>
                                <td>{iconoMoneda}{row.precio_unitario}</td>
                                <td>{(row.bonificacion_pesos !== null ? "$" : (row.bonificacion_porcentaje !== null ? "%" : ""))
                                }{row.bonificacion_pesos !== null ? row.bonificacion_pesos : (row.bonificacion_porcentaje !== null ? row.bonificacion_porcentaje : "-")}</td>
                                <td>{iconoMoneda}{row.subtotal}</td>
                            </tr>
                        ))}
                    </table>
                    <table
                        border="1"
                        style={{
                            borderCollapse: "collapse",
                            width: "100%",
                            height: "55px",
                        }}
                    >
                        <tr align="center">
                            <th>SUBTOTAL</th>
                            <td>
                                {iconoMoneda}{subtotal}
                            </td>
                        </tr>
                        <tr align="center">
                            <th>BONIFICACIÓN AL TOTAL</th>
                            <td>{lista && datosOrden && datosOrden[0] && datosOrden[0].bonificacion_pesos ? iconoMoneda + datosOrden[0].bonificacion_pesos : props.datos.bonificacion_porcentaje}  </td>
                        </tr>
                        <tr align="center">
                            <th >TOTAL</th>
                            <td>
                                {iconoMoneda !== undefined ? `${iconoMoneda} ${datosOrden && datosOrden[0] && datosOrden[0].total !== undefined ? datosOrden[0].total : props.datos.total}` : "Error: Tipo de moneda no reconocido"}
                            </td>
                        </tr>
                    </table>

                </table>
            </div>
            <div>
                <Container fixed>
                    <Grid mt={2}
                        mb={5}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignitemns="center"
                    >
                        <Button
                            variant="contained"
                            type="button"
                            color="error"
                            onClick={() => {
                                props.open(false)
                                props.tabla(true)
                                props.tab && props.tab(true)
                            }}>Volver</Button>
                        <Button
                            variant="contained"
                            type="button"
                            color="success"
                            onClick={Print} >Imprimir</Button>
                    </Grid>
                </Container>
            </div>
        </>

    )
}
