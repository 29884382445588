//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 05/08/2024
// Version : 1
// Description : dialog de expancion de la tabla de instaladores
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function Dialog_graf_insta(props) {
  const [abrirDialog, setAbrirDialog] = useState(props.abrirDialog);

  const handleClose = () => {
    setAbrirDialog(!abrirDialog);
    if (props.onClose) {
      props.onClose();
    }
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <div>
      <Dialog fullScreen open={abrirDialog} onClose={handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Instaladores
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Cerrar
            </Button>
          </Toolbar>
        </AppBar>
        <TableContainer style={{ marginTop: 20 }}>
          <Typography
            align="center"
            style={{ fontWeight: "bold", fontSize: 20 }}
          >
            Instaladores por estado {props.selectedDate.year()}
          </Typography>
          <Table
            sx={{ minWidth: 300, marginTop: 2 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    border: "1px solid black",
                    position: "relative",
                    backgroundColor: "#2B76A6",
                    color: "white",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <b>Meses</b>
                  </div>
                </TableCell>
                {props.meses.map((mes, mesIndex) => (
                  <TableCell
                    key={mesIndex}
                    align="center"
                    colSpan={props.estados.length}
                    style={{
                      backgroundColor: "#2B76A6",
                      color: "white",
                      border: "2px solid black",
                    }}
                  >
                    {mes}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: "#2B76A6",
                    color: "white",
                    border: "1px solid black",
                  }}
                >
                  <b>Estado</b>
                  <hr
                    style={{
                      border: "none",
                      borderTop: "1px solid black",
                    }}
                  />
                  <b>Instaladores</b>
                </TableCell>
                {props.meses.map((mes, mesIndex) =>
                  props.estados.map((estado, estadoIndex) => (
                    <TableCell
                      key={`${mesIndex}-${estadoIndex}`}
                      align="center"
                      style={{
                        backgroundColor: "#2B76A6",
                        color: "white",
                        border: "2px solid black", /// border que tiene problema y desaparece
                        borderBottom: "2px solid black",
                        borderLeft:
                          estadoIndex === 0 ? "2px solid black" : undefined, // Borde izquierdo para el primer estado
                        borderRight:
                          estadoIndex === props.estados.length - 1
                            ? "2px solid black"
                            : undefined, // Borde derecho para el último estado
                      }}
                    >
                      {estado}
                    </TableCell>
                  ))
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.proveedores.map((proveedor, proveedorIndex) => (
                <TableRow
                  key={proveedorIndex}
                  sx={{
                    border: "1px solid black",
                    backgroundColor:
                      proveedorIndex % 2 === 0 ? "#FFFFFF" : "#8D8D8D", // Alterna colores
                  }}
                >
                  <TableCell align="center" sx={{ border: "1px solid black" }}>
                    {proveedor}
                  </TableCell>
                  {props.meses.map((mes, mesIndex) =>
                    props.estados.map((estado, estadoIndex) => (
                      <TableCell
                        key={`${mesIndex}-${estadoIndex}`}
                        align="center"
                        sx={{
                          border: "1px solid black",
                          borderLeft:
                            estadoIndex === 0 ? "2px solid black" : undefined,
                          borderRight:
                            estadoIndex === props.estados.length - 1
                              ? "2px solid black"
                              : undefined,
                        }}
                      >
                        {props.dataset.find(
                          (item) =>
                            item.mes === mes &&
                            item[proveedor] &&
                            item[proveedor][estado] !== undefined
                        )?.[proveedor][estado] || 0}
                      </TableCell>
                    ))
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
    </div>
  );
}

export default Dialog_graf_insta;
