import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

function FormCostos(props) {
  const almacenar = (e) => {
    props.setDataFact({
      ...props.dataFact,
      [e.target.name]: e.target.value || null,
    });
  };

  const readOnly = props.readOnly;

  return (
    <div>
      <Grid containers direction="row" px={1}>
        <Grid
          m={0.5}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          backgroundColor="#ebebeb"
          border={0.5}
          borderRadius={10}
          borderColor={"#AEB6BF"}
        >
          <Typography p={0.5} variant="body2" color="initial">
            Costos absorbidos por la empresa:
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" px={2}>
        <Grid container spacing={1} alignItems="flex-end" direction="row">
          <Grid item xs={9}>
            <TextField
              // autoFocus
              autoComplete="off"
              fullWidth
              label="Motivo 1"
              variant="standard"
              size="small"
              name="motivo1"
              value={props.dataFact.motivo1}
              onChange={almacenar}
              inputProps={{ readOnly: readOnly }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Costo 1"
              size="small"
              variant="filled"
              name="cantmotivo1"
              value={props.dataFact.cantmotivo1}
              onChange={almacenar}
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              inputProps={{ maxLength: 10, readOnly: readOnly }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              autoComplete="off"
              fullWidth
              label="Motivo 2"
              variant="standard"
              size="small"
              name="motivo2"
              value={props.dataFact.motivo2}
              onChange={almacenar}
              inputProps={{ maxLength: 200, readOnly: readOnly }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Costo 2"
              size="small"
              variant="filled"
              name="cantmotivo2"
              value={props.dataFact.cantmotivo2}
              onChange={almacenar}
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              inputProps={{ maxLength: 10, readOnly: readOnly }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              autoComplete="off"
              fullWidth
              label="Motivo 3"
              variant="standard"
              size="small"
              name="motivo3"
              value={props.dataFact.motivo3}
              onChange={almacenar}
              inputProps={{ maxLength: 200, readOnly: readOnly }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Costo 3"
              size="small"
              variant="filled"
              name="cantmotivo3"
              value={props.dataFact.cantmotivo3}
              onChange={almacenar}
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              inputProps={{ maxLength: 10, readOnly: readOnly }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid
            item
            xs={12}
            mt={1}
            mb={0.5}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            backgroundColor="#ebebeb"
            border={0.5}
            borderRadius={10}
            borderColor={"#AEB6BF"}
          >
            <Typography p={0.5} variant="body2" color="initial">
              Costos absorbidos por el cliente:
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="flex-end" direction="row">
          <Grid item xs={9}>
            <TextField
              autoComplete="off"
              fullWidth
              label="Motivo 1"
              variant="standard"
              size="small"
              name="motivocli1"
              value={props.dataFact.motivocli1}
              onChange={almacenar}
              inputProps={{ maxLength: 200, readOnly: readOnly }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Costo 1"
              size="small"
              variant="filled"
              name="cantmotivocli1"
              value={props.dataFact.cantmotivocli1}
              onChange={almacenar}
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              inputProps={{ maxLength: 10, readOnly: readOnly }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              autoComplete="off"
              fullWidth
              label="Motivo 2"
              variant="standard"
              size="small"
              name="motivocli2"
              value={props.dataFact.motivocli2}
              onChange={almacenar}
              inputProps={{ maxLength: 200, readOnly: readOnly }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Costo 2"
              size="small"
              variant="filled"
              name="cantmotivocli2"
              value={props.dataFact.cantmotivocli2}
              onChange={almacenar}
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              inputProps={{ maxLength: 10, readOnly: readOnly }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              autoComplete="off"
              fullWidth
              label="Motivo 3"
              variant="standard"
              size="small"
              name="motivocli3"
              value={props.dataFact.motivocli3}
              onChange={almacenar}
              inputProps={{ maxLength: 200, readOnly: readOnly }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Costo 3"
              size="small"
              variant="filled"
              name="cantmotivocli3"
              value={props.dataFact.cantmotivocli3}
              onChange={almacenar}
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              inputProps={{ maxLength: 10, readOnly: readOnly }}
            />
          </Grid>
        </Grid>
      </Grid>
      <br />
    </div>
  );
}

export default FormCostos;
