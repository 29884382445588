import React, { useState, useEffect } from "react";
import { getData, postData } from "../../../../../services/library";
import { FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Typography, Box, Grid, Button, Dialog, Autocomplete, IconButton, DialogActions, DialogContent, Stack, TextField } from '@mui/material';
import Notify from "../../../../Notify";
import { grey } from '@mui/material/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { logEventos } from "../../../../../services/services";

export default function AgregarMateriales(props) {
    const [articulos, setArticulos] = useState(null);
    const [medidas, setmedidas] = useState(null);
    const [disBtn, setDisBtn] = useState(false);
    const [datosLista, setDatosLista] = useState([{
        usuario: localStorage.getItem('user'),
        cantidad: null,
        uuid_requerimiento: props.uuidreq,
        id_articulo: null,
        precio_unitario: null,
        bonificacion_pesos: null,
        bonificacion_porcentaje: null,
        subtotal: null,
        uuid_ordencompra: props.uuidordencompra ?? null,
        material: null
    }]);

    const [checked, setChecked] = useState(datosLista.id_articulo !== null);

    console.log("checked", checked)

    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    useEffect(() => {
        getData("/stock_articulos", setArticulos);
        getData("/unidad_medida", setmedidas);
    }, []);

    const handleClose = () => {
        props.open(false)
        props.actualizar()
    };

    const handleCantidadChange = (index, cantidad,) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index
                    ? { ...linea, cantidad, subtotal: calcularSubtotalPesos(cantidad, linea.precio_unitario, linea.bonificacion_pesos) }
                    : linea
            )
        );
    };

    const handleArticuloChange = (index, selectedArticulo) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index ? { ...linea, id_articulo: selectedArticulo?.id_articulo || null } : linea,

            )
        );
    };

    const handlePrecioChange = (index, precio_unitario) => {
        setDatosLista((prevDatosLista) =>
            prevDatosLista.map((linea, i) => {
                if (i === index) {
                    const subtotal = linea.bonificacion_pesos
                        ? calcularSubtotalPesos(linea.cantidad, precio_unitario, linea.bonificacion_pesos)
                        : calcularSubtotalPorcentaje(linea.cantidad, precio_unitario, linea.bonificacion_porcentaje);

                    return { ...linea, precio_unitario, subtotal };
                }
                return linea;
            })
        );
    };

    const handleBonificacionPorcentajeChange = (index, bonificacion_porcentaje) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index
                    ? {
                        ...linea,
                        bonificacion_porcentaje,
                        subtotal: calcularSubtotalPorcentaje(linea.cantidad, linea.precio_unitario, bonificacion_porcentaje),
                    }
                    : linea
            )
        );
    };

    const handleBonificacionPesosChange = (index, bonificacion_pesos) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index
                    ? {
                        ...linea,
                        bonificacion_pesos,
                        subtotal: calcularSubtotalPesos(linea.cantidad, linea.precio_unitario, bonificacion_pesos),
                    }
                    : linea
            )
        );
    };

    const calcularSubtotalPesos = (cantidad, precio, bonificacion_pesos) => {
        const cantidadNumerica = parseFloat(cantidad);
        const precioNumerico = parseFloat(precio);
        const bonificacionNumerico = parseFloat(bonificacion_pesos) || 0;
        return (cantidadNumerica * precioNumerico) - bonificacionNumerico;
    };

    const calcularSubtotalPorcentaje = (cantidad, precio, bonificacion_porcentaje) => {
        const cantidadNumerica = parseFloat(cantidad);
        const precioNumerico = parseFloat(precio);
        const bonificacionPorcentaje = parseFloat(bonificacion_porcentaje) || 0;
        const bonificacionDecimal = bonificacionPorcentaje / 100;
        return cantidadNumerica * precioNumerico * (1 - bonificacionDecimal);
    };

    const handleMaterialChange = (index, material) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index ? { ...linea, material } : linea
            )
        );
    };

    const handleMedidaChange = (index, id_unidad_medida) => {
        setDatosLista((prevLineas) =>
            prevLineas.map((linea, i) =>
                i === index ? { ...linea, id_unidad_medida } : linea
            )
        );
    };

    const guardarListaMateriales = (e) => {
        setDisBtn(true);
        postData(e,
            "/lista_materiales",
            {
                datosLista
            },
            setNotificacion,
            logEventos("Editar lista de materiales", "Lista de materiales", "Lista de materiales", localStorage.getItem("user"))
        );
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <Dialog open={props.open} fullWidth={true} >
            <Box sx={{ flexGrow: 1 }}>
                <Grid container backgroundColor="#1F618D" p={1.5}>
                    <Grid item xs={2}>
                        <IconButton onClick={handleClose}>
                            <ArrowBackIcon sx={{ color: grey[50] }} />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs={8}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography variant="h5" color="#ffffff">
                            Agregar un articulo
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <form onSubmit={guardarListaMateriales} autoComplete="off">
                <DialogContent>

                    <Box>
                        <FormControlLabel
                            labelPlacement="start"
                            label={"De Stock"}
                            checked={checked}
                            onChange={handleChange}
                            control={<Checkbox />}
                        />
                        {datosLista.map((linea, index) => (
                            <div key={index} style={{ paddingBottom: '12px', paddingLeft: "15px", paddingTop: "8px" }}>
                                <Stack spacing={1} direction="column" >
                                    <TextField
                                        focused color="primary" required size="small"
                                        style={style}
                                        autoComplete="off"
                                        type="number"
                                        label="Cantidad"
                                        value={linea.cantidad}
                                        onChange={(e) => handleCantidadChange(index, e.target.value)}
                                    />
                                    {checked ?

                                        <Autocomplete
                                            fullWidth
                                            options={articulos}  // Reemplaza con tus opciones de artículos
                                            getOptionLabel={(option) => (option ? option.nombre || '' : '')}
                                            value={linea.articulo}  // Usa la propiedad completa del artículo 
                                            onChange={(event, newValue) => handleArticuloChange(index, newValue)}
                                            renderInput={(params) => (
                                                <TextField {...params} focused color="primary" required={checked === true} label="Articulo" size="small" style={style} />
                                            )}
                                        />
                                        :
                                        <>
                                            <FormControl focused color="primary" margin='dense' size='small' style={style} required={checked === false}>
                                                <InputLabel id="lbl_autorizante">Unidad de medida</InputLabel>
                                                <Select
                                                    value={linea.id_unidad_medida}
                                                    fullWidth
                                                    onChange={(e) => handleMedidaChange(index, e.target.value)}
                                                    name='id_unidad_medida'
                                                    label="Unidad de medida"
                                                >
                                                    {(medidas ?? []).map(elemento => (
                                                        <MenuItem key={elemento.id} value={elemento.id}>
                                                            {elemento.descripcion}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                required={checked === false}
                                                focused color="primary" size="small"
                                                style={style}
                                                label="Articulo/Servicio"
                                                value={linea.material}
                                                name="material"
                                                onChange={(e) => handleMaterialChange(index, e.target.value)} />


                                        </>}

                                    {props.position === 1 ? (
                                        <Stack spacing={1} direction="row" >
                                            <TextField
                                                label="Precio"
                                                type="number"
                                                required
                                                size="small"
                                                focused
                                                multiline
                                                color="primary"
                                                style={{ width: '20ch', backgroundColor: "#ffffff" }}
                                                margin="none"
                                                name='precio_unitario'
                                                value={linea.precio_unitario}
                                                onChange={(e) => handlePrecioChange(index, e.target.value)}
                                            />

                                            <TextField
                                                autoComplete='off'
                                                label="Bonif. $"
                                                type="number"
                                                size="small"
                                                focused
                                                multiline
                                                color="primary"
                                                style={{ width: '20ch', backgroundColor: "#ffffff" }}
                                                margin="none"
                                                name='bonificacion_pesos'
                                                value={linea.bonificacion_pesos}
                                                onChange={(e) => handleBonificacionPesosChange(index, e.target.value ? e.target.value : null)}
                                                InputProps={{ inputProps: { min: 0, max: 100 } }}
                                            />
                                            <TextField
                                                label="Bonif. %"
                                                type="number"
                                                size="small"
                                                focused
                                                multiline
                                                autoComplete='off'
                                                color="primary"
                                                style={{ width: '20ch', backgroundColor: "#ffffff" }}
                                                margin="none"
                                                name='bonificacion_porcentaje'
                                                value={linea.bonificacion_porcentaje}
                                                onChange={(e) => handleBonificacionPorcentajeChange(index, e.target.value ? e.target.value : null)}
                                                InputProps={{ inputProps: { min: 0, max: 100 } }}
                                            />

                                            <TextField
                                                label="Subtotal"
                                                type="number"
                                                required
                                                // disabled
                                                size="small"
                                                focused
                                                color="primary"
                                                style={{ width: '20ch', backgroundColor: "#ffffff" }}
                                                margin="none"
                                                name='subtotal'
                                                value={linea.subtotal}
                                                // onChange={(e) => handleSubtotalChange(index, e.target.value)}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Stack>
                                    ) : null}
                                </Stack>
                            </div>
                        ))}
                    </Box>


                </DialogContent>

                <DialogActions >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItemns="center"
                    >
                        <Button variant="contained" color="error" onClick={handleClose}>VOLVER</Button>
                        <Button disabled={disBtn} color="success" variant="contained" type="submit">
                            Guardar
                        </Button>
                    </Grid>
                </DialogActions>
            </form>
            {
                notificacion && notificacion.open ? (
                    <Notify
                        mensaje={notificacion.mensaje}
                        open={notificacion.open}
                        color={notificacion.color}
                        handleClose={handleClose}
                        severity={notificacion.severity}
                    />
                ) : null
            }
        </Dialog >
    )
}

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 29/11/2023
// Version       : 1.0  
// Description   :  Con este componente el usuario es capaz de :
    - Cargar nuevo material desde editarrequerimientos
    - Cargar nuevo articulo desde add orden de compra con su precio y bonificacion
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
//========================================================================*/