// Developer     : Magalí Perea
// Creation Date : 27-2-2024
// Version       : 1
// Description   : Este componente contiene el alta de tantos domicilios como sea necesario, para un solo cliente. Máximo: 10. Esta limitación es porque me pareció justo, no se debe a nada en específico.

import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  InputAdornment,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import DeleteIcon from "@mui/icons-material/Delete";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { FormHelperText } from "@mui/material";

//Componentes o funciones propias.
import { getData, getCodeArg } from "../../../../../../services/library";
import MapaCliente from "../formularios/MapaCliente";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#c4c4c4",
            "--TextField-brandBorderHoverColor": "#c4c4c4",
            "--TextField-brandBorderFocusedColor": "#666666", //aca
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: " #c4c4c4",
              borderWidth: "1px",
            },
          },
        },
      },
    },
  });

function AltaDomicilios(props) {
  //Estados que guardarán los datos necesarios para completar los datos del formulario.
  const [provincias, setProvincias] = useState(null);
  const [localidades, setLocalidades] = useState(null);
  const [sucursales, setSucursales] = useState(null);
  const [productos, setProductos] = useState(null);
  const [empresas, setEmpresas] = useState(null);
  const [codArg, setCodArg] = useState("");
  const [tipo_moneda, settipo_moneda]= useState(null);

  // Variables o estados que manejan componentes visuales
  const txtfldcolor = "primary";
  const outerTheme = useTheme();
  const bgColor = "#fff";
  const size = "small";
  const [expandedAccordion, setExpandedAccordion] = useState(0); // Estado para rastrear el Accordion expandido

  //--------------------- FUNCIONES ---------------------//
  //Obtengo por única vez al momento de carga del componente y mediante llamadas a la api, los siguientes datos necesarios para rellenar el formulario:
  useEffect(() => {
    getData("/provincias", setProvincias);
    getData("/productos", setProductos);
    getData("/localidades", setLocalidades);
    getData("/empresas", setEmpresas);
    getData('/tipo_moneda_afip', settipo_moneda);
    getCodeArg(setCodArg);

    if (props.tipoCliente === 1) {
      getData("/sucursales/tipoPlanta", setSucursales);
    } else {
      getData("/sucursales", setSucursales);
    }
  }, []);

  //Función que se ejecuta cuando se presiona el iconButton "delete", que elimina el registro indicado de la lista de domicilios de entrega.
  const deshacerAlmacenar = (index) => {
    if (props.listaDom.length > 1) {
      const values = [...props.listaDom];
      values.splice(index, 1); // Elimina el elemento en el índice dado del array original
      // Actualiza el estado con el nuevo array que contiene el elemento eliminado
      props.setListaDom(values);
    } else {
      props.setListaDom([
        {
          nro_dir: props.uuid,
          direccion_entrega: "",
          nombre_direccion: "",
          id_provincia: "",
          id_localidad: "",
          id_sucursal: "",
          contacto: "",
          telefono1: "",
          telefono2: "",
          telefono3: "",
          mails: "",
          latitud: "",
          longitud: "",
          usuario: localStorage.getItem("user"),
          activo: 1,
          verificada: 1,
          id_empresa: "",
          id_tipomoneda:""
        },
      ]);
    }
  };

  // console.log("tipo_moneda", props.listaDom.id_tipomoneda)

  //Funciones para manejar el acordión
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpandedAccordion(panel);
    } else {
      setExpandedAccordion(-1); // Colapsar el acordeón al cerrarse
    }
  };

  const handleAddAccordion = () => {
    props.almacenarDomicilios();
    setExpandedAccordion(props.listaDom.length);
  };

  useEffect(() => {
    if (props.tipoCliente === 1) {
      const listaDom = [...props.listaDom];
      listaDom[0] = {
        ...listaDom[0],
        id_producto: 1,
      };
      props.setListaDom(listaDom);
    }
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {props.listaDom
        ? props.listaDom.map((domicilio, index) => (
          <>
            <div key={index}>
              <Container maxWidth="xl" sx={{ mt: 0.5 }} disableGutters>
                <Accordion
                  defaultExpanded={index === 0}
                  expanded={expandedAccordion === index}
                  onChange={handleAccordionChange(index)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={4}>
                        <Typography variant="body1" color="initial">
                          Direccion De Entrega {index + 1}{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <IconButton
                          color="error"
                          onClick={(e) => {
                            deshacerAlmacenar(index);
                            e.stopPropagation();
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      container
                      direction="row"
                      p={2}
                      borderRadius={3}
                      border={1}
                      borderColor={"#ABB2B9"}
                      backgroundColor="#ebebeb"
                    >
                      <Grid item xs={7} px={1}>
                        <Grid container direction="row" spacing={1}>
                          <Grid item xs={12}>
                            <TextField
                              style={{ backgroundColor: bgColor }}
                              autoFocus
                              fullWidth
                              color={txtfldcolor}
                              size={size}
                              label="Lugar Entrega"
                              value={
                                domicilio.nombre_direccion &&
                                domicilio.nombre_direccion.replace(
                                  /\b\w/g,
                                  (l) => l.toUpperCase()
                                )
                              }
                              name="nombre_direccion"
                              onChange={(e) => {
                                props.handleInputChange(
                                  "nombre_direccion",
                                  e,
                                  index
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            {provincias && (
                              <FormControl size={size} fullWidth required>
                                <InputLabel>Provincia</InputLabel>
                                <Select
                                  style={{ backgroundColor: bgColor }}
                                  name="id_provincia"
                                  labelId="id_provincia"
                                  value={domicilio.id_provincia}
                                  label="Provincia"
                                  onChange={(e) => {
                                    const updatedListaDom = [
                                      ...props.listaDom,
                                    ];
                                    updatedListaDom[index].id_provincia =
                                      e.target.value;
                                    updatedListaDom[index].id_localidad =
                                      null;
                                    props.setListaDom(updatedListaDom);
                                  }}
                                >
                                  {provincias
                                    ? provincias.map((elemento) => (
                                      <MenuItem
                                        key={elemento.id}
                                        value={elemento.id}
                                      >
                                        {elemento.provincia}
                                      </MenuItem>
                                    ))
                                    : null}
                                </Select>
                              </FormControl>
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <FormControl size={size} fullWidth required>
                              <InputLabel>Localidad</InputLabel>
                              <Select
                                style={{ backgroundColor: bgColor }}
                                name="id_localidad"
                                labelId="id_provincia"
                                value={domicilio.id_localidad}
                                label="Provincia"
                                onChange={(e) => {
                                  props.handleInputChange(
                                    "id_localidad",
                                    e,
                                    index
                                  );
                                }}
                              >
                                {localidades &&
                                  localidades
                                    .filter(
                                      (localidad) =>
                                        localidad.id_provincia ===
                                        domicilio.id_provincia
                                    )
                                    .map((localidad) => (
                                      <MenuItem
                                        key={localidad.id}
                                        value={localidad.id}
                                      >
                                        {localidad.localidad}
                                      </MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={3}>
                            {sucursales && (
                              <FormControl size={size} fullWidth required>
                                <InputLabel>Sucursal</InputLabel>
                                <Select
                                  style={{ backgroundColor: bgColor }}
                                  name="id_sucursal"
                                  value={domicilio.id_sucursal}
                                  label="Sucursal"
                                  onChange={(e) => {
                                    props.handleInputChange(
                                      "id_sucursal",
                                      e,
                                      index
                                    );
                                  }}
                                >
                                  {props.tipoCliente === 1
                                    ? sucursales
                                      .filter(
                                        (sucursal) =>
                                          sucursal.tipo === "planta"
                                      )
                                      .map((sucursal) => (
                                        <MenuItem
                                          key={sucursal.id}
                                          value={sucursal.id}
                                        >
                                          {sucursal.nombre}
                                        </MenuItem>
                                      ))
                                    : sucursales.map((sucursal) => (
                                      <MenuItem
                                        key={sucursal.id}
                                        value={sucursal.id}
                                      >
                                        {sucursal.nombre}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            {empresas && (
                              <FormControl size={size} fullWidth required>
                                <InputLabel>Empresa</InputLabel>
                                <Select
                                  style={{ backgroundColor: bgColor }}
                                  name="id_empresa"
                                  value={domicilio.id_empresa}
                                  label="Empresa"
                                  onChange={(e) => {
                                    props.handleInputChange(
                                      "id_empresa",
                                      e,
                                      index
                                    );
                                  }}
                                >
                                  {empresas
                                    .map((empresa) => (
                                      <MenuItem
                                        key={empresa.idempresa}
                                        value={empresa.idempresa}
                                      >
                                        {empresa.empresa}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            )}
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              style={{ backgroundColor: bgColor }}
                              fullWidth
                              color={txtfldcolor}
                              size={size}
                              label="Contacto"
                              value={domicilio.contacto}
                              name="contacto"
                              onChange={(e) => {
                                props.handleInputChange("contacto", e, index);
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              style={{ backgroundColor: bgColor }}
                              fullWidth
                              color={txtfldcolor}
                              size={size}
                              label="Mail"
                              value={domicilio.mails}
                              name="mails"
                              onChange={(e) => {
                                props.handleInputChange("mails", e, index);
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                          {tipo_moneda && (
                              <FormControl size={size} fullWidth required>
                                <InputLabel>Tipo moneda</InputLabel>
                                <Select
                                  style={{ backgroundColor: bgColor }}
                                  name="id_tipomoneda"
                                  labelId="id_tipomoneda"
                                  value={domicilio.id_tipomoneda}
                                  label="Tipo moneda"
                                  onChange={(e) => {
                                    props.handleInputChange(
                                      "id_tipomoneda",
                                      e,
                                      index
                                    );
                                  }}
                                >
                                  {tipo_moneda
                                    ? tipo_moneda.map((elemento) => (
                                      <MenuItem
                                        key={elemento.idtipo_moneda_afip}
                                        value={elemento.idtipo_moneda_afip}
                                      >
                                        {elemento.moneda}
                                      </MenuItem>
                                    ))
                                    : null}
                                </Select>
                              </FormControl>
                            )}
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl>
                              <TextField
                                style={{ backgroundColor: bgColor }}
                                fullWidth
                                color={txtfldcolor}
                                size={size}
                                label="Teléfono 1"
                                value={domicilio.telefono1}
                                name="telefono1"
                                onChange={(e) => {
                                  props.handleInputChange(
                                    "telefono1",
                                    e,
                                    index
                                  );
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {codArg}
                                    </InputAdornment>
                                  ),
                                }}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                inputProps={{
                                  maxLength: 10,
                                }}
                              />
                              <FormHelperText
                                sx={{ bgcolor: "#ebebeb", color: "#5D6D7E" }}
                              >
                                "Cód área (sin 0) + número sin el 15.
                                Ej:341xxxxxx "
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl>
                              <TextField
                                style={{ backgroundColor: bgColor }}
                                fullWidth
                                color={txtfldcolor}
                                size={size}
                                label="Teléfono 2"
                                value={domicilio.telefono2}
                                name="telefono2"
                                onChange={(e) => {
                                  props.handleInputChange(
                                    "telefono2",
                                    e,
                                    index
                                  );
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {codArg}
                                    </InputAdornment>
                                  ),
                                }}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                inputProps={{
                                  maxLength: 10,
                                }}
                              />
                              <FormHelperText
                                sx={{ bgcolor: "#ebebeb", color: "#5D6D7E" }}
                              >
                                "Cód área (sin 0) + número sin el 15.
                                Ej:341xxxxxx "
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl>
                              <TextField
                                style={{ backgroundColor: bgColor }}
                                fullWidth
                                color={txtfldcolor}
                                size={size}
                                label="Teléfono 3"
                                value={domicilio.telefono3}
                                name="telefono3"
                                onChange={(e) => {
                                  props.handleInputChange(
                                    "telefono3",
                                    e,
                                    index
                                  );
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {codArg}
                                    </InputAdornment>
                                  ),
                                }}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                inputProps={{
                                  maxLength: 10,
                                }}
                              />
                              <FormHelperText
                                sx={{ bgcolor: "#ebebeb", color: "#5D6D7E" }}
                              >
                                "Cód área (sin 0) + número sin el 15.
                                Ej:341xxxxxx "
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            {productos && (
                              <FormControl size={size} fullWidth required>
                                <InputLabel>Producto</InputLabel>
                                <Select
                                  style={{ backgroundColor: bgColor }}
                                  name="id_producto"
                                  value={domicilio.id_producto}
                                  label="Producto"
                                  onChange={(e) => {
                                    props.handleInputChange(
                                      "id_producto",
                                      e,
                                      index
                                    );
                                  }}
                                >
                                  {productos
                                    ? productos.map((elemento) => (
                                      <MenuItem
                                        key={elemento.idproducto}
                                        value={elemento.idproducto}
                                      >
                                        {elemento.nombre}
                                      </MenuItem>
                                    ))
                                    : null}
                                </Select>
                              </FormControl>
                            )}
                          </Grid>
                          <ThemeProvider theme={customTheme(outerTheme)}>
                            <Grid item xs={4}>
                              <TextField
                                style={{ backgroundColor: bgColor }}
                                variant="outlined"
                                disableTabFocus
                                inputProps={{
                                  maxLength: 300,
                                  readOnly: true,
                                }}
                                color={"grey"}
                                fullWidth
                                size={size}
                                label="Latitud"
                                value={domicilio.latitud}
                                name="latitud"
                                onChange={(e) => {
                                  props.handleInputChange(
                                    "latitud",
                                    e,
                                    index
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                style={{ backgroundColor: bgColor }}
                                fullWidth
                                color={txtfldcolor}
                                size={size}
                                label="Longitud"
                                value={domicilio.longitud}
                                name="longitud"
                                onChange={(e) => {
                                  props.handleInputChange(
                                    "longitud",
                                    e,
                                    index
                                  );
                                }}
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                          </ThemeProvider>
                        </Grid>
                      </Grid>

                      <Grid item xs={5} style={{ height: "100px" }}>
                        <MapaCliente
                          datosDir={domicilio}
                          handleInputChange={props.handleInputChange}
                          index={index}
                          actualizarCoordenadas={props.actualizarCoordenadas}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Container>
            </div>
          </>
        ))
        : null}
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          left: "50%",
          transform: "translateX(-50%)",
          mb: 3,
        }}
      >
        <Tooltip title="Añadir Domicilio">
          <Fab
            color="primary"
            aria-label="add"
            onClick={handleAddAccordion}
            disabled={props.listaDom.length === 10}
            sx={{ ml: 1 }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </Box>
      <br />
      <br />
      {/* <br /> */}
    </div>
  );
}

export default AltaDomicilios;
