import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import TitleUploaded from "../../../components/TitleUploaded";
import { getData } from "../../../services/library";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import AddFact from "../alta/AddFact";

function FormInicioFact(props) {
  const [showForm, setShowForm] = useState(true);
  const [showAddFact, setShowAddFact] = useState(false);
  const [tiposFact, setTiposFact] = useState([]);
  const [formData, setFormData] = useState({
    id_tipofact: "",
  });
  const [clientes, setClientes] = useState(null);
  const [domicilios, setDomicilios] = useState(null);
  const [cliente, setCliente] = useState({});
  const [direccion, setDireccion] = useState({});
  useEffect(() => {
    getData("/desplegables/tipoFact", setTiposFact);
  }, []);

  useEffect(() => {
    (formData.id_tipofact === 236 || formData.id_tipofact === 237) && getData("/clientes/activos", setClientes);
  }, [formData.id_tipofact]);

  useEffect(() => {
    formData.id_tipofact === 236 &&
      getData("/direccionesentrega/cli/" + formData.id_cliente, setDomicilios);
  }, [formData.id_cliente]);

  console.log("domicilios,", domicilios);

  const handleNext = () => {
    setShowForm(false);
    setShowAddFact(true);
  };

  return (
    <Container maxWidth="xl">
      {showForm && (
        <>
          <TitleUploaded
            title="Nueva Factibilidad"
            handleClose={() => {
              props.setShowAdd(false);
              props.setShowTable(true);
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "auto", // Ajusta la altura automáticamente
              minHeight: "80vh", // Mantén un mínimo de 80vh
            }}
          >
            <Paper
              elevation={10}
              sx={{
                backgroundColor: "#ebebeb",
                width: "auto", // Mantén tus dimensiones
                height: "70vh", // Ajusta la altura automáticamente
                maxHeight: "90vh", // Limita la altura máxima para evitar desbordamiento excesivo
                padding: 2,
                overflow: "auto", // Permite el desplazamiento si el contenido es demasiado grande
                minWidth: "80vh"
              }}
            >
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                style={{ height: "100%" }}
                pt={3}
                spacing={2}
              >
                <Grid item>
                  <FormControl
                    required
                    fullWidth
                    style={{ backgroundColor: "#fff" }}
                  >
                    <InputLabel>Tipo Factibilidad</InputLabel>
                    <Select
                      name="tipo"
                      label="Tipo Factibilidad"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          id_tipofact: e.target.value,
                          id_cliente:
                            e.target.value === 235 ? "" : formData.id_cliente,
                          id_domicilio: "",
                        });
                        setCliente(null);
                        setDireccion(null);
                      }}
                    >
                      {tiposFact.map((tipo) => (
                        <MenuItem key={tipo.id} value={tipo.id}>
                          {tipo.valor}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {(formData.id_tipofact === 236 ||
                  formData.id_tipofact === 237) && (
                    <>
                      <Grid item>
                        <Autocomplete
                          disabled={!clientes}
                          sx={{ backgroundColor: "#fff" }}
                          focused
                          noOptionsText={"Inexistente.."}
                          options={clientes}
                          autoHighlight
                          getOptionLabel={(cliente) => `${cliente.razon_social} - ${cliente.cuit}`}
                          onChange={(event, value) => {
                            setFormData({
                              ...formData,
                              id_cliente: value.id,
                            });
                            setCliente(value);
                          }}
                          loading={!clientes}
                          renderOption={(props, option, index) => (
                            <Box
                              component="li"
                              key={index}
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option.razon_social + "(" + option.cuit + ")"}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Cliente"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {!clientes ? (
                                      <CircularProgress
                                        color="primary"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {formData.id_tipofact === 236 && (
                        <Grid item>
                          <FormControl
                            required
                            fullWidth
                            style={{ backgroundColor: "#fff" }}
                          >
                            <InputLabel>Dirección De Entrega</InputLabel>
                            <Select
                              name="tipo"
                              label="Dirección De Entrega"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  id_domicilio: e.target.value.id,
                                });
                                setDireccion(e.target.value);
                              }}
                            >
                              {domicilios &&
                                domicilios.map((direccion, index) => (
                                  <MenuItem key={index} value={direccion}>
                                    {direccion.direccion_entrega}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    </>
                  )}

                <Grid item>
                  <Grid container direction="row" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleNext()}
                    >
                      Siguiente
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>

        </>
      )}
      {showAddFact && (
        <AddFact
          formInicioData={formData}
          cliente={cliente} // paso los datos del cliente
          idtipofact={formData.id_tipofact}
          direccion={direccion}
          setFormData={setFormData}
          setShowForm={setShowForm}
          setShowAdd={setShowAddFact}
          setDomicilios={setDomicilios}
        />
      )}
    </Container>
  );
}

export default FormInicioFact;
