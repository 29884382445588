import React, { useState, useEffect, useMemo } from 'react'
import { UrlApi } from '../../../services/apirest'
import { Grid, InputAdornment, Container, Checkbox, MenuItem, Button } from '@mui/material'
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import Box from "@mui/material/Box";
import MaterialReactTable from "material-react-table";
import { Select, FormControl, InputLabel, Stack, FormControlLabel, TextField } from '@mui/material'
import PanelOrdenCompra from './PanelOrdenCompra'
import FileUploadMultiple from '../../../services/UploadFiles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Notify from '../../Notify'
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import Title from '../../Title';
import AgregarMateriales from '../requerimientos/ver_requerimientos/lista_requerimientos/AgregarMateriales';
import { getData, postData, putData } from '../../../services/library';
import { logEventos } from '../../../services/services';

export default function EditarOrden(props) {
    const [datos, setDatos] = useState(props.datos);
    const [listaMaterial, setlistaMaterial] = useState([]);
    const [proveedores, setProveedores] = useState('');
    const [tipoMoneda, settipoMoneda] = useState(null);
    const [condVenta, setCondVenta] = useState(null);
    const [bonificacionPesos, setBonificacionPesos] = useState(props.datos.bonificacion_pesos);
    const [bonificacionPorcentaje, setBonificacionPorcentaje] = useState(props.datos.bonificacion_porcentaje);
    const [totalFinal, settotalFinal] = useState(props.datos.total);
    const globalTheme = useTheme();
    const [condVentaNew, setCondVentaNew] = useState(null);
    const [openDialog, setopenDialog] = useState(false);
    const [openclose, setOpenClose] = useState(false);
    const [proveedorSelected, setproveedorSelected] = useState("");
    const [datosReq, setDatosReq] = useState();
    const [FechaE, setFechaE] = useState(datos.fecha_entrega);
    const [tabla, settabla] = useState(true);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [sucursales, setSucursales] = useState(null);
    const [vehiculos, setVehiculos] = useState(null);
    const [subtotal, setsubtotal] = useState(0);
    const [empresas, setempresas] = useState(null);
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const obtenerDatosReq = async () => {
        getData('/requerimientos_materiales/ordencompra/' + datos.uuid_ordencompra, setDatosReq)
    };

    const obtenerMovil = () => {
        getData('/vehiculos/sucursal/' + datos.id_sucursal, setVehiculos)
    };

    const obtenerListaMaterial = () => {
        getData('/lista_materiales/ordencompra/' + datos.uuid_ordencompra, setlistaMaterial)
    };

    useEffect(() => {
        getData('/proveedores/activos', setProveedores)
        getData('/desplegables/tipoMoneda', settipoMoneda)
        getData('/condicion_pago', setCondVenta)
        getData('/sucursales', setSucursales)
        getData('/empresas', setempresas)
    }, []);

    useEffect(() => {
        obtenerMovil()
    }, [datos.id_sucursal]);

    useEffect(() => {
        if (proveedores) {           
            const proveedor = proveedores.find((p) => p.id === datos.id_proveedor)
            setproveedorSelected(proveedor)
        }
    }, [datos.id_proveedor,proveedores]);

    useEffect(() => {
        if (condVenta) {
            const condicion = condVenta.filter((condicion) => condicion.id === datos.condicion_venta)
            setCondVentaNew(condicion[0])
        }
    }, [datos.condicion_venta, condVenta]);

    useEffect(() => {
        obtenerListaMaterial()
        props.datos && obtenerDatosReq()
    }, [datos.uuid_ordencompra]);

    // useEffect(() => {
    //     getBonificacionPesos()
    // }, [bonificacionPesos, subtotal]);

    useEffect(() => {
        getBonificacionPorcentaje()
    }, [bonificacionPorcentaje, subtotal]);

    const getTotal = () => {
        let subtotal = 0
        if (listaMaterial) {
            listaMaterial.map((item) => subtotal = subtotal + item.subtotal)
            setsubtotal(subtotal)
        }
    };

    const getBonificacionPorcentaje = () => {
        if (!!subtotal && !!bonificacionPorcentaje) {
            settotalFinal(subtotal - (subtotal * bonificacionPorcentaje / 100))
        } else if (!!subtotal && !!bonificacionPesos) {
            settotalFinal(subtotal - bonificacionPesos)
        }
        else {
            settotalFinal(subtotal)
        }
    };

    const getBonificacionPesos = () => {
        if (!!subtotal && !!bonificacionPesos) {
            settotalFinal(subtotal - bonificacionPesos)
        } else {
            settotalFinal(subtotal)
        }
    };

    useEffect(() => {
        getTotal()
    }, [listaMaterial]);

    const almacenar = (e) => {
        setDatos({ ...datos, [e.target.name]: e.target.value ? e.target.value : "" })
    };

    const getColumns = (datos) => {
        let columns = [
            { header: 'Requerido', accessorKey: 'cantidad', enableEditing: false, size: 70 },
            { header: 'Articulo', accessorFn: (lm) => lm.nombre ?? lm.material, enableEditing: false, },
            { header: 'Unidad', accessorFn: (lm) => lm.descripcion ?? lm.descripcion_material, enableEditing: false, size: 59 },
            { header: '$ Unitario', accessorKey: 'precio_unitario', type: 'numeric', size: 70 },
            { header: 'Bonif %', accessorKey: 'bonificacion_porcentaje', type: 'numeric', required: false, size: 40 },
            { header: 'Bonif $', accessorKey: 'bonificacion_pesos', type: 'numeric', required: false, size: 40 },
            { header: 'SubTotal', accessorKey: 'subtotal', type: 'double', enableEditing: false, size: 50 },
        ];
        if (datos.origen === "requerimiento") {
            // Insertar la nueva columna en la segunda posición
            columns.splice(1, 0, { header: 'A Comprar', accessorKey: 'cantidad_actualizada', type: 'numeric', size: 70 });
        }

        return columns;
    };

    const columns = useMemo(() => getColumns(datos), [datos])

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme]);


    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 15 }
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    const handleClose = () => {
        props.editar(false)
        props.tabla(true)
        props.tabs(true)
    };

    const handleAbrirModal = () => {
        setopenDialog(true)
        settabla(false)
    };

    const guardarTodo = async ({ exitEditingMode, row, values }) => {
        let subtotal;

        if (values.bonificacion_pesos !== null && values.bonificacion_pesos !== undefined && values.bonificacion_pesos !== '') {
            // Calcular el subtotal con bonificación en pesos
            subtotal = (values.cantidad_actualizada * values.precio_unitario) - values.bonificacion_pesos;
            console.log("subtotal1", subtotal);
        } else if (values.bonificacion_porcentaje !== null && values.bonificacion_porcentaje !== undefined && values.bonificacion_porcentaje !== "") {
            // Calcular el subtotal con bonificación en porcentaje
            subtotal = (values.precio_unitario * values.cantidad_actualizada) * (1 - values.bonificacion_porcentaje / 100);
            console.log("subtotal2", subtotal);
        } else {
            // Calcular el subtotal sin bonificación
            subtotal = values.precio_unitario * values.cantidad_actualizada;
            console.log("subtotal3", subtotal);
        }
        await fetch(UrlApi + '/lista_materiales/lista/' + row.original.idlista, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                precio_unitario: values.precio_unitario,
                cantidad_actualizada: values.cantidad_actualizada,
                subtotal: subtotal,
                bonificacion_pesos: values.bonificacion_pesos ? values.bonificacion_pesos : null,
                bonificacion_porcentaje: values.bonificacion_porcentaje ? values.bonificacion_porcentaje : null,
                uuid_ordencompra: datos.uuid_ordencompra,
                usuario: localStorage.getItem('user')
            })
        });
        obtenerListaMaterial();
        exitEditingMode();
        logEventos("Editar lista materiales de orden compra", "Generar ordenes", "Ordenes compra", localStorage.getItem("user"))
    };

    const almacenarFecha = (e) => {
        setFechaE(e.target.value)
    };

    const guardarOrdenCompra = async (e) => {
        putData(e,
            "/ordencompra/" + datos.idordencompra,
            {
                id_proveedor: proveedorSelected.id,
                comentarios_internos: datos.comentarios_internos,
                pago_anticipado: datos.pago_anticipado,
                iva_incluido: datos.iva_incluido,
                condicion_venta: condVentaNew.id,
                tipo_moneda: datos.tipo_moneda,
                usuario: localStorage.getItem('user'),
                fecha_entrega: datos.fecha_entrega,
                id_estado: 89,
                bonificacion_pesos: parseFloat(bonificacionPesos),
                bonificacion_porcentaje: parseFloat(bonificacionPorcentaje),
                id_vehiculo: datos.id_vehiculo,
                id_sucursal: datos.id_sucursal,
                // total: totalFinal ? totalFinal : total.suma
                total: totalFinal,
                facturar_a_empresa: datos.facturar_a_empresa
            },
            setNotificacion,
            logEventos("Editar orden de compra", "Generar ordenes", "Ordenes compra", localStorage.getItem("user"))
        )

    };

    const handleCreateNewRow = (values) => {
        setCreateModalOpen(true);
    };

    // console.log("datos, ", datos.fecha_entrega)

    return (
        <div>
            <Container maxWidth="xl">
                <Title
                    titulo="Editar orden de compra"
                    handleClose={handleClose} />

                <Box backgroundColor="#F4F6F6"
                    mt={1}
                    p={3}
                    mb={2}
                    border={0.5}
                    borderColor={"#ABB2B9"}
                    borderRadius={4}>
                    <Grid container spacing={2} alignItems='center' >
                        <Grid item xs={3} alignItems='flex-start'>
                            <Autocomplete
                                value={proveedorSelected}
                                clearOnEscape
                                defaultValue={proveedorSelected || null}
                                onChange={(event, value) => {
                                    setproveedorSelected(value);
                                }}
                                options={proveedores}
                                isOptionEqualToValue={(option, value) => option.valueOf === value || value === ""}
                                getOptionLabel={(proveedor) => proveedor.nombre_fantasia || proveedor.razon_social}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        focused
                                        style={style}
                                        fullWidth
                                        placeholder="Tipee para buscar proveedores.."
                                        variant="outlined"
                                        size="small"
                                        label="Proveedores"
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <FormControl style={style} size="small" focused color="primary" required >
                                <InputLabel id="lbl_estado">Condicion De Venta</InputLabel>
                                <Select
                                    variant='outlined'
                                    name='condicion_venta'
                                    value={condVentaNew || ""}
                                    onChange={(e) => {
                                        setCondVentaNew(e.target.value)
                                        setDatos({ ...datos, condicion_venta: e.target.value.id })
                                    }}
                                    label="Condición de venta">
                                    {(condVenta ?? []).map(elemento => (
                                        <MenuItem key={elemento.id} value={elemento}>
                                            {elemento.condicion}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <FormControl fullWidth={true} style={style} focused color="primary" required size="small">
                                <InputLabel id="lbl_estado">Moneda</InputLabel>
                                <Select
                                    required
                                    variant='outlined'
                                    name='tipo_moneda'
                                    value={datos.tipo_moneda || ""}
                                    onChange={almacenar}
                                    label="Moneda">
                                    {(tipoMoneda ?? []).map(elemento => (
                                        <MenuItem key={elemento.id} value={elemento.id}>
                                            {elemento.valor}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                                <FormControl fullWidth={true} style={style} focused color="primary" required size="small">
                                    <InputLabel id="lbl_estado">Facturar a </InputLabel>
                                    <Select
                                        required
                                        variant='outlined'
                                        name='facturar_a_empresa'
                                        value={datos.facturar_a_empresa}
                                        onChange={almacenar}
                                        label="Facturar a ">
                                        {(empresas ?? []).map(elemento => (
                                            <MenuItem key={elemento.idempresa} value={elemento.idempresa}>
                                                {elemento.empresa}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                        <Grid item xs={1}>
                            <FormControlLabel
                                checked={datos.iva_incluido === 1}
                                onChange={(e) => setDatos({ ...datos, "iva_incluido": e.target.checked ? 1 : 0 })}
                                control={<Checkbox />}
                                label="Impuestos incluidos"
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                            />
                        </Grid>

                        <Grid item xs={1} ml={3}>
                            <FormControlLabel
                                checked={datos.pago_anticipado === 1}
                                onChange={(e) => setDatos({ ...datos, "pago_anticipado": e.target.checked ? 1 : 0 })}
                                control={<Checkbox />}
                                label="Pago anticipado"
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                            />
                        </Grid>
                    </Grid>

                    {datosReq && datosReq.length > 0 ?
                        <Grid container spacing={2} alignItems='center' mt={0.5} mb={1.5}>

                            <Grid item xs={3}>
                                <TextField
                                    focused
                                    variant='outlined'
                                    fullWidth={true}
                                    color='primary'
                                    style={style}
                                    label='Movil'
                                    size="small"
                                    value={datosReq[0].patente || "No hay registro"}
                                    InputProps={{
                                        readOnly: true
                                    }} />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    focused
                                    variant='outlined'
                                    style={style}
                                    color='primary'
                                    label='Solicitante'
                                    // name='usuario_registra'
                                    size="small"
                                    value={datosReq[0].usuario_registra || ""}
                                    InputProps={{
                                        readOnly: true
                                    }} />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    focused
                                    variant='outlined'
                                    style={style}
                                    label='Autorizante'
                                    // name='usuario_autoriza'
                                    size="small"
                                    value={datosReq[0].usuario_autoriza}
                                    placeholder="No hay registro"
                                    InputProps={{
                                        readOnly: true
                                    }} />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    focused
                                    variant='outlined'
                                    style={style}
                                    label='Sucursal'
                                    // name=''
                                    size="small"
                                    value={datosReq[0].nombre_sucursal}
                                    placeholder="No hay registro"
                                    InputProps={{
                                        readOnly: true
                                    }} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    focused
                                    color='primary'
                                    style={style}
                                    label='Fecha autorizado'
                                    // name='created_at'
                                    variant='outlined'
                                    size="small"
                                    value={moment(datosReq.fecha_autorizado).format('DD/MM/YYYY')}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} >
                                <TextField
                                    focused
                                    variant='outlined'
                                    style={style}
                                    color='primary'
                                    onChange={almacenar}
                                    size="small"
                                    label='Fecha de entrega'
                                    type='date'
                                    name='fecha_entrega'
                                    value={moment(datos.fecha_entrega).format('YYYY-MM-DD')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                position="start"
                                            ></InputAdornment>)
                                    }} />
                            </Grid>

                        </Grid>
                        :
                        <Grid container spacing={2} alignItems='center' mt={0.5} mb={1.5}>
                            <Grid item xs={4}>
                                <TextField
                                    name='id_sucursal'
                                    select
                                    size='small'
                                    style={style}
                                    required
                                    focused
                                    color="primary"
                                    placeholder='Elija una sucursal'
                                    value={datos.id_sucursal || ""}
                                    onChange={almacenar}
                                    label="Sucursal"
                                >
                                    {(sucursales ?? []).map(elemento => (
                                        <MenuItem key={elemento.id} value={elemento.id}>
                                            {elemento.nombre}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl style={style} focused color="primary" size='small'>
                                    <InputLabel id="lbl_movil">Movil</InputLabel>
                                    <Select
                                        name='id_vehiculo'
                                        value={datos.id_vehiculo || ""}
                                        onChange={almacenar}
                                        label="Movil">
                                        {(vehiculos ?? []).map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.modelo}  -  {elemento.patente}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4} >
                                <TextField
                                    focused
                                    variant='outlined'
                                    style={style}
                                    color='primary'
                                    onChange={almacenar}
                                    size="small"
                                    label='Fecha de entrega'
                                    type='date'
                                    name='fecha_entrega'
                                    value={moment(datos.fecha_entrega).format('YYYY-MM-DD')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                position="start"
                                            ></InputAdornment>)
                                    }} />
                            </Grid>
                        </Grid>
                    }
                    {/* <Grid container spacing={2} alignItems='center' mt={0.5} mb={1.5} ml={1}>
                          
                    </Grid> */}

                    {tabla && listaMaterial ? (
                        <Box mt={2} boxShadow={3}>
                            <ThemeProvider theme={tableTheme}>
                                <MaterialReactTable
                                    enableColumnResizing
                                    columns={columns}
                                    data={listaMaterial}
                                    initialState={initialState}
                                    positionActionsColumn='last'
                                    enableExpandAll={false}
                                    enableToolbarInternalActions={true}
                                    muiTableHeadCellProps={tableHeadCellProps}
                                    muiTableBodyCellProps={{
                                        align: "left",
                                        sx: {
                                            backgroundColor: "#fff",
                                            color: "black",
                                            borderColor: "black"
                                        }
                                    }}
                                    enableColumnActions={false}
                                    enableDensityToggle={false}
                                    // renderTopToolbarCustomActions={() => (
                                    //   <Typography variant="poster" fontSize={20}>
                                    //     LISTADO DE MATERIALES
                                    //   </Typography>
                                    // )}
                                    layoutMode="grid"
                                    localization={MRT_Localization_ES}
                                    enableColumnDragging={false}
                                    enableColumnFilters={false}
                                    enableHiding={false}
                                    editingMode="row"
                                    enableFullScreenToggle={false}
                                    enableBottomToolbar={false}
                                    enableEditing
                                    enableTopToolbar={false}
                                    positionExpandColumn='first'
                                    onEditingRowSave={guardarTodo}
                                />
                            </ThemeProvider>
                        </Box>
                    ) : null}

                    <Grid container justifyContent='space-between' alignItems='center' m={1}>
                        <Stack spacing={2} direction="row" padding={1}>
                            <Button
                                onClick={handleAbrirModal}
                                style={{ backgroundColor: '#363979', color: 'white' }}
                                variant="contained"
                                mr={5}>
                                Agregar Pedidos
                            </Button>
                            <Button
                                onClick={() => setOpenClose(!openclose)}
                                variant="outlined"
                                color="primary"
                                size="small"
                                startIcon={<AttachFileIcon />}>
                                Ver presupuestos
                            </Button>
                            <Button size="small" onClick={handleCreateNewRow} color="primary" variant="contained" > Agregar articulos</Button>
                        </Stack>

                        <Grid item xs={1} mt={1}>
                            <TextField
                                disabled={!!bonificacionPorcentaje}
                                focused
                                variant='outlined'
                                color='primary'
                                label='Bonificación $'
                                size="small"
                                name='bonificacion'
                                style={{
                                    marginLeft: 250, marginRight: 0, backgroundColor: "#ffffff",
                                    width: "100%"
                                }}  // Ajustar el estilo para alinear a la derecha
                                value={bonificacionPesos}
                                onChange={(e) => setBonificacionPesos(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={1} mt={1}>
                            <TextField
                                disabled={!!bonificacionPesos}
                                focused
                                variant='outlined'
                                color='primary'
                                label='Bonificación %'
                                size="small"
                                name='bonificacion'
                                style={{
                                    marginLeft: 125, marginRight: 0, backgroundColor: "#ffffff",
                                    width: "100%"
                                }}  // Ajustar el estilo para alinear a la derecha
                                value={bonificacionPorcentaje}
                                onChange={(e) => setBonificacionPorcentaje(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={1} mt={1} mr={1}>
                            <TextField
                                focused
                                style={{
                                    marginLeft: 'auto', marginRight: 0, backgroundColor: "#ffffff",
                                    width: "100%"
                                }}  // Ajustar el estilo para alinear a la derecha
                                variant='outlined'
                                color='primary'
                                label='Total'
                                name='total'
                                size="small"
                                value={totalFinal && parseFloat(totalFinal ? totalFinal.toFixed(2) : 0)}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>
                    </Grid>


                    <Box sx={{ '& > :not(style)': { m: 1, mt: 1 }, }} autoComplete="off" >
                        <TextField
                            focused variant='outlined' style={style} color='primary' size="small" label='Observaciones' name='observaciones'
                            value={datosReq && datosReq.observaciones}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                    ></InputAdornment>),
                                readOnly: true
                            }} />
                    </Box>

                    <form onSubmit={guardarOrdenCompra}>
                        <Box sx={{ '& > :not(style)': { m: 1, mt: 1 }, }} autoComplete="off" >
                            <TextField
                                placeholder="Comentarios internos"
                                variant='outlined'
                                focused
                                style={style}
                                size="small"
                                name="comentarios_internos"
                                id="id"
                                multiline
                                label="Comentarios internos"
                                color="primary"
                                onChange={almacenar}
                                value={datos.comentarios_internos}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <Grid mt={1} container direction="row" justifyContent="space-between" alignItemns="center">
                            <Button variant="contained" style={{ backgroundColor: "#ff3333", color: "#ffffff", marginRight: "5%" }} onClick={handleClose} >Volver</Button>
                            <Button variant="contained" type="submit" style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                                onSubmit={guardarOrdenCompra}
                            >Guardar Orden</Button>
                        </Grid>
                    </form>
                </Box>
            </Container>
            {openDialog ? <PanelOrdenCompra open={setopenDialog} reqmaterial={datosReq} uuid={datos.uuid_ordencompra} actualizar={obtenerListaMaterial} editar={1} /> : null}
            {openclose ? <FileUploadMultiple fileId={datos.uuid_ordencompra} openclose={true} /> : null}
            {createModalOpen ? <AgregarMateriales position={1} open={setCreateModalOpen} actualizar={obtenerListaMaterial} uuidordencompra={datos.uuid_ordencompra}
            /> : null}
            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            ) : null}
            <br />
        </div >
    )
}

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Candela Grosso   
// Creation Date : 30/11/2023
// Version       : 1.0  
// Description   :  Con este componente el usuario es capaz de :
    - EDITAR UNA ORDEN DE COMPRA
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description: 
//========================================================================*/