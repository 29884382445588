import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Navbar from "../../Navbar";
import TablaPedidos from "./TablaPedidos";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function PedidosTabs(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [showTabs, setShowTabs] = useState(true);

  return (
    <div>
      <Navbar />
      {/* <Box sx={{ width: "100%", mt: 1 }}> */}
      {showTabs ? (
        <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 1 }}>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            aria-label="basic tabs example"
            centered
            variant="fullWidth"
          >
            <Tab label="Todos" {...a11yProps(0)} />
            <Tab label="Por Aprobar" {...a11yProps(1)} />
            <Tab label="Por Asignar" {...a11yProps(2)} />
            <Tab label="Asignados" {...a11yProps(3)} />
            <Tab label="A Facturar" {...a11yProps(4)} />
            <Tab label="No Entregados" {...a11yProps(5)} />
            <Tab label="Facturados" {...a11yProps(6)} />
          </Tabs>
        </Box>
      ) : null}
      <TablaPedidos activeTab={activeTab} setShowTabs={setShowTabs} />
    </div>
  );
}

export default PedidosTabs;
