import React, { useState, useEffect, useMemo } from "react";
import { getData } from "../../../../../services/library";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { Box, Button, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { UrlApi } from "../../../../../services/apirest";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import Fab from "@mui/material/Fab";

import DialogIIBB from "./DialogIIBB";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";

import DialogIIBB_Formulario from "./DialogIIBB_Formulario";

function ImpuestosCliente(props) {
  const [readOnly, setReadOnly] = useState(true);
  const [impuestosCli, setImpuestosCli] = useState(null);
  const [porcentajesPercepcion, setPorcentajesPercepcion] = useState([{}]); //porcentajes de percepcion disponibles en caso de que el cliente sea responsable inscripto.
  const [dialogIIBB, setDialogIIBB] = useState(false);
  const [percepcionIva, setPercepcionIva] = useState();
  const [showFormIIBB, setShowFormIIBB] = useState(false);
  const [editarPercIva, setEditarPercIva] = useState(false);
  const [iibb, setiibb] = useState([
    { provincia: "Cargando...", percepcion: "Cargando..." },
  ]);

  const getPercepcionIva = () => {
    fetch(UrlApi + "/clientes/percepcion_iva/" + props.cliente.id)
      .then((data) => data.json())
      .then((data) => setPercepcionIva(data[0]));
  };

  const almacenarPercIva = (e) => {
    setPercepcionIva({ ...percepcionIva, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getPercepcionIva();
    getData("/desplegables/porcentajesPercepcion", setPorcentajesPercepcion);
    getData("/iibb_clientes/alliibb/" + props.cliente.cuit, setiibb);
  }, []);

  useEffect(()=>{
    getData("/iibb_clientes/alliibb/" + props.cliente.cuit, setiibb);
  },[showFormIIBB])

  async function guardar(e) {
    e.preventDefault();
    setEditarPercIva(false);
    const response = await fetch(UrlApi + "/clientes/" + props.cliente.id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(percepcionIva),
    });
    getPercepcionIva();
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "provincia",
        header: "Provincia",
      },
      {
        accessorKey: "percepcion",
        header: "Percepción",
      },
      {
        accessorKey: "porcentaje_excencion",
        header: "Excencion",
      },
      {
        accessorKey: "vto_excencion",
        header: "Vencimiento Excencion",
      },
    ],
    []
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        sx: {
          fontSize: 14,
          color: "black",
          fontWeight: "normal",
          paddingTop: 1,
          paddingBottom: 1,
          backgroundColor: "#D4E6F1",
          borderColor: "#FDFEFE",
        },
      },
      size: 60,
    },
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: 5,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 14,
      color: "black",
      fontWeight: "normal",
      paddingTop: 1,
      paddingBottom: 1,
      backgroundColor: "#D4E6F1",
      borderColor: "#FDFEFE",
    },
  };

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  return (
    <div>
      <Box boxShadow={3} px={2} py={1} backgroundColor="#ebebeb">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" sx={{ fontSize: 16 }} color="primary">
            Impuestos Nacionales (Percepción de Iva)
          </Typography>
          {editarPercIva ? (
            <Button variant="text" onClick={(e) => guardar(e)}>
              Guardar
            </Button>
          ) : (
            <Button variant="text" onClick={() => setEditarPercIva(true)}>
              Editar
            </Button>
          )}
        </Grid>
        {percepcionIva && (
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={3}>
              <Typography variant="subtitle2" color="initial">
                Percepción Iva
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {porcentajesPercepcion && (
                <FormControl
                  disabled={!editarPercIva}
                  focused
                  color="primary"
                  required
                  size="small"
                  fullWidth
                >
                  <InputLabel>% Percepción</InputLabel>
                  <Select
                    style={{ backgroundColor: "#fff" }}
                    name="porc_perc_iva"
                    label=">% Percepción"
                    value={percepcionIva.porc_perc_iva}
                    onChange={almacenarPercIva}
                  >
                    {porcentajesPercepcion
                      ? porcentajesPercepcion.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.valor}>
                            {elemento.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled={!editarPercIva}
                style={{ backgroundColor: "#fff" }}
                size="small"
                fullWidth
                focused
                label="% Excención"
                value={percepcionIva.porc_exc_perc_iva}
                name="porc_exc_perc_iva"
                onChange={almacenarPercIva}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled={!editarPercIva}
                style={{ backgroundColor: "#fff" }}
                type="date"
                size="small"
                fullWidth
                focused
                label="Vto Excención"
                value={percepcionIva.vto_exc_perc_iva}
                name="vto_exc_perc_iva"
                onChange={almacenarPercIva}
              />
            </Grid>
          </Grid>
        )}
      </Box>
      <br />
      <Box boxShadow={3} pt={1} backgroundColor="#ebebeb">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
        >
          <Typography variant="h6" sx={{ fontSize: 16 }} color="primary">
            Impuestos Provinciales (Ingresos Brutos)
          </Typography>
          <Button variant="text" onClick={() => setShowFormIIBB(true)}>
            Editar
          </Button>
        </Grid>

        <MaterialReactTable
          enableTopToolbar={false}
          muiSearchTextFieldProps={muiSearchTextFieldProps}
          localization={MRT_Localization_ES}
          columns={columns}
          data={iibb}
          enableColumnFilterModes={false}
          enableColumnOrdering={false}
          enableGrouping={false}
          enableEditing={false}
          enableColumnFilterModes={false}
          enableColumnOrdering={false}
          enableGrouping={false}
          initialState={initialState}
          positionActionsColumn="last"
          enableHiding={false}
          enableExpandAll={false}
          enableColumnActions={false}
          enableColumnFilters={false} //Quita filtros por columna
          enableDensityToggle={false}
        />
      </Box>
      <br />
      <br />
      {showFormIIBB && (
        <DialogIIBB_Formulario
          open={showFormIIBB}
          setOpen={setShowFormIIBB}
          cliente={props.cliente}
        />
      )}
    </div>
  );
}

export default ImpuestosCliente;
