import { UrlApi, MailApi, SmsApi } from '../services/apirest'


export function isAuthenticated() {
    return localStorage.getItem('token') && localStorage.getItem('token-expiration') > Date.now()
}


export function getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
            return i;
        }
    }
    return -1; //to handle the case where the value doesn't exist
}



export const checkExisteRecuentoFisico = async (w_url, suc, segmento, fecha) => {
    console.log(w_url, suc, fecha)
    const data = await fetch(w_url + "/stkfisicototal/" + suc + "/" + segmento + "/" + fecha)
    const dataresult = await data.json()
    console.log(dataresult)
    return dataresult;
}

export const cuentamensajes = async () => {
    const data = await fetch(
        UrlApi + "/mensajes/" + localStorage.getItem("user") + "/count"
    );
    const mensajesdata = await data.json();
    console.log(mensajesdata)
    return mensajesdata;
};

export const enviarmails = async (receptor, asunto, texto) => {
    console.log("entro en funcion para enviar mails.")
    await fetch(MailApi + "/xemail", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            receptor: receptor,
            asunto: asunto,
            texto: texto
        })
    })
        .then(res => {
            if (!res.ok) { console.log(res) };
        }
        )
        .catch(err => {
            console.log(err.message)
        })
}

// enviar sms por el smsgateway de sms
export const enviarsms = async (destino, texto) => {
    await fetch(SmsApi + "/sensores", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            destinationnumber: destino,
            textdecoded: texto,
            creatorid: "Netizar - " + process.env.NODE_ENV
        })
    })
        .then((response) => response.json())
        .then((responseData) => console.log(responseData))
        .catch(err => {
            console.log(err.message)
        })
}

///log de eventos
export const logEventos = async (evento, menu, accion, usuario) => {
    await fetch(UrlApi + "/userauths", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            evento: evento,
            menu: menu,
            accion: accion,
            user: usuario
        })
    })
        .then(res => {
            if (!res.ok) { console.log(res) };
        }
        )
        .catch(err => {
            console.log(err.message)
        })
}