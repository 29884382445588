import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, Button, Typography, DialogContent } from '@mui/material';
import moment from 'moment';
import FileUploadMultiple from '../../../services/UploadFiles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { getData } from '../../../services/library';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import Container from "@mui/material/Container";

function DatosOrden(props) {
  const [listaMateriales, setlistaMateriales] = useState(null);
  const [datosReq, setDatosReq] = useState(null)
  const [datos, setDatos] = useState(props.datos);
  const [openclose, setOpenClose] = useState(false);
  const [circular, setCircular] = useState(false);
  const tipoMonedaMapping = {
    82: "u$s",
    83: "$",
    84: "€",
  };

  const tipoMoneda = datos.tipo_moneda;
  const iconoMoneda = tipoMonedaMapping[tipoMoneda] || "Error";

  const obtenerListaMateriales = async () => {
    getData('/lista_materiales/ordencompra/' + datos.uuid_ordencompra, setlistaMateriales)
    setCircular(true);
    setTimeout(() => {
      setCircular(false);
    }, 500);
  };

  useEffect(() => {
    obtenerListaMateriales();
  }, []);

  useEffect(() => {
    getData('/requerimientos_materiales/ordencompra/' + datos.uuid_ordencompra, setDatosReq)
  }, [datos.uuid_ordencompra]);

  const style = {
    backgroundColor: "#ffffff",
    width: "100%"
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#92a898",
      color: theme.palette.common.white,
      fontSize: 14,
      padding: 5
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 5
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  console.log("datos", datos.uuid_ordencompra)
  // datosReq &&console.log("datos",datos)

  return (
    <>
      <Drawer open={props.anchor} onClose={() => props.anchor(false)} anchor="right">
        <Container maxWidth="xl">
          {/* {circular ? <CircularProgress width={"100%"} height={118} /> : null} */}
          <Box
            key={datos.idordencompra}
            width="100%"
            role="presentation" onClick={() => props.anchor(false)}
            backgroundColor="#CCD1D1"
            alignItems="center"
            justifyContent="center"
            mt={7}>
            <Typography
              variant="overline"
              display="block"
              gutterBottom
              textAlign="center"
            >
              Visualización de orden de compra N° {datos.idordencompra}
            </Typography>
          </Box>

          <Box
            backgroundColor="#F4F6F6"
            mt={1}
            p={2}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}>

            {!circular && datos.origen === "requerimiento" ? (
              <>
                {openclose &&
                  <FileUploadMultiple
                    // fileId={datosReq && datosReq[0].uuid_requerimiento}
                    openclose={true}
                    fileIdcoso={datos.uuid_ordencompra}
                  />}

                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                  {datosReq &&
                    <>
                      <Grid item xs={3}>
                        <TextField
                          focused
                          color="primary"
                          size="small"
                          value={datosReq[0].nombre_sucursal ? datosReq[0].nombre_sucursal : "No hay registro"}
                          style={style}
                          label="Sucursal"
                          InputProps={{
                            readOnly: true,
                          }} />
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          focused
                          color="primary"
                          size="small"
                          value={datosReq[0].patente !== null ? datosReq[0].patente : "No hay registro"}
                          style={style}
                          label="Patente"
                          InputProps={{
                            readOnly: true,
                          }} />
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          focused
                          color="primary"
                          size="small"
                          value={moment(datos.fecha_entrega).format("DD-MM-YYYY")}
                          style={style}
                          label="Fecha de entrega"
                          InputProps={{
                            readOnly: true,
                          }} />
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          focused
                          color="primary"
                          size="small"
                          value={datosReq[0].usuario_registra ? `${datosReq[0].nombrepila + "" + datosReq[0].apellido}` : "No hay registro"}
                          style={style}
                          label="Usuario"
                          InputProps={{
                            readOnly: true,
                          }} />
                      </Grid>
                    </>
                  }
                </Grid>
              </>
            ) : null}

            {!circular && datos.origen === "manual" ? (
              <>
                <Grid container spacing={0.5} mt={-1} alignItems="center" justifyContent="space-between">
                  <Grid item xs={3}>
                    <TextField
                      focused
                      color="primary"
                      size="small"
                      value={datos.nombre_sucursal}
                      style={style}
                      label="Sucursal"
                      InputProps={{
                        readOnly: true,
                      }} />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      focused
                      color="primary"
                      size="small"
                      value={datos.patente !== null ? datos.patente : "No hay registro"}
                      style={style}
                      label="Patente"
                      InputProps={{
                        readOnly: true,
                      }} />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      focused
                      color="primary"
                      size="small"
                      value={moment(datos.fecha_entrega).format("DD-MM-YYYY")}
                      style={style}
                      label="Fecha de entrega"
                      InputProps={{
                        readOnly: true,
                      }} />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      focused
                      color="primary"
                      size="small"
                      value={datos.usuario_crea ? `${datos.nombrepila + "" + datos.apellido}` : "No hay registro"}
                      style={style}
                      label="Usuario"
                      InputProps={{
                        readOnly: true,
                      }} />
                  </Grid>
                </Grid>

                {openclose &&
                  <FileUploadMultiple
                    // fileId={datosReq.uuid_requerimiento}
                    openclose={true}
                    fileId={datos.uuid_ordencompra}
                  />}
              </>
            ) : null}

            <Grid container spacing={1} mt={0.5} alignItems="center" justifyContent="space-between">
              <Grid item xs={3}>
                <TextField
                  focused
                  color="primary"
                  size="small"
                  value={datos.nombre_fantasia ?? datos.razon_social}
                  style={style}
                  label="Proveedor"
                  InputProps={{
                    readOnly: true,
                  }} />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  focused
                  color="primary"
                  size="small"
                  value={datos.condicion}
                  style={style}
                  label="Condicion de pago"
                  InputProps={{
                    readOnly: true,
                  }} />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  focused
                  color="primary"
                  size="small"
                  value={datos.iva_incluido === 1 ? "Incluido" : "No incluido"}
                  style={style}
                  label="Iva"
                  InputProps={{
                    readOnly: true,
                  }} />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  focused
                  color="primary"
                  size="small"
                  value={datos.pago_anticipado === 1 ? "Anticipado" : "No anticipado"}
                  style={style}
                  label="Pago"
                  InputProps={{
                    readOnly: true,
                  }} />
              </Grid>
            </Grid>
          </Box>

          <Box style={{ width: "100%" }} >
            <TableContainer component={Paper}>
              <Table sx={{ mt: 1 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Cantidad</StyledTableCell>
                    <StyledTableCell align="left">Unidad</StyledTableCell>
                    <StyledTableCell align="left">Articulo/Servicio</StyledTableCell>
                    <StyledTableCell align="left">Precio unitario </StyledTableCell>
                    <StyledTableCell align="left">Bonificación</StyledTableCell>
                    <StyledTableCell align="left">Subtotal</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listaMateriales && listaMateriales.map((lista) => (
                    <StyledTableRow key={lista.idlista}>
                      <StyledTableCell align="left">{lista.cantidad_actualizada ?? lista.cantidad}</StyledTableCell>
                      <StyledTableCell align="left">{lista.descripcion ?? lista.descripcion_material}</StyledTableCell>
                      <StyledTableCell align="left">{lista.nombre ?? lista.material}</StyledTableCell>
                      <StyledTableCell align="center">{iconoMoneda}{lista.precio_unitario}</StyledTableCell>
                      <StyledTableCell align="center">{(lista.bonificacion_pesos !== null ? "$" : (lista.bonificacion_porcentaje !== null ? "%" : ""))
                      }{lista.bonificacion_pesos !== null ? lista.bonificacion_pesos : (lista.bonificacion_porcentaje !== null ? lista.bonificacion_porcentaje : "-")}</StyledTableCell>
                      <StyledTableCell align="right">{iconoMoneda}{lista.subtotal}</StyledTableCell>
                    </StyledTableRow>

                  ))}
                  <TableRow>
                    <TableCell rowSpan={6} />
                    <TableCell colSpan={3} >Bonificacion al precio total</TableCell>
                    <TableCell align="right">{datos.bonificacion_pesos !== null ? "$" : (datos.bonificacion_porcentaje !== null ? "%" : "")}{datos.bonificacion_pesos ?? datos.bonificacion_porcentaje}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>Total</TableCell>
                    <TableCell align="right"> {iconoMoneda}{datos.total}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

          </Box>

          {datos.comentarios_internos ? (
            <Box>
              <TextField
                focused
                margin="dense"
                variant="filled"
                size="small"
                value={datos.comentarios_internos ? datos.comentarios_internos : "No hay comentarios cargados."}
                style={style}
                multiline

                label="Comentarios internos"
                InputProps={{
                  readOnly: true,
                }} />
            </Box>
          ) : null}

          {datosReq && datosReq.observaciones ? (
            <Box>
              <TextField
                focused
                margin="dense"
                variant="filled"
                size="small"
                value={datosReq.observaciones ? datosReq.observacioness : "No hay observaciones cargadas."}
                style={style}
                multiline
                label="Observaciones"
                InputProps={{
                  readOnly: true,
                }} />
            </Box>
          ) : null}

          {props.datos && datos.observacion_baja ? (
            <Box>
              <TextField
                focused
                margin="dense"
                variant="filled"
                size="small"
                color="error"
                value={datos.observacion_baja}
                style={style}
                multiline
                label="Observaciones"
                InputProps={{
                  readOnly: true,
                }} />
            </Box>
          ) : null}

          <Grid item xs={8}>
            <Button
              variant="outlined"
              ml={5}
              mb={1}
              color="warning"
              size="small"
              onClick={() => setOpenClose(!openclose)}
            > Visualizar presupuestos <AttachFileIcon /></Button>
          </Grid>

        </Container>
      </Drawer>

      <br />
      <br />
    </>
  )

}

export default DatosOrden
