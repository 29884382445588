import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import { logEventos } from "../../services/services";

function Dialog_cancelar_envio(props) {
  const [abrirDialogTrEnvi, setAbrirDialogTrEnvi] = useState(props.abrirDialogTrEnvi);
  const [transferRow, setTransferRow] = useState(props.transferRow);
  const [inventarioTrSumaCan, setInventarioTrSumaCan] = useState([]);

  //funcion para obtener sucursales
  const obtenerInventarioTrSumaCan = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/xalmart/" +
        transferRow.almacen_origen +
        "/" +
        transferRow.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setInventarioTrSumaCan(data));
  };

  useEffect(() => {
    obtenerInventarioTrSumaCan();
  }, [transferRow.almacen_origen, transferRow.cantidad_Enviada]);

  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  const [cantidadInventarioTrSumaCan, setCantidadInventarioTrSumaCan] = useState(0);

// CALCULO DEL TOTAL FINAL
useEffect(() => {
  setCantidadInventarioTrSumaCan(
    (inventarioTrSumaCan[0]
      ? inventarioTrSumaCan[0].cantidad
      : "no disponible") + transferRow.cantidad_enviada
  );
}, [inventarioTrSumaCan]);

  const guardarConfirmacionCancelacion = async (e) => {
    logEventos("Guardar confirmación de cancelación", "Stock Sucursales", "Se guardo cancelación", localStorage.getItem("user"))
    e.preventDefault();
    await fetch(UrlApi + "/stock_transacciones/" + transferRow.id_transaccion, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        en_transito: 0,
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La cancelación se guardó exitosamente !");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la cancelación");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

    // sumar cantidad
    await fetch(
      UrlApi +
        "/stock_inventarios/" +
        transferRow.almacen_origen +
        "/" +
        transferRow.id_articulo,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cantidad: cantidadInventarioTrSumaCan,
          usuario: localStorage.getItem("user"),
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El artículo se guardó exitosamente !");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el artículo");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    handleClose();
  };

  const handleClose = () => {
    setAbrirDialogTrEnvi(!abrirDialogTrEnvi)
      if (props.onClose) {
          props.onClose();
        }
    };  

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <>
    <Dialog
    open={abrirDialogTrEnvi}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title" alignSelf="center">
      {"Confirmar Cancelación"}
    </DialogTitle>
    <DialogContent>
      <Container maxWidth="x1">
        <form onSubmit={guardarConfirmacionCancelacion}>
          <dl style={{ fontSize: "20px", alignItems: "initial" }}>
            <dt>
              <b>N° Transferencia: </b>
              {transferRow.id_transaccion}
            </dt>
            <dt>
              <b>Almacen de Origen: </b>
              {transferRow.nombre_origen}
            </dt>
            <dt>
              <b>Sucursal Destino: </b>
              {transferRow.nombre}
            </dt>
            <dt>
              <b>Articulo: </b>
              {transferRow.nombre_articulo}
            </dt>
            <dt>
              <b>Cantidad: </b>
              {transferRow.cantidad_enviada}
            </dt>
          </dl>
          <p style={{ fontSize: "20px" }}>
            ¿Desea cancelar la transferencia?
          </p>

          <Stack direction="row">
            <Grid container direction={{ xs: "column", sm: "row" }}>
              <Grid item xs={9}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClose}
                >
                  No
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  type="submit"
                >
                  Si
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </Container>
    </DialogContent>
  </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={openSnack}
        autoHideDuration={1500}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="success"
          sx={{ width: "100%" }}
          style={{ backgroundColor: colormensaje, color: "black" }}
        >
          {mensaje}
        </Alert>
      </Snackbar>
    </Stack>
    </>
  )
}

export default Dialog_cancelar_envio