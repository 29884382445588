import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Stack,
  Snackbar,
  TextField,
  Alert,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment"; // PARA LA FECHA
//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS
import FileUploadStock from "./FileUploadStock";
//enviar mails
import { enviarmails } from "../../services/services";
import { grey } from "@mui/material/colors";
import Title from "../Title";
import { postData } from "../../services/library";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
import { logEventos } from "../../services/services";

function Dialog_salida(props) {
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [disabledGuardar, setDisabledGuardar] = useState(false);
  const [colorTextField, setcolorTextField] = useState();
  const [datosArt, setDatosArt] = useState(props.datosArt);
  const [dialogEliminarArticulo, setDialogEliminarArticulo] = useState(
    props.dialogEliminarArticulo
  );
  const [motivos, setMotivos] = useState(props.motivos);
  const [openCloseAdjDoc, setOpenCloseAdjDoc] = useState(false); //ADJUNTAR IMAGEN
  //PARA SACAR LA CANTIDAD DE SALIDA DEL INVENTARIO
  const [cantidadSalida, setCantidadSalida] = useState(0);
  // console.log("/////", usuarioCompras, datosArt.estado_stock);
  const handleCantidadSalida = (e) => {
    setCantidadSalida(e.target.value);
  };

  const [usuarioCompras, setUsuarioCompras] = useState([]);

  const obtenerUsuarioCompras = () => {
    fetch(UrlApi + "/userdata/sector/4/")
      .then((data) => data.json())
      .then((data) => setUsuarioCompras(data));
  };

  useEffect(() => {
    obtenerUsuarioCompras();
  }, []);

 // console.log("USUARIO COMPRAS", usuarioCompras);

  const [reqExiste, setReqExiste] = useState([]);

  const obtenerRequerimientoExistente = () => {
    fetch(UrlApi + "/requerimientos_materiales/reqexiste/" + props.sucursal + "/" + datosArt.id_articulo)
      .then((data) => data.json())
      .then((data) => setReqExiste(data));
  };
  
  useEffect(() => {
    obtenerRequerimientoExistente();
  }, [datosArt]);
  
 // console.log("REQUERIMIENTO", reqExiste, reqExiste.length);

  // CALCULO DEL TOTAL FINAL
  useEffect(() => {
    setCantidadSalida(datosArt.cantidad - datosArt.cantidad_enviada);
  }, [datosArt.cantidad, datosArt.cantidad_enviada]);

  const [cantEnviada, setCantEnviada] = useState(null);
  useEffect(() => {
    cantEnviada === null
      ? setCantidadSalida(datosArt.cantidad)
      : setCantidadSalida(datosArt.cantidad - datosArt.cantidad_enviada);
  }, [dialogEliminarArticulo]);

  const envioMail = async (e) => {
    usuarioCompras &&
      usuarioCompras.length > 0 &&
      usuarioCompras.map((usuario) => {
        if (usuario.email) {
          if (
            cantidadSalida < datosArt.alerta_minima &&
            cantidadSalida > datosArt.valor_minimo
          ) {
          //  console.log("EMAIL de CONSUMO", usuario.email);
            enviarmails(
              usuario.email,
              "Advertencia de Nivel de Stock ",
              `<p>El artículo <b>${datosArt.articulo}</b> del almacén <b>${datosArt.almacen}</b> esta por debajo del punto de pedido.<br/></p>`
            );
          } else if (cantidadSalida < datosArt.valor_minimo) {
            enviarmails(
              usuario.email,
              "Alerta de Stock por Debajo del Nivel Mínimo",
              `<p>El artículo <b>${datosArt.articulo}</b> del almacén <b>${datosArt.almacen}</b> esta por debajo del stock mínimo.<br/></p>`
            );
          }
        }
      });
  };
// *********************************REQUERIMIENTO***************************************** //
const [notificacion, setNotificacion] = useState({
  open: false,
  mensaje: "",
  severity: "",
  color: "",
});
const [uuid, setUuid] = useState(uuidv4());

const [envioRequerimiento, setEnvioRequerimiento] = useState({
  id_movil: null,
  id_sucursal: props.sucursal,
  fecha_entrega: moment(datosArt.fecha_transaccion).format(
    "YYYY-MM-DD HH:mm:ss"
  ),
  usuario_registra: localStorage.getItem('user'),
  usuario_autoriza: null,
  estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
  para_comprar: 1,
  observaciones: "",
  uuid_requerimiento: uuid,
  comentarios_internos: "Pedido Automático - Definir Cantidad",
  para_compras: 0,
  para_rrhh: 0,
  autorizado_jefe_compras: 0,
})

const [datosLista, setDatosLista] = useState([{
  material: null,
  usuario: localStorage.getItem('user'),
  cantidad: 1,
  // id_unidad_medida: datosArt.id_unidad_medida,
  uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
  id_articulo: datosArt.id_articulo
}]);


useEffect(() => {
setDatosLista([{
  material: null,
  usuario: localStorage.getItem('user'),
  cantidad: 1,
  // id_unidad_medida: datosArt.id_unidad_medida,
  uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
  id_articulo: datosArt.id_articulo
}])
setEnvioRequerimiento({
id_movil: null,
id_sucursal: props.sucursal,
fecha_entrega: moment(datosArt.fecha_transaccion).format(
  "YYYY-MM-DD HH:mm:ss"
),
usuario_registra: localStorage.getItem('user'),
usuario_autoriza: null,
estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
para_comprar: 1,
observaciones: "",
uuid_requerimiento: uuid,
comentarios_internos: "Pedido Automático - Definir Cantidad",
para_compras: 0,
para_rrhh: 0,
autorizado_jefe_compras: 0,
})
}, [datosArt])

const guardarListaMateriales = (e) => {
postData(e,
    "/lista_materiales",
    {
        datosLista
    },
    setNotificacion
);
};
// ************************************************************************** //
  const guardarSalida = async (e) => {
    logEventos("Guardar salida de artículo", "Stock Sucursales", "Se guardo la salida", localStorage.getItem("user"))
    e.preventDefault();
    // setDisabledGuardar(true);
    await fetch(UrlApi + "/stock_transacciones", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_accion: 159,
        id_articulo: datosArt.id_articulo,
        almacen_origen: datosArt.id_almacen,
        cantidad_enviada: datosArt.cantidad_enviada,
        detalle_transaccion: datosArt.detalle_transaccion,
        nro_remito_puntodeventa: datosArt.nro_remito_puntodeventa,
        nro_remito_numero: datosArt.nro_remito_numero,
        fecha_transaccion: moment(datosArt.fecha_transaccion).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        id_motivo: datosArt.id_motivo,
        uuid_documentacion: props.newUuidDoc, //ADJUNTAR IMAGEN
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La salida se guardó exitosamente !");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la salida");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    setDisabledGuardar(true);
    await fetch(UrlApi + "/stock_inventarios/" + datosArt.id_inventario, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        cantidad: cantidadSalida,
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La salida se guardó exitosamente !");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la salida");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
      if (cantidadSalida<datosArt.alerta_minima && cantidadSalida>=datosArt.valor_minimo ) {
      //  console.log("ADVERTENCIA AMARILLA");
        if (datosArt.pedido_automatico === 1 && reqExiste.length === 0) {
      //    console.log("ES AUTOMATICO AMARILLO");
          postData(e,
            '/requerimientos_materiales',
            {
                id_sucursal: envioRequerimiento.id_sucursal,
                id_movil: envioRequerimiento.id_movil,
                estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
                para_comprar: envioRequerimiento.para_comprar,
                para_compras: envioRequerimiento.para_compras,
                para_rrhh: envioRequerimiento.para_rrhh,
                fecha_entrega: envioRequerimiento.fecha_entrega,
                usuario_registra: envioRequerimiento.usuario_registra,
                usuario_autoriza: envioRequerimiento.username,
                observaciones: envioRequerimiento.observaciones,
                uuid_requerimiento: uuid,
                comentarios_internos: envioRequerimiento.comentarios_internos,
                autorizado_jefe_compras: 0,
            },
            setNotificacion,
            guardarListaMateriales(e),
            )
            .then((response) => {
              console.log("Respuesta de postData en guardarCabecera:", response);
            })
            .catch((error) => {
              console.error("Error en postData en guardarCabecera:", error);
            });
        }
      } else if (cantidadSalida<datosArt.valor_minimo) {
     //   console.log("ADVERTENCIA ROJA");
        if (datosArt.pedido_automatico === 1 && reqExiste.length === 0) {
      //    console.log("ES AUTOMATICO ROJO");
          postData(e,
            '/requerimientos_materiales',
            {
                id_sucursal: envioRequerimiento.id_sucursal,
                id_movil: envioRequerimiento.id_movil,
                estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
                para_comprar: envioRequerimiento.para_comprar,
                para_compras: envioRequerimiento.para_compras,
                para_rrhh: envioRequerimiento.para_rrhh,
                fecha_entrega: envioRequerimiento.fecha_entrega,
                usuario_registra: envioRequerimiento.usuario_registra,
                usuario_autoriza: envioRequerimiento.username,
                observaciones: envioRequerimiento.observaciones,
                uuid_requerimiento: uuid,
                comentarios_internos: envioRequerimiento.comentarios_internos,
                autorizado_jefe_compras: 0
            },
            setNotificacion,
            guardarListaMateriales(e),
            )
            .then((response) => {
              console.log("Respuesta de postData en guardarCabecera:", response);
            })
            .catch((error) => {
              console.error("Error en postData en guardarCabecera:", error);
            });
        }
      }
    envioMail();
    handleClose();
  };
  // console.log(
  //   "cantidad de salida",
  //   cantidadSalida,
  //   "punto de pedido ",
  //   datosArt.alerta_minima,
  //   "stock min",
  //   datosArt.valor_minimo,
  //   "almacen",
  //   datosArt.almacen
  // );

  const handleClose = () => {
    setDialogEliminarArticulo(!dialogEliminarArticulo);
    if (props.onClose) {
      props.onClose();
    }
  };
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleOpenCloseAdjDoc = (value) => {
    setOpenCloseAdjDoc(value);
  };

  const almacenar = (e) => {
    setDatosArt({
      ...datosArt,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (cantidadSalida < 0) {
      setDisabledGuardar(true);
      setcolorTextField("error");
    } else {
      setDisabledGuardar(false);
      setcolorTextField("primary");
    }
  }, [cantidadSalida, datosArt.cantidad_enviada]);
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  return (
    <>
       <Dialog
    open={dialogEliminarArticulo}
    onClose={handleClose}
    maxWidth="md"
    fullWidth
  >
    <Title
            titulo={`Salida de Artículo de la Sucursal`}
            handleClose={() => {
              handleClose()
            }}
          />
            <form onSubmit={guardarSalida} autoComplete="off">
          <Box
            border={0.5}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={1}
          >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Artículo"
                        size="small"
                        name="nombre_articulo"
                        value={datosArt.articulo}
                        onChange={almacenar}
                        inputProps={{
                          readOnly: true,
                          "aria-label": "Without label",
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Almacen de Origen"
                        size="small"
                        name="almacen"
                        value={datosArt.almacen}
                        onChange={almacenar}
                        inputProps={{
                          readOnly: true,
                          "aria-label": "Without label",
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} py={1} mb={1}>
                      <FormControl style={style} focused>
                        <InputLabel>Motivo</InputLabel>
                        <Select
                          required
                          label="Motivo"
                          size="small"
                          name="id_motivo"
                          value={datosArt.id_motivo}
                          onChange={almacenar}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                        >
                          {motivos
                            ? motivos.map((elemento) => (
                                <MenuItem
                                  key={elemento.id_motivo}
                                  value={elemento.id_motivo}
                                >
                                  {elemento.descripcion_motivo}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} mb={2}>
                      <TextField
                        style={style}
                        label="Detalle Transaccion"
                        name="detalle_transaccion"
                        size="small"
                        placeholder="..."
                        inputProps={{
                          maxLength: 60,
                        }}
                        focused
                        fullWidth
                        autoComplete="off"
                        value={datosArt.detalle_transaccion}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={3} mb={2}>
                      <TextField
                        style={style}
                        label="N° Pto.Venta"
                        name="nro_remito_puntodeventa"
                        size="small"
                        placeholder="0000"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{
                          maxLength: 4,
                        }}
                        focused
                        autoComplete="off"
                        fullWidth
                        value={datosArt.nro_remito_puntodeventa}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={3} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="N° Remito"
                        name="nro_remito_numero"
                        size="small"
                        placeholder="00000123"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{
                          maxLength: 8,
                        }}
                        focused
                        // required
                        fullWidth
                        value={datosArt.nro_remito_numero}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={2.6} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Cantidad de Salida"
                        name="cantidad_enviada"
                        size="small"
                        placeholder="..."
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{
                          maxLength: 6,
                        }}
                        focused
                        required
                        autoComplete="off"
                        fullWidth
                        color={colorTextField}
                        value={datosArt.cantidad_enviada}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={3.4} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Cantidad en el Inventario"
                        size="small"
                        value={cantidadSalida}
                        onChange={handleCantidadSalida}
                        inputProps={{
                          readOnly: true,
                          "aria-label": "Without label",
                        }}
                      />
                    </Grid>
                    <Grid item xs={3.7} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Fecha Transacción"
                        name="fecha_transaccion"
                        size="small"
                        type="date"
                        focused
                        required
                        fullWidth
                        value={moment(datosArt.fecha_transaccion).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={4} py={1} mb={1}>
                      <Button
                        onClick={() => setOpenCloseAdjDoc(!openCloseAdjDoc)}
                        variant="outlined"
                        size="medium"
                        startIcon={<AttachFileIcon />}
                      >
                        ADJUNTAR DOCUMENTACIÓN
                      </Button>
                    </Grid>
                  </Grid>

                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={8}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        Volver
                      </Button>
                    </Grid>

                      <Grid item xs={4}>
                        <Button
                          disabled={disabledGuardar}
                          fullWidth
                          variant="contained"
                          color="success"
                          type="submit"
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
                </form>
                </Dialog>

      {openCloseAdjDoc ? ( // ADJUNTAR COMPROBANTES
        <FileUploadStock
          fileId={props.newUuidDoc}
          openclose={true}
          mostrar={true}
          onClose={() => handleOpenCloseAdjDoc(false)} // Proporciona una función de cierre
        />
      ) : null}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Dialog_salida;
