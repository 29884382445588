//----- Component --------------------------------------------------------/

// Developer : Daiana Curcio / Nicolas Pascucci

// Creation Date : 21/11/2023   

// Version : 1

// Description : Lista de artículos que tiene una familia de artículos con la posibilidad de : *Agregar uno nuevo * Editarlo * Ver adjuntos 
// * Y los proveedores sugeridos
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Stack,
  Grid,
  Tooltip,
  IconButton,
  TextField,
  Autocomplete,
  FormControlLabel,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// ICONOS
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

//ARTICULOS
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

//ADJUNTAR IMAGEN
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR IMAGEN
import FileUploadStock from "./FileUploadStock"; //ADJUNTAR IMAGEN
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
import Switch from '@mui/material/Switch';
import Dialog_definir from "./Dialog_definir";

//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { blueGrey } from "@mui/material/colors";

import Checkbox from '@mui/material/Checkbox';
import Title from "../Title";
import { grey } from "@mui/material/colors";

import { logEventos } from "../../services/services";

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Articulos_cargas(props) {
  let navigate = useNavigate();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const location = useLocation();
  const [row, setRow] = useState(props.row);
  const [articulos, setArticulos] = useState();

  const [uuid_articuloG, setUuid_articuloG] = useState(uuidv4()); //ADJUNTAR IMAGEN
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN
  const [newUuid, setNewUuid]=useState("");//ADJUNTAR IMAGEN
  
  const [colorExiste, setcolorExiste] = useState('primary')
  const [datosArticulos, setDatosArticulos] = useState({
    id_articulo: null,
    id_familia: location.state.id_familia,
    codigo: "",
    nombre: "",
    descripcion: "",
    id_unidadmedida: null,
    valor_minimo: "",
    alerta_minima: "",
    // cantidad_articulo: "",
    usuario: localStorage.getItem("user"),
    uuid_articulo:"", //ADJUNTAR ARCHIVOS
  });

  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);
  const [editar, setEditar] = useState(false);

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [openVerArticulos, setOpenVerArticulos] = useState(true);
  // DIALOG PARA EDITAR LOS DATOS
  const [openEditar, setOpenEditar] = React.useState(false);
   
  const [linea, setLinea]=useState();

  const [checkedAutomatico, setCheckedAutomatico] = useState(false)

  const handleChangeCheckedAutomatico = (event) => {
    setCheckedAutomatico(event.target.checked);
  }
//console.log("/(/((/(/(/(/", checkedAutomatico);
  let req_automatico =(checkedAutomatico? 1 : 0)

  let variableTF = (datosArticulos.stock_variable === 1 ? true : false)
  const [checkedEditar, setCheckedEditar] = React.useState(variableTF);
  const [checked, setChecked] = React.useState(false);
  const handleChangeSwitch = (event) => {
    setChecked(event.target.checked);
  };
  const handleChangeSwitchEditar = (event) => {
    setCheckedEditar(event.target.checked);
    // datosArticulos.valor_minimo = ""
    //   datosArticulos.alerta_minima = ""
  };

  useEffect(() => {
    if (setCheckedEditar === true) {
      datosArticulos.valor_minimo = ""
      datosArticulos.alerta_minima = ""
    }
    setCheckedEditar(variableTF);
  }, [variableTF]);
  
  let variableConv = (datosArticulos.convertible === 1 ? true : false)
  const [checkedConvertir, setCheckedConvertir] = React.useState(false);
  const [checkedEditarConvertir, setCheckedEditarConvertir] = React.useState(variableConv);
  const handleChangeSwitchConvertir = (event) => {
    setCheckedConvertir(event.target.checked);
  };

  const handleChangeSwitchEditarConvertir = (event) => {
    setCheckedEditarConvertir(event.target.checked);
  };

  useEffect(() => {
    setCheckedEditarConvertir(variableConv);
  }, [variableConv,openEditar]);


  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  let variableAutomatico = (datosArticulos.pedido_automatico === 1 ? true : false)
  const [checkedEditarAutomatico, setCheckedEditarAutomatico] = React.useState(variableAutomatico);
  
  const handleChangeSwitchEditarAutomatico = (event) => {
    setCheckedEditarAutomatico(event.target.checked);
  };
  
  useEffect(() => {
    setCheckedEditarAutomatico(variableAutomatico);
  }, [variableAutomatico,openEditar]);
  //console.log("//////////////////DATOS ARTICULOS/&/&/(/(/(/&/(&", datosArticulos, variableAutomatico);

    //*************************************************************************************************************************************/
  const [codigoNuevo, setCodigoNuevo] = useState()
    //funcion para la generacion del codigo
  const obtenerCodigoNuevo = () => {
    fetch(UrlApi + "/stock_articulos/codigo/" + location.state.nro1 + "/" + location.state.nro2 + "/" + location.state.nro3)
      .then((data) => data.json())
      .then((data) => setCodigoNuevo(data));
  };

  let codigoFamilia = location.state.nro1.toString() + location.state.nro2.toString() + location.state.nro3.toString()
  let codigoValor = codigoNuevo && codigoNuevo.length > 0 ? parseInt(codigoNuevo[0].codigo.substring(3)) + parseInt(1): 0
  let codigoAuto = codigoNuevo && codigoNuevo.length > 0 ? codigoFamilia + codigoValor : location.state.nro1.toString() + location.state.nro2.toString() + location.state.nro3.toString() + (1).toString()

  // let codigoAuto = codigoNuevo && codigoNuevo.length > 0 ? codigoNuevo[0].nro1.toString() + codigoNuevo[0].nro2.toString() + codigoNuevo[0].nro3.toString() + (parseInt(codigoNuevo[0].codigo) + parseInt(1)).toString()  : location.state.nro1.toString() + location.state.nro2.toString() + location.state.nro3.toString() + (1).toString()

  //funcion que espera los cambios de la funcion de obtener a los articulos
  useEffect(() => {
    obtenerCodigoNuevo();
  }, [update]);
  //*************************************************************************************************************************************/
  //funcion para obtener articulos por familia
  const obtenerArticulos = () => {
    fetch(UrlApi + "/stock_articulos/xfamilia/" + location.state.id_familia)
      .then((data) => data.json())
      .then((data) => setArticulos(data));
  };

 // console.log("trae los articulos", articulos);

  //funcion que espera los cambios de la funcion de obtener a los articulos
  useEffect(() => {
    obtenerArticulos();
  }, [tabla]);

  const [articulosElect, setArticulosElect] = useState();

  //funcion para obtener articulos
  const obtenerArticulosElect = () => {
    fetch(UrlApi + "/stock_articulos/actual/"+ datosArticulos.id_articulo)
      .then((data) => data.json())
      .then((data) => setArticulosElect(data));
  };

  useEffect(() => {
    obtenerArticulosElect();
  }, [datosArticulos]);

  const [articuloSeleccionado, setArticuloSeleccionado] = useState({});

  //console.log("CONVERTIBLE", checkedConvertir, articuloSeleccionado);

 //funcion para obtener proveedores
  const [proveedores, setProveedores] = useState();

  const obtenerProveedores = () => {
    fetch(UrlApi + "/proveedores")
      .then((data) => data.json())
      .then((data) => setProveedores(data));
  };

  useEffect(() => {
    obtenerProveedores();
  }, [tabla]);
 // console.log("PROVEEDORES", proveedores);

  const [proveedorSeleccionado, setProveedorSeleccionado] = useState({});
  const [proveedorSeleccionadoEditar, setProveedorSeleccionadoEditar] = useState({});
// console.log("PROVEEDOR SELECCIONADO", proveedorSeleccionado);
  //*************************************************************************************************************************************/
  //funcion para obtener unidad de medida
  const [unidadMedida, setUnidadMedida] = useState("");

  const obtenerUnidadDeMedida = () => {
    fetch(UrlApi + "/unidad_medida")
      .then((data) => data.json())
      .then((data) => setUnidadMedida(data));
  };

 // console.log("trae las unidades de medida", unidadMedida);

  //funcion que espera los cambios de la funcion de obtener 
  useEffect(() => {
    obtenerUnidadDeMedida();
  }, []);

 // **************************************************************************************************************************************//
 const [prSugeridos, setPrSugeridos] = useState();
 const [idSugeridos, setIdSugeridos] = useState()
 //console.log("////ID SUGERIDO////", idSugeridos);
 const obtenerPrSugeridos = () => {
  fetch(UrlApi + "/stock_articulos_proveedores/sugeridosid/" + (idSugeridos? idSugeridos.id_articulo: datosArticulos.id_articulo))
    .then((data) => data.json())
    .then((data) => setPrSugeridos(data));
  };

 //console.log("trae los proveedores sugeridos", prSugeridos);

  //funcion que espera los cambios de la funcion de obtener 
  useEffect(() => {
  obtenerPrSugeridos();
   }, [datosArticulos]);

    //*************************************************************************************************************************************/
  //CSV
  const [articulosFamiliasCSV, setArticulosFamiliasCSV] = useState();

  const obtenerArtDeFamiliasCSV = () => {
    fetch(UrlApi + "/stock_articulos/articulosDeFamiliaCSV/"+ location.state.id_familia)
      .then((data) => data.json())
      .then((data) => setArticulosFamiliasCSV(data));
  };
 // console.log("csv", articulosFamiliasCSV);

  useEffect(() => {
    obtenerArtDeFamiliasCSV();
  }, [tabla]);

  //*************************************************************************************************************************************/
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  //*************************************************************************************************************************************/
  // DIALOG PARA CREAR LOS DATOS
  const [openCrear, setOpenCrear] = React.useState(false);
  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrear = (row) => {
    setUuid_articuloG(uuidv4())
    setNewUuid(uuid_articuloG)
    setOpenCrear(true);
    // AL ABRIR EL DIALOG El TEXTFIELDS SE VACIAN
    // datosArticulos.id_articulo = null;
    datosArticulos.id_familia = location.state.id_familia;
    datosArticulos.codigo = codigoAuto;
    datosArticulos.nombre = "";
    datosArticulos.descripcion = "";
    datosArticulos.id_unidadmedida = null;
    datosArticulos.valor_minimo = "";
    datosArticulos.alerta_minima = "";
    datosArticulos.uuid_articulo="";
    
  };
//console.log("//////////UUID/////////", uuid_articuloG);
  const handleCloseCrear = () => {
    setOpenCrear(false);
    setChecked(false)
    setCheckedConvertir(false)
    setCheckedAutomatico(false)
  };

  const [definir, setDefinir] = useState(false)
  const handleClickDefinir = (row) => {
    setDatosArticulos(row);
    setDefinir(true)
  };
  const handleCloseDefinir = () => {
    setDefinir(false);
  };

  //*************************************************************************************************************************************/
  const [inactivo, setInactivo] = useState()
  const [estado, setEstado] = useState()
  const [mensajeConfirma, setMensajeConfirma] = useState(false)
  const [disabledEstado, setDisabledEstado] = useState(false)



  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenEditar = (row) => {
    setEstado(row.estado)
    setInactivo(row.estado)
    setDatosArticulos(row);
    setOpenEditar(true);
  };

  const handleCloseEditar = (row) => {
    setCheckedEditar(false)
    setOpenEditar(false);
    setDatosArticulos(row);
  };

  // Proveedores sugeridos
  const [openSugeridos, setOpenSugeridos] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenSugeridos = (row) => {
    setOpenSugeridos(true);
    setDatosArticulos(row);
    if (prSugeridos === "") {
      prSugeridos("NO HAY PROVEEDORES SUGERIDOS")
    }
  };

  const handleCloseSugeridos = (row) => {
    setOpenSugeridos(false);
    // setDatosArticulos(row);
    setPrSugeridos();
  };

  // EDITAR
  const handleCloseSnackEditar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackEditar(false);
    setOpenEditar(false);
    setUpdate(false);
    setOpenDialogOnOff(false);
  };

  const handleInactivo = () =>{
    if (inactivo === 0 ) {
      setInactivo(1)
      setDisabledEstado(true)
    } else {
      setInactivo(0)
      setDisabledEstado(true)
    }
    setMensajeConfirma(true)
  }
  //*************************************************************************************************************************************/
  const [openDialogOnOff, setOpenDialogOnOff] = useState(false);
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpenCrear(false);
    setOpenEditar(false);
    setOpenDialogOnOff(false);
    setMensajeConfirma(false)
    setDisabledEstado(false)
  };

  const handleCloseDialog = () => {
    setOpenVerArticulos(false);
    props.abrirDialog(false);
  };

  //*************************************************************************************************************************************/
  const columns = useMemo(
    () => [
      { header: "Código", accessorKey: "codigo", size: 85 },
      { header: "Nombre", accessorKey: "nombre", size: 100 },
      { header: "Descripción", accessorKey: "descripcion", size: 130 },
      { header: "Medida", accessorKey: "descripcion_unidad", size: 70 },
      { header: "Stock Mínimo", accessorKey: "valor_minimo", size: 90 },
      { header: "Pto. de Pedido", accessorKey: "alerta_minima", size: 100 },
      { header: "Estado", accessorKey: "estado", size: 100, enableHiding: true },
      { header: "Estado", accessorKey: "estado_palabra", size: 80 },
      {header: "uuid", accessorKey: "uuid_articulo", size: 70, enableHiding: true},
      {header: "Autorizado", accessorKey: "aprobado_palabra", size: 90},
      {header: "stock_variable", accessorKey: "stock_variable", size: 70, enableHiding: true},
      {header: "convertible", accessorKey: "convertible", size: 70, enableHiding: true},
      {header: "convertible_a", accessorKey: "convertible_a", size: 70, enableHiding: true},
      {header: "nombre_convertible", accessorKey: "nombre_convertible", size: 70, enableHiding: true},
      {header: "pedido_automatico", accessorKey: "pedido_automatico", size: 70, enableHiding: true},
      ],
    []
  );

  const columnsCSV = useMemo(
    () => [
      { header: "Código", accessorKey: "codigo", size: 85 },
      { header: "Nombre", accessorKey: "nombre", size: 100 },
      { header: "Descripción", accessorKey: "descripcion", size: 130 },
      { header: "Medida", accessorKey: "descripcion_unidad", size: 70 },
      { header: "Estado Artículo", accessorKey: "estado_palabra", size: 80 },
      {header: "Estado Autorización", accessorKey: "aprobado_palabra", size: 90},
      { header: "Pto. de Pedido", accessorKey: "alerta_minima", size: 100 },
      { header: "Stock Mínimo", accessorKey: "valor_minimo", size: 90 },
      {header: "Se Convierte a:           ", accessorKey: "nombre_convertible", size: 70},
      {header: "Usuario", accessorKey: "usuario", size: 90},
      ],
    []
  );

  //CSV
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `Listado de Artículos de la Familia:${location.state.descripcion}`,
    headers: columnsCSV.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(articulosFamiliasCSV);
  };

  let es_variable = checked? 1 : 0 
  let es_variableEditar = checkedEditar? 1 : 0
  let convertibleEditar = checkedEditarConvertir? 1 : 0
  let es_automatico = checkedEditarAutomatico? 1 : 0
 // console.log("=========VARIABLE==========", es_variable);
  let convertible = checkedConvertir? 1 : 0

  useEffect(() => {
    if (openEditar) {
      // Buscar el artículo con nombre_convertible en el arreglo de opciones
      const articuloPredeterminado = articulosElect.find(
        (articulo) => articulo.nombre === datosArticulos.nombre_convertible
      );

      // Establecer el artículo como valor predeterminado
      setArticuloSeleccionado(articuloPredeterminado);
    }
  }, [openEditar, articulosElect, datosArticulos.nombre_convertible]);

  //*************************************************************************************************************************************/
  //Funcion para guardar al crear
 const [nuevoArt, setNuevoArt] = useState(false)

  const guardarCrear = async (e) => {
    logEventos("Guardar nuevo artículo a la familia", "Stock Familias de Articulos", "Se guardo el nuevo artículo a la familia", localStorage.getItem("user"))
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/stock_articulos", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_articulo: datosArticulos.id_articulo,
        id_familia: datosArticulos.id_familia,
        codigo: datosArticulos.codigo,
        nombre: datosArticulos.nombre,
        descripcion: datosArticulos.descripcion,
        id_unidadmedida: datosArticulos.id_unidadmedida,
        valor_minimo: checked? null : datosArticulos.valor_minimo,
        alerta_minima: checked? null : datosArticulos.alerta_minima,
        usuario: localStorage.getItem("user"),
        uuid_articulo:newUuid, //ADJUNTAR IMAGEN
        estado: 1,
        aprobado: 0,
        stock_variable: es_variable,
        convertible: convertible,
        convertible_a: articuloSeleccionado.id_articulo,
        pedido_automatico: req_automatico,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El artículo se guardó exitosamente !");
          setUpdate(true);
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el artículo");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
      setNuevoArt(true)
      // PARA LIMPIAR LOS DATOS
      handleClickOpenCrear();
      handleCloseCrear()
  };
  
 // console.log("POST CREAR", datosArticulos);
  //*************************************************************** */
  const [ultimaRow, setUltimaRow] = useState();

  const obtenerUltimaRow = () => {
    fetch(UrlApi + "/stock_articulos/ultimarow")
      .then((data) => data.json())
      .then((data) => setUltimaRow(data));
  };

 // console.log("trae la ultima row",ultimaRow === null? parseInt(ultimaRow[0].id_articulo) + parseInt(1) : 1);

  //funcion que espera los cambios de la funcion de obtener unidad de medida
  useEffect(() => {
    obtenerUltimaRow();
    if (nuevoArt) {
      guardarRelacion();  
      setNuevoArt(false)
    }
    
  }, [nuevoArt]);
 //console.log("ESTADO DE NUEVO ART", nuevoArt);
  //************************************************************************************** */

  const guardarRelacion = () =>{
    logEventos("Guardar relación", "Stock Familia de Articulos", "Se guardo la relación", localStorage.getItem("user"))
    // guardar relacion
    for (let i = 0; i < proveedorSeleccionado.length; i++) {
      const provee = proveedorSeleccionado[i].id;
      console.log("Estoy por guardar");
      fetch(UrlApi + "/stock_articulos_proveedores", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_articulo: ultimaRow? parseInt(ultimaRow[0].id_articulo) + parseInt(1) : 1,
          id_proveedor: provee, 
          usuario: localStorage.getItem("user"),
        }),
      })
     .then((res) => {
      if (!res.err) {
        // setOpenSnack(true);
        // setColorMensaje("#D4EFDF");
        // setMensaje("El proveedor sugerido se guardó exitosamente !");
        setUpdate(true);
        console.log("Guardado");
      } else {
        setOpenSnack(false);
        setColorMensaje("#F1948A");
        setMensaje("Error al guardar el artículo");
        console.log("Error");
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
  }
}

const guardarRelacionEditada = (e) =>{
  // guardar relacion
  logEventos("Guardar relación editada", "Stock Familia de Articulos", "Se guardo la relación editada", localStorage.getItem("user"))
  e.preventDefault();
  for (let i = 0; i < proveedorSeleccionadoEditar.length; i++) {
    const provee = proveedorSeleccionadoEditar[i].id;
    console.log("Estoy por guardar");
    fetch(UrlApi + "/stock_articulos_proveedores", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_articulo: datosArticulos.id_articulo,
        id_proveedor: provee, 
        usuario: localStorage.getItem("user"),
      }),
    })
   .then((res) => {
    if (!res.err) {
      setOpenSnack(true);
      setColorMensaje("#D4EFDF");
      setMensaje("El proveedor sugerido se guardó exitosamente !");
      setUpdate(true);
      console.log("Guardado");
    } else {
      setOpenSnack(false);
      setColorMensaje("#F1948A");
      setMensaje("Error al guardar el artículo");
      console.log("Error");
    }
  })
  .catch((err) => {
    console.log(err.message);
  });
  }
}
//console.log("//PROVEEDOR SELECCIONADO//", datosArticulos.id_articulo ,proveedorSeleccionadoEditar);
  //*************************************************************************************************************************************/
  //Funcion para guardar AL EDITAR
  const guardarEditar = async (e) => {
    logEventos("Guardar edición de artículo", "Stock Familia de Articulos", "Se guardo la edición del artículo", localStorage.getItem("user"))
    console.log("Estoy por guardar");
    e.preventDefault();

    await fetch(UrlApi + "/stock_articulos/" + datosArticulos.id_articulo, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_articulo: datosArticulos.id_articulo,
        id_familia: datosArticulos.id_familia,
        codigo: datosArticulos.codigo,
        nombre: datosArticulos.nombre,
        descripcion: datosArticulos.descripcion,
        id_unidadmedida: datosArticulos.id_unidadmedida,
        valor_minimo: checkedEditar?null:datosArticulos.valor_minimo,
        alerta_minima: checkedEditar?null:datosArticulos.alerta_minima,
        stock_variable: es_variableEditar,
        usuario: localStorage.getItem("user"),
        estado: inactivo,
        convertible: convertibleEditar,
        convertible_a: checkedEditarConvertir?articuloSeleccionado.id_articulo:null,
        pedido_automatico: es_automatico,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          // setMensajeConfirma(true)
          setColorMensaje("#D4EFDF");
          setMensaje("El artículo se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el artículo");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
   // console.log(datosArticulos);
  };
 // console.log("POST EDITAR", datosArticulos);

  //*************************************************************************************************************************************/
  const almacenar = (e) => {
    setDatosArticulos({
      ...datosArticulos,
      [e.target.name]: e.target.value,
    });
  };
  //*************************************************************************************************************************************/
  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update){
     obtenerArticulos();
     obtenerPrSugeridos();
    }
      
  }, [update]);
  //*************************************************************************************************************************************/
  const borraRelacion = async (id_proveedor)=>{
    logEventos("Borrar relación", "Stock Familia de Articulos", "Se borro la relación", localStorage.getItem("user"))
    fetch(UrlApi + "/stock_articulos_proveedores/artprovee/" + datosArticulos.id_articulo + "/" + id_proveedor, {
      method: 'DELETE', headers: { 'Content-Type': 'application/json' }
  })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El proveedor sugerido se eliminó");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al eliminar el proveedor sugerido");
          console.log("Error");
        }
      })
 // console.log("///////////// ID SUGERIDOS /////////////////", id_proveedor, idSugeridos.id_articulo);
  }
  
  // ESTILO PARA LA TABLA

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
      },
      size: 120,
      muiTableBodyCellProps: {align: "center"},
    },
  };

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    columnVisibility: { id_articulo: false, uuid_articulo: false, estado: false, stock_variable: false, convertible:false, convertible_a: false, nombre_convertible: false, pedido_automatico: false },
  };
//console.log("()()()()()()VARIABLE CONV()()()(()()", datosArticulos?datosArticulos.convertible:null,variableConv, checkedEditarConvertir);
  // console.log("ID DE FAMILIAA: ", location.state.id_familia);
  //  ***********************************************************************************************************************************//
  // const [existe, setExiste] = useState({})
  // const obtenerSiExiste = () => {
  //   fetch(UrlApi + "/stock_articulos/articulosRepetidos/" + datosArticulos.codigo )
  //     .then((data) => data.json())
  //     .then((data) => setExiste(data));
  //   };
  
  //  console.log("trae si existe el articulo", existe);
  
  //   //funcion que espera los cambios de la funcion de obtener 
  //   useEffect(() => {
  //   obtenerSiExiste();
  //    }, [openCrear,datosArticulos.codigo, datosArticulos.nombre]);
  
  
  //console.log("LINEA: ", linea)
 // console.log("ID ART", datosArticulos.id_articulo);
  //console.log("--- trae el codigo nuevo ---",codigoNuevo, "codigoFamilia", codigoFamilia, "VALOR" ,codigoValor, "codigoAuto",codigoAuto)
  return (
    <>
      {/* ARTICULOS */}
        <AppBar sx={{ position: "relative" }} style={{backgroundColor:"#0979b0"}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={()=>
              navigate("/familias_lista")}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Artículos de la Familia: {location.state.descripcion}
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth="xl" mt={1}>
          {articulos && tabla ? (
            <Box mt={1} mb={0}>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                  enableGrouping // para permitir agrupar x estado x ej
                  layoutMode="grid"
                  displayColumnDefOptions={displayColumnDefOptions}
                  muiTableHeadCellProps={tableHeadCellProps}
                  muiTableBodyCellProps={({ row }) => ({
                    align: "left",
                    sx: {
                      color: "black",
                      borderColor: "black",
                    },
                  })}
                  enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                  enableColumnFilters={false} //desactiva filtros x  columna
                  enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                  enableDensityToggle={false} //
                  localization={MRT_Localization_ES} //idioma
                  enableColumnDragging={false} //para arrastrar columnas
                  initialState={initialState}
                  muiSearchTextFieldProps={muiSearchTextFieldProps}
                  columns={columns}
                  data={articulos} //con lo q la completas (esto cambia siempre)
                  enableStickyFooter
                  enableEditing
                  positionActionsColumn="last" // posicion de la columna de acciones al final
                  // ACCIONES
                  renderToolbarInternalActions={(
                    { table } // boton para nuevo , al lado del buscar
                  ) => (
                    <>
                    <Grid item xs={6} ml={4}>
                  <Button
                   variant="contained"
                   color="primary"
                   endIcon={<FileDownloadIcon />}
                    onClick={handleExportData}
                    style={{ backgroundColor: blueGrey[700], color: "white" }}
                    size="small"
                  >
                    Exportar a CSV
                  </Button>
                  </Grid>
                      <MRT_FullScreenToggleButton table={table} />
                    </>
                  )}
                  renderTopToolbarCustomActions={() => (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBoxTwoToneIcon />}
                        onClick={handleClickOpenCrear}
                        size="small"
                      >
                        NUEVO ARTÍCULO A LA FAMILIA
                      </Button>
                      <Typography variant="poster" fontSize={26}>
                        Lista de Artículos
                      </Typography>
                    </>
                  )}
                  renderRowActions={(
                    { row, table } //las acciones del costado editar y ver
                  ) => (
                    <div>
                      <Tooltip arrow placement="left" title="Editar Artículo">
                        <IconButton
                          onClick={() => handleClickOpenEditar(row.original)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      {/* ADJUNTAR ARCHIVOS */}
                      <Tooltip arrow placement="bottom" title="Ver adjunto" > 
                            <Button backgroundColor="#ffffff" size="small" onClick={() => {
                              setNewUuid(row.original.uuid_articulo)
                              setOpenCloseAdj(!openCloseAdj)
                            }
                            }><AttachFileIcon /></Button>
                        </Tooltip>
                        <Tooltip arrow placement="top" title="Proveedores Sugeridos">
                        <IconButton
                          onClick={() => handleClickOpenSugeridos(row.original)}
                          color="primary"
                        >
                          <GroupAddIcon />
                        </IconButton>
                      </Tooltip>
                      {row.original.stock_variable === 1?
                      <Tooltip arrow placement="top" title="Definir Stock Variable">
                        <IconButton
                          onClick={() => handleClickDefinir(row.original)}
                          color="primary"
                        >
                          <FormatListNumberedRtlIcon />
                        </IconButton>
                      </Tooltip> 
                      :null}
                      
                    </div>
                  )}
                />
              </ThemeProvider>
            </Box>
          ) : null}
        </Container>

        {/* AGREGAR ARTICULO */}
        <Dialog
          open={openCrear}
          onClose={handleCloseCrear}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <Title
            titulo={`Nuevo Artículo a la Familia`}
            handleClose={() => {
              handleCloseCrear()
            }}
          />
          <Box
            border={0.5}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={0.5}
          >
          <DialogContent>
            <Container maxWidth="xl" maxHeight>
              <form onSubmit={guardarCrear} autoComplete="off">
                <Grid container mb={1} spacing={1}>
                  <Grid item xs={3} py={1} >
                    <TextField
                      label="Código"
                      name="codigo"
                      size="small"
                      placeholder="Ej. 1234"
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        readOnly: true,
                        "aria-label": "Without label",
                        maxLength: 10,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosArticulos.codigo}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={9} py={1} >
                    <TextField
                      label="Nombre"
                      name="nombre"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 55,
                      }}
                      focused
                      required
                      fullWidth
                      autoComplete="off"
                      value={datosArticulos.nombre}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={7} >
                    <TextField
                      label="Descripción"
                      name="descripcion"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 60,
                      }}
                      focused
                      required
                      fullWidth
                      autoComplete="off"
                      value={datosArticulos.descripcion}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={5} >
                    <FormControl style={style} focused>
                      <InputLabel>Unidad de medida</InputLabel>
                      <Select
                        label="Unidad de medida"
                        required
                        size="small"
                        name="id_unidadmedida"
                        value={datosArticulos.id_unidadmedida}
                        onChange={almacenar}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {unidadMedida
                          ? unidadMedida.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.descripcion} - {elemento.abreviatura}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={9} >
                  {checked?<span style={{ color: 'black',fontSize: '18px', margin: '15px'  }}>Stock General</span>:<span style={{ color: 'black', fontWeight: 'bold', fontSize: '18px', margin: '15px' }}>Stock General</span>}  
                  <FormControlLabel control={<Switch
                        checked={checked}
                        onChange={handleChangeSwitch}
                  />} label={checked?<span style={{ color: 'black', fontWeight: 'bold', fontSize: '18px' }}>Stock Variable</span>:<span style={{ color: 'black', fontSize: '18px' }}>Stock Variable</span>} />
                  </Grid>
                  <Grid item xs={6} py={1} >
                    <TextField
                      label="Stock Mínimo"
                      name="valor_minimo"
                      size="small"
                      placeholder="..."
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 6,
                      }}
                      focused
                      disabled={checked? true : false}
                      required={checked? false : true}
                      fullWidth
                      autoComplete="off"
                      value={checked?"":datosArticulos.valor_minimo}                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6} py={1}>
                    <TextField
                      label="Punto de Pedido"
                      name="alerta_minima"
                      size="small"
                      placeholder="..."
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 6,
                      }}
                      focused
                      disabled={checked? true : false}
                      required={checked? false : true}
                      fullWidth
                      autoComplete="off"
                      value={checked?"":datosArticulos.alerta_minima}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6} >
                  <FormControlLabel control={<Switch
                        checked={checkedConvertir}
                        onChange={handleChangeSwitchConvertir}
                  />} label={checkedConvertir?<span style={{ color: 'black', fontWeight: 'bold', fontSize: '18px' }}>Convertible</span>:<span style={{ color: 'black', fontSize: '18px' }}>Convertible</span>} />
                  </Grid>
                  {checkedConvertir? 
                  <Grid item xs={6} py={1} >
                      <Autocomplete
                        disabled={!articulos}
                        size="small"
                        focused
                        disableClearable
                        id="stock_articulos_elect"
                        noOptionsText={"Artículo INEXISTENTE"}
                        options={articulosElect}
                        autoHighlight
                        getOptionLabel={(articulos) =>
                          articulos.codigo + "-" + articulos.nombre
                        }
                        onChange={(event, value) =>
                          setArticuloSeleccionado(value)
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            key={option.id}
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.codigo}-{option.nombre}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            focused
                            required
                            size="small"
                            placeholder="Tipee para buscar..."
                            {...params}
                            label="Convertible a"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    :null}
                  <Grid item xs={8} py={1} >
                    <Autocomplete
                      multiple
                      disabled={!proveedores}
                      size="small"
                      focused
                      required
                      disableClearable
                      id="proveedores"
                      noOptionsText={"Proveedor INEXISTENTE"}
                      options={proveedores}
                      autoHighlight
                      getOptionLabel={(proveedores) =>
                        proveedores.razon_social + "-" + proveedores.nro_cuit
                      }
                      onChange={(event, value) =>
                        setProveedorSeleccionado(value)
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.razon_social} - {option.nro_cuit}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          focused
                          size="small"
                          placeholder="Tipee para buscar..."
                          {...params}
                          label="Proveedor"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4} py={1}>
                    <Button
                      onClick={() => setOpenCloseAdj(!openCloseAdj)}
                      variant="outlined"
                      size="medium"
                      startIcon={<AttachFileIcon />}
                    >
                      ADJUNTAR
                    </Button>
                  </Grid>
                  <Grid item xs={12} >
                  <FormControlLabel control={<Switch
                        checked={checkedAutomatico}
                        onChange={handleChangeCheckedAutomatico}
                  />} label={checkedAutomatico?<span style={{ color: 'black', fontWeight: 'bold', fontSize: '18px' }}>Requerimiento Automático</span>:<span style={{ color: 'black', fontSize: '18px' }}>Requerimiento Automático</span>} />
                  </Grid>
               </Grid>

                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={8}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleCloseCrear}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
          </Box>
        </Dialog>

        {/* GUARDAR AL CREAR */}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>

        {/* EDITAR ITEM */}
        <Dialog
          open={openEditar}
          onClose={handleCloseEditar}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <Box sx={{ flexGrow: 1 }}>
          <Grid container backgroundColor="#1F618D" p={1.5} mt={2}>
            <Grid item xs={2}>
              <IconButton onClick={handleCloseEditar}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Editar Artículo
              </Typography>
            </Grid>
          </Grid>
        </Box>
          <Box
            border={0.5}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={1}
          >
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarEditar} autoComplete="off">
                <Grid container  mb={1} spacing={1}>
                  <Grid item xs={3} py={1} >
                    <TextField
                      label="Código"
                      name="codigo"
                      size="small"
                      placeholder="1234"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        readOnly: true,
                        "aria-label": "Without label",
                        maxLength: 10,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosArticulos.codigo}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={9} py={1}>
                    <TextField
                      label="Nombre"
                      name="nombre"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 55,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosArticulos.nombre}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6.3} >
                    <TextField
                      label="Descripción"
                      name="descripcion"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 60,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosArticulos.descripcion}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={5.7} >
                    <FormControl style={style} focused>
                      <InputLabel>Unidad de medida</InputLabel>
                      <Select
                        label="Unidad de medida"
                        required
                        size="small"
                        name="id_unidadmedida"
                        value={datosArticulos.id_unidadmedida}
                        onChange={almacenar}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {unidadMedida
                          ? unidadMedida.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.descripcion} - {elemento.abreviatura}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={9} >
                  {checkedEditar?<span style={{ color: 'black',fontSize: '18px', margin: '15px'  }}>Stock General</span>:<span style={{ color: 'black', fontWeight: 'bold', fontSize: '18px', margin: '15px' }}>Stock General</span>}  
                  <FormControlLabel control={<Switch
                        checked={checkedEditar}
                        onChange={handleChangeSwitchEditar}
                  />} label={checkedEditar?<span style={{ color: 'black', fontWeight: 'bold', fontSize: '18px' }}>Stock Variable</span>:<span style={{ color: 'black', fontSize: '18px' }}>Stock Variable</span>} />
                  </Grid>
                  <Grid item xs={4} py={1}>
                    <TextField
                      label="Stock Mínimo"
                      name="valor_minimo"
                      size="small"
                      placeholder="80"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 6,
                      }}
                      focused
                      disabled={checkedEditar? true : false}
                      required={checkedEditar? false : true}
                      fullWidth
                      value={checkedEditar?"":datosArticulos.valor_minimo}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={4} py={1}>
                    <TextField
                      label="Punto de Pedido"
                      name="alerta_minima"
                      size="small"
                      placeholder="80"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 6,
                      }}
                      focused
                      disabled={checkedEditar? true : false}
                      required={checkedEditar? false : true}
                      fullWidth
                      value={checkedEditar?"":datosArticulos.alerta_minima}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={3} >
                    <Button
                    variant="outlined"
                    disabled={disabledEstado}
                    startIcon={<ChangeCircleIcon />}
                    onClick={handleInactivo}
                    >
                      {estado === 0? "Activar": "Desactivar"}
                    </Button>
                  </Grid>
                  <Grid item xs={6} >
                  <FormControlLabel control={<Switch
                        checked={checkedEditarConvertir}
                        onChange={handleChangeSwitchEditarConvertir}
                  />} label={checkedEditarConvertir?<span style={{ color: 'black', fontWeight: 'bold', fontSize: '18px' }}>Convertible</span>:<span style={{ color: 'black', fontSize: '18px' }}>Convertible</span>} />
                  </Grid>
                  {checkedEditarConvertir? 
                  <Grid item xs={6} py={1} >
                      <Autocomplete
                        disabled={!articulos}
                        size="small"
                        focused
                        disableClearable
                        id="stock_articulos_elect"
                        noOptionsText={"Artículo INEXISTENTE"}
                        options={articulosElect}
                        autoHighlight
                        getOptionLabel={(articulos) =>
                          articulos.codigo + "-" + articulos.nombre
                        }
                        value={articuloSeleccionado}
                        onChange={(event, value) =>
                          setArticuloSeleccionado(value)
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            key={option.id}
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.codigo}-{option.nombre}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            focused
                            required
                            size="small"
                            placeholder={datosArticulos.nombre_convertible}
                            {...params}
                            label="Convertible a"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    :null}
                    <Grid item xs={12} >
                  <FormControlLabel control={<Switch
                        checked={checkedEditarAutomatico}
                        onChange={handleChangeSwitchEditarAutomatico}
                  />} label={checkedEditarAutomatico?<span style={{ color: 'black', fontWeight: 'bold', fontSize: '18px' }}>Requerimiento Automático</span>:<span style={{ color: 'black', fontSize: '18px' }}>Requerimiento Automático</span>} />
                  </Grid>
                  {mensajeConfirma?
                  <Grid item xs={12} mb={2}>
                     <Typography style={{ color: 'black', fontSize: '16px', textAlign: "center" }}>
                       Guarde para confirmar el cambio de estado
                     </Typography>
                   </Grid>: null}
                </Grid>

                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleCloseEditar}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
          </Box>
        </Dialog>

        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>

        {/* GUARDAR AL EDITAR */}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnackEditar}
            autoHideDuration={1500}
            onClose={handleCloseSnackEditar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackEditar}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>

        {openCloseAdj ? ( // ADJUNTAR IMAGEN
          <FileUploadStock
            fileId={newUuid}
            openclose={true}
            mostrar={true}
            onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
          />
          ) : null}

      <Dialog
        open={openSugeridos}
        onClose={handleCloseSugeridos}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiDialog-paper': { width: '50%', maxHeight: 500 } }}
      >
        <Title
            titulo={`Proveedores Sugeridos`}
            handleClose={() => {
              handleCloseSugeridos()
            }}
          />
           <Box
            border={0.5}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={1}
          > 
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={guardarRelacionEditada} autoComplete="off">
          <Grid item xs={12} md={6}>
            <Grid item xs={12} py={1} mb={1}>
                    {openSnack? 
                    (<TextField
                    value={(proveedorSeleccionadoEditar.id = null)}
                    disabled
                    focused
                    fullWidth
                    size="small"
                    label= "Guardando proveedor..."
                    />)
                    :
                    (<Autocomplete
                      multiple
                      multiline
                      disabled={!proveedores}
                      size="small"
                      focused
                      required
                      disableClearable
                      id="proveedores"
                      noOptionsText={"Proveedor INEXISTENTE"}
                      options={proveedores}
                      autoHighlight
                      getOptionLabel={(proveedores) =>
                        proveedores.razon_social + "-" + proveedores.nro_cuit
                      }
                      onChange={(event, value) =>
                        setProveedorSeleccionadoEditar(value)

                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.razon_social} - {option.nro_cuit}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          focused
                          size="small"
                          placeholder="Tipee para buscar..."
                          {...params}
                          label="Agregar proveedor"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                    )}
                  </Grid>
              <Demo>
                <List dense={dense} style={{ backgroundColor: '#F4F6F6' }}>
                  {prSugeridos? prSugeridos.map((proveedor, index) => (
                    <ListItem key={index}>
                      <PersonIcon/>
                      <ListItemText
                        primary={<b>{proveedor.razon_social}</b> || "No disponible"}
                        secondary={proveedor.cuit || "No disponible"}
                      />
                      <IconButton 
                        onClick={() => borraRelacion(proveedor.id_proveedor)}
                        color="error">
                        <DeleteIcon/>
                      </IconButton>
                    </ListItem>
                  )): null}
                </List>
              </Demo>
          </Grid>
          <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="outlined" 
                        color="error"
                        onClick={handleCloseSugeridos}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="submit"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
          </DialogContentText>
        </DialogContent>
        </Box>
      </Dialog> 
      {definir? 
      <Dialog_definir
      definir={definir}
      datosArticulos={datosArticulos}
      ultimaRow={ultimaRow}
      onClose={() => handleCloseDefinir(false)}
      />
      :null}
    </>
  );
}

export default Articulos_cargas;