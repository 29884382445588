//----- Component --------------------------------------------------------/

// Developer : Daiana Curcio / Nicolas Pascucci

// Creation Date : 21/11/2023

// Version : 1

// Description : Contiene la estructura para poder ver el historial de todas las transacciones de la sucursal
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import { Container, Typography, IconButton, Grid } from "@mui/material";
import { createTheme, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";

// Boton desplegable
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

//Notification
import MuiAlert from "@mui/material/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import { lightBlue } from "@mui/material/colors";

//componentes
import Historial_conversion from "./Historial_conversion";
import Historial_consumo from "./Historial_consumo";
import Historial_ajuste from "./Historial_ajuste";
import Historial_reubicaciones from "./Historial_reubicaciones";
import Historial_todas from "./Historial_todas";
import Historial_transf from "./Historial_transf";
import Historial_ingreso from "./Historial_ingreso";
import Historial_salida from "./Historial_salida";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// para las opciones botones
const options = [
  "SALIDAS",
  "TRANSFERENCIAS",
  "INGRESOS",
  "REUBICACIONES",
  "AJUSTE DE STOCK",
  "CONSUMO",
  "CONVERSIÓN",
  "TODAS LAS TRANSACCIONES",
];
const optionsTr = ["ENVIADAS", "RECIBIDAS"];

function Articulos_salidas() {
  let navigate = useNavigate();
  //botones
  const location = useLocation();

  const [openButton, setOpenButton] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(7); // ESTE ES EL DEFAULT -> VAN TODAS LAS TRANSACCIONES

  const [datosSalida, SetDatosSalida] = useState({});
  const [tabla, setTabla] = useState(true); //salidas
  const [tabla2, setTabla2] = useState(true); //ingreso
  const [tabla3, setTabla3] = useState(true); //transf
  const [tabla4, setTabla4] = useState(true); //reubicaciones
  const [tabla5, setTabla5] = useState(true); //todas
  const [tabla6, setTabla6] = useState(true); //ajuste
  const [tabla7, setTabla7] = useState(true); //consumo
  const [tabla8, setTabla8] = useState(true); //conversion

  //*************************************************************************************************************************************/
  // Seleccionar uno del boton
  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpenButton(false);
    switch (index) {
      case 0:
     //   console.log("SALIDAS");
        setSelectedIndex(0);
        break;
      case 1:
    //    console.log("TRANSFERENCIAS");
        setSelectedIndex(1);
        break;
      case 2:
    //    console.log("INGRESOS");
        setSelectedIndex(2);
        break;
      case 3:
    //    console.log("REUBICACIONES");
        setSelectedIndex(3);
        break;
      case 4:
     //   console.log("AJUSTE DE STOCK");
        setSelectedIndex(4);
        break;
      case 5:
    //    console.log("CONSUMO");
        setSelectedIndex(5);
        break;
      case 6:
     //   console.log("CONVERSIÓN");
        setSelectedIndex(6);
        break;
      case 7:
       // console.log("TODAS LAS TRANSACCIONES");
        setSelectedIndex(7);
        break;
      default:
     //   console.log("Todas");
        setSelectedIndex(7);
        break;
    }
  };

  const handleToggle = () => {
    setOpenButton((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenButton(false);
  };
  //*************************************************************************************************************************************/
  const [todas, setTodas] = useState();
  const obtenerTodas = () => {
    fetch(
      UrlApi + "/stock_transacciones/todasprimeravista/" + location.state.id
    )
      .then((data) => data.json())
      .then((data) => setTodas(data));
  };

  useEffect(() => {
    obtenerTodas();
  }, []);
 // console.log("TODAS", todas);
  //*************************************************************************************************************************************/
  //funcion para obtener salidas
  const [salidas, setSalidas] = useState();
  const volverAtras = () => {
    navigate(-1);
  };
  const obtenerSalidas = () => {
    fetch(UrlApi + "/stock_transacciones/salida/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setSalidas(data));
  };

  //funcion que espera los cambios de la funcion
  useEffect(() => {
    obtenerSalidas();
  }, []);

  //*************************************************************************************************************************************/
  //funcion para obtener ingresos
  const [ingresos, setIngresos] = useState();

  const obtenerIngresos = () => {
    fetch(UrlApi + "/stock_transacciones/ingreso/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setIngresos(data));
  };

  //funcion que espera los cambios de la funcion
  useEffect(() => {
    obtenerIngresos();
  }, []);

  //*************************************************************************************************************************************/
  //funcion para obtener transacciones
  const [transferenciasEnviadas, setTransferenciasEnviadas] = useState();

  const obtenerTransferenciasEnviadas = () => {
    fetch(UrlApi + "/stock_transacciones/transferencia/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setTransferenciasEnviadas(data));
  };

  //funcion que espera los cambios de la funcion
  useEffect(() => {
    obtenerTransferenciasEnviadas();
  }, []);
  //*************************************************************************************************************************************/
  const [transferenciasRecibidas, setTransferenciasRecibidas] = useState();
  const obtenerTransferenciasRecibidas = () => {
    fetch(UrlApi + "/stock_transacciones/transferenciare/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setTransferenciasRecibidas(data));
  };

  //funcion que espera los cambios de la funcion
  useEffect(() => {
    obtenerTransferenciasRecibidas();
  }, []);
 // console.log("transferencias recibidas", transferenciasRecibidas);
  //*************************************************************************************************************************************/
  const [
    transferenciasRecibidasPendientes,
    setTransferenciasRecibidasPendientes,
  ] = useState();
  const obtenerTransferenciasRecibidasPendientes = () => {
    fetch(
      UrlApi +
        "/stock_transacciones/transferenciarependiente/" +
        location.state.id
    )
      .then((data) => data.json())
      .then((data) => setTransferenciasRecibidasPendientes(data));
  };

  //funcion que espera los cambios de la funcion
  useEffect(() => {
    obtenerTransferenciasRecibidasPendientes();
  }, []);
  //******************************************************************************************************************************************************//
  // OBTENER REUBICACIONES
  const [reubicaciones, setReubicaciones] = useState();

  const obtenerReubicaciones = () => {
    fetch(UrlApi + "/stock_transacciones/reubicacion/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setReubicaciones(data));
  };

  //funcion que espera los cambios de la funcion
  useEffect(() => {
    obtenerReubicaciones();
  }, []);
  // ******************************************************************************************************************************************************//
  // OBTENER AJUSTE DE STOCK
  const [ajusteStock, setAjusteStock] = useState();

  const obtenerAjusteStock = () => {
    fetch(UrlApi + "/stock_transacciones/ajusteStock/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setAjusteStock(data));
  };

  //funcion que espera los cambios de la funcion
  useEffect(() => {
    obtenerAjusteStock();
  }, []);
  // ******************************************************************************************************************************************************//
  // OBTENER CONSUMO
  const [consumo, setConsumo] = useState();

  const obtenerConsumo = () => {
    fetch(UrlApi + "/stock_transacciones/consumo/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setConsumo(data));
  };

  //funcion que espera los cambios de la funcion
  useEffect(() => {
    obtenerConsumo();
  }, []);
  // ******************************************************************************************************************************************************//
  // OBTENER CONVERSIÓN
  const [conversion, setConversion] = useState();

  const obtenerConversion = () => {
    fetch(UrlApi + "/stock_transacciones/conversion/" + location.state.id)
      .then((data) => data.json())
      .then((data) => setConversion(data));
  };

  //funcion que espera los cambios de la funcion
  useEffect(() => {
    obtenerConversion();
  }, []);
  //************************************TABLA**********************************************************************************/

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]);

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    sorting: [
      {
        id: "fecha_transaccion",
        desc: true,
      },
    ],
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  return (
    <div>
      <AppBar
        sx={{ position: "relative" }}
        style={{ backgroundColor: "#0979b0" }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={volverAtras}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Grid container>
            <Grid item xs={4.56}>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Historial de {location.state.nombre}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <ButtonGroup
                variant="outlined"
                style={{ backgroundColor: lightBlue[700], color: "white" }}
                color="inherit"
                ref={anchorRef}
                aria-label="split button"
              >
                <Button onClick={handleClick}>{options[selectedIndex]}</Button>
                <Button
                  style={{ backgroundColor: lightBlue[800], color: "white" }}
                  size="medium"
                  aria-controls={openButton ? "split-button-menu" : undefined}
                  aria-expanded={openButton ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={openButton}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              selected={index === selectedIndex}
                              onClick={(event) =>
                                handleMenuItemClick(event, index)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {/* SALIDAS */}
      <Container maxWidth="xl" mt={1}>
        {selectedIndex === 0 && salidas && tabla ? (
          <Historial_salida
            tableTheme={tableTheme}
            initialState={initialState}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            tableHeadCellProps={tableHeadCellProps}
            salidas={salidas}
            sucursal={location.state.id}
            nombreSuc={location.state.nombre}
          />
        ) : null}
      </Container>

      {/* INGRESO */}
      <Container maxWidth="xl" mt={1}>
        {selectedIndex === 2 && ingresos && tabla2 ? (
          <Historial_ingreso
            tableTheme={tableTheme}
            initialState={initialState}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            tableHeadCellProps={tableHeadCellProps}
            ingresos={ingresos}
            sucursal={location.state.id}
            nombreSuc={location.state.nombre}
          />
        ) : null}
      </Container>

      {/* TRANSFERENCIA */}
      <Container maxWidth="xl" mt={1}>
        {selectedIndex === 1 && transferenciasEnviadas && tabla3 ? (
          <Historial_transf
            tableTheme={tableTheme}
            initialState={initialState}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            tableHeadCellProps={tableHeadCellProps}
            transferenciasEnviadas={transferenciasEnviadas}
            transferenciasRecibidasPendientes={
              transferenciasRecibidasPendientes
            }
            transferenciasRecibidas={transferenciasRecibidas}
            sucursal={location.state.id}
            nombreSuc={location.state.nombre}
          />
        ) : null}
      </Container>

      {/* REUBICACIONES  */}
      <Container maxWidth="xl" mt={1}>
        {selectedIndex === 3 && reubicaciones && tabla4 ? (
          <Historial_reubicaciones
            tableTheme={tableTheme}
            initialState={initialState}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            tableHeadCellProps={tableHeadCellProps}
            reubicaciones={reubicaciones}
            sucursal={location.state.id}
            nombreSuc={location.state.nombre}
          />
        ) : null}
      </Container>

      {/* TODAS LAS TRANSFERENCIAS */}
      <Container maxWidth="xl" mt={1}>
        {selectedIndex === 7 && todas && tabla5 ? (
          <Historial_todas
            tableTheme={tableTheme}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            tableHeadCellProps={tableHeadCellProps}
            todas={todas}
            sucursal={location.state.id}
            nombreSuc={location.state.nombre}
          />
        ) : null}
      </Container>

      {/* AJUSTE DE STOCK */}
      <Container maxWidth="xl" mt={1}>
        {selectedIndex === 4 && ajusteStock && tabla6 ? (
          <Historial_ajuste
            tableTheme={tableTheme}
            initialState={initialState}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            tableHeadCellProps={tableHeadCellProps}
            ajusteStock={ajusteStock}
            sucursal={location.state.id}
            nombreSuc={location.state.nombre}
          />
        ) : null}
      </Container>

      {/* CONSUMO */}
      <Container maxWidth="xl" mt={1}>
        {selectedIndex === 5 && consumo && tabla7 ? (
          <Historial_consumo
            tableTheme={tableTheme}
            initialState={initialState}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            tableHeadCellProps={tableHeadCellProps}
            sucursal={location.state.id}
            consumo={consumo}
            nombreSuc={location.state.nombre}
          />
        ) : null}
      </Container>

      {/* CONVERSION */}
      <Container maxWidth="xl" mt={1}>
        {selectedIndex === 6 && conversion && tabla8 ? (
          <Historial_conversion
            tableTheme={tableTheme}
            initialState={initialState}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            tableHeadCellProps={tableHeadCellProps}
            sucursal={location.state.id}
            conversion={conversion}
            nombreSuc={location.state.nombre}
          />
        ) : null}
      </Container>
    </div>
  );
}

export default Articulos_salidas;
