import React, { useState, useEffect } from 'react';
import { UrlApi } from '../../../services/apirest';
import { Container, Autocomplete, FormControl, InputLabel, Select, MenuItem, Grid, Box, TextField, IconButton, Button, InputAdornment, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { grey } from '@mui/material/colors';
import PinIcon from '@mui/icons-material/Pin';
import moment from 'moment';
import Notify from '../../Notify'
import Alerta from "../../Alerta";
import CallIcon from '@mui/icons-material/Call';

function Editarchofer(props) {
    const [datosformulario, setDatosformulario] = useState(props.datos);
    const [negocio, setnegocio] = useState(null);
    const [usuarios, setUsuarios] = useState(null)
    const [disBtn, setDisBtn] = useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
    const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
    const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
    const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
    const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
    const [puntosVenta, setPuntosVenta] = useState([])
    const [puntoSeleccionado, setPuntoSeleccionado] = useState(null)
  
    // Fetch para traer puntos de ventas usado en el autocomplete
    const obtenerPuntos = () => {
      fetch(UrlApi + "/puntos_venta/seleccionpunto/"+datosformulario.id)
        .then((data) => data.json())
        .then((data) => setPuntosVenta(data));
    };

    useEffect(() => {
      obtenerPuntos()
    }, [])

    useEffect(() => {
      if (puntosVenta.length > 0) {
        const puntoEncontrado = puntosVenta.find((p) => p.id_punto === datosformulario.nro_puntoventa);
        setPuntoSeleccionado(puntoEncontrado || null);
      }
    }, [datosformulario.nro_puntoventa, puntosVenta]);


    const [codArg, setCodArg]=useState("")
    const getCodArg=()=>{
      fetch(UrlApi+"/desplegables/codArg")
      .then((data)=>data.json())
      .then((data)=>setCodArg(data[0].valor))
    }

    useEffect(()=>{
        setDatosformulario({
            ...props.datos,
    telefono: props.datos.telefono
      ? props.datos.telefono.replace(/^(\+?549)?(\d{10})$/, "$2")
      : "",
        })
    },[props.datos])


    
    const obtenerNegocio = async () => {
        const data = await fetch(UrlApi + '/desplegables/usoVehiculo')
        const negocioData = await data.json()
        setnegocio(negocioData)
    };

    const obtenerUsuarios = async() =>{
        await fetch(UrlApi +'/choferes/xusernameypuesto/'+ datosformulario.username )
        .then(data=> data.json())
        .then(data =>setUsuarios(data))
    };

    useEffect(() => {
        obtenerNegocio()
        obtenerUsuarios()
        getCodArg();
    }, []);

    const handleClose = () => {
        return (
            props.editar(false),
            props.tabla(true),
            props.actualizar(1)
        )
    };

    //Funcion para guardar Choferes
    const guardarChofer = async (e) => {
        setDisBtn(true)
        e.preventDefault()
        const response = await fetch(UrlApi + "/choferes/" + datosformulario.id, {
            method: 'PUT', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id_sisven: datosformulario.id_sisven,
                nombre_apellido: datosformulario.nombre_apellido,
                dni: datosformulario.dni,
                usuario: localStorage.getItem("user"),
                legajo: datosformulario.legajo,
                nro_licencia: datosformulario.nro_licencia,
                vencimiento_licencia: datosformulario.vencimiento_licencia !=="" ?datosformulario.vencimiento_licencia :null,
                examen_carga: datosformulario.examen_carga !== "" ? datosformulario.examen_carga  : null,
                negocio: datosformulario.negocio,
                username: datosformulario.username,
                telefono: datosformulario.telefono
                  ? codArg + datosformulario.telefono
                  : null,
                nro_puntoventa:puntoSeleccionado.id_punto,  
            })
        })
        if (response.ok === false) {
            console.log("ERROR EN API");
            const error = response && response.json();
            setError(error);
            setSeverityAlerta("error")
            setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            console.log("Conexión con api OK");
            try {
                //Parseo respuesta de la api a json()
                const json = await response.json();
                //si api me devuelve lo q le puse en message
                if (json.message) {
                    //Si ese mensaje es "OK"
                    if (json.message === "OK") {
                        console.log("Guardado Satisfactoriamente");
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setMensaje("Guardado");
                        setSeveritySnack("success");
                    } else {
                        console.log("res en api incorrecto. Revisar que sea 'OK'")
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setSeveritySnack("error");
                        setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
                    }
                } else if (json.sqlMessage) {
                    json && setError(json);
                    console.log(json);
                    setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
                    setNotificacion(true);
                    setOpenSnack(true);
                    setColorMensaje("#D4EFDF");
                    setMensaje("Guardado");
                    setSeveritySnack("success");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            }
        }
        handleClose();
    };

    //funcion para almacenar  
    const almacenar = (e) => {
        setDatosformulario({
            ...datosformulario,
            [e.target.name]: e.target.value === "" ? null: e.target.value
        });
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    const size="small"

    return (
      <div>
        <br></br>
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container backgroundColor="#1F618D" p={1.5}>
              <Grid item xs={2}>
                <IconButton onClick={handleClose}>
                  <ArrowBackIcon sx={{ color: grey[50] }} />
                </IconButton>
              </Grid>

              <Grid
                item
                xs={8}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h5" color="#ffffff">
                  Editar chofer
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <form onSubmit={guardarChofer} autoComplete="off">
            <Box
              backgroundColor="#F4F6F6"
              mt={2}
              p={3}
              border={0.5}
              borderColor={"#ABB2B9"}
              borderRadius={4}
            >
              <Grid
                container
                spacing={1}
                direction={{ xs: "column", sm: "row" }}
              >
                <Grid item xs={6}>
                  <TextField
                    size={size}
                    placeholder="Nombre completo"
                    style={style}
                    focused
                    color="primary"
                    required
                    name="nombre_apellido"
                    label="Nombre completo"
                    variant="outlined"
                    value={
                      datosformulario.nombre_apellido &&
                      datosformulario.nombre_apellido.replace(/\b\w/g, (l) =>
                        l.toUpperCase()
                      )
                    }
                    inputProps={{ maxLength: 100 }}
                    onChange={almacenar}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessibilityNewIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    size={size}
                    style={style}
                    focused
                    required
                    color="primary"
                    placeholder="ID de Sisven"
                    type="number"
                    name="id_sisven"
                    label="ID SISVEN"
                    variant="outlined"
                    value={datosformulario.id_sisven}
                    onChange={almacenar}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountBoxIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    size={size}
                    focused
                    margin="dense"
                    placeholder="DNI"
                    color="primary"
                    style={style}
                    type="number"
                    required
                    name="dni"
                    label="DNI"
                    variant="outlined"
                    value={datosformulario.dni}
                    onChange={almacenar}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    size={size}
                    style={style}
                    focused
                    margin="dense"
                    required
                    placeholder="Legajo"
                    type="number"
                    name="legajo"
                    label="Legajo"
                    variant="outlined"
                    value={datosformulario.legajo}
                    onChange={almacenar}
                    inputProps={{ maxLength: 45 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    size={size}
                    style={style}
                    focused
                    margin="dense"
                    placeholder="Nro licencia"
                    type="number"
                    required
                    name="nro_licencia"
                    label="Nro licencia"
                    variant="outlined"
                    value={datosformulario.nro_licencia}
                    onChange={almacenar}
                    inputProps={{ maxLength: 45 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PinIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    size={size}
                    style={style}
                    focused
                    margin="dense"
                    type="date"
                    placeholder="Vencimiento licencia"
                    name="vencimiento_licencia"
                    label="Vencimiento licencia"
                    variant="outlined"
                    value={moment(datosformulario.vencimiento_licencia).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={almacenar}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InsertInvitationIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    size={size}
                    style={style}
                    focused
                    margin="dense"
                    type="date"
                    placeholder="Exámen cargas"
                    name="examen_carga"
                    label="Examen cargas"
                    variant="outlined"
                    value={moment(datosformulario.examen_carga).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={almacenar}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl
                    style={style}
                    size={size}
                    focused
                    color="primary"
                    required
                    margin="dense"
                  >
                    <InputLabel id="lbl_negocio">Negocio</InputLabel>
                    <Select
                      margin="dense"
                      name="negocio"
                      label="Negocio "
                      value={datosformulario.negocio}
                      onChange={almacenar}
                    >
                      {negocio
                        ? negocio.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.valor}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    style={style}
                    focused
                    color="primary"
                    margin="dense"
                    size={size}
                  >
                    <InputLabel id="username">Username</InputLabel>
                    <Select
                      margin="dense"
                      name="username"
                      label="Username "
                      value={datosformulario.username}
                      onChange={almacenar}
                    >
                      {usuarios
                        ? usuarios.map((elemento) => (
                            <MenuItem
                              key={elemento.username}
                              value={elemento.username}
                            >
                              {elemento.username}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} mt={1}>
                  <Autocomplete
                  // disabled={!puntosVenta}
                  size={size}
                  // margin="dense"
                  value={puntoSeleccionado}
                  focused
                  required
                  disableClearable
                  id="puntoventa"
                  noOptionsText={"Punto de Venta NO DISPONIBLE"}
                  options={puntosVenta}
                  autoHighlight
                  isOptionEqualToValue={(option, value) =>
                    option.id_punto === (value && value.id_punto)
                  }
                  getOptionLabel={(option) => `${option.id_punto} - Suc. ${option.nombre}`}
                  onChange={(event, value) =>
                    setPuntoSeleccionado(value)
                  }
                  renderOption={(props, option) => (
                      <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                      >
                          {option.id_punto} - Suc. {option.nombre}
                      </Box>
                  )}
                  renderInput={(params) => (
                      <TextField
                          focused
                          size={size}
                          // margin="dense"
                          required
                          color="primary"
                          style={style}
                          variant="outlined"
                          placeholder="Tipee para buscar..."
                          {...params}
                          label="Punto de Venta"
                          inputProps={{
                              ...params.inputProps,
                          }}
                      />
                  )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    size={size}
                    focused
                    margin="dense"
                    placeholder="Teléfono"
                    color="primary"
                    style={style}
                    type="number"
                    required
                    name="telefono"
                    label="Teléfono"
                    variant="outlined"
                    value={datosformulario.telefono}
                    onChange={almacenar}
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {codArg}
                        </InputAdornment>
                      ),
                    }}
                    helperText="Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Grid>
                
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
                mt={2}
              >
                <Button variant="contained" onClick={handleClose} color="error">
                  Volver
                </Button>

                <Button
                  disabled={disBtn}
                  variant="contained"
                  type="submit"
                  color="success"
                >
                  Guardar Chofer
                </Button>
              </Grid>
            </Box>
          </form>
        </Container>
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={colormensaje}
            finalizar={setFinalizar}
            severity={severitySnack}
          />
        ) : null}
        {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
        {error && mensajeAlerta ? (
          <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
        ) : null}
      </div>
    );
}

export default Editarchofer