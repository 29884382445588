//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 05/08/2024
// Version : 1
// Description : dialog de expancion de graficos por motivo
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState } from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

// Define a set of colors for the chart
const colors = [
  "#B8C1AE",
  "#F1A095",
  "#F1D295",
  "#A8CEF2",
  "#90B2EE",
  "#E7EEAA",
  "#AAEEEA",
  "#71F1EA",
  "#EECAD7",
  "#EBA3BC",
  "#D0F1B4",
  "#A6D7A6",
  "#8AB5A9",
  "#F25C47",
  "#4B7EB6",
  "#80B0EC",
  "#BA9BBD",
  "#C7DBD4",
];

function Dialog_motivos(props) {
  const [abrirDialog, setAbrirDialog] = useState(props.abrirDialog);

  const handleClose = () => {
    setAbrirDialog(!abrirDialog);
    if (props.onClose) {
      props.onClose();
    }
  };

  // Función para renderizar la leyenda
  const renderLegend = (data) => (
    <Box>
      {data.map((item, index) => (
        <Box key={index} display="flex" alignItems="center" marginBottom={1}>
          <Box
            style={{
              width: 20,
              height: 20,
              backgroundColor: colors[index % colors.length],
              marginRight: 10, // Espacio entre el color y la etiqueta
            }}
          />
          <Typography variant="body2">{item.label}</Typography>
        </Box>
      ))}
    </Box>
  );

  // Map colors to data
  const dataWithColors = props.data.map((item, index) => ({
    ...item,
    color: colors[index % colors.length],
  }));

  return (
    <div>
      <Dialog fullScreen open={abrirDialog} onClose={handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Porcentajes de los motivos de los reclamos
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Cerrar
            </Button>
          </Toolbar>
        </AppBar>
        {/* <Typography
          align="center"
          marginTop={2}
          style={{ fontWeight: "bold", fontSize: 20 }}
        >
          Porcentaje de motivos
        </Typography> */}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          padding={4}
        >
          <Box>
            <PieChart
              series={[
                {
                  data: dataWithColors,
                  highlightScope: { faded: "global", highlighted: "item" },
                  faded: {
                    innerRadius: 30,
                    additionalRadius: -30,
                    color: "gray",
                  },
                },
              ]}
              slotProps={{
                legend: {
                  hidden: true, // Oculta la leyenda predeterminada
                },
              }}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: "white",
                  fontWeight: "bold",
                },
              }}
              width={600}
              height={350}
            />
          </Box>
          <Box marginLeft={4}>{renderLegend(dataWithColors)}</Box>
        </Box>
      </Dialog>
    </div>
  );
}

export default Dialog_motivos;
