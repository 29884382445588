import React, { useEffect, useState, useMemo } from 'react';
import { Container, Typography, Tooltip, IconButton, Box, Button, Dialog, DialogContentText, DialogContent, DialogActions, Grid, DialogTitle, MenuItem, Divider, ListItemIcon } from '@mui/material';
import Navbar from '../../Navbar';
import MaterialReactTable, { MRT_FullScreenToggleButton } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import Addvehiculos from './Addvehiculos';
import Notify from '../../Notify';
import Editarvehiculos from './Editarvehiculos';
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckIcon from '@mui/icons-material/Check';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { getData, putData } from '../../../services/library';
import { logEventos } from '../../../services/services';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export default function Vehiculos() {
    const [vehiculos, setVehiculos] = useState(); //aca se almacenan los datos que me trae la api
    const [alta, setAlta] = useState(false); //Estado q mostrará o no la pantalla de alta
    const [tabla, setTabla] = useState(true); //Estado q mostrará o no la tabla (pantalla inicial) dnd estan los choferes
    const [editar, setEditar] = useState(false);  //Estado q mostrará o no el form de edicion de choferes
    const [confirmarActivo, setConfirmarActivo] = useState(false);
    const [confirmarInactivo, setConfirmarInactivo] = useState(false);
    const [datosformulario, setDatosformulario] = useState({});
    const iconRemove = <RemoveCircleOutlineIcon sx={{ fontSize: 40 }} />;
    const iconCheck = <CheckIcon sx={{ fontSize: 40 }} />;
    const globalTheme = useTheme();
    const [notify, setNotify] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });
    const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
    const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);

    const obtenervehiculos = () => {
        setHiddenmuestraactivos(true);
        setHiddenmuestrainactivo(false);
        getData('/vehiculos/activos', setVehiculos)
    };

    const obtenerinactivos = () => {
        setHiddenmuestrainactivo(true);
        setHiddenmuestraactivos(false);
        getData('/vehiculos/inactivos', setVehiculos)
    };

    useEffect(() => {
        obtenervehiculos();
    }, [tabla]);

    const handleClickNuevo = () => {
        setAlta(true)
        setTabla(false)
    };

    const mostrareditar = (datosDeLaLinea) => {
        setEditar(true);
        setTabla(false);
        setDatosformulario(datosDeLaLinea);
    };

    const handleClose = () => {
        setTabla(true)
        setConfirmarActivo(false);
        setConfirmarInactivo(false);
        confirmarActivo && obtenerinactivos()
        confirmarInactivo && obtenervehiculos()
    };

    const columns = useMemo(() =>
        [{ header: 'Sisven', accessorKey: 'id_sisven', size: 95 },
        // { header: 'Marca', accessorKey: 'marca' },
        //{ header: 'Modelo', accessorKey: 'modelo' },
        // { header: 'Capacidad', accessorKey: 'capacidad',size:110 },
        { header: 'Patente', accessorKey: 'patente', size: 95 },
        // { header: 'Tipo', accessorKey: 'tipovehiculos', size: 100 },
        { header: 'Sucursal', accessorKey: 'nombre_sucursal', size: 100 },
        { header: 'Chofer', accessorKey: 'chofer' },
        { header: 'Vto VTV', accessorKey: 'vencimientovtv', type: "date", format: "DD-MM-YYYY" },
        { header: 'Vto Hidraulica', accessorKey: 'vencimientohidraulica', type: "date", format: "DD-MM-YYYY" },
        { header: 'Uso', accessorKey: 'usovehiculos', size: 100 },
        { header: 'Dueño', accessorKey: 'dueniovehiculo', size: 100 },
        ], []);

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme]);

    const muiSearchTextFieldProps = {
        placeholder: "Buscar..",
        sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
        variant: "outlined",
        color: "success",
        size: "small",
    };

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    async function marcarInactivo(e) {
        putData(e,
            "/vehiculos/" + datosformulario.idvehiculo,
            {
                activo: 0
            },
            setNotify,
            logEventos("Marcar vehiculos como inactivo", "Vehiculos", "Vehiculos", localStorage.getItem('user'))
        )
    };

    async function marcarActivo(e) {
        putData(e,
            "/vehiculos/" + datosformulario.idvehiculo,
            {
                activo: 1
            },
            setNotify,
            logEventos("Marcar vehiculos como activo", "Vehiculos", "Vehiculos", localStorage.getItem('user'))
        )
    };

    const tableHeadCellProps = {
        align: "center",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };


    const abrirModalConfirmarActivo = (datosDeLaLinea) => {
        setConfirmarActivo(true);
        setDatosformulario(datosDeLaLinea);
    };

    const abrirModalConfirmarInactivo = (datosDeLaLinea) => {
        setConfirmarInactivo(true);
        setDatosformulario(datosDeLaLinea);
    };

    return (
        <div>
            <Navbar />

            {vehiculos && tabla ? (
                <Container maxWidth="xl" >
                    <Box mt={2} boxShadow={3}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableColumnResizing
                                columns={columns}
                                data={vehiculos}
                                enableRowActions
                                initialState={initialState}
                                positionActionsColumn="last"
                                muiTableHeadCellProps={tableHeadCellProps}
                                localization={MRT_Localization_ES}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "left",
                                    sx: {
                                        backgroundColor:
                                            row.original.activo === 1 ? "#b3e6b3" : "#efc3c3",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                muiSearchTextFieldProps={muiSearchTextFieldProps}
                                enableDensityToggle={false}
                                layoutMode="grid"
                                renderTopToolbarCustomActions={() => (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddBoxTwoToneIcon />}
                                            onClick={handleClickNuevo}
                                        >
                                            Nuevo Registro
                                        </Button>
                                        <Typography variant="poster" fontSize={26}>
                                            <LocalShippingIcon sx={{ size: 20 }} />
                                            Vehiculos
                                        </Typography>
                                    </>
                                )}
                                renderToolbarInternalActions={({ table }) => (
                                    <>
                                        {!hiddenmuestraactivos ?
                                            <Tooltip arrow placement="right" title="Ver vehiculos activos" >
                                                <IconButton color="success" onClick={() => {
                                                    obtenervehiculos();
                                                }}>
                                                    <PersonIcon />
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        {!hiddenmuestrainactivo ?
                                            <Tooltip arrow placement="right" title="Ver vehiculos inactivos" >
                                                <IconButton color="error" onClick={() => {
                                                    obtenerinactivos();
                                                }}>
                                                    <PersonOffIcon />
                                                </IconButton>
                                            </Tooltip>
                                            : null}


                                        <MRT_FullScreenToggleButton table={table} />
                                    </>
                                )}
                                renderRowActionMenuItems={({ closeMenu, row }) => (
                                    <>
                                        <MenuItem
                                            key={0}
                                            onClick={() => {
                                                mostrareditar(row.original)
                                                closeMenu();
                                            }}>
                                            <ListItemIcon>
                                                <EditIcon color='primary' />
                                            </ListItemIcon>
                                            Editar vehiculo
                                        </MenuItem>
                                        <Divider />
                                        {(row.original.activo === 0) ?
                                            <MenuItem
                                                key={1}
                                                onClick={() => {
                                                    abrirModalConfirmarActivo(row.original)
                                                    closeMenu()
                                                }}>
                                                <ListItemIcon>
                                                    <CheckCircleOutlineIcon color="success" />
                                                </ListItemIcon>
                                                Marcar como activo
                                            </MenuItem>
                                            :
                                            <MenuItem
                                                key={2}
                                                onClick={() => {
                                                    abrirModalConfirmarInactivo(row.original)
                                                    closeMenu()
                                                }}>
                                                <ListItemIcon>
                                                    <BlockIcon color="error" />
                                                </ListItemIcon>
                                                Marcar como inactivo
                                            </MenuItem>
                                        }
                                    </>
                                )}
                            />
                        </ThemeProvider>
                    </Box>
                </Container>
            ) : null}

            {confirmarActivo && (
                <ConfirmDialog
                    title={"Activar vehiculo"}
                    subtitle={"¿Desea cambiar el estado del vehiculo como activo?"}
                    open={confirmarActivo}
                    setOpen={setConfirmarActivo}
                    notify={notify}
                    setNotify={setNotify}
                    btnText={"Aprobar"}
                    guardar={marcarActivo}
                    color={"#2ECC71"}
                    icon={iconCheck}
                // actualizar={obtenerchoferes}
                />
            )}

            {confirmarInactivo && (
                <ConfirmDialog
                    title={"Desactivar vehiculo"}
                    subtitle={"¿Desea cambiar el estado del vehiculo como inactivo?"}
                    open={confirmarInactivo}
                    setOpen={setConfirmarInactivo}
                    notify={notify}
                    setNotify={setNotify}
                    btnText={"Confirmar"}
                    guardar={marcarInactivo}
                    color={"#f53649"}
                    icon={iconRemove}
                // actualizar={obtenerinactivos}
                />
            )}

            {alta ? <Addvehiculos alta={setAlta} tabla={setTabla} ></Addvehiculos> : null}

            {editar ? <Editarvehiculos editar={setEditar} tabla={setTabla} datos={datosformulario}></Editarvehiculos> : null}
            {notify && notify.open ? (
                <Notify
                    mensaje={notify.mensaje}
                    open={notify.open}
                    color={notify.color}
                    handleClose={handleClose}
                    severity={notify.severity}
                />
            ) : null}

        </div>
    )

}