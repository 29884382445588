import React, { useState, useEffect, useMemo } from 'react';
import { UrlApi } from '../../../../services/apirest';
import moment from 'moment';
import { Grid, ListItemIcon, MenuItem, Skeleton, Dialog, DialogContent, TextField, DialogActions, Button, Stack, IconButton, Typography, Container, Box, CardHeader, Avatar, Tabs } from '@mui/material';
import { grey } from "@mui/material/colors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import MaterialReactTable from "material-react-table";
import DatosRep from './DatosRep';
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SalidaReparto from './SalidaReparto';
import LlegadaReparto from './LlegadaReparto'
import EventNoteIcon from '@mui/icons-material/EventNote';
import Modalagregarpedidos from './Modalagregarpedidos';
import Notify from '../../../Notify';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import ModalMapa from './ModalMapa';

export default function Verrepartos4(props) {
    const [repartos, setRepartos] = useState(null);
    const globalTheme = useTheme();
    const [salida, setSalida] = useState(false);
    const [llegada, setLlegada] = useState(false);
    const [datosRep, setDatosrep] = useState({});
    const [agregarPedidos, setAgregarPedidos] = useState(false);
    const [editarFecha, seteditarFecha] = useState(false);
    const [fechaSalida, setFechasalida] = useState();
    const [modalMapa, setModalMapa] = useState(false);
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
    const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)  
    let autorizadas = localStorage.getItem('suc_auth');

    const obtenerRepartosxSucursal = () => {
        fetch(UrlApi + '/repartos')
            .then(data => data.json())
            .then(data => {
                let permitidas = data.filter((item) => autorizadas.includes(item.id_sucursal))
                setRepartos(permitidas)
            })
    };

    useEffect(() => {
        obtenerRepartosxSucursal()
    }, [autorizadas]);


    const handleClose = () => {
        props.setpedidosSucursales(true)
        props.setVerReparto(false)
        props.setMostrarSucursales(true)
    };

    const columns = useMemo(() =>
        [{ header: 'Reparto', accessorKey: 'idreparto' },
        { header: 'Vehiculo', accessorKey: 'patente' },
        // { header: 'Reparte', accessorKey: 'chofer', enableGrouping: false, size: 180, },
        { header: 'Fecha de Salida ', accessorFn: (repartos) => moment(repartos.fecha_salida_reparto).format("DD-MM-YYYY"), },
        { header: 'Estado', accessorKey: 'estado_reparto' },
        { header: 'Sucursal', accessorKey: 'sucursal_atiende', size: 120 },
        ], []);

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme])

    const tableHeadCellProps = {
        align: "left",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            // paddingTop: 1,
            // paddingBottom: 1,
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const rowBg = {
        6: "#ffff80",
        // 8: "#ccebff",
        9: "#80ffaa"
    };

    const openSalida = (datosRep) => {
        setSalida(true)
        setDatosrep(datosRep)
    };

    const openLlegada = (datosRep) => {
        setLlegada(true)
        setDatosrep(datosRep)
    };

    const openModalAgregarPedidos = (datosRep) => {
        setAgregarPedidos(true)
        setDatosrep(datosRep)
    };

    const openModalCambiarFecha = (id) => {
        seteditarFecha(true)
        setDatosrep(id)
        console.log("datosrep en cambiar fwecha", datosRep)
    };

    const openModalMapa = (datosRep) => {
        setModalMapa(true)
        setDatosrep(datosRep)

    };

    // console.log("openmodalmapa", modalMapa)

    const cambiarFechaSalida = async (e) => {
        let response = await fetch(UrlApi + '/repartos/' + datosRep.idreparto, {
            method: 'PUT',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ fecha_salida_reparto: fechaSalida })
        })
        if (response.ok === false) {
            const error = response && response.json();
            setError(error);
            alert("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            try {
                const json = await response.json();
                if (json.message) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje(
                        json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
                    );
                    obtenerRepartosxSucursal()
                } else if (json.sqlMessage) {
                    json && setError(json);
                    alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    alert("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje("Guardado");
                    obtenerRepartosxSucursal()
                } else {
                    alert("Error: " + error);
                }
            }
        }
        seteditarFecha(false)
    };

    return (
        <div>
            <Container >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container backgroundColor="#1F618D" p={2}>
                        <Grid item xs={2}>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon sx={{ color: grey[50] }} />
                            </IconButton>
                        </Grid>
                        <Grid
                            item
                            xs={10}
                            mb={1}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h5" color="#ffffff">
                                Repartos asignados por sucursal
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                
                {repartos &&
                    <Box mb={3} boxShadow={3} mt={2}>
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                enableColumnResizing
                                columns={columns}
                                data={repartos}
                                enableRowActions
                                initialState={initialState}
                                positionActionsColumn='last'
                                muiTableHeadCellProps={tableHeadCellProps}
                                localization={MRT_Localization_ES}
                                muiTableBodyCellProps={({ row }) => ({
                                    align: "left",
                                    sx: {
                                        backgroundColor: rowBg[row.original.estado] ?? "#fff",
                                        color: "black",
                                        borderColor: "black",
                                    },
                                })}
                                enableColumnActions={false}
                                enableDensityToggle={false}
                                layoutMode="grid"
                                renderRowActionMenuItems={({ closeMenu, row }) => [                                  
                                    row.original.estado === 8 ?  (
                                        <>
                                    <MenuItem
                                                key={0}
                                                onClick={() => {
                                                    openLlegada(row.original)
                                                    closeMenu();
                                                }}
                                                sx={{ m: 0 }}
                                            >
                                                <ListItemIcon>
                                                    <LocalShippingIcon color="warning" />
                                                </ListItemIcon>
                                                Datos de llegada
                                            </MenuItem>
                                            <MenuItem
                                                key={1}
                                                onClick={() => {
                                                    openModalAgregarPedidos(row.original)
                                                    closeMenu();
                                                }}
                                                sx={{ m: 0 }}
                                            >
                                                <ListItemIcon>
                                                    <EventNoteIcon color="success" />
                                                </ListItemIcon>
                                                Agregar pedidos
                                            </MenuItem>    
                                            </>
                                            )                                    
                                        : null,
                                    row.original.estado === 6 ? 
                                    (
                                    <><MenuItem
                                                key={2}
                                                onClick={() => {
                                                    openSalida(row.original);
                                                    closeMenu();
                                                }}
                                                sx={{ m: 0 }}
                                            >
                                                <ListItemIcon>
                                                    <LocalShippingIcon color='primary' />
                                                </ListItemIcon>
                                                Datos de salida
                                            </MenuItem>,
                                            <MenuItem
                                                key={3}
                                                onClick={() => {
                                                    openModalAgregarPedidos(row.original)
                                                    closeMenu();
                                                }}
                                                sx={{ m: 0 }}
                                            >
                                                <ListItemIcon>
                                                    <EventNoteIcon color="success" />
                                                </ListItemIcon>
                                                Agregar pedidos
                                            </MenuItem>,
                                            <MenuItem
                                                key={4}
                                                onClick={() => {
                                                    openModalCambiarFecha(row.original)
                                                    closeMenu();
                                                }}
                                                sx={{ m: 0 }}
                                            >
                                                <ListItemIcon>
                                                    <EditCalendarIcon />
                                                </ListItemIcon>
                                                Cambiar fecha de salida
                                            </MenuItem>
                                            </>)
                                     : null,
                                    <MenuItem
                                        key={5}
                                        onClick={() => {
                                            openModalMapa(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}
                                    >
                                        <ListItemIcon>
                                            <PersonPinCircleIcon color="secondary" />
                                        </ListItemIcon>
                                        Mapa de pedidos
                                    </MenuItem>,
                                ]}
                                renderDetailPanel={({ row }) => (
                                    <Box>
                                        <DatosRep datos={row.original} />
                                    </Box >
                                )}
                                enableExpandAll={false}
                                enableToolbarInternalActions={true}
                                enableGrouping
                                enableColumnFilters={false} //desactiva filtros x  columna
                                enableHiding={false}
                                enableColumnDragging={false} //No permite que se arrastren las columnas.
                                positionExpandColumn='first'
                                enableStickyFooter
                                enableFullScreenToggle={false}
                                enableBottomToolbar={false}
                                enableTopToolbar={false}
                                enableStickyHeader />
                        </ThemeProvider>
                    </Box>
                }
            </Container>
            {modalMapa ? <ModalMapa datos={datosRep} open={modalMapa} setOpen={setModalMapa} /> : null}
            {salida ? <SalidaReparto open={setSalida} datos={datosRep} actualizar={obtenerRepartosxSucursal}  ></SalidaReparto> : null}
            {llegada ? <LlegadaReparto open={setLlegada} datos={datosRep} actualizar={obtenerRepartosxSucursal}  ></LlegadaReparto> : null}
            {agregarPedidos ? <Modalagregarpedidos open={setAgregarPedidos} datos={datosRep} actualizar={obtenerRepartosxSucursal}></Modalagregarpedidos> : null}
            {editarFecha ?
                <Dialog open={editarFecha} onClose={() => seteditarFecha(false)}>
                    <DialogContent>
                        <TextField
                            label="Fecha Salida"
                            focused
                            color="primary"
                            type='date'
                            name="fecha_salida_reparto"
                            value={fechaSalida ? moment(fechaSalida).format('YYYY-MM-DD') : moment(datosRep.fecha_salida_reparto).format("DD-MM-YYYY")}
                            onChange={(e) => setFechasalida(e.target.value)}
                            inputProps={{
                                min: moment(new Date()).format("YYYY-MM-DD")
                            }}
                        >
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='outlined' color="success" onClick={cambiarFechaSalida}>
                            Guardar
                        </Button>
                    </DialogActions>
                </Dialog>
                : null}

            {
                notificacion ? (
                    <Notify
                        mensaje={mensaje}
                        open={openSnack}
                        color={"#D4EFDF"}
                        severity={"success"}
                        handleClose={() => seteditarFecha(false)}
                    />
                ) : null
            }
        </div>
    )
}
