//----- Component --------------------------------------------------------/

// Developer :  Daiana Curcio / Candela Grosso

// Creation Date :14/12/2023

// Version : 1

// Description : Pantalla para ver los reclamos segun sean : *Pendientes, *Asignados o *Finalizados.

//

//------Changes --------------------------------------------------------------------- -------------//
// Change Date :11-3-2024
// Change by : Magali Perea
// Change description:
//  - Se añadió la propiedad "size" a algunas columnas de la tabla.
//  - Se añadió la modificación a los detalles del reclamo.
//  - Se amplió la longitud de "detalles" a 500 caracteres en el alta de un nuevo reclamo
//  - Se quitó la opción para cambiar directamente el "a cargo", ya que ahora está incluida en la edición.
//  - Se añade la columna "instaladores" y se quitó la de "a cargo"

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  Box,
  Typography,
  ListItemIcon,
  Tooltip,
  MenuItem,
  Button,
} from "@mui/material";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import { AlignHorizontalLeft, Build } from "@mui/icons-material";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import MaterialReactTable from "material-react-table";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import moment from "moment";
import AsignarTecnico from "./AsignarTecnico";
import Detallereclamo from "./DetalleReclamo";
import FinalizarReclamo from "./FinalizarReclamo";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CancelarReclamo from "./CancelarReclamo";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EditIcon from "@mui/icons-material/Edit";
//adjunto
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR
import FileUploadReclamo from "./FileUploadReclamo"; //ADJUNTAR
import { v4 as uuidv4 } from "uuid";
import A_cargo from "./A_cargo";
import EditarReclamo from "./EditarReclamo";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Facturado from "./Facturado";
import { columnMenuStateInitializer } from "@mui/x-data-grid/internals";

export default function Reclamos(props) {
  const [uuid, setUuid] = useState(uuidv4());
  const [reclamos, setReclamos] = useState();
  const [tabla, setTabla] = useState(true);
  const globalTheme = useTheme();
  const [asignar, setAsignar] = useState(false);
  const [dataReclamo, setDataReclamo] = useState({});
  const [finalizar, setFinalizar] = useState(false);
  const [cancelar, setCancelar] = useState(false);
  const [openCargo, setOpenCargo] = useState(false);
  const [showEditar, setShowEditar] = useState(false);
  const [facturar, setFacturar] = useState(false);
  const [cantidadAbiertos, setCantidadAbiertos] = useState()
  const[activeTab,setActiveTab]=useState(props.activeTab)
//console.log("ACTIVE TAP EN RECLAMOS", activeTab);
  // adjuntos
  const [newUuid, setNewUuid] = useState(""); //ADJUNTAR COMPROBANTE
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR COMPROBANTE

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  const obtenerReclamos = (estado) => {
    fetch(UrlApi + "/reclamos/estado/" + estado)
      .then((data) => data.json())
      .then((data) => setReclamos(data));
  };

  const obtenerReclamosInstalaciones = (estado) => {
    fetch(UrlApi + "/reclamos/instalaciones/")
      .then((data) => data.json())
      .then((data) => setReclamos(data));
  };

  const obtenerCantidadInstalacionesAbiertas = (estado) => {
    fetch(UrlApi + "/reclamos/instalacionesAbiertas/")
      .then((data) => data.json())
      .then((data) => setCantidadAbiertos(data));
  };

  useEffect(() => {
    obtenerCantidadInstalacionesAbiertas()
  }, [])
  
  //console.log("Abiertos", cantidadAbiertos);

  const getReclamos = () => {
    switch (props.activeTab) {
      case 0:
        obtenerReclamos(192);
        break;

      case 1:
        obtenerReclamos(170);
        break;

      case 2:
        obtenerReclamos(171);
        break;

      case 3:
        obtenerReclamos(223);
        break;

      case 4:
        obtenerReclamosInstalaciones();
        break;

      default:
        console.log("Error");
        break;
    }
  };

 // console.log("reclamos", reclamos)

  useEffect(() => {
    setActiveTab(props.activeTab)
    getReclamos();
  }, [props.activeTab, tabla]);
  // PARA LOS ESTADOS 170 -asignado,223-cancelado
  const getColumns = (reclamos) => {
    let columns = [
      { header: "OT", accessorKey: "idreclamo", maxSize: 50 },
      {
        header: "Cliente",
        accessorFn: (row) => `${row.id_cliente} - ${row.razon_social}`,
        size: 340
      },
      {
        header: "Registrado",
        accessorFn: (reclamos) =>
          moment(reclamos.created_at).format("DD-MM-YYYY"),
        size: 90,
      },
      { header: "Prioridad", accessorKey: "valor_prioridad", size: 90 },
      { header: "Instalador", accessorKey: "proveedor", size: 90 },
    ];
    return columns;
  };

  const columns = useMemo(() => getColumns(reclamos), [reclamos]);

  // PARA EL ESTADO 171 - finalizado
  const getColumns3 = (reclamos) => {
    let columns3 = [
      { header: "OT", accessorKey: "idreclamo", maxSize: 50 },
      {
        header: "Cliente",
        accessorFn: (row) => `${row.id_cliente} - ${row.razon_social}`,
        size: 340
      },
      {
        header: "Registrado",
        accessorFn: (reclamos) =>
          moment(reclamos.created_at).format("DD-MM-YYYY"),
        size: 90,
      },
      { header: "Prioridad", accessorKey: "valor_prioridad", size: 90 },
      { header: "Instalador", accessorKey: "proveedor", size: 90 },
      {
        header: "Finalizado",
        accessorFn: (reclamos) =>
          moment(reclamos.fecha_finalizado).format("DD-MM-YYYY"),
        size: 90,
      },
      { header: "Facturado", accessorKey: "facturado", size: 90 },
    ];
    return columns3;
  };

  const column3 = useMemo(() => getColumns3(reclamos), [reclamos]);

  //SOLO INSTALACION ID_MOTIVO =19
  const getColumns2 = (reclamos) => {
    let columns2 = [
      { header: "OT", accessorKey: "idreclamo", maxSize: 50 },
      {
        header: "Cliente",
        accessorFn: (row) => `${row.id_cliente} - ${row.razon_social}`,
        size: 340
      },
      {
        header: "Registrado",
        accessorFn: (reclamos) =>
          moment(reclamos.created_at).format("DD-MM-YYYY"),
        size: 90,
      },
      { header: "Prioridad", accessorKey: "valor_prioridad", size: 90 },
      { header: "Instalador", accessorKey: "proveedor", size: 100 },
      { header: "Estado", accessorKey: "valor_estado", size: 85 },
      { header: "Facturado", accessorKey: "facturado", size: 85 },
      {
        header: "Fecha",
        accessorKey: "fecha_finalizado",
        size: 90,
      },
    ];
    return columns2;
  };
  const columns2 = useMemo(() => getColumns2(reclamos), [reclamos]);

  // PARA LOS ESTADOS 192- pendiente
  const getColumns1 = (reclamos) => {
    let columns1 = [
      { header: "OT", accessorKey: "idreclamo", maxSize: 50 },
      {
        header: "Cliente",
        accessorFn: (row) => `${row.id_cliente} - ${row.razon_social}`,
        size: 340
      },
      {
        header: "Registrado",
        accessorFn: (reclamos) =>
          moment(reclamos.created_at).format("DD-MM-YYYY"),
        size: 90,
      },
      { header: "Prioridad", accessorKey: "valor_prioridad", size: 90 },
    ];
    return columns1;
  };

  const columns1 = useMemo(() => getColumns1(reclamos), [reclamos]);

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    sorting:[{id:"idreclamo", desc: true}],
    columnPinning: { right: ['mrt-row-actions'] }
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  //  filtra las columnas según el estado y el motivo
  let filteredColumns = [];
  if (reclamos) {
    if (props.activeTab === 1 || props.activeTab === 3) {
      filteredColumns = columns;
    } else if (props.activeTab === 2) {
      filteredColumns = column3;
    } else if (props.activeTab === 4) {
      filteredColumns = columns2;
    } else if (props.activeTab === 0) {
      filteredColumns = columns1;
    }
  }

  const customLocalization = {
    ...MRT_Localization_ES,
    expandAll: 'Ex', // Cambia 'Expandir todo' a 'Ex'
    expand: 'Ex', // Cambia 'Expandir' a 'Ex'
  };
  
  return (
    <div>
      {tabla && reclamos ? (
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            enableRowActions
            enableColumnResizing
            enableFacetedValues
            columns={filteredColumns}
            data={reclamos}
            initialState={initialState}
            positionActionsColumn="last"
            enableExpandAll={false}
            enableToolbarInternalActions={true}
            muiTableHeadCellProps={tableHeadCellProps}
            muiTableBodyCellProps={({ row }) => ({
              align: "left",
              sx: {
                backgroundColor: props.activeTab === 4 && row.original.valor_estado === "Finalizado"? "#efc3c3":"#ffffff",
                //     row.original.activo === 1 ? "#b3e6b3" : "#efc3c3",
                color: "black",
                borderColor: "black",
              },
            })}
            enableColumnActions={false}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            enableDensityToggle={false}
            layoutMode="grid"
            localization={customLocalization}
            enableColumnDragging={false}
            // enableEditing
            enablePinning={false}
            enableHiding={false}
            enableColumnFilters={false}
            renderDetailPanel={({ row }) => (
              <Detallereclamo datos={row.original} activeTab={activeTab}/>
            )}
            renderTopToolbarCustomActions={() => (
              <>
                <Typography variant="poster" fontSize={26}>
                  Reclamos Técnicos Granel
                </Typography>
                {props.activeTab === 4?
                <Typography variant="poster" fontSize={24}>
                  Abiertos: {cantidadAbiertos?cantidadAbiertos[0].reclamosAbiertos:"0"}
                </Typography>
                :null}
              </>
            )}
            renderRowActionMenuItems={({ closeMenu, row }) => [
              <MenuItem
                disabled={row.original.estado !== 192}
                key={0}
                onClick={() => {
                  closeMenu();
                  setDataReclamo(row.original);
                  setAsignar(true);
                  setUuid(uuidv4());
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <Build color="success" />
                </ListItemIcon>
                Asignar Técnico
              </MenuItem>,
              <MenuItem
                disabled={row.original.estado !== 170}
                key={0}
                onClick={() => {
                  closeMenu();
                  setDataReclamo(row.original);
                  setAsignar(true);
                  setUuid(uuidv4());
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <Build color="success" />
                </ListItemIcon>
                Reasignar Técnico
              </MenuItem>,
              <MenuItem
                disabled={row.original.estado !== 170}
                key={1}
                onClick={() => {
                  closeMenu();
                  setDataReclamo(row.original);
                  setFinalizar(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <BeenhereIcon color="primary" />
                </ListItemIcon>
                Finalizar reclamo
              </MenuItem>,
              <MenuItem
                disabled={row.original.estado !== 170}
                key={2}
                onClick={() => {
                  closeMenu();
                  setDataReclamo(row.original);
                  setCancelar(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <DoDisturbAltIcon color="error" />
                </ListItemIcon>
                Cancelar reclamo
              </MenuItem>,
              <MenuItem
                // disabled={row.original.estado !== 192 && row.original.estado !== 171 && row.original.estado !== 223 }
                onClick={() => {
                  setNewUuid(row.original.uuid);
                  setOpenCloseAdj(!openCloseAdj);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <AttachFileIcon color="action" />
                </ListItemIcon>
                Adjuntos
              </MenuItem>,
              // <MenuItem
              //   onClick={() => {
              //     setDataReclamo(row.original);
              //     setOpenCargo(true);
              //     closeMenu();
              //   }}
              // >
              //   <ListItemIcon>
              //     <AttachMoneyIcon color="action" />
              //   </ListItemIcon>
              //   A Cargo De
              // </MenuItem>,
              <MenuItem
                disabled={
                  row.original.estado === 171 || row.original.estado === 223
                }
                onClick={() => {
                  setDataReclamo(row.original);
                  setShowEditar(true);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <EditIcon color="action" />
                </ListItemIcon>
                Editar
              </MenuItem>,
              <MenuItem
                disabled={row.original.estado !== 171}
                onClick={() => {
                  setDataReclamo(row.original);
                  setFacturar(true);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <ReceiptIcon color="secondary" />
                </ListItemIcon>
                Factura
              </MenuItem>,
            ]}
          />
        </ThemeProvider>
      ) : null}
      {asignar ? (
        <AsignarTecnico
          datos={dataReclamo}
          open={setAsignar}
          tabla={setTabla}
          actualizar={getReclamos}
          uuid={uuid}
          activeTab={activeTab}
        />
      ) : null}
      {finalizar ? (
        <FinalizarReclamo
          datos={dataReclamo}
          open={setFinalizar}
          tabla={setTabla}
          actualizar={getReclamos}
        />
      ) : null}
      {cancelar ? (
        <CancelarReclamo
          datos={dataReclamo}
          open={setCancelar}
          tabla={setTabla}
          actualizar={getReclamos}
        />
      ) : null}
      {openCloseAdj ? ( // ADJUNTAR COMPROBANTES
        <FileUploadReclamo
          fileId={newUuid}
          openclose={true}
          mostrar={true}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}
      {/* {openCargo ? (
        <A_cargo
          datos={dataReclamo}
          open={setOpenCargo}
          tabla={setTabla}
          actualizar={getReclamos}
        />
      ) : null} */}
      {showEditar ? (
        <EditarReclamo
          datos={dataReclamo}
          open={showEditar}
          setOpen={setShowEditar}
          actualizar={getReclamos}
        />
      ) : null}
      {facturar ? (
        <Facturado
          datos={dataReclamo}
          open={setFacturar}
          tabla={setTabla}
          actualizar={getReclamos}
        />
      ) : null}
      <br></br>
      <br></br>
    </div>
  );
}
