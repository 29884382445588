//----- Component --------------------------------------------------------/

// Developer :  Daiana Curcio

// Creation Date : 5/1/2024

// Version : 1

// Description : Agregar motivos de salida

//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState} from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField,
  IconButton,
  Button,
  InputAdornment,
  Dialog,
  DialogContent,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import Notify from "../Notify";
import Alerta from "../Alerta";
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Title from "../Title";
import { logEventos } from "../../services/services";

function AddMotivoSalida(props) {
    const [datosMotivos, setDatosMotivos] = useState({
        id_motivo: null,
        descripcion_motivo: "",
        usuario: localStorage.getItem("user"),
        estado: 1,
    });

    const [uso, setUso] = useState(null);
    const [usuarios, setUsuarios] = useState(null);
    const [disBtn, setDisBtn] = useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
    const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
    const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
    const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
    const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
    const [alta, setAlta] = useState(props.alta); 
   

    const handleClose = ()=>{
      setAlta(!alta)
      if (props.onClose){
        props.onClose();
      }
    }

      const almacenar = (e) => {
        setDatosMotivos({
          ...datosMotivos,
          [e.target.name]: e.target.value === "" ? null : e.target.value,
        });
      };

      const guardarMotivo = async (e) => {
        logEventos("Guardar nuevo motivo de salida", "Stock Motivos de Salida", "Se guardo nuevo motivo de salida", localStorage.getItem("user"))
        setDisBtn(true);
        e.preventDefault();
        const response = await fetch(UrlApi + "/stock_motivos", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            descripcion_motivo: datosMotivos.descripcion_motivo,
            estado: 1,
            usuario: localStorage.getItem("user"),
          }),
        });
        if (response.ok === false) {
          console.log("ERROR EN API");
          const error = response && response.json();
          setError(error);
          setSeverityAlerta("error");
          setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
        } else {
          console.log("Conexión con api OK");
          try {
            //Parseo respuesta de la api a json()
            const json = await response.json();
            //si api me devuelve lo q le puse en message
            if (json.message) {
              //Si ese mensaje es "OK"
              if (json.message === "OK") {
                console.log("Guardado Satisfactoriamente");
                setNotificacion(true);
                setOpenSnack(true);
                setColorMensaje("#D4EFDF");
                setMensaje("Guardado");
                setSeveritySnack("success");
              } else {
                console.log("res en api incorrecto. Revisar que sea 'OK'");
                setNotificacion(true);
                setOpenSnack(true);
                setColorMensaje("#D4EFDF");
                setSeveritySnack("error");
                setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
              }
            } else if (json.sqlMessage) {
              json && setError(json);
              console.log(json);
              setMensajeAlerta(
                "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
              );
            } else {
              setMensajeAlerta("ERROR DESCONOCIDO.");
            }
          } catch (error) {
            if (error.toString().includes("is not valid JSON")) {
              console.log(
                "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
              );
              setNotificacion(true);
              setOpenSnack(true);
              setColorMensaje("#D4EFDF");
              setMensaje("Guardado");
              setSeveritySnack("success");
            } else {
              setMensajeAlerta("ERROR DESCONOCIDO.");
            }
          }
        }
        handleClose();
      };

      const style = {
        backgroundColor: "#ffffff",
        width: "100%",
      };

      const size = "small";

  return (
    <div>
       <Dialog
        open={props.alta}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Title
        titulo={`Cargar nuevo motivo de salida`}
            handleClose={() => {
              handleClose()
            }}
            />
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarMotivo} autoComplete="off">
              <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} mb={3}>
                <TextField
                  fullWidth
                  size={size}
                  margin="normal" 
                  placeholder="  ....."
                  style={style}
                  focused
                  color="primary"
                  required
                  name="descripcion_motivo"
                  label="Descripción del motivo"
                  variant="outlined"
                  value={datosMotivos.descripcion_motivo}
                  inputProps={{
                    maxLength: 60,
                  }}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AnnouncementIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={colormensaje}
          finalizar={setFinalizar}
          severity={severitySnack}
        />
      ) : null}
      {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
      ) : null}
        
    </div>
  )
}

export default AddMotivoSalida