//----- Component --------------------------------------------------------/

// Developer :  Daiana Curcio

// Creation Date : 5/1/2024

// Version : 1

// Description : Editar motivos de salida

//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect } from 'react';
import { UrlApi } from '../../services/apirest';
import { Container,Grid, Stack, Box, TextField, IconButton, Dialog, Button,DialogContent, InputAdornment, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { grey } from '@mui/material/colors';
import Notify from '../Notify';
import Alerta from '../Alerta';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Title from '../Title';
import { logEventos } from '../../services/services';

function EditarMotivo(props) {
    const [datosMotivos, setDatosMotivos] = useState(props.datos); // viene del motivos_salida {editar}
    const [disBtn, setDisBtn] = useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [colormensaje, setColorMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
    const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
    const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
    const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
    const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
    const [editar, setEditar] = useState(props.editar); //Estado q mostrará o no el form de edicion de choferes

    useEffect(()=>{
        setDatosMotivos({
            ...props.datos,
        })
    },[props.datos])

    const handleClose = ()=>{
      setEditar(!editar)
      if (props.onClose){
        props.onClose();
      }
    }

     //Funcion para guardar 
     const guardarEditar = async (e) => {
      logEventos("Guardar editar motivo de salida", "Stock Motivos de Salida", "Se guardo la edición", localStorage.getItem("user"))
        setDisBtn(true)
        e.preventDefault()
        const response = await fetch(UrlApi + "/stock_motivos/" + datosMotivos.id_motivo, {
            method: 'PUT', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                descripcion_motivo: datosMotivos.descripcion_motivo,
          usuario: localStorage.getItem("user"),
        //   estado: inactivo,    
            })
        })
        if (response.ok === false) {
            console.log("ERROR EN API");
            const error = response && response.json();
            setError(error);
            setSeverityAlerta("error")
            setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            console.log("Conexión con api OK");
            try {
                //Parseo respuesta de la api a json()
                const json = await response.json();
                //si api me devuelve lo q le puse en message
                if (json.message) {
                    //Si ese mensaje es "OK"
                    if (json.message === "OK") {
                        console.log("Guardado Satisfactoriamente");
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setMensaje("Guardado");
                        setSeveritySnack("success");
                    } else {
                        console.log("res en api incorrecto. Revisar que sea 'OK'")
                        setNotificacion(true);
                        setOpenSnack(true);
                        setColorMensaje("#D4EFDF");
                        setSeveritySnack("error");
                        setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
                    }
                } else if (json.sqlMessage) {
                    json && setError(json);
                    console.log(json);
                    setMensajeAlerta("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    console.log("Guardado. Advertencia: Actualizar respuesta de api. JSON invalido.");
                    setNotificacion(true);
                    setOpenSnack(true);
                    setColorMensaje("#D4EFDF");
                    setMensaje("Guardado");
                    setSeveritySnack("success");
                } else {
                    setMensajeAlerta("ERROR DESCONOCIDO.");
                }
            }
        }
        handleClose();
    };

    //funcion para almacenar  
    const almacenar = (e) => {
        setDatosMotivos({
            ...datosMotivos,
            [e.target.name]: e.target.value === "" ? null: e.target.value
        });
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%"
    };

    const size="small"

  return (
    <div>
         <br></br>
         <Dialog
        open={props.editar}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Title
            titulo={`Editar motivo de salida`}
            handleClose={() => {
              handleClose()
            }}
          />
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarEditar} autoComplete="off">
              <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} mb={3}>
                <TextField
                  fullWidth
                  size={size}
                  margin="normal" 
                  placeholder="..."
                  style={style}
                  focused
                  color="primary"
                  required
                  name="descripcion_motivo"
                  label="Descripción del Motivo"
                  variant="outlined"
                  value={datosMotivos.descripcion_motivo}
                  inputProps={{
                    maxLength: 60,
                  }}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AnnouncementIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Guardar 
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>


        {/* <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container backgroundColor="#1F618D" p={1.5}>
              <Grid item xs={2}>
                <IconButton onClick={handleClose}>
                  <ArrowBackIcon sx={{ color: grey[50] }} />
                </IconButton>
              </Grid>

              <Grid
                item
                xs={8}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h5" color="#ffffff">
                  Editar motivo de salida
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <form onSubmit={guardarEditar} autoComplete="off">
            <Box
              backgroundColor="#F4F6F6"
              mt={2}
              p={3}
              border={0.5}
              borderColor={"#ABB2B9"}
              borderRadius={4}
            >
               <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={10} md={8} lg={6}>
                <TextField
                  fullWidth
                  size={size}
                  margin="normal" 
                  placeholder="..."
                  style={style}
                  focused
                  color="primary"
                  required
                  name="descripcion_motivo"
                  label="Descripción del Motivo"
                  variant="outlined"
                  value={datosMotivos.descripcion_motivo}
                  inputProps={{
                    maxLength: 60,
                  }}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AnnouncementIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
                mt={2}
              >
                <Button variant="contained" onClick={handleClose} color="error">
                  Volver
                </Button>

                <Button
                  disabled={disBtn}
                  variant="contained"
                  type="submit"
                  color="success"
                >
                  Guardar Edición
                </Button>
              </Grid>
            </Box>
          </form>
        </Container> */}
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={colormensaje}
            finalizar={setFinalizar}
            severity={severitySnack}
          />
        ) : null}
        {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
        {error && mensajeAlerta ? (
          <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
        ) : null}
    </div>
  )
}

export default EditarMotivo