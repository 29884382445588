import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../../../../services/apirest";
// import MaterialTable from "material-table";
import Box from "@mui/material/Box";
import DetalleTanque from "./DetalleTanque";
import ModalUltCert from "./ModalUltCert";
//MRT
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español

//PRuebaA
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

function TablaTanques(props) {
  const [datosCliente, setDatosCliente] = useState(props.datos);
  const [tanques, setTanques] = useState([{}]);
  const [modalCert, setModalCert] = useState(false);
  const [dataTanque, setDataTanque] = useState({});

  const obtenerTanques = () => {
    fetch(UrlApi + "/tanques/nueva/cliente/" + datosCliente.id)
      .then((data) => data.json())
      .then((data) => setTanques(data));
  };

  useEffect(() => {
    obtenerTanques();
  }, []);

  // const verCertificacion =(rowData)=> {
  //   setModalCert(true)
  //   setDataTanque(rowData)
  // }

  const columns = useMemo(
    () => [
      {
        accessorKey: "nro_serie", //access nested data with dot notation
        header: "N° Serie",
      },
      {
        accessorKey: "fabricante_nombre", //access nested data with dot notation
        header: "Fabricante",
      },
      {
        accessorKey: "capacidad", //access nested data with dot notation
        header: "Capacidad",
      },
    ],
    []
  );

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: tanques && tanques.length > 5 ? 10 : 5,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 14,
      color: "black",
      fontWeight: "normal",
      paddingTop: 1,
      paddingBottom: 1,
      backgroundColor: "#D4E6F1",
      borderColor: "#FDFEFE",
    },
  };

  return (
    <div>
      {tanques ? (
        <Box mb={5}>
          <MaterialReactTable
            localization={MRT_Localization_ES}
            enableTopToolbar={false}
            enablePagination={true}
            columns={columns}
            data={tanques}
            enableColumnFilterModes={false}
            enableColumnOrdering={false}
            enableGrouping={false}
            enablePinning={false}
            // enableRowActions
            initialState={initialState}
            enableRowSelection={false}
            positionToolbarAlertBanner="bottom"
            enableHiding={false}
            muiTableHeadCellProps={tableHeadCellProps}
            positionExpandColumn="first"
            renderDetailPanel={({ row }) => (
              <DetalleTanque datos={row.original} />
            )}
            enableExpandAll={false}
            muiTableBodyCellProps={({ row }) => ({
              align: "center",
              sx: {
                backgroundColor: "#ffffff",
                color: "black",
                borderColor: "#FDFEFE",
              },
            })}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableDensityToggle={false}
            enableBottomToolbar={tanques.length > 5 ? true : false}
          />
        </Box>
      ) : (
        <Stack
          sx={{ color: "grey.500" }}
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="primary" />
        </Stack>
      )}
      {modalCert ? (
        <>
          <ModalUltCert datos={dataTanque} modalCert={setModalCert} />
        </>
      ) : null}
    </div>
  );
}

export default TablaTanques;
