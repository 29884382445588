import React, { useEffect, useState, useMemo, useCallback } from "react";

//MUI
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import {
  MenuItem,
  ListItemIcon,
  Container,
  Box,
  Button,
  Typography,
  Stack,
  Skeleton,
  Tooltip,
  IconButton,
  ThemeProvider,
} from "@mui/material";

//Iconos
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import PeopleIcon from "@mui/icons-material/People";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FindInPageIcon from "@mui/icons-material/FindInPage";

//Componentes
import Navbar from "../../Navbar";
import TabsAddProv from "../AddProveedor/TabsAddProv";
import IIBB from "../IIBB/IIBB";
import EditarProveedor from "../EditProveedor/EditarProveedor";
import Imputaciones from "../AddProveedor/ProvImputaciones";
import ModalAltaBaja from "./ModalAltaBaja";
import axios from "axios";

//Constantes/Hooks
import { UrlApi } from "../../../services/apirest";
import { UtilsMaterialTable } from "../hooks/UtilsMUITable";
import HasImputacion from "../AddProveedor/DialogHasImp";
import Notify from "../../Notify";
import { logEventos } from "../../../services/services";
import EstadoDocumentacion from "../Documentacion/EstadoDoucumentacion";

const Proveedores = () => {
  //States para getProveedores
  const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
  const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);
  const [Proveedores, setProveedores] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [Bandera, setBandera] = useState(0);
  const [Notificacion, setNotificacion] = useState({
    open: null,
    mensaje: null,
    severity: null,
    color: null,
  });
  //States
  const [ImputacionesBefore, setImputacionesBefore] = useState([]);

  //States para entrar a los componentes
  const [altaCrear, setaltaCrear] = useState(false); // Modal Para crear un nuevo proveedor a la escucha de el onClick
  const [Tabla, setTabla] = useState(true);
  const [altaImputaciones, setaltaImputaciones] = useState(false);
  const [altaDocumentacion, setaltaDocumentacion] = useState(false);
  const [altaIIBB, setaltaIIBB] = useState(false);
  const [rowProv, setrowProv] = useState();
  const [editProveedor, seteditProveedor] = useState(false);
  const [confirmar, setConfirmar] = useState();
  const [openExisteImp, setopenExisteImp] = useState(false);

  //Hooks
  const {
    tableTheme,
    muiSearchTextFieldProps,
    tableHeadCellProps,
    initialState,
  } = UtilsMaterialTable();

  const columns = useMemo(
    () => [
      { header: "Numero", accessorKey: "id" },
      { header: "Razon Social", accessorKey: "razon_social" },
      { header: "CUIT", accessorKey: "nro_cuit" },
    ],
    []
  );

  const handleCrearProv = () => {
    setaltaCrear(true);
    setTabla(false);
  };

  const HandleImputaciones = (DatosDeLinea) => {
    fetch(
      UrlApi + "/imputacion_proveedores/improv/" + DatosDeLinea.uuid_proveedor,
      { method: "GET" }
    )
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.length > 0) {
          setopenExisteImp(true);
          setImputacionesBefore(data);
        } else {
          setaltaImputaciones(true);
          setTabla(false);
          setrowProv(DatosDeLinea);
        }
      });
  };

  const AgregarImp = () => {
    setopenExisteImp(false);
    setaltaImputaciones(true);
    setTabla(false);
  };
  const closeDialogImp = () => {
    setopenExisteImp(false);
  };

  const handleIIBB = (DatosDeLinea) => {
    setaltaIIBB(true);
    setTabla(false);
    setrowProv(DatosDeLinea);
  };

  const handleDocumentacion = (DatosDeLinea) => {
    setaltaDocumentacion(true);
    setTabla(false);
    setrowProv(DatosDeLinea);
  };
  const handleEdit = (DatosDeLinea) => {
    seteditProveedor(true);
    setTabla(false);
    setrowProv(DatosDeLinea);
  };
  const abrirModalConfirmar = (datosDeLaLinea) => {
    setConfirmar(true);
    setrowProv(datosDeLaLinea);
  };

  const handleVolver = () => {
    setConfirmar(false);
    setTabla(true);
  };

  const getProveedores = useCallback(async () => {
    setIsLoading(true);
    setTabla(false);
    try {
      setHiddenmuestraactivos(true);
      setHiddenmuestrainactivo(false);
      await axios
        .get(UrlApi + "/proveedores/activos")
        .then((response) => setProveedores(response.data));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setTabla(true);
    }
  }, []);
  const getProveedoresInactivos = async () => {
    setIsLoading(true);
    setTabla(false);
    try {
      setHiddenmuestraactivos(false);
      setHiddenmuestrainactivo(true);
      await axios
        .get(UrlApi + "/proveedores/inactivos")
        .then((response) => setProveedores(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setTabla(true);
    }
  };

  useEffect(() => {
    getProveedores();
  }, [getProveedores]);
  useEffect(() => {
    logEventos(
      "Consulta Tabla de Proveedores",
      "Proveedores",
      "proveedores",
      localStorage.getItem("user")
    );
  }, []);

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {Notificacion && Notificacion.open ? (
          <Notify
            open={Notificacion.open}
            mensaje={Notificacion.mensaje}
            severity={Notificacion.severity}
            color={Notificacion.color}
          />
        ) : null}
        <Box mt={2} boxShadow={3}>
          {IsLoading && (
            <>
              <Stack spacing={1} maxHeight maxWidth alignContent={"center"}>
                <Skeleton
                  variant="table"
                  height={100}
                  rows={5}
                  columns={4}
                  sx={{ border: "1px solid #ccc" }}
                />
                <Skeleton variant="rectangular" animation="wave" />
                <Skeleton variant="rectangular" animation="wave" />
                <Skeleton variant="rectangular" animation="wave" />
                <Skeleton variant="rectangular" animation="wave" />
                <Skeleton variant="rectangular" animation="wave" />
                <Skeleton
                  variant="table"
                  height={100}
                  rows={5}
                  columns={4}
                  sx={{ border: "1px solid #ccc" }}
                />
              </Stack>{" "}
            </>
          )}

          {Tabla && Proveedores && !IsLoading ? (
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                columns={columns}
                data={Proveedores}
                localization={MRT_Localization_ES}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                // displayColumnDefOptions={displayColumnDefOptions}
                muiTableHeadCellProps={tableHeadCellProps}
                enableExpandAll={false}
                // enableGlobalFilter={true}
                enableToolbarInternalActions={true}
                renderToolbarInternalActions={({ table }) => (
                  <>
                    {!hiddenmuestraactivos ? (
                      <Tooltip title="Ver Proveedores Activos ">
                        <IconButton
                          color="success"
                          onClick={() => {
                            getProveedores();
                          }}
                        >
                          <PersonIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {!hiddenmuestrainactivo ? (
                      <Tooltip title="Ver Proveedores Inactivos">
                        <IconButton
                          color="error"
                          onClick={() => {
                            getProveedoresInactivos();
                          }}
                        >
                          <PersonOffIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                //enableTopToolbar={false} // habilita o deshabilita TODA la barra de herramientas de arriba
                enableColumnResizing
                layoutMode="grid"
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor:
                      row.original.inactivo === 0 ? "#b3e6b3" : "#efc3c3",
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={false}
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false}
                enableDensityToggle={false} //
                enableColumnDragging={false} //No permite que se arrastren las columnas.
                initialState={initialState}
                enableStickyFooter
                enableRowActions
                positionActionsColumn="last"
                renderRowActionMenuItems={({ closeMenu, row }) => [
                  <MenuItem
                    key="Editar"
                    onClick={() => {
                      handleEdit(row.original);
                      closeMenu();
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon color="primary" />
                    </ListItemIcon>
                    Editar
                  </MenuItem>,
                  <MenuItem
                    key="iibb"
                    onClick={() => {
                      handleIIBB(row.original);
                      closeMenu();
                    }}
                  >
                    <ListItemIcon>
                      <PriceChangeIcon color="secondary" />
                    </ListItemIcon>
                    IIBB
                  </MenuItem>,
                  <MenuItem
                    key="Imputaciones"
                    onClick={() => {
                      HandleImputaciones(row.original);
                      closeMenu();
                    }}
                  >
                    <ListItemIcon>
                      <AssignmentIcon color="warning" />
                    </ListItemIcon>
                    Imputaciones
                  </MenuItem>,
                  <MenuItem
                    key="documentacion"
                    onClick={() => {
                      handleDocumentacion(row.original);
                      closeMenu();
                    }}
                  >
                    <ListItemIcon>
                      <FindInPageIcon color="primary" />
                    </ListItemIcon>
                    Ver Documentacion
                  </MenuItem>,
                  row.original.inactivo === 1 ? (
                    <MenuItem
                      key="Marcar Activo"
                      onClick={() => {
                        abrirModalConfirmar(row.original);
                        closeMenu();
                      }}
                    >
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="success" />
                      </ListItemIcon>
                      Marcar como Activo
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key="Marcar Inactivo"
                      onClick={() => {
                        abrirModalConfirmar(row.original);
                        closeMenu();
                      }}
                    >
                      <ListItemIcon>
                        <BlockIcon color="error" />
                      </ListItemIcon>
                      Marcar como Inactivo
                    </MenuItem>
                  ),
                ]}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleCrearProv}
                    >
                      Nuevo Registro
                    </Button>
                    <Typography variant="poster" fontSize={26}>
                      <PeopleIcon sx={{ fontSize: 20 }} />
                      Proveedores
                    </Typography>
                  </>
                )}
              />
            </ThemeProvider>
          ) : null}
        </Box>
      </Container>

      {altaCrear ? (
        <TabsAddProv
          setnotificacion={setNotificacion}
          alta={setaltaCrear}
          onSuccess={getProveedores}
          tabla={setTabla}
        />
      ) : null}
      {editProveedor ? (
        <EditarProveedor
          setNotificacion={setNotificacion}
          proveedor={rowProv}
          onSuccess={getProveedores}
          alta={seteditProveedor}
          tabla={setTabla}
        />
      ) : null}
      {altaIIBB ? (
        <IIBB
          proveedor={rowProv}
          onSuccess={getProveedores}
          tabla={setTabla}
          alta={setaltaIIBB}
        />
      ) : null}
      {altaDocumentacion ? (
        <EstadoDocumentacion
          proveedor={rowProv}
          tabla={setTabla}
          alta={setaltaDocumentacion}
        />
      ) : null}

      {confirmar ? (
        <ModalAltaBaja
          datosFormulario={rowProv}
          confirmar={setConfirmar}
          handleVolver={handleVolver}
          getProv={getProveedores}
        />
      ) : null}
      {altaImputaciones ? (
        <Imputaciones
          proveedor={rowProv}
          onSuccess={getProveedores}
          alta={setaltaImputaciones}
          tabla={setTabla}
        />
      ) : null}
      {openExisteImp ? (
        <HasImputacion
          open={openExisteImp}
          close={closeDialogImp}
          agregar={AgregarImp}
          ImputacionesBefore={ImputacionesBefore}
        />
      ) : null}
    </div>
  );
};

export default Proveedores;
