//----- Component --------------------------------------------------------/

// Developer :  Nicolas Pascucci

// Creation Date : 21/05/2023

// Version : 1

// Description : Detalle de los resultados de los comerciales

//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date : 

// Change by :

// Change description: 

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonIcon from "@mui/icons-material/Person";
import BuildCircle from "@mui/icons-material/BuildCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HomeIcon from "@mui/icons-material/Home";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PercentIcon from '@mui/icons-material/Percent';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StoreIcon from '@mui/icons-material/Store';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import PropaneIcon from '@mui/icons-material/Propane';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddTaskIcon from '@mui/icons-material/AddTask';
import SellIcon from '@mui/icons-material/Sell';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const ListItemComponent = ({ icon, primary, secondary }) => (
    <ListItem>
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "#EBF5FB", width: 30, height: 30 }}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{ variant: 'h6', fontSize: '13px' }} // Tamaño de fuente para el texto primario
        secondaryTypographyProps={{ variant: 'h5', fontSize: '18px', fontWeight: 'bold' }} // Tamaño de fuente más grande y negrita para el texto secundario
      />
    </ListItem>
  );

  const ListItemComponentDos = ({ icon, primary, secondary }) => (
    <ListItem>
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "#EBF5FB", width: 20, height: 30 }}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{ variant: 'h5', fontSize: '25px' }} // Tamaño de fuente para el texto primario
        secondaryTypographyProps={{ variant: 'h5', fontSize: '18px', fontWeight: 'bold' }} // Tamaño de fuente más grande y negrita para el texto secundario
      />
    </ListItem>
  );

function DetalleResultado(props) {
    const { datos } = props;
    const montoAPagar =  ((datos.monto_pesos * datos.suma_resultado_porcentaje) / 100).toFixed(2); 
    console.log(datos);
  return (
    <div>
    <Grid container spacing={2}>
      <Grid item xs={24} >
      <List
        sx={{
          width: "100%",
          bgcolor: "#EBF5FB",
          border: 0.5,
          borderRadius: 1,
          borderColor: "#A9CCE3",
          display: "flex",
          margin: "auto",
          justifyContent: "space-around",
        }}
      >
        <ListItemComponent
          icon={<PercentIcon color="primary" />}
          primary="Resultado (%)"
          secondary={datos.suma_resultado_porcentaje}
        />
        {/* <ListItemComponent
          icon={<LocalShippingIcon color="primary" />}
          primary="Vendido a Distribuidores"
          secondary={datos.distribuidores}
        />
        <ListItemComponent
          icon={<StoreIcon color="primary" />}
          primary="Vendido a Comercios"
          secondary={datos.comercio}
        />
        <ListItemComponent
          icon={<PersonIcon color="primary" />}
          primary="Vendido a Usuarios"
          secondary={datos.usuarios}
        /> */}
        <ListItemComponent
          icon={<AddTaskIcon color="primary" />}
          primary="Objetivo del Periodo"
          secondary={datos.objetivo}
        />
        <ListItemComponent
          icon={<SellIcon color="primary" />}
          primary={`Vendido en el Periodo (+${datos.porcentaje_vendido}%)`}
          secondary={datos.vendido}
        />
        <ListItemComponent
          icon={<SupportAgentIcon color="primary" />}
          primary={`Clientes del Mes (+${datos.porcentaje_clientes}%)`}
          secondary={datos.clientes}
        />
        <ListItemComponent
          icon={<MoneyOffIcon color="primary" />}
          primary={`Montos Vencidos (+${datos.porcentaje_vencidos}%)`}
          secondary={datos.vencidos}
        />
        <ListItemComponent
          icon={<AttachMoneyIcon color="primary" />}
          primary="Monto a Pagar"
          secondary={montoAPagar}
        />
      </List>
        </Grid>
      <Grid item xs={24}>
      <List
        sx={{
          width: "100%",
          bgcolor: "#EBF5FB",
          border: 0.5,
          borderRadius: 1,
          borderColor: "#A9CCE3",
          display: "flex",
          margin: "auto",
          marginTop:"2"
        }}
      >
                <ListItemComponent
          icon={<FormatListNumberedRtlIcon color="primary" />}
          primary={`Checklist (+${datos.porcentaje_checklist}%)`}
          secondary={datos.checklist}
        />
        <ListItemComponent
          icon={<CheckCircleOutlineIcon color="primary" />}
          primary="Items Cumplidos"
          secondary={datos.cumple===null?"N/A":datos.cumple}
        />
        <ListItemComponent
          icon={<DoDisturbAltIcon color="primary" />}
          primary="Items Incumplidos"
          secondary={datos.nocumple===null?"N/A":datos.nocumple}
        />
      </List>
      </Grid>
      <Grid item xs={24}>
      <List
        sx={{
          width: "100%",
          bgcolor: "#EBF5FB",
          border: 0.5,
          borderRadius: 1,
          borderColor: "#A9CCE3",
          display: "flex",
          margin: "auto",
          marginTop:"2"
        }}
      >
        <ListItemComponentDos
          icon={<PropaneIcon color="primary" />}
          primary="Butano"
        />
          <ListItemComponent
            icon={<PersonAddIcon color="primary" />}
            primary={
              <>
                <Typography variant= 'h6' fontSize= '15px' >Usuarios (+{datos.porcentaje_usuarios}%)</Typography>
                <Typography variant= 'h6' fontSize= '13px'>Objetivo: {datos.objetivo_usuarios}</Typography>
                <Typography variant= 'h6' fontSize= '13px'>Resultado: {datos.usuarios_butano}</Typography>
              </>
              }
          />
          <ListItemComponent
            icon={<AddBusinessIcon color="primary" />}
            primary={
              <>
                <Typography variant= 'h6' fontSize= '15px'>Comercios (+{datos.porcentaje_comercios}%)</Typography>
                <Typography variant= 'h6' fontSize= '13px'>Objetivo: {datos.objetivo_comerciales}</Typography>
                <Typography variant= 'h6' fontSize= '13px'>Resultado: {datos.comercios_butano}</Typography>
              </>
              }
          />
        <ListItemComponent
          icon={<LocalShippingIcon color="primary" />}
          primary={
            <>
            <Typography variant= 'h6' fontSize= '15px'>Distribuidores (+{datos.porcentaje_distribuidores}%)</Typography>
            <Typography variant= 'h6' fontSize= '13px'>Objetivo: {datos.objetivo_distribuidores}</Typography>
            <Typography variant= 'h6' fontSize= '13px'>Resultado: {datos.distribuidores_butano}</Typography>
          </>
          }
        />
      </List>
      </Grid>
     </Grid>
    </div>
  )
}

export default DetalleResultado