//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :26/06/2024
// Version : 1
// Description : Agregar precios
//------Changes ----------------------------------------------------------//
// Change Date :15/8
// Change by :Candela Grosso
// Change description: Acomode el titulo y saque los console.log
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField, Button,
  Stack,
  DialogContent,
  Dialog,
  DialogTitle,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";

//notificacion
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddPrecioEspecialDireccion(props) {
    const [datoslista, setDatoslista] = useState({
        uuid_direccionentrega: "",
        lt_con_iva: "",
        lt_sin_iva: "",
        kg_con_iva: "",
        kg_sin_iva: "",
        vigencia:""
      });
      //Notification
      const [openSnack, setOpenSnack] = React.useState(false);
      const [colormensaje, setColorMensaje] = useState("");
      const [mensaje, setMensaje] = useState("");
      //boton
      const [disabledGuardar, setDisabledGuardar] = useState(false);
// console.log("datos direccion", props.direccion.nro_dir);

      const [datosDirec, setDatosDirec] = useState();
      const obtenerXsucySeg = () => {
        fetch(
          UrlApi +
            "/precios_especiales/xdireccion/" +
            props.direccion.nro_dir 
        )
          .then((data) => data.json())
          .then((data) => setDatosDirec(data));
      };
      useEffect(() => {
        obtenerXsucySeg();
      }, []);
    
      // console.log("DATOS SUC Y SEG", datosDirec);
    //*********************************** NOTIFICACION **************************************************************************************************/
      //Snackbar Notificacion
      const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenSnack(false);
        handleClose();
      };
    
      //**********************************************************************************************************************************//
      const style = {
        backgroundColor: "#ffffff",
        width: "100%",
      };
      const size = "small";
    
      const handleClose = () => {
        props.alta(false);
        props.tabla(true);
      };
      //*************************** GUARDAR *******************************************************************************************************//
      const guardarPrecio = async (e) => {
        // logEventos(
        //   "Guardar actualización del precio",
        //   "puntos de venta",
        //   "Se guardo el precio actualizado",
        //   localStorage.getItem("user")
        // );
        e.preventDefault();
        // setDisBtn(true);
        const response = await fetch(UrlApi + "/precios_especiales", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            uuid_direccionentrega: props.direccion.nro_dir ,
            lt_con_iva: datoslista.lt_con_iva,
            lt_sin_iva: datoslista.lt_sin_iva,
            kg_con_iva: datoslista.kg_con_iva,
            kg_sin_iva: datoslista.kg_sin_iva,
            vigencia: datoslista.vigencia,
            usuario: localStorage.getItem("user"),
          }),
        })
          .then((res) => {
            if (!res.err) {
              // setOpenSnack(true);
              // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
              setColorMensaje("#D4EFDF");
              setMensaje("Se guardó exitosamente !");
              console.log("Guardado");
            } else {
              // setOpenSnack(false);
              setColorMensaje("#F1948A");
              setMensaje("Error al guardar");
              console.log("Error");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
          props.onClose(false)
      };

      const almacenar = (e) => {
        setDatoslista({
          ...datoslista,
          [e.target.name]: e.target.value,
        });
      };
    
      //*************************** HABILITAR Y DESHABILITAR BOTON GUARDAR ***************************************************************************************//
      useEffect(() => {
        if (datoslista.lt_con_iva === "" || datoslista.lt_sin_iva === "" || datoslista.kg_con_iva === "" || datoslista.kg_sin_iva === "" || datoslista.vigencia === "") {
          setDisabledGuardar(true);
        } else {
          setDisabledGuardar(false);
        }
      }, [datoslista]);

      //********************************************************ACTUALIZAR******************************************************************//
        //Fecha fin vigencia
  const fechaVigencia = moment(datoslista.vigencia);
  const fechaFinVigencia = fechaVigencia.subtract(1, "day");
  const fechaFinVigenciaFormat =
    datoslista.vigencia === ""
      ? "dd/mm/aaaa"
      : fechaFinVigencia.format("DD/MM/YYYY");
  const fechaFinVigenciaPut = fechaFinVigencia.format("YYYY-MM-DD");
  const [buscando, setBuscando] = useState(false)

  const guardarPrecioActualizar = async (e) => {
    // logEventos(
    //   "Guardar actualización del precio",
    //   "puntos de venta",
    //   "Se guardo el precio actualizado",
    //   localStorage.getItem("user")
    // );
    e.preventDefault();
    // setDisBtn(true);
    const response = await fetch(UrlApi + "/precios_especiales", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        uuid_direccionentrega: props.direccion.nro_dir,
        lt_con_iva: datoslista.lt_con_iva,
        lt_sin_iva: datoslista.lt_sin_iva,
        kg_con_iva: datoslista.kg_con_iva,
        kg_sin_iva: datoslista.kg_sin_iva,
        vigencia: datoslista.vigencia,
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          // setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setColorMensaje("#D4EFDF");
          setMensaje("Se guardó exitosamente !");
          console.log("Guardado");
        } else {
          // setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

      await fetch(UrlApi + "/precios_especiales/" + datosDirec[0].id_precioespecial, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fin_vigencia: fechaFinVigenciaPut,
        }),
      })
        .then((res) => {
          if (!res.err) {
            console.log("Guardado");
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });

        props.onClose(false)
  };

  const [aumento, setAumento] = useState("");

  const handleAumentoChange = (e) => {
    const nuevoAumento = e.target.value;
    setAumento(nuevoAumento);

    // Si el valor de aumento es umero, actualizamos el PRECIO
    if (!isNaN(nuevoAumento) && nuevoAumento !== "") {
      setDatoslista({
        ...datoslista,
        lt_con_iva:
          parseFloat(datosDirec[0].lt_con_iva) + parseFloat(nuevoAumento) ,
        lt_sin_iva:
          parseFloat(datosDirec[0].lt_sin_iva) + parseFloat(nuevoAumento) ,
        kg_con_iva:
          parseFloat(datosDirec[0].kg_con_iva) + parseFloat(nuevoAumento) ,
        kg_sin_iva:
          parseFloat(datosDirec[0].kg_sin_iva) + parseFloat(nuevoAumento) ,
      });
    } else {
      // Si el aumento está vacío, restablecer a los valores originales de datosDirec
      setDatoslista({
        ...datoslista,
        lt_con_iva: parseFloat(datosDirec[0].lt_con_iva),
        lt_sin_iva: parseFloat(datosDirec[0].lt_sin_iva),
        kg_con_iva: parseFloat(datosDirec[0].kg_con_iva),
        kg_sin_iva: parseFloat(datosDirec[0].kg_sin_iva),
      });
    } 
  };

  const handlePrecioChange = (e) => {
    const nuevoPrecio = e.target.value;
    setDatoslista({
      ...datoslista,
      lt_con_iva: nuevoPrecio,
      lt_sin_iva: nuevoPrecio,
      kg_con_iva: nuevoPrecio,
      kg_sin_iva: nuevoPrecio,
    });
  };

  // console.log("aumento", aumento);

    //*************************** AUMENTO EN PORCENTAJE *******************************************************************************************************//
    const [porcentaje, setPorcentaje] = useState("");

    const handlePorcentajeChange = (e) => {
      const nuevoPorcentaje = e.target.value;
      setPorcentaje(nuevoPorcentaje);
  
      if (!isNaN(nuevoPorcentaje) && nuevoPorcentaje !== "") {
        const porcentajeNumerico = parseFloat(nuevoPorcentaje) / 100;
  
        const nuevoPrecioLitroConIva = datosDirec[0].lt_con_iva * (1 + porcentajeNumerico);
        const nuevoPrecioLitroSinIva = datosDirec[0].lt_sin_iva * (1 + porcentajeNumerico);
        const nuevoPrecioKiloConIva = datosDirec[0].kg_con_iva * (1 + porcentajeNumerico);
        const nuevoPrecioKiloSinIva = datosDirec[0].kg_sin_iva * (1 + porcentajeNumerico);
  
        setDatoslista({
          ...datoslista,
          lt_con_iva: nuevoPrecioLitroConIva.toFixed(2),
          lt_sin_iva: nuevoPrecioLitroSinIva.toFixed(2),
          kg_con_iva: nuevoPrecioKiloConIva.toFixed(2),
          kg_sin_iva: nuevoPrecioKiloSinIva.toFixed(2),
        });
      } else {
        setDatoslista({
          ...datoslista,
          lt_con_iva: datosDirec[0].lt_con_iva,
          lt_sin_iva: datosDirec[0].lt_sin_iva,
          kg_con_iva: datosDirec[0].kg_con_iva,
          kg_sin_iva: datosDirec[0].kg_sin_iva,
        });
      }
    };

    const agregarUnDia = (fecha) => {
      return moment(fecha, 'DD/MM/YYYY').add(1, 'days').format('YYYY-MM-DD');
    };
  
    // Verifica y formatea la fecha mínima
    const fechaMinima = datosDirec && datosDirec[0] && datosDirec[0].vigencia ? agregarUnDia(datosDirec[0].vigencia) : '';
  
      
  return (
    <div>
      <Dialog
        maxWidth="xl"
        open={props.open}
        onClose={() => props.onClose(false)}
        style={{ zIndex: 1 }}
      >
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography variant="h6" align="center" color="#ffffff">
            Precio Especial Para {props.direccion.direccion_entrega}
            </Typography>
          </DialogTitle>
        </Box>
        <DialogContent>
      <Container>
        <form onSubmit={datosDirec && datosDirec.length > 0 ?guardarPrecioActualizar:guardarPrecio} autoComplete="off">
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              {/* <Grid item xs={6}>
                <TextField
                  style={style}
                  required
                  focused
                  size="small"
                  placeholder="Tipee para buscar..."       
                  label="Direccion"
                  value={props.direccion.direccion_entrega}
                />
              </Grid> */}
              {datosDirec && datosDirec.length > 0 ? (
                
                 <>
                  <Grid container justifyContent="left" sx={{ mt: 1 }}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: 19,
                          textDecoration: "underline",
                          textAlign: "left",
                        }}
                      >
                        Precio Vigente :
                      </Typography>
                    </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={0.5}> 
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="lt_con_iva"
                        label="Precio litro con IVA"
                        variant="outlined"
                        value={datosDirec[0].lt_con_iva || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="lt_sin_iva"
                        label="Precio litro sin IVA"
                        variant="outlined"
                        value={datosDirec[0].lt_sin_iva || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="kg_con_iva"
                        label="Precio kilo con IVA"
                        variant="outlined"
                        value={datosDirec[0].kg_con_iva || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="kg_sin_iva"
                        label="Precio kilo sin IVA"
                        variant="outlined"
                        value={datosDirec[0].kg_sin_iva || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="vigencia"
                        label="Vigencia"
                        variant="outlined"
                        value={datosDirec[0].vigencia || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="vigencia"
                        label="Fin de Vigencia"
                        variant="outlined"
                        value={fechaFinVigenciaFormat || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container justifyContent="left" sx={{ mt: 2 }}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: 19,
                          textDecoration: "underline",
                          textAlign: "left",
                        }}
                      >
                        Aumento :
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="left"
                    alignItems="left"
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        disabled={porcentaje !== ""? true:false}
                        color="secondary"
                        name="aumento"
                        label="Aumento en pesos"
                        variant="outlined"
                        value={aumento}
                        onKeyPress={(event) => {
                          if (!/[-0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={handleAumentoChange}
                        InputProps={{
                          startAdornment: (
                              <AttachMoneyIcon position="start">
                              </AttachMoneyIcon>
                          )
                      }}
                      />
                      </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="secondary"
                        name="aumento"
                        label="Aumento en porcentaje"
                        variant="outlined"
                        disabled={aumento !== ""? true:false}
                        value={porcentaje}
                        onKeyPress={(event) => {
                          if (!/[-0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={handlePorcentajeChange}
                        InputProps={{
                          startAdornment: (
                            <PercentIcon position="start">
                              </PercentIcon>
                          )
                        }}
                        />
                        </Grid>
                    </Grid>

                  <Grid container justifyContent="left" sx={{ mt: 2 }}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: 19,
                          textDecoration: "underline",
                          textAlign: "left",
                        }}
                      >
                        Precio Con Aumento :
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mt={0.5}>

                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="lt_con_iva"
                        label="Precio litro con IVA"
                        variant="outlined"
                        value={datoslista.lt_con_iva}
                        onChange={() => {
                          handlePrecioChange();
                          handlePorcentajeChange();
                          almacenar();
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="lt_sin_iva"
                        label="Precio litro sin IVA"
                        variant="outlined"
                        value={datoslista.lt_sin_iva}
                        onChange={() => {
                          handlePrecioChange();
                          handlePorcentajeChange();
                          almacenar();
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="kg_con_iva"
                        label="Precio kilo con IVA"
                        variant="outlined"
                        value={datoslista.kg_con_iva}
                        onChange={() => {
                          handlePrecioChange();
                          handlePorcentajeChange();
                          almacenar();
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="kg_sin_iva"
                        label="Precio kilo sin IVA"
                        variant="outlined"
                        value={datoslista.kg_sin_iva}
                        onChange={() => {
                          handlePrecioChange();
                          handlePorcentajeChange();
                          almacenar();
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        type="date"
                        name="vigencia"
                        label="Vigencia"
                        variant="outlined"
                        value={datoslista.vigencia}
                        onChange={almacenar}
                        inputProps={{
                          min: fechaMinima,
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>

                <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
                mt={1}
              >
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="lt_con_iva"
                    label="Precio litro con IVA"
                    variant="outlined"
                    value={datoslista.lt_con_iva}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="lt_sin_iva"
                    label="Precio litro sin IVA"
                    variant="outlined"
                    value={datoslista.lt_sin_iva}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="kg_con_iva"
                    label="Precio kilo con IVA"
                    variant="outlined"
                    value={datoslista.kg_con_iva}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="kg_sin_iva"
                    label="Precio kilo sin IVA"
                    variant="outlined"
                    value={datoslista.kg_sin_iva}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    type="date"
                    name="vigencia"
                    label="Vigencia"
                    variant="outlined"
                    value={datoslista.vigencia}
                    onChange={almacenar}
                  />
                </Grid>
              </Grid>
                </>
                )}
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={() => props.onClose(false)} color="error">
                Volver
              </Button>

              <Button
                variant="contained"
                type="submit"
                color="success"
                disabled={disabledGuardar}
              >
                Guardar Precio
              </Button>
            </Grid>
          </Box>
        </form>
        {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
      </Container>
      </DialogContent>
      </Dialog>
    </div>
  )
}

export default AddPrecioEspecialDireccion