import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import ModalCapacidades from "./ModalCapacidades";
import VerificacionDomicilio from "./Mapas/VerificacionDomicilio"

function BarraDatosPedido(props) {
  const [showModalCap, setShowModalCap] = useState(false);
  const [showModalMapa, setShowModalMapa] = useState(false);

  // console.log("llegan a la barra: ",  props)
  return (
    <div>
      <Box my={1}>
        <Grid
          p={2}
          container
          backgroundColor={grey[100]}
          border={1}
          borderColor={grey[300]}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid container alignItems="center" justifyContent="space-between">
            {props.tanqueSel && !props.tanqueSel.idbateria ? (
              <Grid item xs={1}>
                <InputLabel focused>Capacidad</InputLabel>
                {props.tanqueSel ? (
                  <InputLabel>{props.tanqueSel.capacidad} m3</InputLabel>
                ) : null}
              </Grid>
            ) : null}

            {props.tanqueSel.idbateria &&
            props.tanquesxbat &&
            props.tanquesxbat.length > 0 ? (
              <>
                <Grid item xs={1.5}>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => setShowModalCap(true)}
                  >
                    Ver Capacidades
                  </Button>
                </Grid>
              </>
            ) : null}
            {props.tksSinCap && props.tksSinCap.length <= 0 ? (
              <Grid item xs={2}>
                <InputLabel focused> Cap Max (85%)</InputLabel>
                <InputLabel>{props.capMaxKg} kg</InputLabel>
              </Grid>
            ) : null}
            <Grid item xs={1.5}>
              <InputLabel focused> KG A Entregar</InputLabel>
              <InputLabel>{props.kgCarga} kg</InputLabel>
            </Grid>
            <Grid item xs={1.5}>
              <InputLabel focused>Sucursal</InputLabel>
              {props.sucursal ? (
                <InputLabel>{props.sucursal}</InputLabel>
              ) : (
                <InputLabel>-</InputLabel>
              )}
            </Grid>
            <Grid item xs={1.5}>
              <InputLabel focused>Condición Pago</InputLabel>
              <InputLabel>{props.condicionPago}</InputLabel>
            </Grid>
            <Grid item xs={1.5}>
              <InputLabel focused>$ con IVA</InputLabel>
              <InputLabel>{isNaN(props.precioIva) ? 0 : parseFloat(props.precioIva).toFixed(2)}</InputLabel>
            </Grid>
            <Grid item xs={1.5}>
              <InputLabel focused>$ sin IVA</InputLabel>
              <InputLabel>{isNaN(props.precionormal) ? 0 : parseFloat(props.precionormal).toFixed(2)}</InputLabel>
            </Grid>
            <Grid item xs={1.5}>
              <Button
                variant="text"
                size="small"
                onClick={() => setShowModalMapa(true)}
                disabled={!props.direccion}
              >
                {props.direccion.verificada === 0
                  ? "Verificar Domicilio"
                  : "Ver Domicilio"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {showModalCap ? (
        <ModalCapacidades
          open={showModalCap}
          setOpen={setShowModalCap}
          tanquesxbat={props.tanquesxbat}
        />
      ) : null}
      {showModalMapa ? (
        <VerificacionDomicilio
          direccion={props.direccion}
          open={showModalMapa}
          setOpen={setShowModalMapa}
        />
      ) : null}
    </div>
  );
}

export default BarraDatosPedido;
