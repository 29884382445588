import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import moment from "moment";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EngineeringIcon from "@mui/icons-material/Engineering";
import NotesIcon from "@mui/icons-material/Notes";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

function DetailPanel(props) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Container
        maxWidth="xl"
        sx={{
          backgroundColor: "#ebebeb",
          paddingTop: 2,
          paddingBottom: 2,
        }}
        disableGutters
      >
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={5.8}
            backgroundColor="#fff"
            borderRadius={1}
            border={0.5}
            borderColor={"#D5DBDB"}
          >
            <List sx={{ bgcolor: "background.paper" }} dense={true}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#039be5" }}>
                    <StickyNote2Icon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="button" color="text.primary">
                      motivo
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {props.reclamo.descripcion_motivo}
                      </Typography>
                      {" — " + props.reclamo.detalle}
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#039be5" }}>
                    <LocationOnIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="button" color="text.primary">
                      dirección de entrega
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {props.reclamo.direccion_entrega}
                      </Typography>
                      {props.reclamo.nombre_direccion
                        ? ` — ${props.reclamo.nombre_direccion} `
                        : ""}{" "}
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#039be5" }}>
                    <CalendarMonthIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="button" color="text.primary">
                      fecha de apertura
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {props.reclamo.fecha_abierto}
                      </Typography>
                      {`— Hace ${moment(props.reclamo.fecha_abierto)
                        .startOf("day")
                        .fromNow()}`}
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      backgroundColor:
                        props.reclamo.prioridad === 197
                          ? "#ffd600"
                          : props.reclamo.prioridad === 196
                          ? "#ffb74d"
                          : props.reclamo.prioridad === 195
                          ? "#ff7043"
                          : "#dd2c00",
                    }}
                  >
                    <WarningAmberIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="button" color="text.primary">
                      prioridad
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {props.reclamo.descr_prioridad}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: props.reclamo.estado!==223?"#039be5":"#E74C3C"}}>
                    <WorkHistoryIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="button" color="text.primary">
                      estado
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {props.reclamo.descr_estado}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            xs={5.8}
            backgroundColor="#fff"
            borderRadius={1}
            border={0.5}
            borderColor={"#D5DBDB"}
          >
            <List sx={{ bgcolor: "background.paper" }} dense={true}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#039be5" }}>
                    <EngineeringIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="button" color="text.primary">
                      instalador
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {!!props.reclamo.instalador
                          ? props.reclamo.instalador
                          : "Desconocido/Sin asignar"}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              {
                props.reclamo.estado!==223 ? (
                  <>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: "#039be5" }}>
                        <NotesIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="button" color="text.primary">
                          detalles de finalización
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {props.reclamo.detalles_finalizacion
                              ? props.reclamo.detalles_finalizacion
                              : "No registrado"}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: "#039be5" }}>
                        <CalendarMonthIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="button" color="text.primary">
                          fecha de finalización
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {props.reclamo.fecha_finalizado}
                          </Typography>
                          {props.reclamo.fecha_finalizado
                            ? `Hace ${moment(props.reclamo.fecha_finalizado)
                                .startOf("day")
                                .fromNow()} `
                            : "No registrada."}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  </>
                ):(
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: "#E74C3C" }}>
                        <CancelPresentationIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="button" color="text.primary">
                          detalles cancelación
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {props.reclamo.detalles_cancelacion}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>

                )
              }

              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#039be5" }}>
                    <MonetizationOnIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="button" color="text.primary">
                      costos a cargo de
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {props.reclamo.descr_a_cargo}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default DetailPanel;
