import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  Alert,
  Snackbar,
} from "@mui/material";
import moment from "moment"; // PARA LA FECHA
import { logEventos } from "../../services/services";
//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS
import FileUploadStock from "./FileUploadStock";
import { grey } from "@mui/material/colors";
import Title from "../Title";

function Dialog_reubicar(props) {
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [disabledGuardarRe, setDisabledGuardarRe] = useState(false);
  const [colorTextField, setcolorTextField] = useState();

  const [dialogReubicarArticulo, setDialogReubicarArticulo] = useState(props.dialogReubicarArticulo);
  const [datosArt, setDatosArt] = useState(props.datosArt)
  const [almacenSeleccionado, setAlmacenSeleccionado] = useState({});
  const [openCloseAdjDoc, setOpenCloseAdjDoc] = useState(false); //ADJUNTAR IMAGEN

  // CALCULO DEL TOTAL FINAL
  const [cantReubicacion, setCantReubicacion] = useState(datosArt.cantidad);
  const [cantEnviada, setCantEnviada] = useState(props.cantEnviada);
  useEffect(() => {
    setCantReubicacion(datosArt.cantidad - datosArt.cantidad_enviada);
  }, [datosArt.cantidad, datosArt.cantidad_enviada]);
  useEffect(() => {
    cantEnviada === null
      ? setCantReubicacion(datosArt.cantidad)
      : setCantReubicacion(datosArt.cantidad - datosArt.cantidad_enviada);
  }, [dialogReubicarArticulo]);

  // Calculo reubicacion
  const handleCantidadReubicacion = (e) => {
      setCantReubicacion(e.target.value);
    };

  const [cantidadInventarioTrResta, setCantidadInventarioTrResta] = useState(0);
  
  const [inventarioTrResta, setInventarioTrResta] = useState([]);
  //funcion para obtener sucursales
  const obtenerInventarioTrResta = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/xalmart/" +
        datosArt.id_almacen +
        "/" +
        datosArt.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setInventarioTrResta(data));
  };

  useEffect(() => {
    obtenerInventarioTrResta();
  }, [datosArt.almacen_origen, datosArt.id_articulo]);

    // REUBICACION
    const [inventarioRe, setInventarioRe] = useState([]);

    //funcion para obtener sucursales
    const obtenerInventarioRe = () => {
      fetch(
        UrlApi +
          "/stock_inventarios/xalmart/" +
          almacenSeleccionado.id_almacen +
          "/" +
          datosArt.id_articulo
      )
        .then((data) => data.json())
        .then((data) => setInventarioRe(data));
    };
  
    useEffect(() => {
      obtenerInventarioRe();
    }, [almacenSeleccionado, datosArt.cantidad_enviada]);
  // CALCULO DEL TOTAL FINAL
  useEffect(() => {
    setCantidadInventarioTrResta(
      inventarioTrResta[0]
        ? parseInt(inventarioTrResta[0].cantidad) -
            parseInt(datosArt.cantidad_enviada)
        : null
    );
  }, [datosArt.cantidad_enviada]);

  const [cantidadInventarioReSuma, setCantidadInventarioReSuma] = useState(0);

  // CALCULO DEL TOTAL FINAL
  useEffect(() => {
    setCantidadInventarioReSuma(
      inventarioRe[0]
        ? parseInt(inventarioRe[0].cantidad) +
            parseInt(datosArt.cantidad_enviada)
        : null
    );
  }, [inventarioRe, datosArt.cantidad_enviada, almacenSeleccionado.id_almacen]);

  const guardarReubicacion = async (e) => {
    logEventos("Guardar reubicación", "Stock Sucursales", "Se guardo la reubicación", localStorage.getItem("user"))
      e.preventDefault();
      setDisabledGuardarRe(true);
      await fetch(UrlApi + "/stock_transacciones", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_accion: 176,
          id_articulo: datosArt.id_articulo,
          almacen_origen: datosArt.id_almacen,
          almacen_destino: almacenSeleccionado.id_almacen,
          cantidad_enviada: datosArt.cantidad_enviada,
          detalle_transaccion: datosArt.detalle_transaccion,
          fecha_transaccion: moment(datosArt.fecha_transaccion).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          uuid_documentacion: props.newUuidDoc, //ADJUNTAR IMAGEN
          usuario: localStorage.getItem("user"),
        }),
      })
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("La reubicación se guardó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar la reubicación");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      setDisabledGuardarRe(true);
      // restar cantidad
      await fetch(
        UrlApi +
          "/stock_inventarios/" +
          datosArt.id_almacen +
          "/" +
          datosArt.id_articulo,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            cantidad: cantidadInventarioTrResta,
            usuario: localStorage.getItem("user"),
          }),
        }
      )
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      // suma en el almacen de destino
      if (inventarioRe.length === 0) {
        await fetch(UrlApi + "/stock_inventarios", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id_almacen: almacenSeleccionado.id_almacen,
            id_articulo: datosArt.id_articulo,
            cantidad: datosArt.cantidad_enviada,
            usuario: localStorage.getItem("user"),
          }),
        })
          .then((res) => {
            if (!res.err) {
              setOpenSnack(true);
              setColorMensaje("#D4EFDF");
              setMensaje("El artículo se guardó exitosamente !");
            } else {
              setOpenSnack(false);
              setColorMensaje("#F1948A");
              setMensaje("Error al guardar el artículo");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else {
        await fetch(
          UrlApi +
            "/stock_inventarios/" +
            almacenSeleccionado.id_almacen +
            "/" +
            datosArt.id_articulo,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              cantidad: cantidadInventarioReSuma,
              usuario: localStorage.getItem("user"),
            }),
          }
        )
          .then((res) => {
            if (!res.err) {
              setOpenSnack(true);
              setColorMensaje("#D4EFDF");
              setMensaje("El artículo se guardó exitosamente !");
            } else {
              setOpenSnack(false);
              setColorMensaje("#F1948A");
              setMensaje("Error al guardar el artículo");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
      // PARA LIMPIAR LOS DATOS
      handleClose();
    };
//console.log("///////////////////////", cantidadInventarioTrResta,cantidadInventarioReSuma, inventarioRe);
  const handleClose = () => {
    setDialogReubicarArticulo(!dialogReubicarArticulo)
      if (props.onClose) {
          props.onClose();
        }
    };
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenSnack(false);
    };

  const handleOpenCloseAdjDoc = (value) => {
      setOpenCloseAdjDoc(value);
    };

  const almacenar = (e) => {
      setDatosArt({
        ...datosArt,
        [e.target.name]: e.target.value,
      });
    };
    
  useEffect(() => {
      if (cantReubicacion < 0) {
        setDisabledGuardarRe(true);
        setcolorTextField("error");
      } else {
        setDisabledGuardarRe(false);
        setcolorTextField("primary");
      }
    }, [cantReubicacion, datosArt.cantidad_enviada]);

    const style = {
      backgroundColor: "#ffffff",
      width: "100%",
    };
  return (
    <>
       <Dialog
    open={dialogReubicarArticulo}
    onClose={handleClose}
    maxWidth="md"
    fullWidth
  >
    <Title
            titulo={`Reubicación de Artículo en la Sucursal`}
            handleClose={() => {
              handleClose()
            }}
          />
      <form onSubmit={guardarReubicacion} autoComplete="off">
    <Box
      border={0.5}
      borderColor={grey[300]}
      backgroundColor="#F4F6F6"
      p={2}
      py={2}
      mt={1}
    >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3} py={1} mb={1}>
                <TextField
                  style={style}
                  label="Artículo"
                  required
                  size="small"
                  name="nombre_articulo"
                  value={datosArt.articulo}
                  onChange={almacenar}
                  inputProps={{
                    readOnly: true,
                    "aria-label": "Without label",
                  }}
                />
              </Grid>
              <Grid item xs={3} py={1} mb={1}>
                <TextField
                  style={style}
                  label="Almacen de Origen"
                  size="small"
                  name="almacen"
                  value={datosArt.almacen}
                  onChange={almacenar}
                  inputProps={{
                    readOnly: true,
                    "aria-label": "Without label",
                  }}
                />
              </Grid>
              <Grid item xs={6} py={1} mb={1}>
                <Autocomplete
                  disabled={!props.almacenesActual}
                  size="small"
                  focused
                  required
                  disableClearable
                  id="stock_almacenes"
                  noOptionsText={"Almacén INEXISTENTE"}
                  options={props.almacenesActual}
                  autoHighlight
                  getOptionLabel={(almacenesActual) =>
                    almacenesActual.id_almacen +
                    "-" +
                    almacenesActual.nombre
                  }
                  onChange={(event, value) =>
                    setAlmacenSeleccionado(value)
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.id}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.id_almacen} - {option.nombre}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      style={style}
                      focused
                      required
                      size="small"
                      placeholder="Tipee para buscar..."
                      {...params}
                      label="Almacén de Destino"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextField
                  style={style}
                  label="Detalle Reubicación"
                  name="detalle_transaccion"
                  size="small"
                  placeholder="..."
                  inputProps={{
                    maxLength: 60,
                  }}
                  focused
                  fullWidth
                  value={datosArt.detalle_transaccion}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={4} py={1} mb={1}>
                <TextField
                  style={style}
                  label="Cantidad en el Inventario"
                  size="small"
                  value={cantReubicacion}
                  onChange={handleCantidadReubicacion}
                  inputProps={{
                    readOnly: true,
                    "aria-label": "Without label",
                  }}
                />
              </Grid>
              <Grid item xs={4} py={1} mb={1}>
                <TextField
                  style={style}
                  label="Cantidad a Reubicar"
                  name="cantidad_enviada"
                  size="small"
                  placeholder="..."
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 6,
                  }}
                  focused
                  required
                  fullWidth
                  color={colorTextField}
                  value={datosArt.cantidad_enviada}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={4} py={1} mb={1}>
                <TextField
                  style={style}
                  label="Fecha Reubicación"
                  name="fecha_transaccion"
                  size="small"
                  type="date"
                  focused
                  required
                  fullWidth
                  value={moment(datosArt.fecha_transaccion).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={4} py={1} mb={1}>
                <Button
                  onClick={() => setOpenCloseAdjDoc(!openCloseAdjDoc)}
                  variant="outlined"
                  size="medium"
                  startIcon={<AttachFileIcon />}
                >
                  ADJUNTAR DOCUMENTACIÓN
                </Button>
              </Grid>
            </Grid>

            <Stack direction="row">
              <Grid container direction={{ xs: "column", sm: "row" }}>
                <Grid item xs={8}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Volver
                  </Button>
                </Grid>

                <Grid item xs={4}>
                  <Button
                    disabled={disabledGuardarRe}
                    fullWidth
                    variant="contained"
                    color="success"
                    type="submit"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Stack>
     </Box>
        </form>
        </Dialog>

    {openCloseAdjDoc ? ( // ADJUNTAR COMPROBANTES
    <FileUploadStock
      fileId={props.newUuidDoc}
      openclose={true}
      mostrar={true}
      onClose={() => handleOpenCloseAdjDoc(false)} // Proporciona una función de cierre
    />
  ) : null}
  <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={openSnack}
        autoHideDuration={1500}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="success"
          sx={{ width: "100%" }}
          style={{ backgroundColor: colormensaje, color: "black" }}
        >
          {mensaje}
        </Alert>
      </Snackbar>
    </Stack>
  </>
  )
}

export default Dialog_reubicar