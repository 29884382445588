//----- Component --------------------------------------------------------/

// Developer :  Daiana Curcio / Candela Grosso

// Creation Date :14/12/2023

// Version : 1

// Description : Para finalizar los reclamos

//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import {
  Dialog,
  Box,
  Grid,
  IconButton,
  Typography,
  Button,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { useState, useEffect } from "react";
import { grey } from "@mui/material/colors";
import Notify from "../../Notify";
import moment from "moment";
import { putData } from "../../../services/library";
import { enviarmails } from "../../../services/services";
import { UrlApi } from "../../../services/apirest";
import { logEventos } from "../../../services/services";

function CancelarReclamo(props) {
  const [disbtn, setdisBtn] = useState(false);
  const [fecha, setFecha] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [detalle, setDetalle] = useState({
    detalles_cancelacion: "",
  });
  const { datos } = props;
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  // mail
  const [usuarioMail, setUsuarioMail] = useState([]);

  const obtenerUsuarioMail = () => {
    fetch(UrlApi + "/reclamos_config/")
      .then((data) => data.json())
      .then((data) => setUsuarioMail(data));
  };

  useEffect(() => {
    obtenerUsuarioMail();
  }, []);

  // console.log("USUARIO MAIL", usuarioMail);
  // console.log("DETALLE",detalle);
  // console.log("DATA RECLAMO",datos);
 // console.log("N° reclamo", datos.idreclamo);
 // console.log("Cliente", datos.razon_social);
 // console.log("Motivo", datos.descripcion_motivo);
//  console.log("Detalle R", datos.detalle);
 // console.log("Asignado", datos.proveedor);
 // console.log("Detalle Canc", datos.detalles_cancelacion);

  const handleGuardarReclamo = async (e) => {
   logEventos("Guardar la cancelación de un reclamo", "Reclamos Granel", "Se guardo la cancelación del reclamo", localStorage.getItem("user"))
    await putData(
      e,
      "/reclamos/" + props.datos.idreclamo,
      {
        estado: 223,
        fecha_finalizado: moment(fecha).format("YYYY-MM-DD"),
        detalles_cancelacion: detalle.detalles_cancelacion,
      },
      setNotificacion
    );
    // Luego, verificar si hay usuarios con direcciones de correo electrónico y enviar correos electrónicos si es necesario
    if (usuarioMail && usuarioMail.length > 0 && detalle && datos) {
      usuarioMail.forEach((usuario) => {
        // Verificar que el usuario tiene una dirección de correo electrónico
        if (usuario.email_usuario) {
          // Llamar a la función para enviar correos electrónicos
          enviarmails(
            usuario.email_usuario,
            "Reclamo Cancelado",
            `<p>Se canceló :
            <br><b>El reclamo N° : </b>${datos.idreclamo}</br>
            <br><b>Del cliente : </b>${datos.razon_social}</br>
            <br><b>Por el motivo de : </b>${datos.descripcion_motivo}</br>
            <br><b>Con detalle : </b>${datos.detalle}</br>
            <br><b>Asignado al técnico : </b>${datos.proveedor}</br>
            <br><b>Acciones realizadas : </b>${detalle.detalles_cancelacion}</br>.</p>`
          );
        } else {
          console.warn(
            `El usuario con ID ${usuario.idreclamos_config} no tiene una dirección de correo electrónico válida.`
          );
        }
      });
    }
  };

  const handleClose = () => {
    props.tabla(true);
    props.open(false);
    props.actualizar();
  };

  const almacenar = (e) => {
    setDetalle({
      ...detalle,
      [e.target.name]: e.target.value,
    });
  };
 // console.log(detalle);
  return (
    <div>
      <Dialog open={props.open} fullWidth>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container backgroundColor="#1F618D" p={1.5}>
            <Grid item xs={2}>
              <IconButton onClick={() => props.open(false)}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Cancelar reclamo
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <DialogContent>
          <DialogContentText textAlign="center">
            ¿Desea marcar el reclamo como cancelado en la fecha{" "}
            {moment(fecha).format("DD-MM-YYYY")}?
            <TextField
              sx={{ mt: 2 }}
              autoComplete="off"
              label="Detalles"
              name="detalles_cancelacion"
              size="small"
              placeholder="..."
              inputProps={{
                maxLength: 150,
              }}
              focused
              required
              fullWidth
              value={detalle.detalles_cancelacion}
              onChange={almacenar}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            mt={0}
          >
            <Button
              disabled={disbtn}
              variant="contained"
              type="submit"
              color="success"
              size="small"
              onClick={handleGuardarReclamo}
            >
              Cancelar Reclamo
            </Button>
          </Grid>
        </DialogActions>

        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            handleClose={handleClose}
            severity={notificacion.severity}
          />
        ) : null}
      </Dialog>
    </div>
  );
}

export default CancelarReclamo;
