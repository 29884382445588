//----- Component --------------------------------------------------------/
// Developer     : Guillermo Sedran
// Creation Date : 14/11/2023
// Version       : 1
// Description   : Dialog para agregar una acción a el hallazgo de auditoria
//
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description:
//
// Change Date   :
// Change by     :
// Change description:
//
//========================================================================/

import React, { useState } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Box,
  Container,
  DialogContent,
  DialogTitle,
  Stack,
  Grid,
  TextField,
} from "@mui/material";
import moment from "moment";

// LOG EVENTOS
import { logEventos } from "../../services/services";

// ENVIAR MAILS
import { enviarmails } from "../../services/services";

// Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// ICONOS
import AttachFileIcon from "@mui/icons-material/AttachFile";

//ADJUNTAR ARCHIVOS
// import FileUploadMultiple from "../../services/UploadFiles"; //ADJUNTAR ARCHIVOS
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR ARCHIVOS
import FileUploadAccion from "./FileUploadAccion";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Auditorias_agregar_accion(props) {
  // ADJUNTOS
  const [uuid_accion, setUuid_accion] = useState(uuidv4()); //ADJUNTAR ARCHIVOS
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR COMPROBANTE
  const [newUuid, setNewUuid] = useState(props.newUuid); //ADJUNTAR COMPROBANTE

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  console.log("NEW UUID", newUuid);
  console.log("UUID HALLAZGO", uuid_accion);

  //*************************************************************************************************************************************/

  const [row, setRow] = useState(props.row);
  console.log("ROW ACCION: ", row);
  const [date, setDate] = useState(props.date);
  const [openCrearAccion, setOpenCrearAccion] = useState(props.openCrearAccion);
  const [datosAuditoriaAcciones, setDatosAuditoriaAcciones] = useState({
    id_hallazgo: row.original.id_hallazgo,
    accion_realizada: "",
    fecha: date,
    estado: 182,
  });

  // NOTIFICACION
  const [openSnack, setOpenSnack] = useState(props.openSnack);
  const [colormensaje, setColorMensaje] = useState(props.colormensaje);
  const [mensaje, setMensaje] = useState(props.mensaje);
  // DESHABILITAR BOTON AL GUARDAR
  const [disBtn, setDisBtn] = useState(props.disBtn);

  // -----------------------------------------------------------

  const handleClickOpenCrear = () => {
    setOpenCrearAccion(true);
    // AL ABRIR EL DIALOG LOS TEXTFIELDS SE VACIAN
    datosAuditoriaAcciones.accion_realizada = "";
    datosAuditoriaAcciones.fecha = date;
    datosAuditoriaAcciones.estado = 182;
  };

  // ------------------------------------------------------------------------

  //Funcion para guardar al crear
  const guardarCrear = async (e) => {
    logEventos("Crear accion", "Auditorias", "Crear acción en hallazgo de auditorias", localStorage.getItem("user"))

    console.log("Estoy por guardar");
    setDisBtn(true);
    e.preventDefault();
    await fetch(UrlApi + "/auditorias_acciones", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_hallazgo: datosAuditoriaAcciones.id_hallazgo,
        accion_realizada: datosAuditoriaAcciones.accion_realizada,
        fecha: datosAuditoriaAcciones.fecha,
        uuid_accion: uuid_accion,
        estado: datosAuditoriaAcciones.estado,
        usuario_que_registra:  localStorage.getItem("user")
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La acción se guardó exitosamente !");
          console.log("Guardado");
          // ENVIAR MAIL
          row &&
            enviarmails(
              row.original.email_auditor,
              "Auditoria: Nueva Acción",
              `<p>Se agregó una nueva acción al hallazgo: ${row.original.problema_sugerencia} .</p>
          <p><b>Descripción:</b> ${datosAuditoriaAcciones.accion_realizada} .</p>`
            );
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la acción");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // PARA LIMPIAR LOS DATOS
    handleClickOpenCrear();

    // VERIFICACION PARA EL ESTADO DE LA AUDITORIA
    // Si todas las acciones están finalizadas, actualizar el estado de la auditoría a 'finalizado'
    await fetch(UrlApi + "/auditorias/" + datosAuditoriaAcciones.id_hallazgo, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        estado: 179,
      }),
    });

     // Actualizar auditorías después de completar todas las operaciones
     props.actualizarAuditorias();
  };

  //  ------------------------------------------------------------------------

  const almacenar = (e) => {
    setDatosAuditoriaAcciones({
      ...datosAuditoriaAcciones,
      [e.target.name]: e.target.value,
    });
  };

  //  ------------------------------------------------------------------------

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setDisBtn(false);

    props.handleCloseDialogCrearAccion();
  };

  console.log("datosAuditoriaAcciones", datosAuditoriaAcciones);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={2}
      >
        <DialogTitle alignSelf="center">
          <b>{"Agregar Acción"}</b>
        </DialogTitle>
        <TextField
          focused
          required
          size="small"
          type="date"
          placeholder="Fecha de Realización"
          name="fecha"
          label="Fecha de Realización"
          variant="outlined"
          value={moment(datosAuditoriaAcciones.fecha).format("YYYY-MM-DD")}
          onChange={almacenar}
          inputProps={{
            min: row.original.fecha_registro,
            max: date
          }}
        />
      </Box>

      <DialogContent>
        <Container maxWidth="xl">
          <form onSubmit={guardarCrear}>
            <Grid container mb={1} spacing={1}>
              <Grid item xs={12} mb={2}>
                <TextField
                  label="Descripción"
                  fullWidth
                  required
                  size="small"
                  name="accion_realizada"
                  value={datosAuditoriaAcciones.accion_realizada}
                  onChange={almacenar}
                />
              </Grid>
            </Grid>

            <Stack direction="row">
              <Grid container direction={{ xs: "column", sm: "row" }}>
                <Grid item xs={9}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={props.handleCloseDialogCrearAccion}
                  >
                    Volver
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={disBtn}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </form>
        </Container>
      </DialogContent>

      {openCloseAdj ? ( // ADJUNTAR
        <FileUploadAccion
          // ARREGLAR DE DONDE TOMA EL FILEID
          fileId={newUuid}
          openclose={true}
          mostrar={true}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}

      {/* MENSAJE AL GUARDAR */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Auditorias_agregar_accion;
