//----- Component --------------------------------------------------------/
// Developer     : Guillermo Sedran
// Creation Date : 27/12/2023
// Version       : 1
// Description   : Tabla para Agregar, Editar y Deshabilitar Sectores
//
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   :
// Change by     :
// Change description:
//
// Change Date   :
// Change by     :
// Change description:
//
//========================================================================/

import Navbar from "../Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Stack,
  Grid,
  Tooltip,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

// LOG EVENTOS
import { logEventos } from "../../services/services";

//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import BlockIcon from "@mui/icons-material/Block";

// ICONOS
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import EditIcon from "@mui/icons-material/Edit";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Sectores() {
  const [sectores, setSectores] = useState({});
  const [datosFormulario, setDatosFormulario] = useState({
    nombre_sector: "",
    estado: 1,
  });
  const [update, setUpdate] = useState(false);
  const [tabla, setTabla] = useState(true);

  const obtenerSectores = () => {
    fetch(UrlApi + "/sectores/tabla")
      .then((data) => data.json())
      .then((data) => {
        setSectores(data); // Datos de la tabla
      });
  };

  useEffect(() => {
    obtenerSectores();
  }, [tabla]);

  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerSectores();
  }, [update]);

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  //*************************************************************************************************************************************/

  const columns = useMemo(
    () => [
      { header: "ID", accessorKey: "id_sector", size: 100 },
      { header: "Nombre", accessorKey: "nombre_sector", size: 100 },
    ],
    []
  );
  //*************************************************************************************************************************************/

  const muiSearchTextFieldProps = {
    // para el buscar
    placeholder: "Buscar...",
    sx: { minWidth: "18rem" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  //*************************************************************************************************************************************/

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpenEditar(false);
    setOpenCrear(false);
    setOpenDialogOnOff(false);
  };

  //*************************************************************************************************************************************/

  // DIALOG PARA CREAR LOS DATOS
  const [openCrear, setOpenCrear] = React.useState(false);

  const handleClickOpenCrear = () => {
    setOpenCrear(true);
    // AL ABRIR EL DIALOG VACIO EL TEXTFIELD
    datosFormulario.nombre_sector = "";
  };

  const handleCloseCrear = () => {
    setOpenCrear(false);
    // RECARGAR TABLA
    obtenerSectores();
  };

  //*************************************************************************************************************************************/

  // DIALOG PARA EDITAR LOS DATOS
  const [openEditar, setOpenEditar] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenEditar = (row) => {
    setOpenEditar(true);
    setDatosFormulario(row);
  };

  const handleCloseEditar = (row) => {
    setOpenEditar(false);
  };

  console.log("EDITAR SECTOR: ", openEditar);

  //**********************************************************************************************/

  // DIALOG PARA ACTIVAR/DESACTIVAR LA RELACIÓN
  const [openDialogOnOff, setOpenDialogOnOff] = React.useState(false);

  const handleCloseDialogOnOff = () => {
    setOpenDialogOnOff(!openDialogOnOff);
  };

  const dialogActivar = (row) => {
    setDatosFormulario(row);
    setOpenDialogOnOff(true);
  };

  //*************************************************************************************************************************************/

  const guardarCrear = async (e) => {
    logEventos("Guardar sector", "Sectores", "Guardar sector", localStorage.getItem("user"))

    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/sectores", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nombre_sector: datosFormulario.nombre_sector,
        estado: 1,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El sector se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el sector");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  console.log(datosFormulario);

  //*************************************************************************************************************************************/

  const guardarEditar = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/sectores/" + datosFormulario.id_sector, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        nombre_sector: datosFormulario.nombre_sector,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El sector se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el sector");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  console.log(datosFormulario);

  //*************************************************************************************************************************************/

  const almacenar = (e) => {
    setDatosFormulario({
      ...datosFormulario,
      [e.target.name]: e.target.value,
    });
  };

  //*************************************************************************************************************************************/

  // FUNCIONES PARA GUARDAR:
  // ACTIVAR TIPO DE ENVASE
  const guardarActivo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/sectores/" + datosFormulario.id_sector, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        estado: 1,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El sector se guardó exitosamente !");
          setUpdate(true);
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el sector");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // DESACTIVAR TIPO DE ENVASE
  const guardarInactivo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/sectores/" + datosFormulario.id_sector, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        estado: 0,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El sector se guardó exitosamente !");
          setUpdate(true);
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el sector");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //*************************************************************************************************************************************/
  // ESTILO PARA LA TABLA

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
        sx: {
          fontSize: 14,
          fontStyle: "italic",
        },
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size: 20,
    },
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        <Box mt={2} mb={4}>
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
              enableGrouping // para permitir agrupar x estado x ej
              displayColumnDefOptions={displayColumnDefOptions}
              layoutMode="grid"
              muiTableHeadCellProps={{
                align: "left", // los titulos de la tabla
              }}
              muiTableBodyCellProps={({ row }) => ({
                align: "left", //el contenido de la tabla
                sx: {
                  backgroundColor:
                    row.original.estado === 1 ? "#66FF66" : "#FF5050",
                  color: "black",
                  borderColor: "black",
                  //COLOR AL PASAR EL MOUSE POR ENCIMA
                  "&:hover": {
                    backgroundColor:
                      row.original.estado === 1 ? "#3ad63a" : "#f22929",
                  },
                  "& .MuiTableCell-root:first-child": {
                    border: "none",
                  },
                },
              })}
              enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
              enableColumnFilters={false} //desactiva filtros x columna
              enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
              enableDensityToggle={false}
              enableColumnDragging={false} //para arrastrar columnas
              enableEditing={false}
              localization={MRT_Localization_ES} //idioma
              initialState={initialState}
              muiSearchTextFieldProps={muiSearchTextFieldProps}
              columns={columns}
              data={sectores}
              enableStickyFooter
              positionActionsColumn="last" // posicion de la columna de acciones al final
              enablePagination={false}
              enableRowActions
              renderRowActions={({ row }) => (
                <>
                  <Tooltip arrow placement="left" title="Editar Sector">
                    <Button
                      backgroundColor="#ffffff"
                      size="small"
                      onClick={() => handleClickOpenEditar(row.original)}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    arrow
                    placement="bottom"
                    title={
                      row.original.estado === 1
                        ? "Bloquear Sector"
                        : "Activar Sector"
                    }
                  >
                    <IconButton onClick={() => dialogActivar(row.original)}>
                      <BlockIcon
                        sx={{
                          color:
                            row.original.estado === 1 ? "red" : "lightgreen",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              renderTopToolbarCustomActions={() => (
                <>
                  <Grid container alignItems="center">
                    <Typography variant="poster" fontSize={26}>
                      Sectores
                    </Typography>
                  </Grid>

                  <Grid container alignItems="center" mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleClickOpenCrear}
                      size="small"
                    >
                      Agregar Sector
                    </Button>
                  </Grid>
                </>
              )}
            />
          </ThemeProvider>
        </Box>
      </Container>

      {/* CREAR ITEM */}
      <Dialog
        open={openCrear}
        onClose={handleCloseCrear}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle marginLeft={3}>
          <b>{"Agregar Sector"}</b>
        </DialogTitle>
        <form onSubmit={guardarCrear}>
          <DialogContent>
            <Container maxWidth="xl">
              <Grid container mb={1}>
                <Grid item xs={12} mb={1}>
                  <TextField
                    label="Nombre"
                    name="nombre_sector"
                    size="small"
                    placeholder="..."
                    focused
                    required
                    fullWidth
                    id="nombre_sector"
                    value={datosFormulario.nombre_sector}
                    onChange={almacenar}
                  />
                </Grid>
              </Grid>
            </Container>
          </DialogContent>

          <Stack direction="row" justifyContent="center" spacing={2} mb={3}>
            <Grid container justifyContent="center">
              <Grid item xs={8}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseCrear}
                >
                  Volver
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  color="success"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </Dialog>

      {/* EDITAR ITEM */}
      <Dialog
        open={openEditar}
        onClose={handleCloseEditar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle marginLeft={3}>
          <b>{"Editar Sector"}</b>
        </DialogTitle>

        <form onSubmit={guardarEditar}>
          <DialogContent>
            <Container maxWidth="xl">
              <Grid container mb={1}>
                <Grid item xs={12} mb={1}>
                  <TextField
                    label="Nombre"
                    name="nombre_sector"
                    size="small"
                    placeholder="..."
                    focused
                    required
                    fullWidth
                    id="nombre_sector"
                    value={datosFormulario.nombre_sector}
                    onChange={almacenar}
                  />
                </Grid>
              </Grid>
            </Container>
          </DialogContent>

          <Stack direction="row" justifyContent="center" spacing={2} mb={3}>
            <Grid container justifyContent="center">
              <Grid item xs={8}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseEditar}
                >
                  Volver
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  color="success"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </Dialog>

      {/* DIALOG PARA BLOQUEAR/DESBLOQUEAR LA RELACION */}
      <Dialog open={openDialogOnOff} onClose={handleCloseDialogOnOff}>
        <Box
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: datosFormulario.estado === 1 ? "#e32712" : "#24ff1c",
            color: datosFormulario.estado === 1 ? "red" : "green",
          }}
        >
          <DialogTitle>
            <Typography align="center">
              <b>
                SE {datosFormulario.estado === 1 ? "BLOQUEARÁ" : "DESBLOQUEARÁ"}{" "}
                EL SECTOR:
              </b>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              textAlign={"center"}
              style={{ marginBottom: "40px" }}
            >
              <Grid item textAlign={"center"} ml={2}>
                <br />
                <b>{datosFormulario.nombre_sector}</b>
              </Grid>
            </DialogContentText>
            <DialogContentText
              textAlign={"center"}
              style={{ marginBottom: "-10px" }}
            >
              {"¿Confirma que quiere " +
                (datosFormulario.estado === 1 ? "bloquear" : "desbloquear") +
                " este sector?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack spacing={4} direction="row">
              <Grid item mr={16}>
                <Button
                  style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                  variant="contained"
                  onClick={handleCloseDialogOnOff}
                >
                  Volver
                </Button>
              </Grid>
              {datosFormulario.estado === 1 ? (
                <Button
                  style={{ backgroundColor: "#fc7a23", color: "#ffffff" }}
                  onClick={guardarInactivo}
                  variant="contained"
                >
                  Bloquear
                </Button>
              ) : (
                <Button
                  style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                  onClick={guardarActivo}
                  variant="contained"
                >
                  Desbloquear
                </Button>
              )}
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>

      {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Sectores;
