import React, { useState, useEffect, useMemo, useRef } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Stack,
  Snackbar,
  TextField,
  Autocomplete,
} from "@mui/material";
import moment from "moment"; // PARA LA FECHA
import AutorenewIcon from "@mui/icons-material/Autorenew";
//enviar mails
import { enviarmails } from "../../services/services";
import { grey } from "@mui/material/colors";
import Title from "../Title";
import { postData } from "../../services/library";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
import { logEventos } from "../../services/services";

function Dialog_conversion(props) {
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [abrirDialogConversion, setAbrirDialogConversion] = useState(
    props.abrirDialogConversion
  );
  const [datosArt, setDatosArt] = useState(props.datosArt);
  const [almacenes, setAlmacenes] = useState(props.almacenes);
  const [almacenSeleccionado, setAlmacenSeleccionado] = useState({});
  const [disabledGuardarRe, setDisabledGuardarRe] = useState(false);
  const [colorTextField, setcolorTextField] = useState();
  // const [usuarioCompras, setUsuarioCompras] = useState([props.usuarioCompras]);

  const [usuarioCompras, setUsuarioCompras] = useState([]);

  const obtenerUsuarioCompras = () => {
    fetch(UrlApi + "/userdata/sector/4/")
      .then((data) => data.json())
      .then((data) => setUsuarioCompras(data));
  };

  useEffect(() => {
    obtenerUsuarioCompras();
  }, []);

 // console.log("USUARIO COMPRAS", usuarioCompras);

  const [reqExiste, setReqExiste] = useState([]);

const obtenerRequerimientoExistente = () => {
  fetch(UrlApi + "/requerimientos_materiales/reqexiste/" + props.sucursal + "/" + datosArt.id_articulo)
    .then((data) => data.json())
    .then((data) => setReqExiste(data));
};

useEffect(() => {
  obtenerRequerimientoExistente();
}, [datosArt]);

//console.log("REQUERIMIENTO", reqExiste, reqExiste.length);

  const [inventarioTrResta, setInventarioTrResta] = useState([]);
  //funcion para obtener sucursales
  const obtenerInventarioTrResta = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/xalmart/" +
        datosArt.id_almacen +
        "/" +
        datosArt.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setInventarioTrResta(data));
  };

  useEffect(() => {
    obtenerInventarioTrResta();
  }, [datosArt.almacen_origen, datosArt.id_articulo]);

  const [inventarioConv, setInventarioConv] = useState([props.inventarioConv]);
  const obtenerInventarioConv = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/xalmart/" +
        almacenSeleccionado.id_almacen +
        "/" +
        datosArt.convertible_a
    )
      .then((data) => data.json())
      .then((data) => setInventarioConv(data));
  };

  useEffect(() => {
    obtenerInventarioConv();
  }, [almacenSeleccionado.id_almacen, datosArt.convertible_a]);
  const [cantidadInventarioConv, setCantidadInventarioConv] = useState(0);

  // CALCULO DEL TOTAL FINAL
  useEffect(() => {
    setCantidadInventarioConv(
      inventarioConv[0]
        ? parseInt(inventarioConv[0].cantidad) +
            parseInt(datosArt.cantidad_enviada)
        : null
    );
  }, [inventarioConv, datosArt.cantidad_enviada, almacenSeleccionado.id_almacen]);

  const [cantidadInventarioTrResta, setCantidadInventarioTrResta] = useState(0);
  const handleCantidadInventarioTrResta = (e) => {
    setCantidadInventarioTrResta(e.target.value);
  };

  // CALCULO DEL TOTAL FINAL
  useEffect(() => {
    setCantidadInventarioTrResta(
      inventarioTrResta[0]
        ? parseInt(inventarioTrResta[0].cantidad) -
            parseInt(datosArt.cantidad_enviada)
        : null
    );
  }, [datosArt.cantidad_enviada]);
  // console.log(
  //   "%%%%%CANTIDAD%%%%%",
  //   inventarioTrResta,
  //   cantidadInventarioTrResta
  // );

  const envioMail = async (e) => {
    usuarioCompras &&
      usuarioCompras.length > 0 &&
      usuarioCompras.map((usuario) => {
        if (usuario.email) {
          if (
            cantidadInventarioTrResta < datosArt.alerta_minima &&
            cantidadInventarioTrResta > datosArt.valor_minimo
          ) {
          //  console.log("EMAIL de CONSUMO", usuario.email);
            enviarmails(
              usuario.email,
              "Advertencia de Nivel de Stock ",
              `<p>El artículo <b>${datosArt.articulo}</b> del almacén <b>${datosArt.almacen}</b> esta por debajo del punto de pedido.<br/></p>`
            );
          } else if (cantidadInventarioTrResta < datosArt.valor_minimo) {
            enviarmails(
              usuario.email,
              "Alerta de Stock por Debajo del Nivel Mínimo",
              `<p>El artículo <b>${datosArt.articulo}</b> del almacén <b>${datosArt.almacen}</b> esta por debajo del stock mínimo.<br/></p>`
            );
          }
        }
      });
  };
  // console.log(
  //   "cantidad de cantidadInventarioTrResta",
  //   cantidadInventarioTrResta,
  //   "punto de pedido ",
  //   datosArt.alerta_minima,
  //   "stock min",
  //   datosArt.valor_minimo,
  //   "almacen",
  //   datosArt.almacen
  // );
// *********************************REQUERIMIENTO***************************************** //
const [notificacion, setNotificacion] = useState({
  open: false,
  mensaje: "",
  severity: "",
  color: "",
});
const [uuid, setUuid] = useState(uuidv4());

const [envioRequerimiento, setEnvioRequerimiento] = useState({
  id_movil: null,
  id_sucursal: props.sucursal,
  fecha_entrega: moment(datosArt.fecha_transaccion).format(
    "YYYY-MM-DD HH:mm:ss"
  ),
  usuario_registra: localStorage.getItem('user'),
  usuario_autoriza: null,
  estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
  para_comprar: 1,
  observaciones: "",
  uuid_requerimiento: uuid,
  comentarios_internos: "Pedido Automático - Definir Cantidad",
  para_compras: 0,
  para_rrhh: 0,
  autorizado_jefe_compras: 0,
})

const [datosLista, setDatosLista] = useState([{
  material: null,
  usuario: localStorage.getItem('user'),
  cantidad: 1,
  // id_unidad_medida: datosArt.id_unidad_medida,
  uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
  id_articulo: datosArt.id_articulo
}]);


useEffect(() => {
setDatosLista([{
  material: null,
  usuario: localStorage.getItem('user'),
  cantidad: 1,
  // id_unidad_medida: datosArt.id_unidad_medida,
  uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
  id_articulo: datosArt.id_articulo
}])
setEnvioRequerimiento({
id_movil: null,
id_sucursal: props.sucursal,
fecha_entrega: moment(datosArt.fecha_transaccion).format(
  "YYYY-MM-DD HH:mm:ss"
),
usuario_registra: localStorage.getItem('user'),
usuario_autoriza: null,
estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
para_comprar: 1,
observaciones: "",
uuid_requerimiento: uuid,
comentarios_internos: "Pedido Automático - Definir Cantidad",
para_compras: 0,
para_rrhh: 0,
autorizado_jefe_compras: 0,
})
}, [datosArt])

const guardarListaMateriales = (e) => {
postData(e,
    "/lista_materiales",
    {
        datosLista
    },
    setNotificacion
);
};
// ************************************************************************** //
  const guardarConversion = async (e) => {
    logEventos("Guardar conversión", "Stock Sucursales", "Se guardo conversión", localStorage.getItem("user"))
    e.preventDefault();
    await fetch(UrlApi + "/stock_transacciones", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_accion: 209,
        id_transaccion: datosArt.id_transaccion,
        id_articulo: datosArt.id_articulo,
        convertido_a: datosArt.convertible_a,
        almacen_origen: datosArt.id_almacen,
        almacen_destino: almacenSeleccionado.id_almacen,
        cantidad_enviada: datosArt.cantidad_enviada,
        fecha_transaccion: moment(datosArt.fecha_transaccion).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La conversión se guardó exitosamente !");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al convertir el artículo");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

    // ------ { AGREGAR SEGUNDO GUARDAR (?) } ------
    e.preventDefault();
    if (inventarioConv.length === 0) {
      await fetch(UrlApi + "/stock_inventarios", {
        // PUT SI YA EXISTE / POST SI ES NUEVO
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_articulo: datosArt.convertible_a,
          cantidad: datosArt.cantidad_enviada,
          id_almacen: almacenSeleccionado.id_almacen,
          usuario: localStorage.getItem("user"),
        }),
      })
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El artículo se guardó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      await fetch(
        UrlApi +
          "/stock_inventarios/" +
          datosArt.id_almacen +
          "/" +
          datosArt.id_articulo,
        {
          // PUT SI YA EXISTE / POST SI ES NUEVO
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            cantidad: cantidadInventarioTrResta,
            usuario: localStorage.getItem("user"),
          }),
        }
      )
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El artículo se guardó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      await fetch(
        UrlApi +
          "/stock_inventarios/" +
          almacenSeleccionado.id_almacen +
          "/" +
          datosArt.convertible_a,
        {
          // PUT SI YA EXISTE / POST SI ES NUEVO
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            cantidad: cantidadInventarioConv,
            usuario: localStorage.getItem("user"),
          }),
        }
      )
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El artículo se guardó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      await fetch(
        UrlApi +
          "/stock_inventarios/" +
          datosArt.id_almacen +
          "/" +
          datosArt.id_articulo,
        {
          // PUT SI YA EXISTE / POST SI ES NUEVO
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            cantidad: cantidadInventarioTrResta,
            usuario: localStorage.getItem("user"),
          }),
        }
      )
        .then((res) => {
          if (!res.err) {
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("El artículo se guardó exitosamente !");
          } else {
            setOpenSnack(false);
            setColorMensaje("#F1948A");
            setMensaje("Error al guardar el artículo");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    if (cantidadInventarioTrResta<datosArt.alerta_minima && cantidadInventarioTrResta>=datosArt.valor_minimo ) {
    //  console.log("ADVERTENCIA AMARILLA");
      if (datosArt.pedido_automatico === 1 && reqExiste.length === 0) {
      //  console.log("ES AUTOMATICO AMARILLO");
        postData(e,
          '/requerimientos_materiales',
          {
              id_sucursal: envioRequerimiento.id_sucursal,
              id_movil: envioRequerimiento.id_movil,
              estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
              para_comprar: envioRequerimiento.para_comprar,
              para_compras: envioRequerimiento.para_compras,
              para_rrhh: envioRequerimiento.para_rrhh,
              fecha_entrega: envioRequerimiento.fecha_entrega,
              usuario_registra: envioRequerimiento.usuario_registra,
              usuario_autoriza: envioRequerimiento.username,
              observaciones: envioRequerimiento.observaciones,
              uuid_requerimiento: uuid,
              comentarios_internos: envioRequerimiento.comentarios_internos,
              autorizado_jefe_compras: 0,
          },
          setNotificacion,
          guardarListaMateriales(e),
          )
          .then((response) => {
            console.log("Respuesta de postData en guardarCabecera:", response);
          })
          .catch((error) => {
            console.error("Error en postData en guardarCabecera:", error);
          });
      }
    } else if (cantidadInventarioTrResta<datosArt.valor_minimo) {
     // console.log("ADVERTENCIA ROJA");
      if (datosArt.pedido_automatico === 1 && reqExiste.length === 0) {
     //   console.log("ES AUTOMATICO ROJO");
        postData(e,
          '/requerimientos_materiales',
          {
              id_sucursal: envioRequerimiento.id_sucursal,
              id_movil: envioRequerimiento.id_movil,
              estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
              para_comprar: envioRequerimiento.para_comprar,
              para_compras: envioRequerimiento.para_compras,
              para_rrhh: envioRequerimiento.para_rrhh,
              fecha_entrega: envioRequerimiento.fecha_entrega,
              usuario_registra: envioRequerimiento.usuario_registra,
              usuario_autoriza: envioRequerimiento.username,
              observaciones: envioRequerimiento.observaciones,
              uuid_requerimiento: uuid,
              comentarios_internos: envioRequerimiento.comentarios_internos,
              autorizado_jefe_compras: 0
          },
          setNotificacion,
          guardarListaMateriales(e),
          )
          .then((response) => {
            console.log("Respuesta de postData en guardarCabecera:", response);
          })
          .catch((error) => {
            console.error("Error en postData en guardarCabecera:", error);
          });
      }
    }
    envioMail();
    handleClose();
  };

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleClose = () => {
    setAbrirDialogConversion(!abrirDialogConversion);
    if (props.onClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    if (cantidadInventarioTrResta < 0) {
      setDisabledGuardarRe(true);
      setcolorTextField("error");
    } else {
      setDisabledGuardarRe(false);
      setcolorTextField("primary");
    }
  }, [cantidadInventarioTrResta, datosArt.cantidad_enviada]);

    const almacenar = (e) => {
        setDatosArt({
          ...datosArt,
          [e.target.name]: e.target.value,
        });
      };

    const style = {
      backgroundColor: "#ffffff",
      width: "100%",
    };  
  return (
    <>
       <Dialog
    open={abrirDialogConversion}
    onClose={handleClose}
    maxWidth="md"
    fullWidth
  >
    <Title
            titulo={`Conversión de Artículo`}
            handleClose={() => {
              handleClose()
            }}
          />
      <form onSubmit={guardarConversion} autoComplete="off">
          <Box
            border={0.5}
            borderRadius={4}
            borderColor={grey[300]}
            backgroundColor="#F4F6F6"
            p={2}
            py={2}
            mt={1}
          >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={5} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Artículo"
                        required
                        size="small"
                        name="nombre_articulo"
                        value={datosArt.articulo}
                        onChange={almacenar}
                        inputProps={{
                          readOnly: true,
                          "aria-label": "Without label",
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} py={1} mb={1}></Grid>
                    <Grid item xs={5} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Convierte a"
                        required
                        size="small"
                        name="nombre_convertible"
                        value={datosArt.nombre_convertible}
                        onChange={almacenar}
                        inputProps={{
                          readOnly: true,
                          "aria-label": "Without label",
                        }}
                      />
                    </Grid>
                    <Grid item xs={5} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Almacen de Origen"
                        size="small"
                        name="almacen"
                        value={datosArt.almacen}
                        inputProps={{
                          readOnly: true,
                          "aria-label": "Without label",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      py={1}
                      mb={1}
                      style={{ textAlign: "center" }}
                    >
                      <AutorenewIcon
                        color="primary"
                        style={{ fontSize: "2rem" }}
                      />
                    </Grid>
                    <Grid item xs={5} py={1} mb={1}>
                      <Autocomplete
                        disabled={!almacenes}
                        size="small"
                        focused
                        disableClearable
                        id="stock_almacenes"
                        noOptionsText={"Almacén INEXISTENTE"}
                        options={almacenes}
                        autoHighlight
                        getOptionLabel={(almacenes) =>
                          almacenes.id_almacen + "-" + almacenes.nombre
                        }
                        onChange={(event, value) =>
                          setAlmacenSeleccionado(value)
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            key={option.id}
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.id_almacen} - {option.nombre}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            style={style}
                            focused
                            required
                            size="small"
                            placeholder="Tipee para buscar..."
                            {...params}
                            label="Almacén de Destino"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={5} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Cantidad en el Inventario"
                        name="cantidad"
                        size="small"
                        inputProps={{
                          readOnly: true,
                          "aria-label": "Without label",
                        }}
                        value={datosArt.cantidad}
                        onChange={almacenar}
                      />
                    </Grid>
                    <Grid item xs={2} py={1} mb={1}></Grid>
                    <Grid item xs={5} py={1} mb={1}>
                      <TextField
                        style={style}
                        label="Cantidad a Convertir"
                        name="cantidad_enviada"
                        size="small"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{
                          maxLength: 60,
                        }}
                        focused
                        required
                        fullWidth
                        color={colorTextField}
                        value={datosArt.cantidad_enviada}
                        onChange={almacenar}
                      />
                    </Grid>
                  </Grid>

                  <Stack direction="row">
                    <Grid container direction={{ xs: "column", sm: "row" }}>
                      <Grid item xs={9}>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleClose}
                        >
                          No
                        </Button>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          fullWidth
                          disabled={disabledGuardarRe}
                          variant="contained"
                          color="success"
                          type="submit"
                        >
                          Si
                        </Button>
                      </Grid>
                    </Grid>
                  </Stack>
          </Box>
        </form>
        </Dialog>

    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={openSnack}
        autoHideDuration={1500}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="success"
          sx={{ width: "100%" }}
          style={{ backgroundColor: colormensaje, color: "black" }}
        >
          {mensaje}
        </Alert>
      </Snackbar>
    </Stack>
        </>
  )
}

export default Dialog_conversion;
