//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :26/06/2024
// Version : 1
// Description : Agregar precios
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField,
  IconButton,
  Button,
  InputAdornment,
  Autocomplete,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContent,
  Dialog,
  DialogTitle,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import { logEventos } from "../../../services/services";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";

//notificacion
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function AddPrecioAlta(props) {
    const [datoslista, setDatoslista] = useState({
        uuid_direccionentrega: "",
        lt_con_iva: "",
        lt_sin_iva: "",
        kg_con_iva: "",
        kg_sin_iva: "",
        vigencia:""
      });
      //Notification
      const [openSnack, setOpenSnack] = React.useState(false);
      const [colormensaje, setColorMensaje] = useState("");
      const [mensaje, setMensaje] = useState("");
      //boton
      const [disabledGuardar, setDisabledGuardar] = useState(false);
console.log("datos direccion", props.direccion);
      const [datosDirec, setDatosDirec] = useState();
      const obtenerXsucySeg = () => {
        fetch(
          UrlApi +
            "/precios_especiales/xdireccion/" +
            props.direccion 
        )
          .then((data) => data.json())
          .then((data) => setDatosDirec(data));
      };
      useEffect(() => {
        obtenerXsucySeg();
      }, []);
    
      console.log("DATOS SUC Y SEG", datosDirec);
    //*********************************** NOTIFICACION **************************************************************************************************/
      //Snackbar Notificacion
      const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenSnack(false);
        handleClose();
      };
    
      //**********************************************************************************************************************************//
      const style = {
        backgroundColor: "#ffffff",
        width: "100%",
      };
      const size = "small";
    
      const handleClose = () => {
        props.alta(false);
        props.tabla(true);
      };
      //*************************** GUARDAR *******************************************************************************************************//
      const guardarPrecio = async (e) => {
        // logEventos(
        //   "Guardar actualización del precio",
        //   "puntos de venta",
        //   "Se guardo el precio actualizado",
        //   localStorage.getItem("user")
        // );
        e.preventDefault();
        // setDisBtn(true);
        const response = await fetch(UrlApi + "/precios_especiales", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            uuid_direccionentrega: props.direccion,
            lt_con_iva: datoslista.lt_con_iva,
            lt_sin_iva: datoslista.lt_sin_iva,
            kg_con_iva: datoslista.kg_con_iva,
            kg_sin_iva: datoslista.kg_sin_iva,
            vigencia: datoslista.vigencia,
            usuario: localStorage.getItem("user"),
          }),
        })
          .then((res) => {
            if (!res.err) {
              // setOpenSnack(true);
              // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
              setColorMensaje("#D4EFDF");
              setMensaje("Se guardó exitosamente !");
              console.log("Guardado");
            } else {
              // setOpenSnack(false);
              setColorMensaje("#F1948A");
              setMensaje("Error al guardar");
              console.log("Error");
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
          props.onClose(false)
      };

      const almacenar = (e) => {
        setDatoslista({
          ...datoslista,
          [e.target.name]: e.target.value,
        });
      };
    
      //*************************** HABILITAR Y DESHABILITAR BOTON GUARDAR ***************************************************************************************//
      useEffect(() => {
        if (datoslista.lt_con_iva === "" || datoslista.lt_sin_iva === "" || datoslista.kg_con_iva === "" || datoslista.kg_sin_iva === "" || datoslista.vigencia === "") {
          setDisabledGuardar(true);
        } else {
          setDisabledGuardar(false);
        }
      }, [datoslista]);

      
  return (
    <div>
      <Dialog
        maxWidth="xl"
        open={props.open}
        onClose={() => props.onClose(false)}
        style={{ zIndex: 1 }}
      >
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography variant="h6" align="center" color="#ffffff">
            Precio Especial
            </Typography>
          </DialogTitle>
        </Box>
        <DialogContent>
      <Container>
        <form onSubmit={guardarPrecio} autoComplete="off">
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              {/* <Grid item xs={6}>
                <TextField
                  style={style}
                  required
                  focused
                  size="small"
                  placeholder="Tipee para buscar..."       
                  label="Direccion"
                  value={props.direccion.direccion_entrega}
                />
              </Grid> */}
              {datosDirec && datosDirec.length > 0 ? (
                <>
                  <Typography component="div" sx={{ fontSize: 19, mt: 3 }}>
                  "Ya existe un Precio, para actualizarlo vaya a la seccion de Precios Especiales"
                </Typography>
                </>
              ) : (
                <>

                <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
                mt={1}
              >
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="lt_con_iva"
                    label="Precio litro con IVA"
                    variant="outlined"
                    value={datoslista.lt_con_iva}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="lt_sin_iva"
                    label="Precio litro sin IVA"
                    variant="outlined"
                    value={datoslista.lt_sin_iva}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="kg_con_iva"
                    label="Precio kilo con IVA"
                    variant="outlined"
                    value={datoslista.kg_con_iva}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="kg_sin_iva"
                    label="Precio kilo sin IVA"
                    variant="outlined"
                    value={datoslista.kg_sin_iva}
                    onChange={almacenar}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    type="date"
                    name="vigencia"
                    label="Vigencia"
                    variant="outlined"
                    value={datoslista.vigencia}
                    onChange={almacenar}
                  />
                </Grid>
              </Grid>
                </>
                )}
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={() => props.onClose(false)} color="error">
                Volver
              </Button>

              <Button
                variant="contained"
                type="submit"
                color="success"
                disabled={disabledGuardar}
              >
                Guardar Precio
              </Button>
            </Grid>
          </Box>
        </form>
        {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
      </Container>
      </DialogContent>
      </Dialog>
    </div>
  )
}

export default AddPrecioAlta