import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Notify from "../../components/Notify";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SaveIcon from '@mui/icons-material/Save';

function ConfirmDialog(props) {
  const [disBtn, setDisBtn] = useState();

  const handleClose = () => {
    props.setOpen(false);
    props.setNotify({ open: false });
  };

  const tipo=typeof props.items

  return (
    <div>
      {props.notify.open && (
        <Notify
          open={props.notify.open}
          severity={props.notify.severity}
          mensaje={props.notify.mensaje}
          handleClose={props.atras ? props.atras : handleClose}
          color={props.notify.color}
        />
      )}
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle borderTop={6} borderColor={props.color}></DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            wrap="wrap"
          >
            <Grid item xs={3}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Avatar sx={{ bgcolor: props.color, width: 80, height: 80 }}>
                    {props.icon}
                  </Avatar>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="body1"
                    color="#2E4053"
                    gutterBottom
                    fontWeight="bold"
                  >
                    {" "}
                    {props.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="textSecondary">
                    {props.subtitle}
                  </Typography>
                </Grid>
               <Grid item>
                   {/* {tipo === "object" ? (
                    <ul>
                      {props.items.map((elemento, index) => (
                        <>
                          <li key={index}>
                            <Typography variant="body1" color="initial">
                              {" "}
                              Dirección de entrega {elemento.index + 1}{" "}
                            </Typography>
                            <ul>
                              {Object.keys(elemento).map(
                                (key, innerIndex) =>
                                  key !== "index" &&
                                  elemento[key] && (
                                    <li key={innerIndex}>{key}</li>
                                  )
                              )}
                            </ul>
                          </li>
                        </>
                      ))}
                    </ul>
                  ) : (
                    "ola"
                  )} */}

                  {
                    // props.items.typeOf==="Object"&&(
                      <ul>
                    {props.items&&props.items.map((item)=>(
                        <li>
                      <Typography variant="body1" color="textSecondary">
                        {item}
                      </Typography>
                    </li>
                    ))}
                  </ul>
                    // )
                  }
                  {/* <ul>
                    {props.items&&props.items.map((item)=>(
                        <li>
                      <Typography variant="body1" color="textSecondary">
                        {item}
                      </Typography>
                    </li>
                    ))}
                  </ul> */}
                </Grid>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ marginTop: 8 }}
                >
                  {props.confirmText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={0} justifyContent="space-between">
            <Button
              onClick={handleClose}
              disabled={disBtn}
              color="error"
              variant="contained"
            >
              Volver
            </Button>

            <Button
              variant="contained"
              disabled={disBtn}
              autoFocus
              color="success"
              onClick={(e) => {
                props.guardar(e);
                setDisBtn(true);
              }}
            >
              {props.btnText ?? "Guardar"}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmDialog;
