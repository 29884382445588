import React, { useState, useEffect } from "react";
import { Box, Grid, FormControl, InputLabel, MenuItem, Select, Autocomplete, TextField, Tabs, Tab } from "@mui/material";
import { getData } from "../../../../services/library";
import InputMask from "react-input-mask";
import Comprobanteexiste from "./Comprobanteexiste";
import InfoIcon from '@mui/icons-material/Info';
import moment from "moment";
import Cuerpo2 from "./Cuerpo2";
import Pie2 from "./Pie2";
import Extra from "./Extra2";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

export default function Formcomprobante(props) {
    const [modal, setModal] = useState(false);
    const iconInfo = <InfoIcon sx={{ fontSize: 40 }} />;
    const [activeTab, setActiveTab] = useState(0);
    const [showTabs, setShowTabs] = useState(true);

    const { datosCabecera, setdatosCabecera, tiposComprobantes, proveedores, sucursales, condicionesPago,
        tipoMoneda, proveedorSelected, setproveedorSelected, imputaciones, setImputaciones, condVentaNew,
        setcondVentaNew, total, setTotal, comprobanteExiste, setcomprobanteExiste, comprobanteSelected,
        setcomprobanteSelected, datosImpuestos, setdatosImpuestos, datosCuerpo, setdatosCuerpo, valorPorcentajes, calcularSubtotalIva,
        setCalcularSubtotalIva, datosPie, setdatosPie, neto, setnetoGravado, otrostributos, setotrosTributos,
        saldonoaplic, setsaldonoaplic, tiposIvaSeleccionados, setTiposIvaSeleccionados, impuestos, style, periodos, periodoSelected, setperiodoSelected } = props;

    const almacenarCabecera = (e) => {
        const { name, value } = e.target;

        setdatosCabecera(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        proveedorSelected && getData('/imputacion_proveedores/improv/' + proveedorSelected.uuid_proveedor, setImputaciones)
        proveedorSelected && setcondVentaNew(proveedorSelected.id_condicionventa)
    }, [proveedorSelected, setproveedorSelected]);

    const setearCondicion = (e) => {
        setcondVentaNew(e.target.value);
    };

    const setearPeriodo = (e) => {
        setperiodoSelected(e.target.value)
    };

    useEffect(() => {
        comprobanteSelected && proveedorSelected && getData('/comprobantes_cabecera/xidpynro/' + comprobanteSelected.id + '/' + proveedorSelected.id + '/' + datosCabecera.nro_comprobante, setcomprobanteExiste)
    }, [proveedorSelected, comprobanteSelected, datosCabecera.nro_comprobante]);

    useEffect(() => {
        comprobanteExiste && comprobanteExiste.length !== 0 ? setModal(true) : setModal(false)
    }, [comprobanteExiste])

    console.log("periodo selected", periodoSelected);
    console.log("periodos", periodos);


    return (
        <div>
            <Box
                backgroundColor="#F4F6F6"
                mt={1}
                p={2}
                border={0.5}
                borderColor={"#ABB2B9"}
                borderRadius={4}>

                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <FormControl style={style} focused required={proveedorSelected === null}>
                            <InputLabel>Tipo de comprobante</InputLabel>
                            <Select
                                label="Tipo de comprobante"

                                size="small"
                                name="id_tipocomprobante"
                                value={comprobanteSelected}
                                onChange={(e) => setcomprobanteSelected(e.target.value)}
                            >
                                {tiposComprobantes
                                    ? tiposComprobantes.map((elemento) => (
                                        <MenuItem key={elemento.id} value={elemento}>
                                            {elemento.descripcion}
                                        </MenuItem>
                                    ))
                                    : null}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <Autocomplete
                            focused
                            size="small"
                            style={style}
                            noOptionsText={"Proveedor INEXISTENTE"}
                            options={proveedores}
                            autoHighlight
                            getOptionLabel={(proveedor) => proveedor.nombre_fantasia || proveedor.razon_social}
                            onChange={(e, value) => setproveedorSelected(value)}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    key={option.nombre_fantasia}
                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                >
                                    {`${option.nombre_fantasia ? option.nombre_fantasia : option.razon_social} - ${option.nro_cuit}`}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    focused
                                    fullWidth
                                    placeholder="Tipee para buscar proveedores.."
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    label="Proveedores"
                                    required={proveedorSelected === null}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl style={style} focused required>
                            <InputLabel>Condicion de venta</InputLabel>
                            <Select
                                size="small"
                                name="id_condicionpago"
                                value={condVentaNew}
                                label="Condicion de venta"
                                onChange={setearCondicion}
                            >
                                {condicionesPago
                                    ? condicionesPago.map((elemento) => (

                                        <MenuItem key={elemento.id} value={elemento.id}>
                                            {elemento.condicion}
                                        </MenuItem>
                                    ))
                                    : null}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl style={style} focused required>
                            <InputLabel>Imputado a</InputLabel>
                            <Select
                                size="small"
                                name="id_imputacion"
                                value={datosCabecera.id_imputacion}
                                label="Imputado a "
                                onChange={almacenarCabecera}
                            >
                                {imputaciones
                                    ? imputaciones.map((elemento) => (

                                        <MenuItem key={elemento.idimputacion} value={elemento.idimputacion}>
                                            {elemento.imp_descripcion}
                                        </MenuItem>
                                    ))
                                    : null}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                        <FormControl style={style} focused required>
                            <InputLabel>Sucursal</InputLabel>
                            <Select
                                size="small"
                                name="id_sucursal"
                                value={datosCabecera.id_sucursal}
                                label="Sucursal "
                                onChange={almacenarCabecera}
                            >
                                {sucursales
                                    ? sucursales.map((elemento) => (

                                        <MenuItem key={elemento.id} value={elemento.id}>
                                            {elemento.nombre}
                                        </MenuItem>
                                    ))
                                    : null}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                        <InputMask
                            mask="9999-99999999"
                            value={datosCabecera.nro_comprobante}
                            maskChar=" "
                            onChange={almacenarCabecera}
                        >
                            {() => (
                                <TextField
                                    size="small"
                                    defaultValue=""
                                    type="text"
                                    style={style}
                                    focused
                                    required
                                    id="nro_comprobante"
                                    name="nro_comprobante"
                                    label="Número comprobante"
                                    placeholder="____-________"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            )}
                        </InputMask>
                    </Grid>

                    <Grid item xs={2}>
                        <FormControl style={style} required focused>
                            <InputLabel >Periodo contable</InputLabel>
                            <Select
                                label="Periodo contable"
                                size="small"
                                name="id_periodocontable"
                                value={periodoSelected}
                                onChange={setearPeriodo}>
                                {periodos ?
                                    periodos.map((elemento) => (
                                        <MenuItem key={elemento.idperiodo_contable} value={elemento.idperiodo_contable}>
                                            {elemento.mes}-{elemento.año}
                                        </MenuItem>
                                    )) : null}

                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            type="date"
                            focused
                            fullWidth
                            required
                            size="small"
                            color="primary"
                            style={style}
                            label="Fecha de comprobante"
                            name="fecha_factura"
                            value={moment(datosCabecera.fecha_factura).format("YYYY-MM-DD")}
                            onChange={almacenarCabecera}
                            inputProps={{
                                max: moment().startOf('month').format('YYYY-MM-DD')
                            }} />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            type="date"
                            focused
                            fullWidth
                            size="small"
                            color="primary"
                            style={style}
                            label="Vencimiento comprobante"
                            name="vto_factura"
                            value={moment(datosCabecera.vto_factura).format("YYYY-MM-DD")}
                            onChange={almacenarCabecera}
                            inputProps={{
                                min: moment(datosCabecera.fecha_factura).format("YYYY-MM-DD")
                            }} />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            type="number"
                            focused
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            fullWidth
                            size="small"
                            color="primary"
                            style={style}
                            label="Nro cae"
                            name="nro_cae"
                            value={datosCabecera.nro_cae}
                            onChange={almacenarCabecera} />
                    </Grid>

                    <Grid item xs={2}>
                        <TextField
                            type="date"
                            required={datosCabecera.nro_cae !== null || ""}
                            focused
                            fullWidth
                            size="small"
                            color="primary"
                            style={style}
                            label="Vencimiento cae"
                            name="vto_cae"
                            value={moment(datosCabecera.vto_cae).format("YYYY-MM-DD")}
                            onChange={almacenarCabecera}
                            inputProps={{
                                min: moment(datosCabecera.fecha_factura).format('YYYY-MM-DD')
                            }}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <TextField
                            type="text"
                            focused
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            onKeyPress={(event) => {
                                if (!/[\d.-]/.test(event.key) && event.key !== "Backspace") {
                                    event.preventDefault();
                                }
                            }}
                            autoComplete="off"
                            fullWidth
                            size="small"
                            color="primary"
                            style={style}
                            label="Total"
                            name="total"
                            value={total}
                            onChange={(e) => setTotal(e.target.value)} />
                    </Grid>

                    <Grid item xs={2.5}>
                        <FormControl style={style} focused required>
                            <InputLabel>Moneda</InputLabel>
                            <Select
                                label="Moneda"
                                size="small"
                                name="id_tipomoneda"
                                value={datosCabecera.id_tipomoneda ? datosCabecera.id_tipomoneda : " "}
                                onChange={almacenarCabecera}
                            >
                                {tipoMoneda
                                    ? tipoMoneda.map((elemento) => (
                                        <MenuItem key={elemento.id} value={elemento.id}>
                                            {elemento.valor}
                                        </MenuItem>
                                    ))
                                    : null}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={2.5}>
                        <TextField
                            type="number"
                            focused
                            fullWidth
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            size="small"
                            color="primary"
                            style={style}
                            label="Tipo de cambio"
                            name="tipo_cambio"
                            value={datosCabecera.tipo_cambio}
                            onChange={almacenarCabecera} />
                    </Grid>
                </Grid>
                <Box display="flex" >
                    <Box flex={0.7} display="flex" flexDirection="column">

                        {showTabs ? (
                            <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 1 }}>
                                <Tabs
                                    value={activeTab}
                                    onChange={(event, newValue) => setActiveTab(newValue)}
                                    aria-label="basic tabs example"
                                    centered
                                    variant="fullWidth"
                                >
                                    <Tab label="Conceptos" {...a11yProps(0)} />
                                    <Tab label="Impuestos" {...a11yProps(1)} />

                                </Tabs>
                            </Box>
                        ) : null}
                        {activeTab === 0 ? (
                            <Cuerpo2
                                datosImpuestos={datosImpuestos}
                                setdatosImpuestos={setdatosImpuestos}
                                datosCuerpo={datosCuerpo}
                                setdatosCuerpo={setdatosCuerpo}
                                uuid={datosCabecera.uuid_comprobante}
                                valorPorcentajes={valorPorcentajes}
                                calcularSubtotalIva={calcularSubtotalIva}
                                setCalcularSubtotalIva={setCalcularSubtotalIva}
                                datosPie={datosPie}
                                setdatosPie={setdatosPie}
                                total={total}
                                setTotal={setTotal}
                                neto={neto}
                                setnetoGravado={setnetoGravado}
                                otrostributos={otrostributos}
                                setotrosTributos={setotrosTributos}
                                saldonoaplic={saldonoaplic}
                                setsaldonoaplic={setsaldonoaplic}
                                tiposIvaSeleccionados={tiposIvaSeleccionados}
                                setTiposIvaSeleccionados={setTiposIvaSeleccionados}
                                comprobanteSelected={comprobanteSelected}
                                setcomprobanteSelected={setcomprobanteSelected}
                            />
                        )
                            : null}
                        {activeTab === 1 ? (
                            <>
                                <Pie2
                                    datosImpuestos={datosImpuestos}
                                    setdatosImpuestos={setdatosImpuestos}
                                    datosCuerpo={datosCuerpo}
                                    setdatosCuerpo={setdatosCuerpo}
                                    uuid={datosCabecera.uuid_comprobante}
                                    impuestos={impuestos}
                                    neto={neto}
                                    setnetoGravado={setnetoGravado}
                                    datosPie={datosPie}
                                    total={total}
                                    setTotal={setTotal}
                                    setdatosPie={setdatosPie}
                                    otrostributos={otrostributos}
                                    setotrosTributos={setotrosTributos}
                                    saldonoaplic={saldonoaplic}
                                    setsaldonoaplic={setsaldonoaplic}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    tiposIvaSeleccionados={tiposIvaSeleccionados}
                                    setTiposIvaSeleccionados={setTiposIvaSeleccionados}
                                    valorPorcentajes={valorPorcentajes}
                                    comprobanteSelected={comprobanteSelected}
                                    setcomprobanteSelected={setcomprobanteSelected}
                                />
                            </>
                        ) : null}
                    </Box>
                    <Box flex={0.3}>
                        <Extra datosCuerpo={datosCuerpo}
                            setdatosCuerpo={setdatosCuerpo}
                            impuestos={impuestos}
                            otrostributos={otrostributos}
                            setotrosTributos={setotrosTributos}
                            total={total}
                            setTotal={setTotal}
                            datosPie={datosPie}
                            setdatosPie={setdatosPie}
                            uuid={datosCabecera.uuid_comprobante}
                            saldonoaplic={saldonoaplic}
                            setsaldonoaplic={setsaldonoaplic}
                            neto={neto}
                            tiposIvaSeleccionados={tiposIvaSeleccionados}
                            setnetoGravado={setnetoGravado}
                            datosImpuestos={datosImpuestos}
                            setdatosImpuestos={setdatosImpuestos}
                            valorPorcentajes={valorPorcentajes} />
                    </Box>
                </Box>
            </Box>

            {modal ?
                (
                    <Comprobanteexiste
                        title={"Comprobante existente"}
                        subtitle={"Ya existe un comprobante con este nro y proveedor"}
                        open={modal}
                        setOpen={setModal}
                        color={"#f53649"}
                        icon={iconInfo}
                    />
                ) : null}
        </div>
    )
}