import React, { useState, useEffect, useMemo } from "react";
import { Grid, Box, MenuItem, ListItemIcon, Container, List } from '@mui/material';
import { UrlApi } from '../../../../services/apirest';
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import EventNoteIcon from '@mui/icons-material/EventNote';
import DeleteIcon from '@mui/icons-material/Delete';
import Modaldatospedidos from './Modaldatospedidos'
import Modalquitarpedido from './Modalquitarpedido';
import WhereToVoteTwoToneIcon from "@mui/icons-material/WhereToVoteTwoTone";
import { number } from "prop-types";
import DialogEntregado from "../../../referencias-tablas/pedidos/DialogEntregado";

export default function VerDatosRep(props) {
    const [datos, setDatos] = useState(props.datos);
    const [pedidos, setPedidos] = useState();
    const [openPedidos, setopenPedidos] = useState(false);
    const [eliminarPedido, seteliminarPedidos] = useState(false)
    const [datosPedidos, setDatosPedidos] = useState({});

    const obtenerPedidos = () => {
        fetch(UrlApi + '/pedidos/reparto/' + datos.nro_reparto)
            .then(data => data.json())
            .then(data => setPedidos(data))
    };

    useEffect(() => {
        obtenerPedidos()
    }, [props.sucursalSeleccionada, props.setsucursalSeleccionada]);

    const openEditar = (datos) => {
        setopenPedidos(true)
        setDatosPedidos(datos)
    };

    const openModalEliminar = (datos) => {
        seteliminarPedidos(true)
        setDatosPedidos(datos)
    };

    const columns = useMemo(() =>
        [{ header: '#', accessorKey: 'id', size: 45, enableEditing: false, },
        { header: 'Sisven ID', accessorKey: 'sisven_id', size: 48, enableEditing: false, },
        { header: 'Razón Social', accessorKey: 'razon_social', size: 150, enableEditing: false },
        { header: 'Dirección de entrega', accessorKey: 'direccion_entrega', size: 150, enableEditing: false },
        { header: 'Kg a cargar', accessorKey: 'kgacargar', size: 50, enableEditing: false },
        { header: 'Estado', accessorKey: 'estado', size: 50, enableEditing: false },
        { header: 'Orden', accessorKey: 'orden_prioridad', size: 50, type: "number", enableEditing: (row) => row.original.id_estado === 23 },

        ], []);

    const tableHeadCellProps = {
        align: "left",
        sx: {
            fontSize: 14,
            color: "black",
            fontWeight: "normal",
            paddingTop: 1,
            paddingBottom: 1,
            backgroundColor: "#D4E6F1",
            borderColor: "#FDFEFE",
        },
    };

    const rowBg = {
        19: "#FFFFFF",
        20: "#FEF5E7", //revisado -> natural
        21: "#ABEBC6", //aprobado -> verde clarito
        22: "#DC7633", //rechazado -> rojo
        23: "#85C1E9", //asignado -> celestito
        41: "#DC7633", //entregado
        25: "#D7BDE2", //Facturado -> violeta
        32: "#EC7063", //CANCELADO -> BORDÓ
        // 40: "#FFFF80", //pendiente -> amarillo
    };

    const editarOrden = async ({ exitEditingMode, row, values }) => {
        let response = await fetch(UrlApi + '/pedidos/' + row.original.id, {
            method: 'PUT',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                orden_prioridad: values.orden_prioridad
            })
        })
        obtenerPedidos()
        exitEditingMode()
    };

    return (
        <div>
            {pedidos &&
                <Box boxShadow={3} display="flex" flexDirection="column" sx={{ width: "120%", ml: -1 }}>
                    <MaterialReactTable
                        editingMode="row"
                        // enableFacetedValues
                        columns={columns}
                        data={pedidos}
                        enableEditing
                        enableRowActions
                        positionActionsColumn='last'
                        muiTableHeadCellProps={tableHeadCellProps}
                        localization={MRT_Localization_ES}
                        muiTableBodyCellProps={({ row }) => ({
                            align: "left",
                            sx: {
                                backgroundColor:
                                    rowBg[row.original.id_estado] ?? "#fff",
                                color: "black",
                                borderColor: "black",
                            },
                        })}
                        enableColumnActions={false}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            // row.original.estado_reparto !== 6 || (row.original.id_estado === 40 || row.original.id_estado === 41 || row.original.id_estado === 23) ? (
                            //     <MenuItem
                            //         key={0}
                            //         onClick={() => {
                            //             openEditar(row.original)
                            //             closeMenu();
                            //         }}
                            //         sx={{ m: 0 }}
                            //     >
                            //         <ListItemIcon>
                            //             <WhereToVoteTwoToneIcon color="success" />
                            //         </ListItemIcon>
                            //          Registrar Visita
                            //     </MenuItem>
                            // ) : null
                            // ,
                            <MenuItem
                                disabled={row.original.id_estado !== 23}
                                key={1}
                                onClick={() => {
                                    openModalEliminar(row.original)
                                    closeMenu();
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <DeleteIcon color="error" />
                                </ListItemIcon>
                                Quitar pedido del reparto
                            </MenuItem>
                            // <MenuItem
                            // key={2}
                            // onClick></MenuItem>            
                        ]}
                        enableColumnFilters={false}
                        enableColumnDragging={false}
                        enableHiding={false}
                        enableTopToolbar={false}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        onEditingRowSave={editarOrden}
                    />
                </Box>
            }

            {/* {openPedidos ? (
                <Modaldatospedidos actualizar={obtenerPedidos} datos={datosPedidos} open={setopenPedidos} />
            ) : null} */}

            {
                openPedidos ? (
                    <DialogEntregado
                        pedido={datosPedidos}
                        open={openPedidos}
                        setOpen={setopenPedidos}
                        actualizar={obtenerPedidos}
                    />
                ) : null
            }
            {eliminarPedido ? (
                <Modalquitarpedido actualizar={obtenerPedidos} datos={datosPedidos} open={seteliminarPedidos} />
            ) : null}

        </div>
    )
}

