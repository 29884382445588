import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

function Modalnohayprecio(props) {

  const { open, setOpen, segmento, precio, sucursal, id_segmento, precio_especial, id_sucursal, datosCli } = props;

  // console.log("props.", precio[0].lt_con_iva);
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>Faltan Datos de Precios</DialogTitle>
      <DialogContent>
        {id_segmento === 9 && "El segmento del cliente es desconocido. Es necesario modificar el dato para poder continuar con la carga del pedido."}
        {precio_especial === 1 && precio.length === 0 && "Esta dirección de entrega tiene precio especial. Por favor verifique que los datos estén cargados correctamente para poder continuar con la carga del pedido."}
        {id_sucursal && id_segmento !== 9 && precio.length === 0 && `No hay precios cargados para la sucursal ${sucursal} y segmento ${segmento}. Por favor ingrese una lista de precios para poder continuar con la carga del pedido.`}
        {precio.length > 0 && datosCli.unidad_fact === 215 &&
          (!precio[0].precio_kilo_con_iva ||
            !precio[0].precio_kilo_sin_iva ||
            !precio[0].kg_con_iva ||
            !precio[0].kg_sin_iva) && "No hay precios en kilos cargados correctamente. Por favor verifique los datos para poder continuar con la carga del pedido."}
        {precio.length > 0 && datosCli.unidad_fact === 216 &&
          (precio[0].precio_litro_con_iva == null ||
            precio[0].precio_litro_sin_iva == null ||
            precio[0].lt_con_iva == null ||
            precio[0].lt_sin_iva == null) && "No hay precios en litros cargados correctamente. Por favor verifique los datos para poder continuar con la carga del pedido."}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="warning"
          onClick={() => setOpen(false)}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Modalnohayprecio;