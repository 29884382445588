//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :12/06/2024
// Version : 1
// Description : Creación de puntos de venta
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField,
  IconButton,
  Button,
  InputAdornment,
  Autocomplete,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import NumbersIcon from "@mui/icons-material/Numbers";
import { logEventos } from "../../../services/services";
//notificacion
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddPuntoVenta(props) {
  const [datosPuntoVenta, setDatosPuntoVenta] = useState({
    id_punto: null,
    id_sucursal: null,
    estado: 1,
  });

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  //boton
  const [disBtn, setDisBtn] = useState(false);
  //errores
  const [errorIdPunto, setErrorIdPunto] = useState(false); //error para id_punto
  const [errorIdPuntoBtn, setErrorIdPuntoBtn] = useState(true); //error para id_punto

  /*   GET     */
  const [sucursal, setSucursal] = useState();
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState("");

  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setSucursal(data));
  };
  useEffect(() => {
    obtenerSucursales();
  }, []);

  const [puntoVenta, setPuntoVenta] = useState();

  const obtenerPuntoVenta = () => {
    fetch(UrlApi + "/puntos_venta")
      .then((data) => data.json())
      .then((data) => setPuntoVenta(data));
  };
  useEffect(() => {
    obtenerPuntoVenta();
  }, []);

 // console.log("PUNTOS DE VENTA", puntoVenta);

  const [repetido, setRepetido] = useState(false);

  useEffect(() => {
    if (puntoVenta) {
      // Buscar el punto de venta que coincida con datosPuntoVenta.id_punto
      const puntoVentaRepetido = puntoVenta.find(
        (pv) => pv.id_punto === datosPuntoVenta.id_punto
      );
    // console.log("REPEEE", puntoVentaRepetido);

      // Actualizar el estado solo si se encuentra un punto de venta repetido
      if (puntoVentaRepetido) {
        setRepetido(true);
      } else {
        setRepetido(false);
      }
    }
  }, [datosPuntoVenta.id_punto, puntoVenta]);

  //console.log("SUCURSAL SELECCIONADA AGREGAR", sucursalSeleccionada);

  const handleClose = () => {
    props.alta(false);
    props.tabla(true);
  };

  const almacenar = (e) => {
    const { name, value } = e.target;

    if (name === "id_punto") {
      if (/^[0-9]{0,4}$/.test(value)) {
        setDatosPuntoVenta({
          ...datosPuntoVenta,
          [name]: value,
        });
        setErrorIdPunto(value.length !== 4);
        setErrorIdPuntoBtn(value.length !== 4);
      }
    } else {
      setDatosPuntoVenta({
        ...datosPuntoVenta,
        [name]: value === "" ? null : value,
      });
    }
  };

  const guardarPunto = async (e) => {
    logEventos(
      "Guardar punto de venta nuevo",
      "puntos de venta",
      "Se guardo el puntos de venta nuevo",
      localStorage.getItem("user")
    );
    e.preventDefault();
    if (datosPuntoVenta.id_punto.length !== 4) {
      setErrorIdPunto(true);
      setErrorIdPuntoBtn(true);
      return;
    }
    setDisBtn(true);
    const response = await fetch(UrlApi + "/puntos_venta", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_punto: datosPuntoVenta.id_punto,
        id_sucursal: sucursalSeleccionada.id,
        estado: 1,
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setColorMensaje("#D4EFDF");
          setMensaje("Se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // handleClose();
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

 // console.log("datos punto venta", datosPuntoVenta);

  useEffect(() => {
    if (
      repetido === true ||
      errorIdPuntoBtn === true ||
      Object.keys(sucursalSeleccionada).length === 0
    ) {
      setDisBtn(true);
    } else {
      setDisBtn(false);
    }
  }, [repetido, datosPuntoVenta, sucursalSeleccionada]);

 // console.log("ERROR ID ", errorIdPunto);

  //--------------------------- NOTIFICACION----------------------------------------------//
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    handleClose();
  };

  return (
    <div>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container backgroundColor="#1F618D" p={1.5}>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Cargar nuevo punto de venta
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <form onSubmit={guardarPunto} autoComplete="off">
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size={size}
                  placeholder="0000"
                  style={style}
                  focused
                  color="primary"
                  required
                  name="id_punto"
                  label="N° Pto. de venta"
                  variant="outlined"
                  value={datosPuntoVenta.id_punto}
                  inputProps={{
                    maxLength: 4,
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <NumbersIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={errorIdPunto || repetido}
                  helperText={
                    errorIdPunto
                      ? "Debe tener 4 dígitos"
                      : repetido === true
                      ? "Ya registrado"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  disabled={!sucursal}
                  size={size}
                  focused
                  disableClearable
                  id="sucursales"
                  noOptionsText={"Sucursal INEXISTENTE"}
                  options={sucursal}
                  autoHighlight
                  getOptionLabel={(sucursal) =>
                    sucursal.id + "-" + sucursal.nombre
                  }
                  onChange={(event, value) => setSucursalSeleccionada(value)}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.id}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.id}-{option.nombre}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      style={style}
                      required
                      focused
                      size="small"
                      placeholder="Tipee para buscar..."
                      {...params}
                      label="Sucursal"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>

              <Button
                disabled={disBtn}
                variant="contained"
                type="submit"
                color="success"
              >
                Guardar Punto de Venta
              </Button>
            </Grid>
          </Box>
        </form>
        {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
      </Container>
    </div>
  );
}

export default AddPuntoVenta;
