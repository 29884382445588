//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio /Nicolas Pascucci
// Creation Date : 19/6/2024
// Version : 1
// Description : Tabla de lista de precios
//------Changes ----------------------------------------------------------//
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState, useMemo } from "react";
import { logEventos } from "../../services/services";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, TextField, ThemeProvider, useTheme } from "@mui/material";
import Navbar from "../Navbar";
import { Container, Typography, Box, Button } from "@mui/material";
import { UrlApi } from "../../services/apirest";
import Actualizar_precio from "./Actualizar_precio";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AddPrecio from "./AddPrecio";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";

function Lista_precios() {
  const [lista, setLista] = useState(); // p/tabla
  const [tabla, setTabla] = useState(true);
  const [alta, setAlta] = useState(false); //Estado q mostrará o no la pantalla de alta
  const [actualizar, setActualizar] = useState(false); //Estado q mostrará o no la pantalla de alta
  const globalTheme = useTheme();
  const [selectedDate, setSelectedDate] = useState('');
  const [fechaVigencia, setFechaVigencia] = useState()

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const obtenerListaFecha = () => {
    fetch(UrlApi + "/lista_precios/porfecha/"+ selectedDate)
      .then((data) => data.json())
      .then((data) => setFechaVigencia(data));
  };
  useEffect(() => {
    obtenerListaFecha()
  }, [selectedDate, tabla])
  
  
    useEffect(() => {
      logEventos("Acceso al menú lista de precios", "Lista de precios", "Ver la lista de precios y actualizarla")
  }, [])

  //*********************************** OBTENER **************************************************************************************************/
  const obtenerLista = () => {
    fetch(UrlApi + "/lista_precios/")
      .then((data) => data.json())
      .then((data) => setLista(data));
  };

  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerLista();
  }, [tabla]);
  //********************************** TABLA ***************************************************************************************************/
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const columns = useMemo(
    () => [
      // { header: "ID lista", accessorKey: "id_lista", size: 100 },
      {
        header: " ",
        columns: [
          {
            header: "Sucursal",
            accessorKey: "nombre",
            size: 150,
          },
          {
            header: "Segmento",
            accessorKey: "segmento",
            size: 150,
          },
        ],
      },
      {
        header: "Precio del Litro",
        columns: [
          {
            header: "Con IVA",
            accessorKey: "precio_litro_con_iva",
            size: 100,
          },
          {
            header: "Sin IVA",
            accessorKey: "precio_litro_sin_iva",
            size: 100,
          },
        ],
      },
      {
        header: "Precio del Kilo",
        columns: [
          {
            header: "Con IVA",
            accessorKey: "precio_kilo_con_iva",
            size: 100,
          },
          {
            header: "Sin IVA",
            accessorKey: "precio_kilo_sin_iva",
            size: 100,
          },
        ],
      },
      {
        header: "Vigencia",
        columns: [
          {
            header: "Inicio",
            accessorKey: "vigencia",
            size: 100,
          },
          {
            header: "Fin",
            accessorKey: "fin_vigencia",
            size: 100,
          },
        ],
      },
    ],
    []
  );

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  //*************************************************************************************************************************************/
  const handleClickActualizar = () => {
    setActualizar(true);
    setTabla(false);
  };

  const handleClickNuevo = () => {
    setAlta(true);
    setTabla(false);
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1} mb={2}>
        {lista && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableColumnResizing
                columns={columns}
                data={selectedDate === ''?lista:fechaVigencia}
                initialState={initialState}
                positionActionsColumn="last"
                enableExpandAll={false}
                enableToolbarInternalActions={true}
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleClickNuevo}
                    >
                      Nuevo Precio
                    </Button>
                    <Typography variant="poster" fontSize={26}>
                      Lista de Precios
                    </Typography>
                    <TextField
                      focused
                      size="small"
                      label="Fecha"
                      type="date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AutorenewIcon />}
                      onClick={handleClickActualizar}
                    >
                      Actualizar Precio
                    </Button>
                  </>
                )}
                layoutMode="grid"
                localization={MRT_Localization_ES}
                enableColumnDragging={false} //No permite que se arrastren las columnas.
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderRowActions={(
                  { row } //las acciones del costado editar y ver
                ) => <div></div>}
              />
            </ThemeProvider>
            <br />
            <br />
          </Box>
        ) : null}
        {actualizar ? (
          <Actualizar_precio
            actualizar={setActualizar}
            tabla={setTabla}
          ></Actualizar_precio>
        ) : null}

        {alta ? (
          <AddPrecio
            alta={setAlta}
            tabla={setTabla}
          ></AddPrecio>
        ) : null}
      </Container>
    </div>
  );
}

export default Lista_precios;
