//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 05/08/2024
// Version : 1
// Description : tabla de tanques
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../../services/apirest";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function Tabla_tanque() {
  const [cantidadTanque, setCantidadTanque] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().subtract(1, "month").startOf("month")
  );

  const obtenerCantidadTanque = () => {
    const formattedDate = selectedDate.format("YYYY"); // Formatear la fecha seleccionada
    fetch(UrlApi + "/reclamos/cantidadTanques/fecha/" + formattedDate)
      .then((data) => data.json())
      .then((data) => setCantidadTanque(data));
  };
  useEffect(() => {
    obtenerCantidadTanque();
  }, [selectedDate]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

 // console.log("CANTIDAD TANQUE", cantidadTanque);

  const paperStyle3 = {
    padding: "10px",
    margin: "10px",
    width: "1200px",
  };
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

 // console.log("AÑO ACTUAL: ", currentYear);

  const todosLosMeses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const obtenerNombreMes = (fecha) => {
    const mes = dayjs(fecha, "YYYY-MM").format("M") - 1; // Extraer el mes (0-11)
    return todosLosMeses[mes];
  };

  const transformarDatosParaTabla = (data) => {
    if (!data || data.length === 0) {
      return { dataset: [], tanques: [], meses: todosLosMeses };
    }

    const tanques = Object.keys(data[0]).filter((key) => key !== "fecha");
    const meses = todosLosMeses;

    // Crear el dataset para la tabla utilizando .map
    const dataset = tanques.map((tanque) => {
      const fila = { tanque };
      meses.forEach((mes) => {
        const item = data.find((item) => obtenerNombreMes(item.fecha) === mes);
        fila[mes] = item ? item[tanque] || 0 : 0;
      });
      return fila;
    });

    return { dataset, tanques, meses };
  };

  const { dataset, tanques, meses } = transformarDatosParaTabla(cantidadTanque);

  const HeaderCell = styled(TableCell)({
    marginTop: "2px",
    fontSize: 13,
    color: "white",
    fontWeight: "normal",
    backgroundColor: "#2471A3",
    borderColor: "white",
    padding: "6.3px",
  });

  return (
    <div>
      <Paper elevation={10} style={{ ...paperStyle3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DatePicker
              views={["year"]}
              label="Año"
              value={selectedDate}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
            />
          </LocalizationProvider>
          <Typography
            align="center"
            style={{
              fontWeight: "bold",
              fontSize: 20,
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            Tabla de tanques del año {selectedDate.year()}
          </Typography>
        </div>
        <TableContainer>
          <Table
            sx={{ minWidth: 300, marginTop: 2 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <HeaderCell
                  align="center"
                  sx={{ border: "1px solid black", position: "relative" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <b>Meses</b>
                    <hr
                      style={{
                        width: "100%",
                        border: "none",
                        borderTop: "1px solid black",
                        transformOrigin: "center",
                      }}
                    />
                    <b>Tanque</b>
                  </div>
                </HeaderCell>
                {meses.map((mes, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    style={{
                      backgroundColor: "#2B76A6",
                      color: "white",
                      border: "1px solid black",
                    }}
                  >
                    {mes}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tanques.map((tanque, index) => (
                <TableRow key={index} sx={{ border: "1px solid black" }}>
                  <TableCell align="center" sx={{ border: "1px solid black" }}>
                    {tanque}
                  </TableCell>
                  {meses.map((mes, mesIndex) => (
                    <TableCell
                      key={mesIndex}
                      align="center"
                      sx={{ border: "1px solid black" }}
                    >
                      {dataset.find((item) => item.tanque === tanque)?.[mes] ||
                        0}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default Tabla_tanque;
