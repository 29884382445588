//----- Component --------------------------------------------------------/

// Developer : Daiana Curcio

// Creation Date : 23/11/2023

// Version : 1

// Description : pantalla para autorizaciones de confirmación de transferencias
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Stack,
  Grid,
  Tooltip,
  IconButton,
  TextField,
  Autocomplete,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { logEventos } from "../../services/services";

//ICONOS
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Navbar from "../Navbar";
import Dialog_autorizar_confirm from "./Dialog_autorizar_confirm";

function Articulos_autorizacion_transf() {
  const [transferencia, setTransferencia] = useState();
  const [abrirConfirmacion, setAbrirConfirmacion] = useState(false);
  const [transRow, setTransRow] = useState();
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    logEventos(
      "Acceso al menú stock autorizar transferencias",
      "Stock Autorizar Transferencias",
      "Autorizar transferencias con cantidades pendientes",
      localStorage.getItem("user")
    );
  }, []);

  const obtenerConfirmacionAutorizacion = () => {
    fetch(UrlApi + "/stock_transacciones/confirmacionParaAutorizar")
      .then((data) => data.json())
      .then((data) => setTransferencia(data));
  };

  //console.log("Transacciones", transferencia);

  //funcion que espera los cambios de la funcion de obtener a los articulos
  useEffect(() => {
    obtenerConfirmacionAutorizacion();
  }, [tabla]);

  const handleOpenConfir = (value) => {
    setAbrirConfirmacion(value);
    setUpdate(true);
  };

  useEffect(() => {
    if (update) {
      obtenerConfirmacionAutorizacion();
      setUpdate(false);
    }
  }, [update]);

//  console.log("ESTADO ABRIR OCNF", abrirConfirmacion);

  const columns = useMemo(
    () => [
      { header: "Envío", accessorKey: "fecha_transaccion", size: 60 },
      { header: "Recep.", accessorKey: "fecha_recibido", size: 80 },
      { header: "Suc.Origen", accessorKey: "nombre_suc_origen", size: 130 },
      {
        header: "Suc.Destino",
        accessorKey: "nombre_sucursal_destino",
        size: 130,
      },
      { header: "Artículo", accessorKey: "nombre_articulo", size: 130 },
      { header: "Cant.Enviada", accessorKey: "cantidad_enviada", size: 100 },
      { header: "Cant.Recibida", accessorKey: "cantidad_recibida", size: 100 },
      {
        header: "Cant.Pendiente",
        accessorKey: "cantidad_pendiente",
        size: 100,
      },
      {
        header: "Observaciones",
        accessorKey: "detalle_confirmacion",
        size: 100,
      },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };

  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    sorting: [
      {
        id: "fecha_recibido",
        desc: true,
      },
    ],
    // columnVisibility: { id_articulo: false, uuid_articulo: false, estado: false },
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="xl" mt={1} mb={2}>
        {transferencia && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={{
                  align: "left", //el contenido de la tabla
                }}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={transferencia} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableEditing
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Autorización de Confirmación de Transferencias
                    </Typography>
                  </>
                )}
                renderRowActions={({ row, table }) => (
                  //las acciones del costado editar y ver
                  <div>
                    <Tooltip
                      arrow
                      placement="top"
                      title="Autorizar Confirmación"
                    >
                      <IconButton
                        onClick={() => {
                          setAbrirConfirmacion(!abrirConfirmacion);
                          setTransRow(row.original);
                        }}
                        color="primary"
                      >
                        <TaskAltIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>
      {abrirConfirmacion ? (
        <Dialog_autorizar_confirm
          row={transRow}
          abrirConfirmacion={abrirConfirmacion}
          onClose={() => handleOpenConfir(false)}
        />
      ) : null}
    </>
  );
}

export default Articulos_autorizacion_transf;
