//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :19/06/2024
// Version : 1
// Description : Actualizar precio
//------Changes ----------------------------------------------------------//
// Change Date : 15/8
// Change by : Candela Grosso
// Change description: Cambie el titulo y saque los console.log
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField,
  Button,
  Autocomplete,
  Stack,
} from "@mui/material";
import PercentIcon from '@mui/icons-material/Percent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

//notificacion
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
import Title from "../../Title";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Actualizar_precio_especial(props) {
  const [disabledGuardar, setDisabledGuardar] = useState(false);
  const [datoslista, setDatoslista] = useState({
    uuid_direccionentrega: "",
    lt_con_iva: "",
    lt_sin_iva: "",
    kg_con_iva: "",
    kg_sin_iva: "",
    vigencia:""
  });

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  //boton
  const [disBtn, setDisBtn] = useState(false);
  //*********************************** OBTENER **************************************************************************************************/
  //Fecha fin vigencia
  const fechaVigencia = moment(datoslista.vigencia);
  const fechaFinVigencia = fechaVigencia.subtract(1, "day");
  const fechaFinVigenciaFormat =
    datoslista.vigencia === ""
      ? "dd/mm/aaaa"
      : fechaFinVigencia.format("DD/MM/YYYY");
  const fechaFinVigenciaPut = fechaFinVigencia.format("YYYY-MM-DD");
  const [buscando, setBuscando] = useState(false)
  const [clientes, setClientes] = useState();
  const [clienteSeleccionada, setClienteSeleccionada] = useState("");

  const obtenerclientes = () => {
    fetch(UrlApi + "/clientes/precioespecial")
      .then((data) => data.json())
      .then((data) => setClientes(data));
  };
  useEffect(() => {
    obtenerclientes();
  }, []);
  
  const [direcciones, setDirecciones] = useState();
  const [direccionSeleccionada, setDireccionSeleccionada] = useState("");

  const obtenerdirecciones = () => {
    setBuscando(true)
    fetch(UrlApi + "/direccionesentrega/precioespecialcliente/"+ clienteSeleccionada.id)
      .then((data) => data.json())
      .then((data) => setDirecciones(data))
      .then((data) => setDireccionSeleccionada(""))
      .then((data) => setBuscando(false));
  };
  useEffect(() => {
    obtenerdirecciones();
  }, [clienteSeleccionada]);

  // console.log("Direccion SELECCIONADO", direccionSeleccionada);

  const [datosDirec, setDatosDirec] = useState();

  const obtenerXsucySeg = () => {
    fetch(
      UrlApi +
        "/precios_especiales/xdireccion/" +
        direccionSeleccionada.nro_dir 
    )
      .then((data) => data.json())
      .then((data) => setDatosDirec(data));
  };
  useEffect(() => {
    obtenerXsucySeg();
  }, [direccionSeleccionada]);

  // console.log("DATOS SUC Y SEG", datosDirec);

  //*********************************** NOTIFICACION **************************************************************************************************/
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    handleClose();
  };
  //**********************************************************************************************************************************//
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  const handleClose = () => {
    props.actualizar(false);
    props.tabla(true);
  };
  //*************************** GUARDAR *******************************************************************************************************//
  const guardarPrecio = async (e) => {
    // logEventos(
    //   "Guardar actualización del precio",
    //   "puntos de venta",
    //   "Se guardo el precio actualizado",
    //   localStorage.getItem("user")
    // );
    e.preventDefault();
    // setDisBtn(true);
    const response = await fetch(UrlApi + "/precios_especiales", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        uuid_direccionentrega: direccionSeleccionada.nro_dir ,
        lt_con_iva: datoslista.lt_con_iva,
        lt_sin_iva: datoslista.lt_sin_iva,
        kg_con_iva: datoslista.kg_con_iva,
        kg_sin_iva: datoslista.kg_sin_iva,
        vigencia: datoslista.vigencia,
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setColorMensaje("#D4EFDF");
          setMensaje("Se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

      await fetch(UrlApi + "/precios_especiales/" + datosDirec[0].id_precioespecial, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fin_vigencia: fechaFinVigenciaPut,
        }),
      })
        .then((res) => {
          if (!res.err) {
            console.log("Guardado");
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
  };
  const almacenar = (e) => {
    setDatoslista({
      ...datoslista,
      [e.target.name]: e.target.value,
    });
  };
  //*************************** AUMENTO EN PESO *******************************************************************************************************//
  const [aumento, setAumento] = useState("");

  const handleAumentoChange = (e) => {
    const nuevoAumento = e.target.value;
    setAumento(nuevoAumento);

    // Si el valor de aumento es umero, actualizamos el PRECIO
    if (!isNaN(nuevoAumento) && nuevoAumento !== "") {
      setDatoslista({
        ...datoslista,
        lt_con_iva:
          parseFloat(datosDirec[0].lt_con_iva) + parseFloat(nuevoAumento) ,
        lt_sin_iva:
          parseFloat(datosDirec[0].lt_sin_iva) + parseFloat(nuevoAumento) ,
        kg_con_iva:
          parseFloat(datosDirec[0].kg_con_iva) + parseFloat(nuevoAumento) ,
        kg_sin_iva:
          parseFloat(datosDirec[0].kg_sin_iva) + parseFloat(nuevoAumento) ,
      });
    } else {
      // Si el aumento está vacío, restablecer a los valores originales de datosDirec
      setDatoslista({
        ...datoslista,
        lt_con_iva: parseFloat(datosDirec[0].lt_con_iva),
        lt_sin_iva: parseFloat(datosDirec[0].lt_sin_iva),
        kg_con_iva: parseFloat(datosDirec[0].kg_con_iva),
        kg_sin_iva: parseFloat(datosDirec[0].kg_sin_iva),
      });
    } 
  };

  const handlePrecioChange = (e) => {
    const nuevoPrecio = e.target.value;
    setDatoslista({
      ...datoslista,
      lt_con_iva: nuevoPrecio,
      lt_sin_iva: nuevoPrecio,
      kg_con_iva: nuevoPrecio,
      kg_sin_iva: nuevoPrecio,
    });
  };

  // console.log("aumento", aumento);

    //*************************** AUMENTO EN PORCENTAJE *******************************************************************************************************//
    const [porcentaje, setPorcentaje] = useState("");

    const handlePorcentajeChange = (e) => {
      const nuevoPorcentaje = e.target.value;
      setPorcentaje(nuevoPorcentaje);
  
      if (!isNaN(nuevoPorcentaje) && nuevoPorcentaje !== "") {
        const porcentajeNumerico = parseFloat(nuevoPorcentaje) / 100;
  
        const nuevoPrecioLitroConIva = datosDirec[0].lt_con_iva * (1 + porcentajeNumerico);
        const nuevoPrecioLitroSinIva = datosDirec[0].lt_sin_iva * (1 + porcentajeNumerico);
        const nuevoPrecioKiloConIva = datosDirec[0].kg_con_iva * (1 + porcentajeNumerico);
        const nuevoPrecioKiloSinIva = datosDirec[0].kg_sin_iva * (1 + porcentajeNumerico);
  
        setDatoslista({
          ...datoslista,
          lt_con_iva: nuevoPrecioLitroConIva.toFixed(2),
          lt_sin_iva: nuevoPrecioLitroSinIva.toFixed(2),
          kg_con_iva: nuevoPrecioKiloConIva.toFixed(2),
          kg_sin_iva: nuevoPrecioKiloSinIva.toFixed(2),
        });
      } else {
        setDatoslista({
          ...datoslista,
          lt_con_iva: datosDirec[0].lt_con_iva,
          lt_sin_iva: datosDirec[0].lt_sin_iva,
          kg_con_iva: datosDirec[0].kg_con_iva,
          kg_sin_iva: datosDirec[0].kg_sin_iva,
        });
      }
    };

    useEffect(() => {
      if ((aumento === "" && porcentaje === "") || datoslista.vigencia === "" || Object.keys(direccionSeleccionada).length === 0 || Object.keys(clienteSeleccionada).length === 0) {
        setDisabledGuardar(true);
      } else {
        setDisabledGuardar(false);
      }
    }, [aumento, porcentaje, datoslista, direccionSeleccionada, clienteSeleccionada]);

    const agregarUnDia = (fecha) => {
      return moment(fecha, 'DD/MM/YYYY').add(1, 'days').format('YYYY-MM-DD');
    };
  
    // Verifica y formatea la fecha mínima
    const fechaMinima = datosDirec && datosDirec[0] && datosDirec[0].vigencia ? agregarUnDia(datosDirec[0].vigencia) : '';
  
  return (
    <div>
      <Container>
        <Title titulo={"Actualizar lista de precios"} handleClose={handleClose}/>
        <form onSubmit={guardarPrecio} autoComplete="off">
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={6}>
                {clientes && (
                  <Autocomplete
                    disabled={!clientes}
                    size={size}
                    focused
                    disableClearable
                    id="clientes"
                    noOptionsText={"Cliente INEXISTENTE"}
                    options={clientes}
                    autoHighlight
                    getOptionLabel={(clientes) =>
                      clientes.id + "-" + clientes.razon_social
                    }
                    onChange={(event, value) => setClienteSeleccionada(value)}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id}-{option.razon_social}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Cliente"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
              {/* <FormControl fullWidth={true} style={style} focused color="primary" required size={size}>
                  <InputLabel id="direcciones">Direcion Entrega</InputLabel>
                  <Select
                    required
                    variant='outlined'
                    name='tipo_moneda'
                    value={datoslista.uuid_direccionentrega}
                    onChange={almacenar}
                    label="Direccion Entrega">
                    {direcciones ? direcciones.map(elemento => (
                      <MenuItem key={elemento.id} value={elemento.nro_dir}>
                        {elemento.direccion_entrega}
                      </MenuItem>
                    )) : null}
                  </Select>
                </FormControl>*/}
                {buscando? 
                      (<TextField
                      // value={(bocaSeleccionada.idbocas_carga = null)}
                      disabled
                      focused
                      fullWidth
                      size="small"
                      label= "Buscando Direccion..."
                      />)
                      :
                    (
                //  {direcciones && ( 
                  <Autocomplete
                    disabled={!direcciones}
                    size={size}
                    focused
                    disableClearable
                    id="direcciones"
                    noOptionsText={"Dirección INEXISTENTE"}
                    options={direcciones}
                    autoHighlight
                    getOptionLabel={(direcciones) =>
                      direcciones.id + "-" + direcciones.direccion_entrega
                    }
                    onChange={(event, value) => setDireccionSeleccionada(value)}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.id}-{option.direccion_entrega}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={style}
                        required
                        focused
                        size="small"
                        placeholder="Tipee para buscar..."
                        {...params}
                        label="Direccion de entrega"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )} 
              </Grid>
              {datosDirec && datosDirec.length > 0 ? (
                <>
                  <Grid container justifyContent="left" sx={{ mt: 1 }}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: 19,
                          textDecoration: "underline",
                          textAlign: "left",
                        }}
                      >
                        Precio Vigente :
                      </Typography>
                    </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={0.5}> 
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="lt_con_iva"
                        label="Precio litro con IVA"
                        variant="outlined"
                        value={datosDirec[0].lt_con_iva || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="lt_sin_iva"
                        label="Precio litro sin IVA"
                        variant="outlined"
                        value={datosDirec[0].lt_sin_iva || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="kg_con_iva"
                        label="Precio kilo con IVA"
                        variant="outlined"
                        value={datosDirec[0].kg_con_iva || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="kg_sin_iva"
                        label="Precio kilo sin IVA"
                        variant="outlined"
                        value={datosDirec[0].kg_sin_iva || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="vigencia"
                        label="Vigencia"
                        variant="outlined"
                        value={datosDirec[0].vigencia || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        color="primary"
                        name="vigencia"
                        label="Fin de Vigencia"
                        variant="outlined"
                        value={fechaFinVigenciaFormat || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container justifyContent="left" sx={{ mt: 2 }}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: 19,
                          textDecoration: "underline",
                          textAlign: "left",
                        }}
                      >
                        Aumento :
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="left"
                    alignItems="left"
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        disabled={porcentaje !== ""? true:false}
                        color="secondary"
                        name="aumento"
                        label="Aumento en pesos"
                        variant="outlined"
                        value={aumento}
                        onKeyPress={(event) => {
                          if (!/[-0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={handleAumentoChange}
                        InputProps={{
                          startAdornment: (
                              <AttachMoneyIcon position="start">
                              </AttachMoneyIcon>
                          )
                      }}
                      />
                      </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="secondary"
                        name="aumento"
                        label="Aumento en porcentaje"
                        variant="outlined"
                        disabled={aumento !== ""? true:false}
                        value={porcentaje}
                        onKeyPress={(event) => {
                          if (!/[-0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={handlePorcentajeChange}
                        InputProps={{
                          startAdornment: (
                            <PercentIcon position="start">
                              </PercentIcon>
                          )
                        }}
                        />
                        </Grid>
                    </Grid>

                  <Grid container justifyContent="left" sx={{ mt: 2 }}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: 19,
                          textDecoration: "underline",
                          textAlign: "left",
                        }}
                      >
                        Precio Con Aumento :
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mt={0.5}>

                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="lt_con_iva"
                        label="Precio litro con IVA"
                        variant="outlined"
                        value={datoslista.lt_con_iva}
                        onChange={() => {
                          handlePrecioChange();
                          handlePorcentajeChange();
                          almacenar();
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="lt_sin_iva"
                        label="Precio litro sin IVA"
                        variant="outlined"
                        value={datoslista.lt_sin_iva}
                        onChange={() => {
                          handlePrecioChange();
                          handlePorcentajeChange();
                          almacenar();
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="kg_con_iva"
                        label="Precio kilo con IVA"
                        variant="outlined"
                        value={datoslista.kg_con_iva}
                        onChange={() => {
                          handlePrecioChange();
                          handlePorcentajeChange();
                          almacenar();
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        name="kg_sin_iva"
                        label="Precio kilo sin IVA"
                        variant="outlined"
                        value={datoslista.kg_sin_iva}
                        onChange={() => {
                          handlePrecioChange();
                          handlePorcentajeChange();
                          almacenar();
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        size={size}
                        style={style}
                        focused
                        color="primary"
                        required
                        type="date"
                        name="vigencia"
                        label="Vigencia"
                        variant="outlined"
                        value={datoslista.vigencia}
                        onChange={almacenar}
                        inputProps={{
                          min: fechaMinima,
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Typography component="div" sx={{ fontSize: 19, mt: 3 }}>
                  "Seleccione La Direccion de Entrega"
                </Typography>
              )}
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>

              <Button
                // disabled={disBtn}
                variant="contained"
                type="submit"
                color="success"
                disabled={disabledGuardar}
              >
                Guardar Actualización
              </Button>
            </Grid>
          </Box>
        </form>
        {/* SNACKBAR QUE SE MOSTRARA AL GUARDAR LOS CAMBIOS */}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
      </Container>
    </div>
  );
}

export default Actualizar_precio_especial;
