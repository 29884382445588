
//----- Component --------------------------------------------------------/

// Developer : Daiana Curcio / Nicolas Pascucci

// Creation Date : 21/11/2023

// Version : 1

// Description : Estan las sucursales que puede ver la persona que esta logueada.Ademas tiene la posibilidad de ir a almacenes para administrarlos o
// puede ir al inventario de esa sucursal para luego administrar los artículos.
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import Navbar from "../Navbar";
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Stack,
  Grid,
  Tooltip,
  IconButton,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import InputMask from "react-input-mask"; // para la mascara del numero de comprobante

// ICONOS
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';


//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useNavigate } from 'react-router-dom';
import { CenterFocusStrong } from "@material-ui/icons";
import { logEventos } from "../../services/services";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Sucursales_cargas() {
  const [sucursales, setSucursales] = useState([]);
  let navigate = useNavigate();
  let navigate2 = useNavigate();
  const [datosSucursal, setDatosSucursal] = useState({
    id: null,
    nombre: "",
    domicilio: "",
    telefonos: "",
    datos: "",
    lat: null,
    lng: null,
    usuario: localStorage.getItem("user"),
  });
  const [datosSucursalRow, setDatosSucursalRow] = useState({
    nombre: "",
  })
  const [tabla, setTabla] = useState(true);
  //   const [update, setUpdate] = useState(false);
  const [editar, setEditar] = useState(false);

  const [update, setUpdate] = useState(false);
  const [openclose, setOpenClose] = useState(false); //ADJUNTAR IMAGEN
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);

  const [openVerAlmacen, setOpenVerAlmacen] = useState(false);
  const [ abrirDialog, setAbrirDialog] = useState(false)
  const [openVerArticulos, setOpenVerArticulos] = useState(false);
  const [abrirDialog2, setAbrirDialog2] = useState(false)
//  console.log("DATOS DE SUCURSALES", datosSucursal);

  useEffect(() => {
    logEventos("Acceso al menú stock sucursales", "Stock Sucursales", "Ver los almacenes e inventarios por sucursal", localStorage.getItem("user"))
}, [])
  //*************************************************************************************************************************************/
  //funcion para obtener sucursales
  const obtenerSucursales = () => {
    fetch(UrlApi + "/stock_sucursales/" + localStorage.getItem("suc_auth").split(","))
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  useEffect(() => {
    obtenerSucursales();
  }, []);

 // console.log( "/stock_sucursales/" + localStorage.getItem("suc_auth").split(","))  ////si la sucursal esta en las autorizadas a ver por el usuario

  //*************************************************************************************************************************************//

// VER ALMACENES POR SUCURSAL
  const handleClickOpenVerAlmacen = (row) => {
    setOpenVerAlmacen(true);
   setDatosSucursalRow(row);
   setAbrirDialog(true);
  };

  const handleCloseVerAlmacen = () => {
    setOpenVerAlmacen(false)
  };

  const handleClickOpenVerArticulos = (row) => {
    setOpenVerArticulos(true);
   setDatosSucursalRow(row);
   setAbrirDialog2(true);
  };

  const handleCloseVerArticulos = () => {
    setOpenVerArticulos(false)
  };
  //*************************************************************************************************************************************/
  const [openDialogOnOff, setOpenDialogOnOff] = React.useState(false);
  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpenDialogOnOff(false);
  };

  //*************************************************************************************************************************************/
  const columns = useMemo(
    () => [
      { header: "ID", accessorKey: "id", size: 25 },
      { header: "Nombre", accessorKey: "nombre", size: 140 },
      { header: "Domicilio", accessorKey: "direccion", size: 200 },
      { header: "Latitud", accessorKey: "latitud", size: 80 },
      { header: "Longitud", accessorKey: "longitud", size: 80 },
    ],
    []
  );
   
  //*************************************************************************************************************************************/
  const almacenar = (e) => {
    setDatosSucursal({
      ...datosSucursal,
      [e.target.name]: e.target.value,
    });
  };
  //*************************************************************************************************************************************/
  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerSucursales();
  }, [update]);

  // ESTILO PARA LA TABLA

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {sucursales && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableT
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={sucursales} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableEditing
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={() => (
                  <div>
                    <Typography variant="poster" style={{ fontSize: 26, textAlign: "center" }}>
                    Stock Sucursales
                    </Typography>
                 </div>
                )}
                renderRowActions={({ row, table }) => (
                  //las acciones del costado editar y ver
                  <>
                    <div>
                       <Tooltip arrow placement="top" title="Almacenes">
                        <IconButton
                          onClick={() =>
                            navigate2("/almacenes_cargas/" + row.original.id, { state: row.original })} // para verlo en otra pantalla
                          color="primary"
                        >
                          <AddBusinessIcon />
                        </IconButton>
                      </Tooltip>                  
                    </div>
                    <div>
                    <Tooltip arrow placement="top" title="Inventario">
                        <IconButton
                          onClick={() =>
                            navigate("/lista_articulos/" + row.original.id, { state: row.original })}
                          color="primary"
                        >
                          <AddShoppingCartIcon />
                        </IconButton>
                      </Tooltip>   
                    </div>
                  </>
                )}
                />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>
    </>
  );
}

export default Sucursales_cargas;
