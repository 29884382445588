//----- Component --------------------------------------------------------/

// Developer : Daiana Curcio

// Creation Date : 23/11/2023

// Version : 1

// Description : pantalla para autorizaciones de confirmación de transferencias y que puedan devolver el stock o eliminarlo
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Box,
  DialogContentText,
  Typography,
  DialogActions,
} from "@mui/material";
import moment from "moment"; // PARA LA FECHA
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { grey } from '@mui/material/colors';
import { logEventos } from "../../services/services";

function Dialog_autorizar_confirm(props) {
  const [datosAutorizar, setDatosAutorizar] = useState({
    fecha_transaccion: date,
    id_motivo: "",
    detalle_transaccion: "",
  });
  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);
  const [openCrear, setOpenCrear] = React.useState(false);
  //   const [update, setUpdate] = useState(false);

  const [row, setRow] = useState(props.row);
  const [abrirConfirmacion, setAbrirConfirmacion] = useState(
    props.abrirConfirmacion
  );

//  console.log("ROW", row);

  //CERRAR DIALOG CONFIRMACION
  const handleClose = () => {
    setAbrirConfirmacion(!abrirConfirmacion);

    // setSnack(true)
    if (props.onClose) {
      props.onClose();
    }
  };

  // OBTENER FECHA ACTUAL
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  const [motivos, setMotivos] = useState();
  //funcion para obtener sucursales
  const obtenerMotivos = () => {
    fetch(UrlApi + "/stock_motivos")
      .then((data) => data.json())
      .then((data) => setMotivos(data));
  };

  useEffect(() => {
    obtenerMotivos();
  }, []);

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  //*****************************************************************************/
  //TRANSFERENCIA
  const [dialogTransferirArticulo, setDialogTransferirArticulo] =
    useState(false);
  const handleCloseDialogTransferirArticulo = () => {
    setDialogTransferirArticulo(false);
  };

  const handleClickOpenTransferirArtSuc = (row) => {
    setDialogTransferirArticulo(true);
    // setDatosArt(row);
  };

  const handleCloseTransferir = () => {
    setDialogTransferirArticulo(false);
  };
  //*****************************************************************************/
  // SALIDA
  const [dialogEliminarArticulo, setDialogEliminarArticulo] = useState(false);
  const handleCloseDialogEliminarArticulo = () => {
    setDialogEliminarArticulo(false);
  };

  const handleClickOpenEliminarArtSuc = async (row) => {
    setDialogEliminarArticulo(true);
    // setDatosArt(row);
  };

  const handleCloseSalida = () => {
    setDialogEliminarArticulo(false);
  };
  //*****************************************************************************/
  //Funcion para GUARDAR DEVOLUCION
  const guardarDevolucion = async (e) => {
    logEventos("Guardar devolución de artículo", "Stock Autorizar Trasferencias", "Se guardo devolución", localStorage.getItem("user"))
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/stock_transacciones/" + row.id_transaccion, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        estado_confirmacion: 1,
        estado_pendiente: 1,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          //   setUpdate(true);
          // setMensajeConfirma(true)
          setColorMensaje("#D4EFDF");
          setMensaje("La transferencia se autorizó!");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al autorizar la transferencia");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    await fetch(UrlApi + "/stock_transacciones", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_accion: 160,
        id_articulo: props.row.id_articulo,
        almacen_origen: props.row.almacen_destino,
        sucursal_destino: props.row.id_sucursal,
        cantidad_enviada: props.row.cantidad_pendiente,
        detalle_transaccion: datosAutorizar.detalle_transaccion,
        // nro_remito_puntodeventa:props.row.nro_remito_puntodeventa,
        // nro_remito_numero:props.row.nro_remito_numero,
        fecha_transaccion: moment(datosAutorizar.fecha_transaccion).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        en_transito: 1,
        usuario: localStorage.getItem("user"),
      }),
    }).then((res) => {
      if (!res.err) {
        setOpenSnack(true);
        setColorMensaje("#D4EFDF");
        setMensaje("La transferencia se guardó exitosamente !");
        console.log("Guardado");
      } else {
        setOpenSnack(false);
        setColorMensaje("#F1948A");
        setMensaje("Error al guardar la transferencia");
        console.log("Error");
      }
    });
   // console.log(row.id_transaccion);
    handleClose();
  };

  //Funcion para GUARDAR DEVOLUCION
  const guardarSalida = async (e) => {
    logEventos("Guardar rechazo de artículo", "Stock Autorizar Trasferencias", "Se rechazo autorización", localStorage.getItem("user"))
    console.log("Estoy por guardar salida");
    e.preventDefault();
    await fetch(UrlApi + "/stock_transacciones/" + row.id_transaccion, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        estado_confirmacion: 1,
        estado_pendiente: 0,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          //   setUpdate(true);
          // setMensajeConfirma(true)
          setColorMensaje("#D4EFDF");
          setMensaje("La transferencia se autorizó!");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al autorizar la transferencia");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    await fetch(UrlApi + "/stock_transacciones", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_accion: 159,
        id_articulo: props.row.id_articulo,
        almacen_origen: props.row.almacen_origen,
        cantidad_enviada: props.row.cantidad_pendiente,
        detalle_transaccion: datosAutorizar.detalle_transaccion,
        // nro_remito_puntodeventa:props.row.nro_remito_puntodeventa,
        // nro_remito_numero:props.row.nro_remito_numero,
        fecha_transaccion: moment(datosAutorizar.fecha_transaccion).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        id_motivo: datosAutorizar.id_motivo,
        usuario: localStorage.getItem("user"),
      }),
    }).then((res) => {
      if (!res.err) {
        setOpenSnack(true);
        setColorMensaje("#D4EFDF");
        setMensaje("La salida se guardó exitosamente !");
        console.log("Guardado");
      } else {
        setOpenSnack(false);
        setColorMensaje("#F1948A");
        setMensaje("Error al guardar la salida");
        console.log("Error");
      }
    });
  //  console.log(row.id_transaccion);
    handleClose();
  };

  const almacenar = (e) => {
    setDatosAutorizar({
      ...datosAutorizar,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Dialog open={abrirConfirmacion} onClose={handleClose}>
          <Box backgroundColor={"#1F618D"} mt={1}>
            <DialogTitle>
              <Typography variant="h6" align="center" color="#ffffff">
              Transferencia del Artículo: {props.row.nombre_articulo}
              </Typography>
            </DialogTitle>
          </Box>
          <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <DialogContent>
            <DialogContentText textAlign={"center"}>
            <b>¿Qué Desea Hacer con la Cantidad Pendiente?</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItemns="center"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                style={{ backgroundColor: "#1976D2", color: "#ffffff" }}
              >
                Volver
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleClickOpenTransferirArtSuc}
                style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
              >
                Devolver
              </Button>
              <Button
                style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleClickOpenEliminarArtSuc}
              >
                Eliminar
              </Button>
            </Grid>
          </DialogActions>
          </Box>
      </Dialog>

      {/* ------ DIALOG DEVOLVER ARTICULO ------ */}
      <Dialog open={dialogTransferirArticulo} onClose={handleCloseDialogTransferirArticulo}>
        <form onSubmit={guardarDevolucion}>
          <Box backgroundColor={"#1F618D"} mt={1}>
            <DialogTitle>
              <Typography variant="h6" align="center" color="#ffffff">
              Devolver Artículo a la Sucursal
              </Typography>
            </DialogTitle>
          </Box>
          <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <Container maxWidth="xl">
          <Grid container mt={2} mb={1} spacing={1}>
                  <Grid item xs={6} py={0} mb={1}>
                    <TextField
                      label="Artículo"
                      required
                      size="small"
                      name="articulo"
                      value={props.row.nombre_articulo}
                      inputProps={{
                        readOnly: true,
                        "aria-label": "Without label",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} py={1} mb={1}>
                    <TextField
                      label="Sucursal de Origen"
                      size="small"
                      name="sucursales"
                      value={props.row.nombre_suc_origen}
                      inputProps={{
                        readOnly: true,
                        "aria-label": "Without label",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} py={1} mb={1}>
                    <TextField
                      label="Sucursal de Destino"
                      size="small"
                      name="sucursales"
                      value={props.row.nombre_sucursal_destino}
                      inputProps={{
                        readOnly: true,
                        "aria-label": "Without label",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} py={1} mb={1}>
                    <TextField
                      label="Recibido"
                      size="small"
                      value={props.row.cantidad_recibida}
                      inputProps={{
                        readOnly: true,
                        "aria-label": "Without label",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} py={1} mb={1}>
                    <TextField
                      label="Cantidad a Devolver"
                      size="small"
                      inputProps={{
                        readOnly: true,
                        "aria-label": "Without label",
                      }}
                      value={props.row.cantidad_pendiente}
                    />
                  </Grid>
                  <Grid item xs={6} py={1} mb={1}>
                    <TextField
                      label="Fecha Envio"
                      name="fecha_transaccion"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosAutorizar.fecha_transaccion).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <TextField
                      label="Detalle Transaccion"
                      name="detalle_transaccion"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 60,
                      }}
                      focused
                      required
                      fullWidth
                      autoComplete="off"
                      value={datosAutorizar.detalle_transaccion}
                      onChange={almacenar}
                    />
                  </Grid>
                </Grid>
          </Container>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItemns="center"
            >
              <Button
                variant="contained"
                onClick={handleCloseTransferir}
                style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
              >
                Volver
              </Button>
              <Button
                style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                type="submit"
                variant="contained"
                color="primary"
              >
                Devolver
              </Button>
            </Grid>
          </DialogActions>
          </Box>
        </form>
      </Dialog>
  
      {/* ------ DIALOG ELIMINAR ARTICULO ------ */}
      <Dialog open={dialogEliminarArticulo} onClose={handleCloseDialogEliminarArticulo}>
        <form onSubmit={guardarSalida}>
          <Box backgroundColor={"#1F618D"} mt={1}>
            <DialogTitle>
              <Typography variant="h6" align="center" color="#ffffff">
              Eliminar Artículo
              </Typography>
            </DialogTitle>
          </Box>
          <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <Container maxWidth="xs">
          <Grid container mt={2} mb={1} spacing={1}>
            {/* <Box mt={1} ml={1}> */}
                  <Grid item xs={6} py={1} mb={1}>
                    <TextField
                      label="Artículo"
                      size="small"
                      name="nombre_articulo"
                      value={props.row.nombre_articulo}
                      onChange={almacenar}
                      inputProps={{
                        readOnly: true,
                        "aria-label": "Without label",
                      }}
                    />
                  </Grid>
                  {/* </Box> */}
                  {/* <Box mt={1} ml={2}> */}
                  <Grid item xs={5.5} py={1} mb={1}>
                    <TextField
                      label="Sucursal de Origen"
                      size="small"
                      name="almacen"
                      value={props.row.nombre_suc_origen}
                      onChange={almacenar}
                      inputProps={{
                        readOnly: true,
                        "aria-label": "Without label",
                      }}
                    />
                  </Grid>
                  {/* </Box> */}
                  <Grid item xs={12} py={1} mb={1} mr={2}>
                    <FormControl style={style} focused>
                      <InputLabel>Motivo</InputLabel>
                      <Select
                        required
                        label="Motivo"
                        size="small"
                        name="id_motivo"
                        value={datosAutorizar.id_motivo}
                        onChange={almacenar}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                      >
                        {motivos
                          ? motivos.map((elemento) => (
                              <MenuItem
                                key={elemento.id_motivo}
                                value={elemento.id_motivo}
                              >
                                {elemento.descripcion_motivo}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={11.5} mb={2}>
                    <TextField
                      label="Detalle Transaccion"
                      name="detalle_transaccion"
                      size="small"
                      placeholder="..."
                      inputProps={{
                        maxLength: 60,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosAutorizar.detalle_transaccion}
                      autoComplete="off"
                      onChange={almacenar}
                    />
                  </Grid>
                  {/* <Box mt={0}> */}
                  <Grid item xs={5.5} py={1} mb={1}>
                    <TextField
                      label="Cantidad a Eliminar"
                      size="small"
                      inputProps={{
                        readOnly: true,
                        "aria-label": "Without label",
                      }}
                      value={props.row.cantidad_pendiente}
                    />
                  </Grid>
                  {/* </Box> */}
                  <Grid item xs={6} py={1} mb={1}>
                    <TextField
                      label="Fecha Transacción"
                      name="fecha_transaccion"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosAutorizar.fecha_transaccion).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenar}
                    />
                  </Grid>
                </Grid>
          </Container>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItemns="center"
            >
              <Button
                variant="contained"
                onClick={handleCloseSalida}
                style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
              >
                Volver
              </Button>
              <Button
                style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                type="submit"
                variant="contained"
                color="primary"
              >
                Eliminar
              </Button>
            </Grid>
          </DialogActions>
          </Box>
        </form>
      </Dialog>

    </div>
  );
}

export default Dialog_autorizar_confirm;
