import React, { useState, useEffect, useRef } from "react";
import { UrlApi } from "../../../services/apirest";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import MapaCliente from "./MapaCliente";
import InputAdornment from "@mui/material/InputAdornment";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { logDOM } from "@testing-library/react";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&::before": {
              borderBottom: "1px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "1px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "1px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });

function FormDir(props) {
  const [localidades, setLocalidades] = useState(null);
  const [productos, setProductos] = useState(null);
  const [provincias, setProvincias] = useState(null);
  const [sucursales, setSucursales] = useState(null);
  const [empresas, setEmpresas] = useState(null);
  const sucRef = useRef(null);
  const [tipo_moneda, settipo_moneda] = useState(null);
  // const secondTextFieldRef = useRef(null);

  const obtenerProvincias = () => {
    fetch(UrlApi + "/provincias") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setProvincias(data)); //seteo Sucursales
  };

  const obtenerProductos = () => {
    fetch(UrlApi + "/productos")
      .then((data) => data.json())
      .then((data) => setProductos(data));
  };

  const obtenerLocalidades = () => {
    fetch(UrlApi + "/localidades/prov/" + props.dataDir.id_provincia)
      .then((data) => data.json())
      .then((data) => setLocalidades(data));
  };

  const obtenerEmpresas = () => {
    fetch(UrlApi + "/empresas")
      .then((data) => data.json())
      .then((data) => setEmpresas(data));
  };

  const obtenerSucTipoPlanta = () => {
    fetch(UrlApi + "/sucursales/tipoPlanta") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setSucursales(data)); //seteo Sucursales
  };

  const obtenertipoMoneda = () => {
    fetch(UrlApi + "/tipo_moneda_afip")
      .then((data) => data.json())
      .then((data) => settipo_moneda(data));
  };

  useEffect(() => {
    obtenerProvincias();
    obtenerProductos();
    obtenerSucTipoPlanta();
    obtenerEmpresas();
    obtenertipoMoneda();
  }, []);

  const [cambieProv, setCambieProv] = useState(false);

  useEffect(() => {
    if (cambieProv) {
      props.dataDir.id_localidad = "";
    }
    obtenerLocalidades();
  }, [props.dataDir.id_provincia, cambieProv]);

  const variant = "standard";

  const almacenar = (e) => {
    props.setDataDir({ ...props.dataDir, [e.target.name]: e.target.value });
  };

  const latitudRegex = /^-?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
  const longitudRegex =
    /^-?((1[0-7]\d(\.\d+)?|180(\.0+)?)|([0-9]?\d(\.\d+)?))$/;
  const regexTxt = /^-?\d*\.?\d*$/; // Expresión regular para permitir solo números, punto decimal opcional y el signo "-"

  const readOnly = props.readOnly;

  const outerTheme = useTheme();
  const sucAuth = localStorage.getItem("suc_auth").split(",").map(Number);

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        pt={2}
      >
        <Grid item xs={6} px={2}>
          <Grid container spacing={1} direction="row">
            <Grid item xs={12}>
              <TextField
                required
                placeholder="Vivienda / Granja "
                autoComplete="off"
                variant={variant}
                size="small"
                fullWidth
                type="text"
                name="nombre_direccion"
                label="Lugar de entrega"
                color="primary"
                onChange={almacenar}
                value={
                  props.dataDir.nombre_direccion
                    ? props.dataDir.nombre_direccion.replace(/\b\w/g, (l) =>
                        l.toUpperCase()
                      )
                    : props.dataDir.nombre_direccion
                }
                inputProps={{ readOnly: readOnly }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl
                size="small"
                fullWidth
                variant={variant}
                required
                disabled={readOnly}
              >
                <InputLabel>Provincia</InputLabel>
                <Select
                  name="id_provincia"
                  labelId="id_provincia"
                  value={props.dataDir.id_provincia}
                  label="Provincia"
                  onChange={(e) => {
                    almacenar(e);
                    setCambieProv(true);
                  }}
                >
                  {provincias
                    ? provincias.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.provincia}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                size="small"
                fullWidth
                variant={variant}
                required
                disabled={readOnly}
              >
                <InputLabel>Localidad</InputLabel>
                <Select
                  name="id_localidad"
                  label="Localidad"
                  value={props.dataDir.id_localidad}
                  onChange={almacenar}
                >
                  {localidades
                    ? localidades.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.localidad}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              {tipo_moneda && (
                <FormControl variant={variant} size="small" fullWidth required>
                  <InputLabel>Tipo moneda</InputLabel>
                  <Select
                    name="id_tipomoneda"
                    labelId="id_tipomoneda"
                    value={props.dataDir.id_tipomoneda}
                    label="Tipo moneda"
                    onChange={almacenar}
                  >
                    {tipo_moneda
                      ? tipo_moneda.map((elemento) => (
                          <MenuItem
                            key={elemento.idtipo_moneda_afip}
                            value={elemento.idtipo_moneda_afip}
                          >
                            {elemento.moneda}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant={variant}
                size="small"
                fullWidth
                placeholder="Sr .. "
                type="text"
                name="contacto"
                label="Contacto"
                color="primary"
                onChange={almacenar}
                value={props.dataDir.contacto}
                inputProps={{ maxLength: 200, readOnly: readOnly }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                variant={variant}
                size="small"
                fullWidth
                placeholder="ejemplo@mail.com"
                type="email"
                name="mails"
                id="mails"
                label="Mails"
                color="primary"
                onChange={almacenar}
                value={props.dataDir.mails}
                inputProps={{ maxLength: 300, readOnly: readOnly }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoComplete="off"
                variant={variant}
                size="small"
                fullWidth
                name="telefono1"
                label="Telefono 1"
                color="primary"
                onChange={almacenar}
                value={props.dataDir.telefono1}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{ maxLength: 10, readOnly: readOnly }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {props.codArg}
                    </InputAdornment>
                  ),
                }}
                helperText="Cód área (sin 0) + el número sin el 15."
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoComplete="off"
                variant={variant}
                size="small"
                fullWidth
                name="telefono2"
                label="Telefono 2"
                color="primary"
                onChange={almacenar}
                value={props.dataDir.telefono2}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{ maxLength: 10, readOnly: readOnly }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {props.codArg}
                    </InputAdornment>
                  ),
                }}
                helperText="Cód área (sin 0) + el número sin 15. "
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoComplete="off"
                variant={variant}
                size="small"
                fullWidth
                name="telefono3"
                label="Telefono 3"
                color="primary"
                onChange={almacenar}
                value={props.dataDir.telefono3}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{ maxLength: 10, readOnly: readOnly }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {props.codArg}
                    </InputAdornment>
                  ),
                }}
                helperText="Cód área (sin 0) + el número sin 15."
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl
                size="small"
                fullWidth
                variant={variant}
                required
                disabled={readOnly}
                onKeyDown={(e) => {
                  e.preventDefault();
                  sucRef.current.focus();
                }}
                color={
                  props.dataDir.id_producto === "" ||
                  props.dataDir.id_producto === null
                    ? "warning"
                    : "primary"
                }
                focused={!props.dataDir.id_producto}
              >
                {/* {console.log(props.dataDir)} */}
                <InputLabel>Producto</InputLabel>
                <Select
                  name="id_producto"
                  labelId="id_producto"
                  value={props.dataDir.id_producto}
                  label="Producto"
                  onChange={almacenar}
                >
                  {productos
                    ? productos.map((elemento) => (
                        <MenuItem
                          key={elemento.idproducto}
                          value={elemento.idproducto}
                        >
                          {elemento.nombre}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <ThemeProvider theme={customTheme(outerTheme)}>
              <Grid item xs={4}>
                <TextField
                  disableTabFocus
                  autoComplete="off"
                  focused
                  variant={variant}
                  size="small"
                  fullWidth
                  name="latitud"
                  label="Latitud"
                  value={props.dataDir.latitud}
                  inputProps={{
                    maxLength: 300,
                    readOnly: true,
                  }}
                  onChange={(e) => {
                    almacenar(e);
                  }}
                  InputProps={{
                    pattern: "[-\\d]*",
                  }}
                  onBlur={(e) => {
                    const textok = regexTxt.test(e.target.value);
                    const latok = latitudRegex.test(e.target.value);
                    props.setLatLongParams({
                      ...props.latLongParams,
                      latOK:
                        e.target.value === "" ||
                        (textok === true && latok === true)
                          ? true
                          : false,
                    });
                  }}
                  error={!props.latLongParams.latOK}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disableTabFocus
                  autoComplete="off"
                  focused
                  variant={variant}
                  size="small"
                  fullWidth
                  name="longitud"
                  label="Longitud"
                  onChange={almacenar}
                  value={props.dataDir.longitud}
                  inputProps={{ maxLength: 300, readOnly: true }}
                  color={props.dataDir.direccion_entrega ? "grey" : "primary"}
                  onBlur={(e) => {
                    const textok = regexTxt.test(e.target.value);
                    const longok = longitudRegex.test(e.target.value);
                    props.setLatLongParams({
                      ...props.latLongParams,
                      longOK:
                        e.target.value === "" ||
                        (textok === true && longok === true)
                          ? true
                          : false,
                    });
                  }}
                  error={!props.latLongParams.longOK}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  focused
                  variant={variant}
                  size="small"
                  fullWidth
                  name="dist_suc_cli"
                  label="Distancia Cliente - Sucursal"
                  onChange={almacenar}
                  value={props.dataFact.dist_suc_cli}
                  inputProps={{ readOnly: true }}
                  color={"grey"}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  focused
                  variant={variant}
                  size="small"
                  fullWidth
                  label="Distancia productor - sucursal"
                  onChange={almacenar}
                  value={
                    props.dataSucursal && props.dataSucursal.distanciabocacarga
                  }
                  InputProps={{ readOnly: true }}
                  color={"grey"}
                />
              </Grid>
            </ThemeProvider>
          </Grid>
        </Grid>

        <Grid item xs={6} px={2}>
          <Grid container spacing={1} direction="row">
            <Grid item xs={6}>
              <FormControl
                size="small"
                fullWidth
                variant={variant}
                required
                disabled={readOnly}
              >
                <InputLabel>Sucursal</InputLabel>
                <Select
                  inputRef={sucRef}
                  name="id_sucursal"
                  label="Sucursal"
                  value={props.dataDir.id_sucursal}
                  onChange={almacenar}
                >
                  {sucursales &&
                    sucursales
                      .filter(
                        (sucursal) =>
                          sucAuth.includes(sucursal.id) ||
                          sucursal.id === parseInt(props.dataDir.id_sucursal)
                      )
                      .map((sucursal) => (
                        <MenuItem key={sucursal.id} value={sucursal.id}>
                          {sucursal.nombre}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                size="small"
                fullWidth
                variant={variant}
                required
                disabled={readOnly}
              >
                <InputLabel>Empresa</InputLabel>
                <Select
                  // inputRef={sucRef}
                  name="id_empresa"
                  label="Empresa"
                  value={props.dataDir.id_empresa}
                  onChange={almacenar}
                >
                  {empresas &&
                    empresas.map((sucursal) => (
                      <MenuItem
                        key={sucursal.idempresa}
                        value={sucursal.idempresa}
                      >
                        {sucursal.empresa}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ height: "280px" }}>
              <MapaCliente
                sucursalId={props.dataDir.id_sucursal}
                setDataDir={props.setDataDir}
                dataDir={props.dataDir}
                dataSucursal={props.dataSucursal}
                setDataSucursal={props.setDataSucursal}
                mapParams={props.mapParams}
                setMapParams={props.setMapParams}
                dataFact={props.dataFact}
                setDataFact={props.setDataFact}
                latLongParams={props.latLongParams}
                setLatLongParams={props.setLatLongParams}
                readOnly={readOnly}
                setActiveTab={props.setActiveTab}
                direccionAmpliacion={props.direccion}
                txtFieldLabel={"Direccion de entrega: "}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
    </div>
  );
}

export default FormDir;
