//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci

// Creation Date : 21/05/2024

// Version : 1

// Description : Panel donde ven los resultados mensuales de los comerciales
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, createTheme, useTheme } from "@mui/material";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { Box, Typography, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material";
// Boton desplegable
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import "dayjs/locale/es";
import { UrlApi } from "../../../services/apirest";
import DetalleResultado from "./DetalleResultado";
import Navbar from "../../Navbar";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Confirmar_aprobar from "./Confirmar_aprobar";
import Confirmar_desaprobar from "./Confirmar_desaprobar";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { logEventos } from "../../../services/services";
//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { blueGrey } from "@mui/material/colors";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ConfirmarRevisar from "./ConfirmarRevisar";
import moment from "moment";
import Confirmar_pendiente from "./Confirmar_pendiente";


function Panelrrhh(props) {
  const [muestra, setMuestra] = useState(0)
  const [datosRow, setDatosRow] = useState({})
  const [aprobar, setAprobar] = useState(false)
  const [desaprobar, setDesaprobar] = useState(false)
  const [pendiente, setPendiente] = useState(false)
  const [revisar, setRevisar] = useState(false)
  const [resultados, setResultados] = useState({})
  const [resultadosAprobados, setResultadosAprobados] = useState({})
  const [resultadosDesaprobados, setResultadosDesaprobados] = useState({})
  const [resultadosCSV, setResultadosCSV] = useState({})
  const [resultadosAprobadosCSV, setResultadosAprobadosCSV] = useState({})
  const [resultadosDesaprobadosCSV, setResultadosDesaprobadosCSV] = useState({})
  const [selectedDate, setSelectedDate] = useState(moment(new Date()).format("YYYY-MM"));
  // const [username, setUsername] = useState()
  const [resultadosUsuario, setResultadosUsuario] = useState({})
  const [resultadosAprobadosUsuario, setResultadosAprobadosUsuario] = useState({})
  const [resultadosDesaprobadosUsuario, setResultadosDesaprobadosUsuario] = useState({})
// ******************** Obtener para los comerciales individualmente *********************** //
// const obtenerUser = () => {
//   fetch(UrlApi + "/representantes/getid/"+localStorage.getItem("user"))
//     .then((data) => data.json())
//     .then((data) => setUsername(data));
// };
    
// useEffect(() => {
//   obtenerUser();
// }, []);

const obtenerResultadosUsuario = () => {
  fetch(UrlApi + "/resultados_comerciales/mesusuario/"+formattedDate+"/"+localStorage.getItem("user"))
    .then((data) => data.json())
    .then((data) => setResultadosUsuario(data));
};
useEffect(() => {
  if (props.esComercial === true) {
    obtenerResultadosUsuario();
  }
}, [selectedDate, props.esComercial]);

const obtenerResultadosAprobadosUsuario = () => {
  fetch(UrlApi + "/resultados_comerciales/mesaprobadousuario/"+formattedDate+"/"+localStorage.getItem("user"))
    .then((data) => data.json())
    .then((data) => setResultadosAprobadosUsuario(data));
};
useEffect(() => {
  if (props.esComercial === true) {
    obtenerResultadosAprobadosUsuario();
  }
}, [selectedDate, props.esComercial]);

const obtenerResultadosDesaprobadosUsuario = () => {
  fetch(UrlApi + "/resultados_comerciales/mesdesaprobadousuario/"+formattedDate+"/"+localStorage.getItem("user"))
    .then((data) => data.json())
    .then((data) => setResultadosDesaprobadosUsuario(data));
};   
useEffect(() => {
  if (props.esComercial === true) {
    obtenerResultadosDesaprobadosUsuario();
  }
}, [selectedDate, props.esComercial]);

// ****************************************************************************************  //
  const handleClickPendientes = (row) => {
  setMuestra(0)
  };
  const handleClickAprobados = (row) => {
    setMuestra(1)
  };
  const handleClickDesaprobados = (row) => {
    setMuestra(2)
  };

  const obtenerResultados = () => {
    fetch(UrlApi + "/resultados_comerciales/mes/"+formattedDate)
      .then((data) => data.json())
      .then((data) => setResultados(data));
  };
      
  useEffect(() => {
    obtenerResultados();
  }, [selectedDate]);

  const obtenerResultadosAprobados = () => {
    fetch(UrlApi + "/resultados_comerciales/mesaprobado/"+formattedDate)
      .then((data) => data.json())
      .then((data) => setResultadosAprobados(data));
  };
      
  useEffect(() => {
    obtenerResultadosAprobados();
  }, [selectedDate]);

  const obtenerResultadosDesaprobados = () => {
    fetch(UrlApi + "/resultados_comerciales/mesdesaprobado/"+formattedDate)
      .then((data) => data.json())
      .then((data) => setResultadosDesaprobados(data));
  };
      
  useEffect(() => {
    obtenerResultadosDesaprobados();
  }, [selectedDate]);

  // console.log("datos", resultados);

  const obtenerResultadosCSV = () => {
    fetch(UrlApi + "/resultados_comerciales/mesCSV/"+formattedDate)
      .then((data) => data.json())
      .then((data) => setResultadosCSV(data));
  };
      
  useEffect(() => {
    obtenerResultadosCSV();
  }, [selectedDate]);

  const obtenerResultadosAprobadosCSV = () => {
    fetch(UrlApi + "/resultados_comerciales/mesaprobadoCSV/"+formattedDate)
      .then((data) => data.json())
      .then((data) => setResultadosAprobadosCSV(data));
  };
      
  useEffect(() => {
    obtenerResultadosAprobadosCSV();
  }, [selectedDate]);

  const obtenerResultadosDesaprobadosCSV = () => {
    fetch(UrlApi + "/resultados_comerciales/mesdesaprobadoCSV/"+formattedDate)
      .then((data) => data.json())
      .then((data) => setResultadosDesaprobadosCSV(data));
  };
      
  useEffect(() => {
    obtenerResultadosDesaprobadosCSV();
  }, [selectedDate]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const formattedDate = `${selectedDate}-01`
  // console.log('Mes seleccionado:', formattedDate);

  const handleClickAprobar = (row) => {
    setDatosRow(row)
    setAprobar(true);
  };

  
  const handleVolver = () => {
    setAprobar(false);
    obtenerResultados()
    obtenerResultadosAprobados()
    obtenerResultadosDesaprobados()
  };
  
  const handleClickRevisar = (row) => {
    setDatosRow(row)
    setRevisar(true);
  };

  const handleVolverRevisar = () => {
    setRevisar(false);
    obtenerResultados()
    obtenerResultadosAprobados()
    obtenerResultadosDesaprobados()
  };

  const handleClickDesaprobar = (row) => {
    setDatosRow(row)
    setDesaprobar(true);
  };

  const handleClickPasarPendiente = (row) => {
    setDatosRow(row)
    setPendiente(true);
  };

  const handleVolverDesaprobar = () => {
    setDesaprobar(false);
    obtenerResultados()
    obtenerResultadosAprobados()
    obtenerResultadosDesaprobados()
  };

  const handleVolverPendiente = () => {
    setPendiente(false);
    obtenerResultados()
    obtenerResultadosAprobados()
    obtenerResultadosDesaprobados()
  };
  // *********************************************************** Tabla ********************************************************************** //
const muiSearchTextFieldProps = {
  placeholder: "Buscar..",
  sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
  variant: "outlined",
  color: "primary",
  size: "small",
};

const tableHeadCellProps = {
  align: "left",
  sx: {
    fontSize: 16,
    color: "white",
    fontWeight: "normal",
    backgroundColor: "#2471A3",
    borderColor: "black",
  },
};

const globalTheme = useTheme();
const tableTheme = useMemo(
  () =>
    createTheme({
      palette: {
        mode: globalTheme.palette.mode,
        primary: globalTheme.palette.primary, //primary color for the table
        info: {
          main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
        },
        background: {
          default:
            globalTheme.palette.mode === "light"
              ? "rgb(234, 239, 241)"
              : "#000", //pure black table in dark mode for fun
        },
      },
      typography: {
        button: {
          textTransform: "none", //customize typography styles for all buttons in table by default
          fontSize: "1rem",
        },
      },
      components: {
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: "0.8rem",
            },
          },
        },
      },
    }),
  [globalTheme]);

const initialState = {
  //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
  density: "compact",
  showGlobalFilter: true,
  pagination: { pageIndex: 0, pageSize: 10 },
  // columnSizing: {
  //   ['mrt-row-actions']: 150, // Custom width for the actions column
  // },
};

const columns = useMemo(
  // TABLA Objetivos
  () => [
    { header: "Legajo", accessorKey: "legajo", size:110},
    { header: "Comercial", accessorKey: "nombre_apellido", size: 110 },
    { header: "Sucursal", accessorKey: "nombre_sucursal", size: 110 },
    {
      header: "Objetivo",
      accessorKey: "objetivo",
      size: 100,
    },
    {
      header: "Monto en $",
      accessorKey: "monto_pesos",
      size: 100,
    },
    {
      header: "Kg. Vendido",
      accessorKey: "vendido",
      size: 100,
    },
    // {
    //   header: "Mes",
    //   accessorKey: "mes",
    //   size: 100,
    //   enableHiding: true,
    // },
    {
      header: "Checklist",
      accessorKey: "checklist",
      size: 90,
    },
    {
      header: "Revisado",
      accessorKey: "revisado_valor",
      size: 90,
    },
  ],
  []
);

const columnsCSV = useMemo(
  // TABLA Objetivos
  () => [
    { header: "Legajo", accessorKey: "legajo", size:110},
    { header: "Comercial", accessorKey: "nombre_apellido", size: 110 },
    { header: "Sucursal", accessorKey: "nombre_sucursal", size: 110 },
    { header: "Resultado %", accessorKey: "suma_resultado_porcentaje", size: 110 },
    {
      header: "Objetivo",
      accessorKey: "objetivo",
      size: 100,
    },
    {
      header: "Monto en $",
      accessorKey: "monto_pesos",
      size: 100,
    },
    {
      header: "Kg. Vendido",
      accessorKey: "vendido",
      size: 100,
    },
    {
      header: "Clientes",
      accessorKey: "clientes",
      size: 100,
    },
    {
      header: "Vencidos",
      accessorKey: "vencidos",
      size: 100,
    },
    {
      header: "Checklist",
      accessorKey: "checklist",
      size: 100,
    },
    { header: "Cumple", accessorKey: "cumple", size: 110 },
    { header: "No Cumple", accessorKey: "nocumple", size: 110 },
    { header: "Objetivo Distribuidores B", accessorKey: "objetivo_distribuidores", size: 110 },
    { header: "Resultado Distribuidores B", accessorKey: "distribuidores_butano", size: 110 },
    { header: "Objetivo Comercios B", accessorKey: "objetivo_comerciales", size: 110 },
    { header: "Resultado Comercios B", accessorKey: "comercios_butano", size: 110 },
    { header: "Objetivo Usuarios B", accessorKey: "objetivo_usuarios", size: 110 },
    { header: "Resultado Usuarios B", accessorKey: "usuarios_butano", size: 110 },
    {
      header: "Monto a Pagar",
      accessorKey: "monto_a_pagar",
      size: 100,
    },
  ],
  []
);

useEffect(() => {
  logEventos("Tabla de resultados de evaluacion de comerciales", "Evaluacion Comerciales", "Se visualizan los resultados de los comerciales", localStorage.getItem("user"))
}, [])

  //CSV
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `Lista de resultados_`+ `${muestra===0?"Pendientes":muestra===1?"Aprobados":"Desaprobados"}`+  `_`+`${selectedDate}`,
    headers: columnsCSV.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(muestra===0?resultadosCSV:muestra===1?resultadosAprobadosCSV:resultadosDesaprobadosCSV);
  };

  const customLocalization = {
    ...MRT_Localization_ES,
    expandAll: 'Ex', // Cambia 'Expandir todo' a 'Ex'
    expand: 'Ex', // Cambia 'Expandir' a 'Ex'
  };

  const userLog = localStorage.getItem("adminrrhh")
  console.log("USER",userLog);

  const currentMonth = moment();
  const monthOptions = Array.from({ length: 12 }, (_, i) => {
      const month = currentMonth.clone().subtract(i, 'months').format('YYYY-MM');
      return { value: month, label: currentMonth.clone().subtract(i, 'months').format('MMMM YYYY') };
  }); 

return (
  <div style={{marginTop:20}}>
  <Navbar/>
  <br></br>
    {props.esComercial === false?
  <div style={{ textAlign: 'center' }}>
    <Button
      onClick={handleExportData}
      variant="contained"
      style={{ backgroundColor: blueGrey[400], color: "white" }}
      size="small"
      endIcon={<FileDownloadIcon />}
    >
      Exportar a CSV
    </Button>
  </div>
    :null}
    <Box mt={2} mb={4}>
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          enableExpandAll={false}
          enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
          enableGrouping // para permitir agrupar x estado x ej
          layoutMode="grid"
          muiTableHeadCellProps={tableHeadCellProps}
          muiTableBodyCellProps={({ row }) => ({
            align: "left",
            sx: {
              backgroundColor:
              row.original.estado === 1 ? "#b3e6b3" :(row.original.estado===2? "#efc3c3":"#FFFFFF"),
              color: "black",
              borderColor: "black",
              // columnSizing: {
              //   ['mrt-row-actions']: 150, // Ancho personalizado para la columna de acciones
              // },
            },
          })}
          enableEditing
          displayColumnDefOptions={{
            'mrt-row-actions': {
              // header: 'Change Account Settings', //change header text
              size: 80, //make actions column wider
            },
          }}
          enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
          enableColumnFilters={false} //desactiva filtros x  columna
          enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
          enableDensityToggle={false} //
          localization={customLocalization} //idioma
          enableColumnDragging={false} //para arrastrar columnas
          initialState={initialState}
          muiSearchTextFieldProps={muiSearchTextFieldProps}
          columns={columns}
          data={props.esComercial === true?(muestra===0?resultadosUsuario:muestra===1?resultadosAprobadosUsuario:resultadosDesaprobadosUsuario):(muestra===0?resultados:muestra===1?resultadosAprobados:resultadosDesaprobados)} //con lo q la completas (esto cambia siempre)
          enableStickyFooter
          renderDetailPanel={({ row }) => (
            <DetalleResultado datos={row.original} />
          )}
          // enableEditing
          positionActionsColumn="last" // posicion de la columna de acciones al final
          // ACCIONES
          renderToolbarInternalActions={(
            { table } // boton para nuevo , al lado del buscar
          ) => (
            <>
              <MRT_FullScreenToggleButton table={table} />
              <Tooltip title="Ver Pendientes">
                <IconButton
                  color="primary"
                  onClick={() => {
                    handleClickPendientes();
                  }}
                >
                  <AccessAlarmsIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Ver Desaprobados">
                <IconButton
                  color="error"
                  onClick={() => {
                    handleClickDesaprobados();
                  }}
                >
                  <BlockIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Ver Aprobados">
                <IconButton
                  color="success"
                  onClick={() => {
                    handleClickAprobados()
                  }}
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          renderTopToolbarCustomActions={() => (
            <>
              <Typography variant="poster" fontSize={26}>
                Resultados {muestra===0?"Pendientes":muestra===1?"Aprobados":"Desaprobados"}
              </Typography>
              <FormControl focused color="primary" required size="small">
                <InputLabel id="mes">Período</InputLabel>
                <Select
                    required
                    variant='outlined'
                    name='mes'
                    value={selectedDate}
                    onChange={handleDateChange}
                    label="Período"
                    labelId="mes"  // Asegúrate de que labelId coincida con el InputLabel
                >
                    {monthOptions.map(elemento => (
                        <MenuItem key={elemento.value} value={elemento.value}>
                            {elemento.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            </>
          )}
          renderRowActions={(
            { row } //las acciones del costado editar y ver
          ) => (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
              {props.esComercial === true?null:(
              <>
              {userLog === "0" || userLog === "null"?
              <Tooltip arrow placement="left" title="Revisar">
              <IconButton
                color="primary"
                disabled={row.original.revisado === 0?false:true}
                onClick={() => handleClickRevisar(row.original)}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
              :null}
            {userLog === "1"?
              <>
                <Tooltip arrow placement="top" title="Aprobar" >
                  <IconButton
                    color="success"
                    disabled={row.original.revisado === 0?true:(row.original.checklist === "Completo"? (row.original.estado===1?true:false):true)}
                    onClick={() => handleClickAprobar(row.original)}
                  >
                    <CheckCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow placement="top" title="Desaprobar">
                  <IconButton
                    disabled={row.original.revisado === 0?true:(row.original.checklist === "Completo"? (row.original.estado===2?true:false):true)}
                    onClick={() => handleClickDesaprobar(row.original)}
                    color="error"
                  >
                    <BlockIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow placement="top" title="Pasar a Pendiente">
                  <IconButton
                    disabled={row.original.revisado === 0?true:(row.original.checklist === "Completo"? (row.original.estado===0?true:false):true)}
                    onClick={() => handleClickPasarPendiente(row.original)}
                    color="primary"
                  >
                    <AccessAlarmsIcon />
                  </IconButton>
                </Tooltip>
                </>
              :null}
                </>
            )}
            </Box>
          )}
        />
      </ThemeProvider>
    </Box>
    {aprobar?
    <Confirmar_aprobar
    aprobar={aprobar}
    datosRow={datosRow}
    onClose={() => handleVolver()}
    />
    :null}
    {desaprobar?
    <Confirmar_desaprobar
    desaprobar={desaprobar}
    datosRow={datosRow}
    onClose={() => handleVolverDesaprobar()}
    />
    :null}
    {revisar?
    <ConfirmarRevisar
    revisar={revisar}
    datosRow={datosRow}
    onClose={() => handleVolverRevisar()}
    />
    :null}
    {pendiente?
    <Confirmar_pendiente
    pendiente={pendiente}
    datosRow={datosRow}
    onClose={() => handleVolverPendiente()}
    />
    :null}
  </div>
  )
}

export default Panelrrhh