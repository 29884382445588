//----- Component --------------------------------------------------------/

// Developer : Daiana Curcio / Nicolas Pascucci

// Creation Date : 3/1/2024

// Version : 1

// Description : Historial de ajuste de stock con importación a CSV
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { Box, Typography, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material";
// Boton desplegable
import Button from "@mui/material/Button";
//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { blueGrey } from "@mui/material/colors";

function Historial_ajuste(props) {
  //CSV AJUSTE DE STOCK
  const [ajusteStockCSV, setAjusteStockCSV] = useState();

  const obtenerAjusteStockCSV = () => {
    fetch(UrlApi + "/stock_transacciones/ajusteStockCSV/" + props.sucursal)
      .then((data) => data.json())
      .then((data) => setAjusteStockCSV(data));
  };

  //funcion que espera los cambios de la funcion
  useEffect(() => {
    obtenerAjusteStockCSV();
  }, []);

  useEffect(() => {
    if (ajusteStockCSV && ajusteStockCSV.length > 0) {
      const ajusteOrdenados = ajusteStockCSV.sort(
        (a, b) => new Date(b.fecha_transaccion) - new Date(a.fecha_transaccion)
      );
      setAjusteStockCSV([...ajusteOrdenados]); // Actualiza el estado con el array ordenado
    }
  }, [ajusteStockCSV]);

  //************************************************************************************************************************************************ */
  const columns7 = useMemo(
    // TABLA AJUSTE DE STOCK
    () => [
      {
        header: "Fecha",
        accessorKey: "fecha_transaccion",
        size: 60,
        type: "date",
        format: "YYYY-MM-DD HH:mm",
      },
      { header: "Artículo", accessorKey: "nombre_articulo", size: 110 },
      {
        header: "Almacén de Origen",
        accessorKey: "almacen_nombre_origen",
        size: 100,
      },
      {
        header: "Nueva Cantidad",
        accessorKey: "cantidad_recibida",
        size: 20,
        enableHiding: true,
      },
    ],
    []
  );

  const columnsAjusteCSV = useMemo(
    // TABLA AJUSTE DE STOCK CSV
    () => [
      { header: "Artículo", accessorKey: "nombre_articulo", size: 110 },
      {
        header: "Almacén de Origen",
        accessorKey: "almacen_nombre_origen",
        size: 100,
      },
      {
        header: "Nueva Cantidad",
        accessorKey: "cantidad_recibida",
        size: 20,
        enableHiding: true,
      },
      {
        header: "Fecha",
        accessorKey: "fecha_transaccion",
        size: 60,
        type: "date",
        format: "YYYY-MM-DD HH:mm",
      },
      { header: "Usuario", accessorKey: "usuario", size: 75 },
    ],
    []
  );
  //************************************************************************************************************************************************ */

  //CSV ajuste
  const csvOptionsAjuste = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `Historial de Ajustes de Stock de Artículos de la Sucursal ${props.nombreSuc}`,
    headers: columnsAjusteCSV.map((c) => c.header),
  };
  const csvExporterAjust = new ExportToCsv(csvOptionsAjuste);

  const handleExportDataAjust = () => {
    csvExporterAjust.generateCsv(ajusteStockCSV);
  };

  return (
    <div>
      <Box mt={2} mb={4}>
        <ThemeProvider theme={props.tableTheme}>
          <MaterialReactTable
            enableExpandAll={false}
            enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
            enableGrouping // para permitir agrupar x estado x ej
            layoutMode="grid"
            muiTableHeadCellProps={props.tableHeadCellProps}
            muiTableBodyCellProps={({ row }) => ({
              align: "left",
              sx: {
                color: "black",
                borderColor: "black",
              },
            })}
            enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
            enableColumnFilters={false} //desactiva filtros x  columna
            enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
            enableDensityToggle={false} //
            localization={MRT_Localization_ES} //idioma
            enableColumnDragging={false} //para arrastrar columnas
            initialState={props.initialState}
            muiSearchTextFieldProps={props.muiSearchTextFieldProps}
            columns={columns7}
            data={props.ajusteStock} //con lo q la completas (esto cambia siempre)
            enableStickyFooter
            // enableEditing
            positionActionsColumn="last" // posicion de la columna de acciones al final
            // ACCIONES
            renderToolbarInternalActions={(
              { table } // boton para nuevo , al lado del buscar
            ) => (
              <>
                <Grid item xs={6} ml={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<FileDownloadIcon />}
                    onClick={handleExportDataAjust}
                    style={{
                      backgroundColor: blueGrey[700],
                      color: "white",
                    }}
                    size="small"
                  >
                    Exportar a CSV
                  </Button>
                </Grid>
                <MRT_FullScreenToggleButton table={table} />
              </>
            )}
            renderTopToolbarCustomActions={() => (
              <>
                <Typography variant="poster" fontSize={26}>
                  Ajuste de Stock
                </Typography>
              </>
            )}
          />
        </ThemeProvider>
      </Box>
    </div>
  );
}

export default Historial_ajuste;
