import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Button,
  Dialog,
  Typography,
  IconButton,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import { lightBlue, blueGrey } from "@mui/material/colors";
import ReactToPrint from "react-to-print";

function ImprimirPDF(props) {
  const [imprimirPdf, setImprimirPdf] = useState(props.imprimirPdf)
  const [datosArt, setDatosArt] = useState(props.datosArt)
  const [artSuc, setArtSuc] = useState(props.artSuc)
  const [tabla, setTabla] = useState(props.tabla)
  const [sucursal, setSucursal] = useState(props.sucursal)
  const componentRef = useRef(props.componentRef);

  var datePdf = new Date();
  datePdf.setDate(datePdf.getDate());
  var datePdfFormat = datePdf.toLocaleDateString().substring(0, 10);

  var datePdfHora = new Date();
  var datePdfHoraFormat = datePdfHora.getHours();
  var timePdfFormat = datePdfHora.toLocaleTimeString();

 // console.log("Fecha: " + datePdfFormat);
 // console.log("Hora: " + timePdfFormat);


  const handleClose = () => {
    setImprimirPdf(!imprimirPdf)
      if (props.onClose) {
          props.onClose();
        }
    }; 
  return (
    <>
    <Dialog fullScreen open={imprimirPdf} onClose={handleClose}>
        <AppBar
          sx={{ position: "relative" }}
          style={{ backgroundColor: "#0979b0" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Planilla de Inventario de {sucursal}
            </Typography>
            <ReactToPrint
              trigger={() => (
                <Button
                  autoFocus
                  style={{ backgroundColor: blueGrey[400], color: "white" }}
                  endIcon={<PrintIcon />}
                >
                  Imprimir
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Toolbar>
        </AppBar>
        {artSuc && tabla ? (
          <div
            ref={componentRef}
            style={{
              marginTop: "20px",
              margin: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              style={{
                marginBottom: "10px",
                marginLeft: "20px",
                textAlign: "left",
              }}
            >
              {datePdfFormat} -- {timePdfFormat}
            </Typography>
            <Typography
              variant="h5"
              style={{
                marginBottom: "20px",
                marginLeft: "20",
                textAlign: "center",
              }}
            >
              Inventario de: {sucursal}
            </Typography>
            <table
              id="articulosTable"
              style={{ width: "100%", borderCollapse: "collapse" }}
            >
              <thead>
                <tr style={{ backgroundColor: "#ddd" }}>
                  <th
                    style={{
                      padding: "20px",
                      border: "2px solid #ddd",
                      textAlign: "left",
                    }}
                  >
                    Almacén
                  </th>
                  <th
                    style={{
                      padding: "20px",
                      border: "2px solid #ddd",
                      textAlign: "left",
                    }}
                  >
                    Artículo
                  </th>
                  <th
                    style={{
                      padding: "20px",
                      border: "2px solid #ddd",
                      textAlign: "left",
                    }}
                  >
                    Cantidad
                  </th>
                  <th
                    style={{
                      padding: "20px",
                      border: "2px solid #ddd",
                      textAlign: "left",
                    }}
                  >
                    Cantidad Real
                  </th>
                </tr>
              </thead>
              <tbody>
                {artSuc.map((articulo) => (
                  <tr
                    key={articulo.id_inventario}
                    style={{ border: "1px solid #ddd" }}
                  >
                    <td
                      style={{
                        padding: "20px",
                        border: "2px solid #ddd",
                        textAlign: "left",
                      }}
                    >
                      {articulo.almacen}
                    </td>
                    <td
                      style={{
                        padding: "20px",
                        border: "2px solid #ddd",
                        textAlign: "left",
                      }}
                    >
                      {articulo.articulo}
                    </td>
                    <td
                      style={{
                        padding: "20px",
                        border: "2px solid #ddd",
                        textAlign: "left",
                      }}
                    >
                      {articulo.cantidad}
                    </td>
                    <td
                      style={{
                        padding: "20px",
                        border: "2px solid #ddd",
                        textAlign: "left",
                      }}
                    ></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div
              style={{
                marginTop: "80px",
                marginLeft: "auto",
                textAlign: "center",
                width: "300px",
              }}
            >
              <hr />
              <br />
              Firma
            </div>
          </div>
        ) : null}
      </Dialog>
    </>
  )
}

export default ImprimirPDF;