//----- Component --------------------------------------------------------/
// Developer     : Guillermo Sedran
// Creation Date : 13/11/2023
// Version       : 1
// Description   : Tabla principal para los hallazgos de auditoria, contiene a los demas componentes
//
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   : 22/03/2024
// Change by     : Guillermo Sedran
// Change description: Condición para obtener datos al ser responsable con sector NULL
//
// Change Date   :
// Change by     :
// Change description:
//
//========================================================================/

import Navbar from "../Navbar";
import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Stack,
  Grid,
  Tooltip,
  TextField,
  Autocomplete,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

// LOG EVENTOS
import { logEventos } from "../../services/services";

// ENVIAR MAILS
import { enviarmails } from "../../services/services";

// CHECKBOX
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// COMPONENTES
import Auditorias_acciones from "./Auditorias_acciones";
import Auditorias_agregar_hallazgo from "./Auditorias_agregar_hallazgo";

// ICONOS
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import CircularProgress from "@mui/material/CircularProgress";
import AltRouteIcon from "@mui/icons-material/AltRoute";

// Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

//ADJUNTAR ARCHIVOS
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR ARCHIVOS
import FileUploadHallazgo from "./FileUploadHallazgo";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Auditorias() {
  const [auditorias, setAuditorias] = useState({});
  const [datosAuditorias, setDatosAuditorias] = useState({});
  const [countTotal, setCountTotal] = useState(0);
  const [countRegistrado, setCountRegistrado] = useState(0);
  const [countEnProceso, setCountEnProceso] = useState(0);
  const [countFinalizado, setCountFinalizado] = useState(0);
  const [countAprobado, setCountAprobado] = useState(0);
  const [IconoDeCarga, setIconoDeCarga] = useState(false);
  const [deshabilitarBotonRechazar, setDeshabilitarBotonRechazar] =
    useState(false);
  const [deshabilitarBotonAceptar, setDeshabilitarBotonAceptar] =
    useState(false);
  const [uuid_accion, setUuid_accion] = useState(uuidv4()); //ADJUNTAR ARCHIVOS

  // ADJUNTOS
  const [uuid_hallazgo, setUuid_hallazgo] = useState(uuidv4()); //ADJUNTAR ARCHIVOS
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR COMPROBANTE
  const [newUuid, setNewUuid] = useState(""); //ADJUNTAR COMPROBANTE

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  console.log("DATOS NO SE QUE ", auditorias);
  const [tabla, setTabla] = useState(true);
  const [disBtn, setDisBtn] = useState(false);

  const [sectores, setSectores] = useState();

  // RECARGAR
  const [recargarAcciones, setRecargarAcciones] = useState(false);

  // AUTOCOMPLETE
  const [sectorSeleccionado, setSectorSeleccionado] = useState(null);

  // DIALOG
  const [openDialogDelegar, setOpenDialogDelegar] = React.useState(false);

  // Comprobar si se ha seleccionado un sector para habilitar o deshabilitar el botón "Delegar"
  console.log("UUID PARA LOS CORREOS", uuid_accion);

  //*************************************************************************************************************************************/

  // OBTENER FECHA ACTUAL
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  //*************************************************************************************************************************************/

  // CASQUETE
  const [checkSwitchVerAprobados, setCheckSwitchVerAprobados] = useState(false);

  const handleOnChangeSwitchVerAprobados = (e) => {
    setCheckSwitchVerAprobados(e.target.checked);
    // obtenerValorAcuniado();
  };

  console.log("checkSwitchVerAprobados", checkSwitchVerAprobados);

  //*************************************************************************************************************************************/

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");

  //*************************************************************************************************************************************/

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    // setUpdate(false);
    // setDisBtn(false);
    setOpenDialogConfirmar(false);
    setDeshabilitarBotonAceptar(false);
    setOpenDialogARDelegacion(false);
    setOpenDialogDelegar(false);
    obtenerAuditorias();

    setTimeout(() => {
      setRecargarAcciones(false);
    }, 500); // Retraso de 1 segundo
  };

  //*************************************************************************************************************************************/

  const columns = useMemo(
    () => [
      { header: "ID", accessorKey: "id_hallazgo", size: 30 },
      { header: "Auditor", accessorKey: "auditor", size: 100 },
      { header: "F. Registro", accessorKey: "fecha_registro", size: 70 },
      { header: "Sucursal", accessorKey: "sucursal", size: 100 },
      { header: "Problema", accessorKey: "problema_sugerencia", size: 210 },
      { header: "Responsable", accessorKey: "responsable", size: 100 },
      {
        header: "Sector Delegado",
        accessorKey: "nombre_sector_delegado",
        size: 120,
      },
      { header: "Vencimiento", accessorKey: "vencimiento", size: 85 },
      {
        header: "UUID",
        accessorKey: "uuid_hallazgo",
        size: 85,
        enableHiding: true,
      },
      { header: "Estado", accessorKey: "valor", size: 65 },
      {
        header: "Email Auditor",
        accessorKey: "email_auditor",
        size: 85,
        enableHiding: true,
      },
      {
        header: "Email Responsable",
        accessorKey: "email_responsable",
        size: 85,
        enableHiding: true,
      },
      {
        header: "ID Sector Delegado",
        accessorKey: "id_sector_delegado",
        size: 85,
        enableHiding: true,
      },
      {
        header: "Estado Delegación",
        accessorKey: "estado_delegacion",
        size: 85,
        enableHiding: true,
      },
    ],
    []
  );

  // ---------------------------------------------------------------

  const obtenerAuditorias = () => {
    const sector = localStorage.getItem("sector");
    const sucursalesAuth = localStorage.getItem("suc_auth");

    if (
      sector &&
      !isNaN(parseInt(sector)) &&
      (!sucursalesAuth || sucursalesAuth.trim() === "")
    ) {
      // Si el usuario TIENE un SECTOR pero NO tiene SUCURSALES autorizadas
      fetch(UrlApi + "/auditorias/xsector/" + sector)
        // fetch(UrlApi + "/auditorias/xsector/conaprobados/" + sector)
        .then((data) => data.json())
        .then((data) => {
          setAuditorias(data);
        });

      console.log("SECTOR PERO NO SUCURSALES");
    } else if (
      sucursalesAuth &&
      sucursalesAuth.trim() !== "" &&
      // (!sector || isNaN(parseInt(sector)))
      (localStorage.getItem("auditor") === "1" ||
        localStorage.getItem("controlador") === "1")
    ) {
      // Si el usuario TIENE SUCURSALES autorizadas Y ES AUDITOR O CONTROLADOR pero NO tiene SECTOR
      const urlxsucursales = checkSwitchVerAprobados
        ? "/auditorias/xsucursal/conaprobados/"
        : "/auditorias/xsucursal/sinaprobados/";

      fetch(UrlApi + urlxsucursales + sucursalesAuth.split(","))
        .then((data) => data.json())
        .then((data) => {
          setAuditorias(data);
          setCountTotal(data.length);
          obtenerAuditoriasRegistrado();
        });

      console.log("SUCURSALES PERO NO SECTOR");
    } else if (
      sucursalesAuth &&
      sucursalesAuth.trim() !== "" &&
      (!sector || isNaN(parseInt(sector)))
    ) {
      // Si el usuario TIENE SUCURSALES autorizadas pero NO tiene SECTOR (RESPONSABLE)
      const urlxsucursales = "/auditorias/xsucursal/conaprobados/";

      fetch(UrlApi + urlxsucursales + sucursalesAuth.split(","))
        .then((data) => data.json())
        .then((data) => {
          setAuditorias(data);
          setCountTotal(data.length);
          obtenerAuditoriasRegistrado();
        });

      console.log("SUCURSALES PERO NO SECTOR");
    } else if (
      sector &&
      !isNaN(parseInt(sector)) &&
      sucursalesAuth &&
      sucursalesAuth.trim() !== ""
    ) {
      // Si el usuario tiene ambos: sector y sucursales autorizadas
      fetch(UrlApi + "/auditorias/xsector/" + sector)
        .then((dataSector) => dataSector.json())
        .then((dataSector) => {
          // fetch(UrlApi + "/auditorias/xsucursal/" + sucursalesAuth.split(","))
          fetch(
            UrlApi +
              "/auditorias/xsucursal/conaprobados/" +
              sucursalesAuth.split(",")
          )
            .then((dataSucursal) => dataSucursal.json())
            .then((dataSucursal) => {
              // Hacer una unión o combinación de los datos de ambos fetch
              const mergedData = [...dataSector, ...dataSucursal];
              setAuditorias(mergedData);
              setCountTotal(mergedData.length);
              obtenerAuditoriasRegistrado();
            });
        });

      console.log("SUCURSALES Y SECTOR");
    } else {
      console.log("No se cumplió ninguna condición");
    }
  };

  // ***************************************
  // CONTADORES DE CANTIDAD
  // SOLO SE MUESTRAN CUANDO ES AUDITOR
  // ***************************************
  const obtenerAuditoriasRegistrado = () => {
    fetch(
      UrlApi +
        "/auditorias/registrado/xsucursal/" +
        localStorage.getItem("suc_auth").split(",")
    )
      .then((data) => data.json())
      .then((data) => {
        setCountRegistrado(data.length); // Actualizar el contador con la longitud de los datos
      });
  };

  const obtenerAuditoriasEnProceso = () => {
    fetch(
      UrlApi +
        "/auditorias/enproceso/xsucursal/" +
        localStorage.getItem("suc_auth").split(",")
    )
      .then((data) => data.json())
      .then((data) => {
        setCountEnProceso(data.length); // Actualizar el contador con la longitud de los datos
      });
  };

  const obtenerAuditoriasFinalizado = () => {
    fetch(
      UrlApi +
        "/auditorias/finalizado/xsucursal/" +
        localStorage.getItem("suc_auth").split(",")
    )
      .then((data) => data.json())
      .then((data) => {
        setCountFinalizado(data.length); // Actualizar el contador con la longitud de los datos
      });
  };

  const obtenerAuditoriasAprobado = () => {
    fetch(
      UrlApi +
        "/auditorias/aprobado/xsucursal/" +
        localStorage.getItem("suc_auth").split(",")
    )
      .then((data) => data.json())
      .then((data) => {
        setCountAprobado(data.length); // Actualizar el contador con la longitud de los datos
      });
  };

  // ********************************************************

  const obtenerSectores = () => {
    fetch(UrlApi + "/sectores/sinrepetirmail/activos")
      .then((data) => data.json())
      .then((data) => {
        const localStorageSector = localStorage.getItem("sector");
        if (localStorageSector) {
          // Filtrar el sector que coincide con el ID de sector del localStorage "sector"
          const sectoresFiltrados = data.filter(
            (sector) => sector.id_sector !== parseInt(localStorageSector)
          );
          setSectores(sectoresFiltrados);
        } else {
          setSectores(data);
        }
      });
  };

  // ---------------------------------------------------------------

  useEffect(() => {
    obtenerAuditorias();
  }, [tabla]);

  useEffect(() => {
    obtenerAuditorias();
  }, [checkSwitchVerAprobados]);

  // CONTADORES
  useEffect(() => {
    obtenerAuditoriasRegistrado();
  }, [tabla]);

  useEffect(() => {
    obtenerAuditoriasEnProceso();
  }, [tabla]);

  useEffect(() => {
    obtenerAuditoriasFinalizado();
  }, [tabla]);

  useEffect(() => {
    obtenerAuditoriasAprobado();
  }, [tabla]);

  // ---------------------------------------------------------------

  // SECTORES PARA DELEGAR
  useEffect(() => {
    obtenerSectores();
  }, []);

  //*************************************************************************************************************************************/
  //  DIALOG PARA CARGA DE HALLAZGO
  const [openDialogAgregarHallazgo, setOpenDialogAgregarHallazgo] =
    React.useState(false);

  const handleCloseDialogCrearHallazgo = () => {
    setOpenDialogAgregarHallazgo(!openDialogAgregarHallazgo);
  };

  const handleOpenDialogCrearHallazgo = () => {
    setOpenDialogAgregarHallazgo(true);
    // GENERAR UUID PARA ARCHIVOS ADJUNTOS
    setUuid_hallazgo(uuidv4());
    setNewUuid(uuid_hallazgo);
  };

  //  DIALOG PARA ACEPTAR/RECHAZAR LA DELEGACION DE HALLAZGO
  const [openDialogARDelegacion, setOpenDialogARDelegacion] =
    React.useState(false);

  const handleOpenDialogARDelegacion = (row) => {
    setDatosAuditorias(row);
    setOpenDialogARDelegacion(true);
  };

  const handleCloseDialogARDelegacion = () => {
    setOpenDialogARDelegacion(!openDialogARDelegacion);
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const handleCloseCrearHallazgo = () => {
    setOpenDialogAgregarHallazgo(false);
    // RECARGAR TABLA
    obtenerAuditorias();
  };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //  DIALOG PARA CARGA DE HALLAZGO
  const [openDialogConfirmar, setOpenDialogConfirmar] = React.useState(false);

  const handleOpenDialogConfirmar = (row) => {
    setDatosAuditorias(row);
    setOpenDialogConfirmar(true);
  };

  const handleCloseDialogConfirmar = () => {
    setOpenDialogConfirmar(!openDialogConfirmar);
  };

  //*************************************************************************************************************************************/

  //  DIALOG PARA CARGA DE HALLAZGO
  const handleOpenDialogDelegar = (row) => {
    setDatosAuditorias(row);
    setOpenDialogDelegar(true);
  };

  const handleCloseDialogDelegar = () => {
    setOpenDialogDelegar(!openDialogDelegar);
  };

  //*************************************************************************************************************************************/

  const guardarConfirmar = async (e) => {
    logEventos("Aprobar hallazgo", "Auditorias", "Se aprobó el hallazgo", localStorage.getItem("user"))

    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/auditorias/" + datosAuditorias.id_hallazgo, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        estado: 181,
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El hallazgo se guardó exitosamente !");
          console.log("Guardado");
          // ENVIAR MAIL
          datosAuditorias &&
            enviarmails(
              datosAuditorias.email_responsable,
              "Auditoria: Hallazgo Aprobado",
              `
              <div style="background-color: #f2f2f2; border-radius: 10px; padding: 20px; text-align: center;">

              <p>El hallazgo "${datosAuditorias.problema_sugerencia}" (ID: ${datosAuditorias.id_hallazgo}) fue comprobado y aprobado por el auditor.</p>
              </div>`
            );

          // RECARGAR CONTADORES DE ESTADO DE HALLAZGO
          // Verificación del rol de "Auditor" en el localStorage
          if (localStorage.getItem("auditor") === "1") {
            obtenerAuditoriasRegistrado();
            obtenerAuditoriasEnProceso();
            obtenerAuditoriasFinalizado();
            obtenerAuditoriasAprobado();
          }
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el hallazgo");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //*************************************************************************************************************************************/

  const enviarCorreoAceptarDelegacion = async () => {
    try {
      // Envío de correo electrónico
      await new Promise((resolve, reject) => {
        // AL AUDITOR
        datosAuditorias &&
          enviarmails(
            datosAuditorias.email_auditor,
            "Auditoria: Delegación de Hallazgo Aceptada",
            `
            <div style="background-color: #f2f2f2; border-radius: 10px; padding: 20px; text-align: center;">
              <h3>La delegación del hallazgo "${datosAuditorias.problema_sugerencia}" (ID: ${datosAuditorias.id_hallazgo}) fue aceptada por el sector "${datosAuditorias.nombre_sector_delegado}".</h3>
            </div>
            `
          );

        // AL RESPONSABLE
        datosAuditorias &&
          enviarmails(
            datosAuditorias.email_responsable,
            "Auditoria: Delegación de Hallazgo Aceptada",
            `
            <div style="background-color: #f2f2f2; border-radius: 10px; padding: 20px; text-align: center;">
              <h3>La delegación del hallazgo "${datosAuditorias.problema_sugerencia}" (ID: ${datosAuditorias.id_hallazgo}) fue aceptada por el sector "${datosAuditorias.nombre_sector_delegado}".</h3>
            </div>
            `
          )
            .then(async () => {
              // Obtener la fecha actual
              const currentDate = new Date().toISOString().substring(0, 10);

              console.log("FECHA ACTUAL: ", currentDate);

              // Si el envío de correo es exitoso, guardar la acción relacionada con la aceptación de la delegación
              await fetch(UrlApi + "/auditorias_acciones", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  id_hallazgo: datosAuditorias.id_hallazgo,
                  accion_realizada: `El sector "${datosAuditorias.nombre_sector_delegado}" aceptó la delegación del hallazgo`,
                  fecha: currentDate, // Utilizar la fecha actual
                  uuid_accion: null,
                  estado: 183,
                  usuario_que_registra: localStorage.getItem("user"),
                }),
              });

              // PUT para actualizar el hallazgo
              await fetch(
                UrlApi + "/auditorias/" + datosAuditorias.id_hallazgo,
                {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    estado_delegacion: 1,
                  }),
                }
              );

              resolve();
            })
            .catch((error) => {
              reject(error);
            });
      });
    } catch (error) {
      throw error;
    }
  };

  const enviarCorreoRechazarDelegacion = async () => {
    try {
      // Envío de correo electrónico
      await new Promise((resolve, reject) => {
        // AL AUDITOR
        datosAuditorias &&
          enviarmails(
            datosAuditorias.email_auditor,
            "Auditoria: Delegación de Hallazgo Rechazada",
            `
            <div style="background-color: #f2f2f2; border-radius: 10px; padding: 20px; text-align: center;">
              <h3>La delegación del hallazgo "${datosAuditorias.problema_sugerencia}" (ID: ${datosAuditorias.id_hallazgo}) fue rechazada por el sector "${datosAuditorias.nombre_sector_delegado}".</h3>
            </div>
            `
          );

        // AL RESPONSABLE
        datosAuditorias &&
          enviarmails(
            datosAuditorias.email_responsable,
            "Auditoria: Delegación de Hallazgo Rechazada",
            `
            <div style="background-color: #f2f2f2; border-radius: 10px; padding: 20px; text-align: center;">
              <h3>La delegación del hallazgo "${datosAuditorias.problema_sugerencia}" (ID: ${datosAuditorias.id_hallazgo}) fue rechazada por el sector "${datosAuditorias.nombre_sector_delegado}".</h3>
            </div>
            `
          )
            .then(async () => {
              // Obtener la fecha actual
              const currentDate = new Date().toISOString().substring(0, 10);

              console.log("FECHA ACTUAL: ", currentDate);

              // Si el envío de correo es exitoso, guardar la acción relacionada con el rechazo de la delegación
              await fetch(UrlApi + "/auditorias_acciones", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  id_hallazgo: datosAuditorias.id_hallazgo,
                  accion_realizada: `El sector "${datosAuditorias.nombre_sector_delegado}" rechazó la delegación del hallazgo`,
                  fecha: currentDate, // Utilizar la fecha actual
                  estado: 183,
                  usuario_que_registra: localStorage.getItem("user"),
                }),
              });

              // PUT para actualizar el hallazgo
              await fetch(
                UrlApi + "/auditorias/" + datosAuditorias.id_hallazgo,
                {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    id_sector_delegado: null,
                    estado_delegacion: null,
                  }),
                }
              );

              resolve();
            })
            .catch((error) => {
              reject(error);
            });
      });
    } catch (error) {
      throw error;
    }
  };

  const aceptarDelegacion = async (e) => {
    logEventos("Aceptar delegacion", "Auditorias", "Se aceptó delegar el hallazgo de auditoria", localStorage.getItem("user"))

    setIconoDeCarga(true);
    setDeshabilitarBotonRechazar(true);
    console.log("Estoy por guardar");
    e.preventDefault();
    try {
      // Envío de correo electrónico
      await enviarCorreoAceptarDelegacion();

      // Recargar tabla de acciones
      setRecargarAcciones(true);

      // Si el envío de correo es exitoso
      setIconoDeCarga(false);
      setOpenSnack(true);
      setColorMensaje("#D4EFDF");
      setMensaje("La delegación se aceptó exitosamente");
      setDeshabilitarBotonRechazar(false);
    } catch (error) {
      // Si hay un error en el envío de correo
      setIconoDeCarga(false);
      setOpenSnack(true);
      setColorMensaje("#F1948A");
      setMensaje("Error al enviar el correo");
      console.error("Error al enviar el correo:", error);
      setDeshabilitarBotonRechazar(false);
    }
  };

  const rechazarDelegacion = async (e) => {
    logEventos("Rechazar delegacion", "Auditorias", "Se rechazó delegar el hallazgo de auditoria", localStorage.getItem("user"))

    setIconoDeCarga(true);
    setDeshabilitarBotonAceptar(true);
    console.log("Estoy por guardar");
    e.preventDefault();
    try {
      // Envío de correo electrónico
      await enviarCorreoRechazarDelegacion();
      // Si el envío de correo es exitoso
      setIconoDeCarga(false);
      setOpenSnack(true);
      setColorMensaje("#D4EFDF");
      setMensaje("La delegación se rechazó exitosamente");
      setDeshabilitarBotonAceptar(false);
    } catch (error) {
      // Si hay un error en el envío de correo
      setIconoDeCarga(false);
      setOpenSnack(true);
      setColorMensaje("#F1948A");
      setMensaje("Error al enviar el correo");
      console.error("Error al enviar el correo:", error);
      setDeshabilitarBotonAceptar(false);
    }
  };

  //*************************************************************************************************************************************/

  const guardarDelegar = async (e) => {
    logEventos("Delegar hallazgo", "Auditorias", "Se delegó el hallazgo a otro sector", localStorage.getItem("user"))

    console.log("Estoy por guardar");
    e.preventDefault();
    setIconoDeCarga(true);

    // Obtener la fecha actual
    const currentDate = new Date().toISOString().substring(0, 10);
    try {
      await fetch(UrlApi + "/auditorias/" + datosAuditorias.id_hallazgo, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_sector_delegado: sectorSeleccionado.id_sector,
          estado_delegacion: null,
        }),
      });

      const emails = sectorSeleccionado.emails.split(",");

      // Envía correos electrónicos a cada dirección asociada al sector seleccionado
      for (const email of emails) {
        await enviarmails(
          email.trim(),
          "Auditoria: Delegación de Hallazgo",
          `
        <div style="background-color: #f2f2f2; border-radius: 10px; padding: 20px; text-align: center;">
        <h3>Se solicitó delegar el hallazgo "${datosAuditorias.problema_sugerencia}" (ID: ${datosAuditorias.id_hallazgo}) al sector "${sectorSeleccionado.nombre_sector}".</h3>
        <p><strong>Auditor:</strong> "${datosAuditorias.auditor}" </p>
        <p><strong>Responsable:</strong> "${datosAuditorias.responsable}" </p>
        </div>
        <br/>
        `
        );
      }

      // GUARDAR ACCION
      // Mensaje para la acción realizada
      const mensajeAccion = `Se delegó el hallazgo al sector "${sectorSeleccionado.nombre_sector}"`;

      // Petición POST para crear una nueva acción
      await fetch(UrlApi + "/auditorias_acciones", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_hallazgo: datosAuditorias.id_hallazgo,
          accion_realizada: mensajeAccion,
          fecha: currentDate,
          estado: 183,
          usuario_que_registra: localStorage.getItem("user"),
        }),
      });

      // Recargar tabla de acciones
      setRecargarAcciones(true);

      // Mostrar mensaje de éxito
      setOpenSnack(true);
      setColorMensaje("#D4EFDF");
      setMensaje("La solicitud de delegación fue enviada !");
      console.log("Solicitud enviada");
      setIconoDeCarga(false);
    } catch (error) {
      setOpenSnack(false);
      setColorMensaje("#F1948A");
      setMensaje("Error al enviar la delegación");
      console.error("Error", error);
      setIconoDeCarga(false);
    }
  };

  //*************************************************************************************************************************************/

  const muiSearchTextFieldProps = {
    // para el buscar
    placeholder: "Buscar...",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const tableHeadCellProps = {
    align: "left", // Titulos de la tabla
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  //*************************************************************************************************************************************/
  // ESTILO PARA LA TABLA
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",
      },
      size: 95,
    },
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    grouping: ["sucursal"],
    sorting: [
      {
        id: "sucursal",
        desc: true,
      },
      {
        id: "vencimiento",
        desc: true,
      },
    ],
    pagination: { pageIndex: 0, pageSize: 10 },
    columnVisibility: {
      uuid_hallazgo: false,
      email_auditor: false,
      email_responsable: false,
      id_sector_delegado: false,
      estado_delegacion: false,
    },
  };

  //*************************************************************************************************************************************/

  useEffect(() => {
    logEventos("Carga de auditorias", "Auditorias", "Lista auditorias", localStorage.getItem("user"))
}, [])

  //*************************************************************************************************************************************/


  console.log("AUDITORIAS: ", auditorias);
  console.log("Sectores: ", sectores);
  console.log("Sector Seleccionado: ", sectorSeleccionado);
  console.log("datosAuditorias", datosAuditorias);
  console.log("Icono de Carga: ", IconoDeCarga);

  console.log("SECTOR LOCAL STORAGE: ", localStorage.getItem("sector"));
  console.log("ES AUDITOR?", localStorage.getItem("auditor"));

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        <Box mt={2} mb={4}>
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
              enableGrouping
              displayColumnDefOptions={displayColumnDefOptions}
              layoutMode="grid"
              muiTableHeadCellProps={tableHeadCellProps}
              muiTableBodyCellProps={({ row }) => ({
                align: "left", //el contenido de la tabla
                sx: {
                  backgroundColor:
                    row.original.estado === 179
                      ? "#FFFFB6"
                      : row.original.estado === 180 // FINALIZADO
                      ? "#BEFFB6"
                      : row.original.estado === 181 // APROBADO
                      ? "#B6EAFF"
                      : "#F0F0F0",
                },
              })}
              enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
              enableColumnFilters={false} //desactiva filtros x columna
              enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
              enableDensityToggle={false}
              enableColumnDragging={false} //para arrastrar columnas
              enableEditing={false}
              localization={MRT_Localization_ES} //idioma
              initialState={initialState}
              muiSearchTextFieldProps={muiSearchTextFieldProps}
              columns={columns}
              data={auditorias} //con lo q la completas (esto cambia siempre)
              enableStickyFooter
              positionActionsColumn="last" // posicion de la columna de acciones al final
              enablePagination={false}
              enableRowActions
              renderRowActions={({ row }) => (
                <>
                  {localStorage.getItem("auditor") === "1" ? (
                    // el usuario es AUDITOR y NO FORMA PARTE de ningún SECTOR:
                    // muestro botón de aprobación del hallazgo
                    <>
                      <Tooltip arrow placement="bottom" title="Ver adjunto">
                        <Button
                          style={{ padding: "6px", minWidth: "auto" }}
                          backgroundColor="#ffffff"
                          size="small"
                          onClick={() => {
                            setNewUuid(row.original.uuid_hallazgo);
                            setOpenCloseAdj(!openCloseAdj);
                          }}
                        >
                          <AttachFileIcon />
                        </Button>
                      </Tooltip>

                      {row.original.estado !== 181 && (
                        // SI ES AUDITOR Y TODAVIA NO SE APROBÓ EL HALLAZGO
                        <>
                          <Tooltip arrow placement="bottom" title="Delegar">
                            <Button
                              style={{ padding: "6px", minWidth: "auto" }}
                              backgroundColor="#ffffff"
                              size="small"
                              onClick={() =>
                                handleOpenDialogDelegar(row.original)
                              }
                            >
                              <CallMissedOutgoingIcon
                                style={{ color: "#b50ce8" }}
                              />
                            </Button>
                          </Tooltip>
                        </>
                      )}

                      {row.original.estado === 180 && (
                        // HALLAZGO - ESTADO: "FINALIZADO"
                        <>
                          {/* CONFIRMAR/APROBAR PENDIENTE */}
                          <Tooltip
                            arrow
                            placement="bottom"
                            title="Confirmar Hallazgo"
                          >
                            <Button
                              style={{ padding: "6px", minWidth: "auto" }}
                              size="small"
                              onClick={() =>
                                handleOpenDialogConfirmar(row.original)
                              }
                            >
                              <CheckCircleIcon color="success" />
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </>
                  ) : localStorage.getItem("auditor") !== "1" &&
                    localStorage.getItem("sector") === "null" ? (
                    // ES RESPONSABLE (NO AUDITOR NI SECTOR)
                    <>
                      <Tooltip arrow placement="bottom" title="Ver adjunto">
                        <Button
                          style={{ padding: "6px", minWidth: "auto" }}
                          backgroundColor="#ffffff"
                          size="small"
                          onClick={() => {
                            setNewUuid(row.original.uuid_hallazgo);
                            setOpenCloseAdj(!openCloseAdj);
                          }}
                        >
                          <AttachFileIcon />
                        </Button>
                      </Tooltip>

                      {/* Botón Delegar (Sectores) */}
                      {row.original.estado !== 181 && (
                        // TODAVIA NO SE APROBÓ EL HALLAZGO
                        <>
                          {/* BOTON PARA DELEGAR (ELEGIR SECTOR) */}
                          {row.original.id_sector_delegado === null && (
                            <>
                              {/* TODAVIA NO SE ACEPTÓ LA DELEGACIÓN (SOLO VA A APARECER DONDE NO HAYA SECTOR ASIGNADO)*/}
                              <Tooltip arrow placement="bottom" title="Delegar">
                                <Button
                                  style={{ padding: "6px", minWidth: "auto" }}
                                  backgroundColor="#ffffff"
                                  size="small"
                                  onClick={() =>
                                    handleOpenDialogDelegar(row.original)
                                  }
                                >
                                  <CallMissedOutgoingIcon
                                    style={{ color: "#b50ce8" }}
                                  />
                                </Button>
                              </Tooltip>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : localStorage.getItem("auditor") !== "1" &&
                    localStorage.getItem("sector") !== "null" ? (
                    // VISTA DE USUARIO SECTOR
                    <>
                      <Tooltip arrow placement="bottom" title="Ver adjunto">
                        <Button
                          style={{ padding: "6px", minWidth: "auto" }}
                          backgroundColor="#ffffff"
                          size="small"
                          onClick={() => {
                            setNewUuid(row.original.uuid_hallazgo);
                            setOpenCloseAdj(!openCloseAdj);
                          }}
                        >
                          <AttachFileIcon />
                        </Button>
                      </Tooltip>

                      {/* Botón Delegación (Aceptar/Rechazar) */}
                      {/* {parseInt(localStorage.getItem("sector")) ===
                      parseInt(row.original.id_sector_delegado) &&
                      row.original.estado_delegacion !== 1 ? (
                        // Mostrar el botón "Delegación"
                        <Tooltip arrow placement="bottom" title="Delegación">
                          <Button
                            style={{
                              color: "#b50ce8",
                              padding: "6px",
                              minWidth: "auto",
                            }}
                            backgroundColor="#ffffff"
                            size="small"
                            onClick={() =>
                              handleOpenDialogARDelegacion(row.original)
                            }
                          >
                            <AltRouteIcon />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip arrow placement="bottom" title="Delegar">
                          <Button
                            style={{ padding: "6px", minWidth: "auto" }}
                            backgroundColor="#ffffff"
                            size="small"
                            onClick={() =>
                              handleOpenDialogDelegar(row.original)
                            }
                          >
                            <CallMissedOutgoingIcon
                              style={{ color: "#b50ce8" }}
                            />
                          </Button>
                        </Tooltip>
                      )} */}

                      {parseInt(localStorage.getItem("sector")) ===
                        parseInt(row.original.id_sector_delegado) &&
                      row.original.estado_delegacion !== 1 &&
                      row.original.estado !== 181 ? (
                        // Mostrar el botón "Delegación"
                        <Tooltip arrow placement="bottom" title="Delegación">
                          <Button
                            style={{
                              color: "#b50ce8",
                              padding: "6px",
                              minWidth: "auto",
                            }}
                            backgroundColor="#ffffff"
                            size="small"
                            onClick={() =>
                              handleOpenDialogARDelegacion(row.original)
                            }
                          >
                            <AltRouteIcon />
                          </Button>
                        </Tooltip>
                      ) : row.original.estado !== 181 ? (
                        // Mostrar el botón "Delegar"
                        <Tooltip arrow placement="bottom" title="Delegar">
                          <Button
                            style={{ padding: "6px", minWidth: "auto" }}
                            backgroundColor="#ffffff"
                            size="small"
                            onClick={() =>
                              handleOpenDialogDelegar(row.original)
                            }
                          >
                            <CallMissedOutgoingIcon
                              style={{ color: "#b50ce8" }}
                            />
                          </Button>
                        </Tooltip>
                      ) : null}
                    </>
                  ) : (
                    (localStorage.getItem("auditor") !== "1" ||
                      localStorage.getItem("controlador") === "1") && (
                      // NO ES AUDITOR
                      <>
                        <Tooltip arrow placement="bottom" title="Ver adjunto">
                          <Button
                            style={{ padding: "6px", minWidth: "auto" }}
                            backgroundColor="#ffffff"
                            size="small"
                            onClick={() => {
                              setNewUuid(row.original.uuid_hallazgo);
                              setOpenCloseAdj(!openCloseAdj);
                            }}
                          >
                            <AttachFileIcon />
                          </Button>
                        </Tooltip>
                      </>
                    )
                  )}
                </>
              )}
              renderTopToolbarCustomActions={() => (
                <>
                  {localStorage.getItem("auditor") === "1" ? (
                    <Grid container alignItems="center">
                      <Typography variant="poster" fontSize={26}>
                        Reporte Adm. de Incidentes
                      </Typography>

                      <Grid container mt={1}>
                        <Grid item xs={12}>
                          <Typography variant="poster" fontSize={15}>
                            <b>Total:</b> {countTotal}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="poster" fontSize={15}>
                            <b>Registrado:</b> {countRegistrado}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="poster" fontSize={15}>
                            <b>En Proceso:</b> {countEnProceso}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="poster" fontSize={15}>
                            <b>Finalizado:</b> {countFinalizado}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="poster" fontSize={15}>
                            <b>Aprobado:</b> {countAprobado}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container alignItems="center">
                      <Typography variant="poster" fontSize={26}>
                        Reporte Adm. de Incidentes
                      </Typography>
                    </Grid>
                  )}

                  {localStorage.getItem("auditor") === "1" ||
                  localStorage.getItem("controlador") === "1" ? (
                    <Grid container alignItems="center" mt={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBoxTwoToneIcon />}
                        onClick={handleOpenDialogCrearHallazgo}
                        size="small"
                      >
                        Agregar Hallazgo
                      </Button>

                      <Stack direction="row" spacing={1} alignItems="center">
                        <FormControlLabel
                          // inputRef={checkboxRef}
                          checked={checkSwitchVerAprobados}
                          onChange={handleOnChangeSwitchVerAprobados}
                          control={<Checkbox />}
                          style={{ width: "23px", height: "23px" }}
                        />
                        <Typography style={{ marginLeft: "15px" }}>
                          Ver Aprobados{" "}
                        </Typography>
                      </Stack>
                    </Grid>
                  ) : null}
                </>
              )}
              // ***************************************************************
              // AGREGAR SELECT PARA CAMBIAR SEGUN LOS ESTADOS DE LOS HALLAZGOS
              // ***************************************************************

              renderDetailPanel={({ row }) => (
                <>
                  <Container
                    maxWidth="xl"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "10px",
                      backgroundColor: "rgb(230, 230, 230)",
                      marginTop: "-10px",
                      marginLeft: "130px",
                      marginBottom: "-10px",
                      border: "1px solid black", // Borde negro
                    }}
                  >
                    <Container maxWidth="x1">
                      <Box
                        sx={{
                          width: "100%", // Utiliza el ancho máximo disponible
                          maxWidth: "1200px", // Establece un ancho máximo específico si lo deseas
                          // backgroundColor: "rgb(25,118,210)",
                          backgroundColor: "#2471A3",
                          color: "white",
                          marginTop: "12px",
                          borderRadius: "10px",
                          overflow: "auto",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12} ml={2} mt={0.5} mb={0.5} mr={2}>
                            <Typography variant="poster" fontSize={16}>
                              {row.original.descripcion_acciones}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          width: "122%",
                        }}
                      >
                        {recargarAcciones ? (
                          <Grid item marginLeft={18} marginTop={1}>
                            <CircularProgress />
                          </Grid>
                        ) : (
                          <>
                            <Auditorias_acciones
                              row={row}
                              auditorias={auditorias}
                              countTotal={countTotal}
                              obtenerAuditorias={obtenerAuditorias}
                              closeSnackPrincipal={handleCloseSnack}
                            />
                          </>
                        )}
                      </Box>
                    </Container>
                  </Container>
                </>
              )}
            />
          </ThemeProvider>
        </Box>

        {openCloseAdj ? ( // ADJUNTAR
          <FileUploadHallazgo
            fileId={newUuid}
            openclose={true}
            mostrar={true}
            onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
          />
        ) : null}

        {/* AGREGAR HALLAZGO */}
        <Dialog
          open={openDialogAgregarHallazgo}
          onClose={handleCloseDialogCrearHallazgo}
          maxWidth="lg"
          fullWidth
        >
          <Box
            style={{
              borderWidth: 2,
              borderStyle: "solid",
            }}
          >
            <Auditorias_agregar_hallazgo
              openDialogAgregarHallazgo={openDialogAgregarHallazgo}
              disBtn={disBtn}
              // ADJUNTOS
              newUuid={newUuid}
              // CERRAR
              handleCloseCrearHallazgo={handleCloseCrearHallazgo}
            />
          </Box>
        </Dialog>

        {/* DIALOG PARA CONFIRMAR LA FINALIZACIÓN DE LA ACCIÓN */}
        <Dialog open={openDialogConfirmar} onClose={handleCloseDialogConfirmar}>
          <Box
            style={{
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: "#24ff1c",
              color: "green",
            }}
          >
            <DialogContent>
              <DialogTitle textAlign={"center"}>
                {"¿Confirma que quiere finalizar este hallazgo?"}
              </DialogTitle>
            </DialogContent>
            <DialogActions>
              <Stack spacing={4} direction="row">
                <Grid item mr={16}>
                  <Button
                    style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                    variant="contained"
                    onClick={handleCloseDialogConfirmar}
                  >
                    Volver
                  </Button>
                </Grid>

                <Button
                  style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                  onClick={guardarConfirmar}
                  variant="contained"
                >
                  Confirmar
                </Button>
              </Stack>
            </DialogActions>
          </Box>
        </Dialog>

        {/* DELEGAR A SUCURSAL */}
        <Dialog
          open={openDialogDelegar}
          onClose={handleCloseDialogDelegar}
          maxWidth="sm"
          fullWidth
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={2}
          >
            <DialogTitle alignSelf="center" mr={3}>
              <b>{"Delegar Hallazgo"}</b>
            </DialogTitle>
          </Box>

          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarDelegar}>
                <Grid container mb={1} spacing={1}>
                  <Grid item xs={12} mb={2}>
                    <Autocomplete
                      size="small"
                      required
                      disableClearable
                      noOptionsText={"Sectores No Disponibles"}
                      options={sectores}
                      autoHighlight
                      getOptionLabel={(sectores) =>
                        sectores.id_sector + "-" + sectores.nombre_sector
                      }
                      onChange={(event, value) => setSectorSeleccionado(value)}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.id_sector} - {option.nombre_sector}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          placeholder="Tipee para buscar..."
                          {...params}
                          label="Sector"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleCloseDialogDelegar}
                      >
                        Volver
                      </Button>
                    </Grid>
                    {IconoDeCarga ? (
                      <Grid item marginLeft={4} marginTop={-1}>
                        <CircularProgress />
                      </Grid>
                    ) : (
                      <Grid item xs={3}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={!sectorSeleccionado} // Deshabilitar el botón si no se ha seleccionado un sector
                        >
                          Delegar
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Dialog>

        {/* DIALOG PARA CONFIRMAR/RECHAZAR LA DELEGACION */}
        <Dialog
          open={openDialogARDelegacion}
          onClose={handleCloseDialogARDelegacion}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle alignSelf="center" mr={3}>
            <b>{"Delegación de Hallazgo"}</b>
          </DialogTitle>

          <DialogContent>
            <div
              style={{
                textAlign: "center",
                fontFamily: "Arial, sans-serif",
                fontSize: "18px",
                lineHeight: "1.6",
              }}
            >
              <Container maxWidth="x1">
                El sector{" "}
                <span style={{ fontWeight: "bold" }}>
                  {datosAuditorias.nombre_sector_delegado}
                </span>{" "}
                tiene asignado el hallazgo{" "}
                <span style={{ fontWeight: "bold" }}>
                  {datosAuditorias.problema_sugerencia}
                </span>
              </Container>
            </div>
          </DialogContent>

          <DialogActions>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                  variant="contained"
                  onClick={handleCloseDialogARDelegacion}
                >
                  Volver
                </Button>
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  {IconoDeCarga ? (
                    <>
                      <Grid
                        item
                        marginRight={10}
                        marginLeft={10}
                        marginTop={-1}
                      >
                        <CircularProgress />
                      </Grid>

                      <Grid
                        item
                        marginRight={10}
                        marginLeft={10}
                        marginTop={-1}
                      >
                        <CircularProgress />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item>
                        <Button
                          style={{
                            backgroundColor: "#d32f2f",
                            color: "#ffffff",
                          }}
                          variant="contained"
                          onClick={rechazarDelegacion}
                          disabled={deshabilitarBotonRechazar}
                        >
                          Rechazar Delegación
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          style={{
                            backgroundColor: "#2e7d32",
                            color: "#ffffff",
                          }}
                          variant="contained"
                          onClick={aceptarDelegacion}
                          disabled={deshabilitarBotonAceptar}
                        >
                          Confirmar Delegación
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnack}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="success"
              sx={{ width: "100%" }}
              style={{ backgroundColor: colormensaje, color: "black" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
      </Container>
    </div>
  );
}

export default Auditorias;
