//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 05/08/2024
// Version : 1
// Description : Pantalla principal de estadisticas de reclamos
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Navbar from "../../../Navbar";
import Tabla_motivos from "./Tabla_motivos";
import Grafico_motivos from "./Grafico_motivos";
import Tabla_estados from "./Tabla_estados";
import Grafico_estados from "./Grafico_estados";
import Tabla_instaladores from "./Tabla_instaladores";

function Estadisticas_reclamos() {
  return (
    <div>
      <Navbar />
      <Box
        bgcolor="#2B76A6"
        padding={1}
        borderRadius={0}
        color="white"
        textAlign="center"
        display="flex" // Agregado para que los elementos se coloquen en línea
        alignItems="center"
        marginTop={1}
      >
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Typography variant="h4" component="div">
            Panel de estadísticas de reclamos
          </Typography>
        </div>
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "row", // Cambia a row para que los elementos estén uno al lado del otro
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          gap: "30px", // espacio entre los elementos
        }}
      >
        <div>
          <Tabla_motivos />
        </div>
        <div>
          <Grafico_motivos />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <Tabla_estados />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Grafico_estados />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Tabla_instaladores />
        </div>
      </div>
    </div>
  );
}

export default Estadisticas_reclamos;
