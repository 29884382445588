//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci / Daiana Curcio

// Creation Date :12/12/2023

// Version : 1

// Description : Vista de stock de artículos en todas las sucursales

//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import AppBar from "@mui/material/AppBar";
import Navbar from "../Navbar";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Stack,
  Grid,
  Tooltip,
  IconButton,
  TextField,
  FormControlLabel,
  Toolbar,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { logEventos } from "../../services/services";

//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { blueGrey } from "@mui/material/colors";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Stock_por_sucursales() {
  const [update, setUpdate] = useState(false);
  const [tabla, setTabla] = useState(true);

  useEffect(() => {
    logEventos("Acceso al menú stock general", "Stock General", "Ver los artículos que hay por sucursal", localStorage.getItem("user"))
}, [])

  //funcion para obtener STOCK POR SUCURSAL
  const [stockPorSucursal, setStockPorSucursal] = useState();

  const obtenerStockPorSucursal = () => {
    fetch(UrlApi + "/stock_inventarios/inventarioxsucursal") //cambiar fetch
      .then((data) => data.json())
      .then((data) => setStockPorSucursal(data));
  };

  //funcion que espera los cambios de la funcion de obtener familias
  useEffect(() => {
    obtenerStockPorSucursal();
  }, []);

  //funcion de actualizar la tabla
  useEffect(() => {
    if (update) obtenerStockPorSucursal();
  }, [update]);
 // console.log("/////////////////INVENTARIOS///////////", stockPorSucursal);
  //*************************************************************************************************************************************/
  //CSV
  const [stockGeneralCSV, setStockGeneralCSV] = useState();

  const obtenerStockGeneralCSV = () => {
    fetch(UrlApi + "/stock_inventarios/stockGeneralCSV/")
      .then((data) => data.json())
      .then((data) => setStockGeneralCSV(data));
  };
  //console.log("csv", stockGeneralCSV);
  // CSV
  useEffect(() => {
    obtenerStockGeneralCSV();
  }, [tabla]);
  //************************************************///   TABLA   ///**********************************************************************************/
  const columns = useMemo(
    () => [
      { header: "Artículo", accessorKey: "nombre_articulo", size: 150 },
      {
        header: "Sucursal",
        accessorKey: "nombre_sucursal",
        size: 150,
      },
      { header: "Almacén", accessorKey: "nombre_almacen", size: 100 },
      {
        header: "Cantidad",
        accessorKey: "cantidad",
        size: 100,
        aggregationFn: "sum", //show the max age in the group (lots of pre-built aggregationFns to choose from)
        AggregatedCell: ({ cell, table }) => (
          <>
            {" "}
            <Box sx={{ color: "black", display: "inline" }}>
              {cell.getValue()}
            </Box>
          </>
        ),
      },
      {
        header: "Unidad de Medida",
        accessorKey: "descripcion",
        size: 100,
        aggregationFn: "max", //show the max age in the group (lots of pre-built aggregationFns to choose from)
        //required to render an aggregated cell
        AggregatedCell: ({ cell, table }) => (
          <>
            {" "}
            <Box sx={{ color: "black", display: "inline" }}>
              {cell.getValue()}
            </Box>
          </>
        ),
      },
    ],
    []
  );

  const columnsCSV = useMemo(
    () => [
      { header: "Artículo", accessorKey: "nombre_articulo", size: 100 },
      {
        header: "Sucursal",
        accessorKey: "nombre_sucursal",
        size: 150,
      },
      { header: "Almacén", accessorKey: "nombre_almacen", size: 100 },
      { header: "Cantidad", accessorKey: "cantidad", size: 100 },
      { header: "Unidad de Medida", accessorKey: "descripcion", size: 100 },
      { header: "Usuario", accessorKey: "usuario", size: 100 },
    ],
    []
  );

  // ---------------------------------------------------------------//
  //CSV
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `Stock General `,
    headers: columnsCSV.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(stockGeneralCSV);
  };

  // -------------------------------------------------------------------- //

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };
  //*************************************************************************************************************************************/

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    grouping: ["nombre_articulo", "nombre_sucursal"],
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {stockPorSucursal && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableExpandAll={false}
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={stockPorSucursal} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                // enableEditing
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                   <Grid item xs={6} ml={2}>
                    <Button
                      type="primary"
                      variant="contained"
                      style={{ backgroundColor: blueGrey[700], color: "white" }}
                      onClick={handleExportData}
                      size="small"
                      endIcon={<FileDownloadIcon />}
                    >
                      Exportar a CSV
                    </Button>
                   </Grid>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Stock General
                    </Typography>
                  </>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>
    </div>
  );
}

export default Stock_por_sucursales;
